<div class="container sommaire-loyers" id="sommaires" *ngIf="data?.Loyers?.length !== 0 && data?.iMasquerLoyers !== 1">
    <h3 class="summary w-100 mb-3"><i class="fas fa-building"></i> {{ '@loyers.titre' | translate }}</h3>
    <div class="row marge justify-content-center">
        <div class="col-11 col-sm-11 col-lg-11">
            <div class="se-connecter text-center" *ngIf="!connected && !data?.fc">
                <p class="texte text-center">{{ '@loyers.pourVisionner' | translate }}</p>
                <button class="connect" *ngIf="!connected && !data?.fc" (click)="seConnecter()">{{'@se.connecter' | translate}}</button>
            </div>
            <div *ngIf="connected || data?.fc">
                <table class="table table-striped">
                    <thead>
                        <tr class="top-barre">
                            <th class="text-left" style="width: 100px">{{ '@loyers.colUnite' | translate }}</th>
                            <th class="text-center" style="width: 150px">{{ '@menu.terrain.superficie' | translate }}</th>
                            <th class="text-center" style="width: 120px">{{ '@loyers.colMontant' | translate }}</th>
                            <th class="text-center d-none d-lg-table-cell" style="width: 150px">{{ '@loyers.colEcheance' | translate }}</th>
                            <th class="text-center d-none d-lg-table-cell">{{ '@loyers.colRemarque' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let loyer of data?.Loyers">
                            <td>{{ loyer?.sIdentifiant }}</td>
                            <td class="text-center">{{ loyer?.iPiedsCarre }}</td>
                            <td class="text-center">{{ loyer?.iMontant | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            <td class="text-center d-none d-lg-table-cell">
                                <span [hidden]="loyer.iVacant == 1">{{ loyer?.dEcheance | echeanceloyers }}</span>
                                <span [hidden]="loyer.iVacant != 1">{{ '@loyers.echeance.vancant' | translate }}</span>
                            </td>
                            <td class=" d-none d-lg-table-cell">{{ loyer?.sCommentaires }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr *ngIf="statsLoyers.totalLoyersRes">
                            <th colspan="2">{{ '@loyers.totalLoyersRes' | translate }}</th>
                            <th class="text-center">{{statsLoyers.totalLoyersRes | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}}</th>
                            <th class=" d-none d-lg-table-cell"></th>
                            <th class=" d-none d-lg-table-cell"></th>
                        </tr>
                        <tr *ngIf="statsLoyers.totalLoyersComm">
                            <th colspan="2">{{ '@loyers.totalLoyersComm' | translate }}</th>
                            <th class="text-center">{{statsLoyers.totalLoyersComm | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}}</th>
                            <th class=" d-none d-lg-table-cell"></th>
                            <th class=" d-none d-lg-table-cell"></th>
                        </tr>
                        <!-- <tr *ngIf="statsLoyers.totalLoyersCommRecup">
                            <th colspan="2">{{ '@loyers.totalLoyersCommRecup' | translate }}</th>
                            <th class="text-center">{{statsLoyers.totalLoyersCommRecup | pmmlArgent}}</th>
                            <th class=" d-none d-lg-table-cell"></th>
                            <th class=" d-none d-lg-table-cell"></th>
                        </tr> -->
                        <tr *ngIf="statsLoyers.totalLoyersRang">
                            <th colspan="2">{{ '@loyers.totalLoyersRang' | translate }}</th>
                            <th class="text-center">{{statsLoyers.totalLoyersRang | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}}</th>
                            <th class=" d-none d-lg-table-cell"></th>
                            <th class=" d-none d-lg-table-cell"></th>
                        </tr>
                        <tr *ngIf="statsLoyers.totalSta">
                            <th colspan="2">{{ '@loyers.totalSta' | translate }}</th>
                            <th class="text-center">{{statsLoyers.totalSta | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}}</th>
                            <th class=" d-none d-lg-table-cell"></th>
                            <th class=" d-none d-lg-table-cell"></th>
                        </tr>
                        <tr *ngIf="statsLoyers.totalAutre">
                            <th colspan="2">{{ '@loyers.totalAutre' | translate }}</th>
                            <th class="text-center">{{statsLoyers.totalAutre | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}}</th>
                            <th class=" d-none d-lg-table-cell"></th>
                            <th class=" d-none d-lg-table-cell"></th>
                        </tr>
                        <tr *ngIf="data?.sTypeLogement !== 'iCommercial'">
                            <th colspan="2">{{ '@loyers.totalMensuel' | translate }}</th>
                            <th class="text-center">{{statsLoyers.totalMensuel | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}}</th>
                            <th class=" d-none d-lg-table-cell"></th>
                            <th class=" d-none d-lg-table-cell"></th>
                        </tr>
                        <tr>
                            <th colspan="2">{{ '@loyers.totalAnnuel' | translate }}</th>
                            <th class="text-center">{{statsLoyers.totalAnnuel | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}}</th>
                            <th class=" d-none d-lg-table-cell"></th>
                            <th class=" d-none d-lg-table-cell"></th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>