import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, Observable } from 'rxjs';

import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { environment } from "../../../../environments/environment";
import { GeneralService } from '../../../api/general.service';
import { AuthService } from './../../../auth.service';

import { ConnexionFavorisDialogComponent } from "../connexion-favoris-dialog/connexion-favoris-dialog.component";
const FILTRE_VENTE_LOCATION_TYPES = [ "iCommercial", "iIndustriel", "iBureaux" ];
const FILTRE_SUPPERFICIE_TYPES = [ "iCommercial", "iIndustriel", "iBureaux", "iSemiCommercial", "iTerrain" ];


@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.scss'],
  providers: []
})
export class RechercheComponent implements OnInit {
  @ViewChild('toggleFiltreRecherche') toggleButton: ElementRef;
  @ViewChild('btnFiltreRecherche') btnFiltreRecherche: ElementRef;

  // Filtre pour la recherche
  filtre = {
    sTypeLogement: 'defaut',
    nombresUnites: 'defaut',
    IDCategorie: null,
    q: null,
    superficieMin: null,
    superficieMax: null,
    regionSelectionne: null,
    venteLocation: undefined,
    favoris: null,
    nouveaute: undefined,
    mode: null,
  };

  choix: String = 'carte';
  choixFiltre: any;

  environment = environment;
  // Declaration de variables
  regions: any;
  secteurs: any;

  toogleMobile: any;
  toggleMoreOptions: any;

  // Affichage des inputs pour superficie ou unite
  afficheSuperficie: Boolean;
  afficheUnite: Boolean;
  afficherVenteLocation: Boolean;

  constructor(
    private auth: AuthService,
    private general: GeneralService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    // private renderer: Renderer2
  ) {}
  
  // Fermture des filtres quand click out
  // @HostListener('document:mousedown', ['$event'])
  // onGlobalClick(event): void {
  //   if (!this.elementRef.nativeElement.contains(event.target)) {
  //     this.filtreOuvert = false;
  //   }
  // }
  listings : any[] = [];

  @Input('type')
  public set type(type: string) {
    this.filtre.sTypeLogement = type;
  }
  @Input('venteLocation')
  public set venteLocation(venteLocation: string) {
    if(venteLocation === undefined) return;
    this.filtre.venteLocation = venteLocation + "";  
    this.filtrer();
  }
  @Input() courtier: any;
  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('barreRecherche') barreRecherche: ElementRef;
  @ViewChild('autoTrigger', { static: true }) autoTrigger: MatAutocompleteTrigger;
  @Output() choisi = new EventEmitter<any>();
  @Output() recherche = new EventEmitter<any>();
  @Output() activeLoader = new EventEmitter<boolean>();
  @Output() modeAffichage = new EventEmitter<any>();

  dialogRefFavorisTooltip;
  dialogRefConnexionFavoris;
// Form Control : ChampRecherche, dropwDown Regions
  campRecherche = new FormControl();
  dropwdownEndroit = new FormControl();

  optionsRecherche: string[] = [];
  optionsRechercheFiltres: Observable<string[]>;
  queryUrl: any;

  ngOnInit(){
    this.queryUrl = window.location.href.match(/(affichage|modeaffichage)=(\w{1,7})/);

    if(this.queryUrl) {
      this.choixFiltre = this.queryUrl[2];
      this.afficherMode(this.choixFiltre);
    } else this.choixFiltre = 'carte'

    if(this.courtier && this.courtier !== undefined) {
      this.choixFiltre = 'propsCourtier';
      // this.afficherMode(this.choixFiltre);
    }
    
    this.filtrageSuperficieUnite();
    this.toggleMoreOptions = false;
    // this.getSecteurs();
    this.getRegions();
    // this.listeRegionsAdmin();

    this.optionsRechercheFiltres = this.campRecherche.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filtrerAC(value))
    );
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    // Debounce time dans la barre de recherche
    if (!environment?.masquerBarreRecherche) {
      if (this.barreRecherche?.nativeElement) {
        fromEvent(this.barreRecherche?.nativeElement, 'keyup').pipe(
          map((event: any) => {
            return [event.target.value, event.which];
          })
          , filter(([texte]) => texte.length > 2 || texte.length === 0), debounceTime(1200), distinctUntilChanged())
          .subscribe(([text, key]) => {
            this.filtrer(key == 13);
          });
      }
    }
  }

  private _filtrerAC(value: string): string[] {
    const filterValue = (value || '').toLowerCase();
    return this.optionsRecherche.filter(option => option.toLowerCase().includes(filterValue)).slice(0, 6);
  }

  // API GET
  async getRegions() {
    this.regions = await this.general.listeRegions();
    this.optionsRecherche.push(...this.regions.map((region) => region.sNomSecteur));
  }

  // TODO : Mettre la liste des regions
  // TODO : Augementer le Zoom

  // POUR AJOUTER LISTE DE REGIONS ADMIN AU CHOIX DE DROPDOWN - RECHERCHE PROPRIETE
  // regionsAdmin = [];
  // async listeRegionsAdmin() {
  //   this.regionsAdmin = await this.general?.listeRegionsAdmin();
  //   this.optionsRecherche.push(...this.regionsAdmin?.map((regionAdmin) => regionAdmin.sNomRegion));
  // }

  // async getSecteurs(){
  //   this.secteurs = await this.general?.listeSecteurs();
  //   this.optionsRecherche.push(...this.secteurs?.map((secteur) => secteur.sNomVille));
  // }

  filtrerType() {
    if (this.filtre.sTypeLogement == 'nouveaute') {
      this.filtre.sTypeLogement = undefined;
      this.filtre.nouveaute = 1;
    }
    this.filtre.venteLocation = undefined;
    this.filtrer();
  }

  reactionDeconnecte(){
    // Pop up pour la connexion : Utilisation de l'onglet favoris
    if (!this.estConnecte()) {
      let target: any;
      
      target = new ElementRef("");
      // target = new ElementRef(event.currentTarget);
      this.dialogRefConnexionFavoris = this.dialog.open(ConnexionFavorisDialogComponent, {
        hasBackdrop: false,
        data: { trigger: target }
      });
    } else {
      this.filtrer();
    }
  }

  nouveaute: Number = 0;
  ancienTypeLogement = 'tout';
  firstFiltrer = true;
  // Filtreur pour 'Trouver'
  filtrer(perdreFocus = false) {
    // Reset des filtres si sTypeLogement modifié
    if (this.filtre.sTypeLogement !== this.ancienTypeLogement && !this.firstFiltrer) {
      this.filtre.nombresUnites = null;
      this.filtre.superficieMin = null;
      this.filtre.superficieMax = null;
      this.filtre.venteLocation = null;
    }
    this.firstFiltrer = false;

    let filtres: any = Object.assign({}, this.filtre);

    if(perdreFocus) {
      this.container?.nativeElement?.focus();
      this.autoTrigger?.closePanel();
    }

    let _neLng: any, _swLng: any, _neLat: any, _swLat: any;
    this.route.queryParams
      .subscribe((params) => {
        _swLng = params.long1
        _neLng = params.long2
        _swLat = params.lat1
        _neLat = params.lat2
      });

    if (_neLng && _swLat) Object.assign(filtres, { _swLng, _neLng, _swLat, _neLat });

    for (const champ in filtres) {
      if(filtres[champ] == null) delete filtres[champ];
      if(!filtres[champ]) delete filtres[champ];
      if(filtres[champ] == 'defaut') delete filtres[champ];
    }

    if(filtres.favoris) {
      const favorisBool = filtres.favoris;
      delete filtres.favoris;
      filtres.favoris = favorisBool ? "1" : "0";
      // Redirection vers l'onglet mode liste lorsque le bouton favoris est cliqué en map   
      if (this.choixFiltre == "carte") this.choisi.emit('tableau');
      this.choixFiltre = 'tableau';
    }

    if(filtres.nombresUnites) {
      let [iUnitesMin, iUnitesMax] = filtres.nombresUnites.split('-');
      delete filtres.nombresUnites;
      filtres.iUnitesMin = iUnitesMin;
      filtres.iUnitesMax = iUnitesMax;
    }
    filtres.mode = this.choixFiltre;
    
    this.filtrageSuperficieUnite();
    this.ancienTypeLogement = filtres.sTypeLogement;
    this.recherche.emit(filtres);
    this.activeLoader.emit();
  }

  effacerAC() {
    this.filtre.q = null;
    this.filtrer();
  }

  afficherMode(mode: any) {   
    this.choisi.emit(mode);
    this.choixFiltre = mode;
    
    this.filtre = {
      sTypeLogement: this.filtre?.sTypeLogement,
      nombresUnites: this.filtre?.nombresUnites,
      IDCategorie: this.filtre?.IDCategorie,
      q: this.filtre?.q,
      superficieMin: this.filtre?.superficieMin,
      superficieMax: this.filtre?.superficieMax,
      regionSelectionne: null,
      venteLocation: this.filtre.venteLocation,
      favoris: null,
      nouveaute: undefined,
      mode,
    };
    this.filtrer()
  }

  filtrageSuperficieUnite(){
    // Désactive les champs si aucun type est choisi
    this.afficheSuperficie = FILTRE_SUPPERFICIE_TYPES.includes(this.filtre.sTypeLogement);
    this.afficheUnite = !this.afficheSuperficie;
    this.afficherVenteLocation = FILTRE_VENTE_LOCATION_TYPES.includes(this.filtre.sTypeLogement);
  }

  estConnecte() {
    return this.auth.token ? true : false;
  }

  filtreOuvert: Boolean = false;
  ouvertureFiltre() {
    this.filtreOuvert = !this.filtreOuvert;
  }
}
