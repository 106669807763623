<div class="descriptif-section d-flex align-items-center justify-content-center">
    <div class="containeur w-100">
        <div class="row mt-5">
            <div class="titre-section w-100" style="text-align: center">
                <h2>{{ '@conditions.location' | translate }}</h2>
                <h3>{{ '@terme.bail' | translate }}</h3>
            </div>
        </div>
        <div class="row marge">
            <div class="col-xl-6 secr d-flex flex-column align-items-end justify-content-start" >
                <div class="general w-100">
                    <h3>5 {{ '@ans' | translate }}</h3>
                    <table class="ordinateur" style="width: 100%">
                        <tr>
                          <td class="table-title">#</td>
                          <td class="table-value">{{ data?.sJsonData?.Bail1?.fr?.Numero | translateValeur : data?.sJsonData?.Bail1?.an?.Numero }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@superficie.disponible.sqft' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.Bail1?.fr?.Superficie | translateValeur : data?.sJsonData?.Bail1?.an?.Superficie }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@etage' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.Bail1.fr?.Etage | translateValeur : data?.sJsonData?.Bail1?.an?.Etage }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">Allocation ($ / {{ '@pi2' | translate }})</td>
                            <td class="table-value">{{ data?.sJsonData?.Bail1?.fr?.Allocation | translateValeur : data?.sJsonData?.Bail1?.an?.Allocation }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@loyer.additionnel.estime' }} ($/ {{ '@pi2' | translate }})</td>
                            <td class="table-value">{{ data?.sJsonData?.Bail1?.fr?.LoyerAditionel | translateValeur : data?.sJsonData?.Bail1?.an?.LoyerAditionel }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@disponibilite' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.Bail1?.fr?.Disponibilite | translateValeur : data?.sJsonData?.Bail1?.Disponibilite }}</td>
                        </tr>
                    </table>
                    <table class="mobile" style="width: 100%">
                        <tr class="m-tr-titre">
                          <td class="m-td-titre">#</td>
                        </tr>
                        <tr class="m-tr-valeur">
                          <td class="m-td-valeur">{{ data?.sJsonData?.Bail1?.fr?.Numero | translateValeur : data?.sJsonData?.an?.Numero }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@superficie.disponible.sqft' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.Bail1?.fr?.Superficie | translateValeur : data?.sJsonData?.Bail1?.an?.Superficie }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@etage' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.Bail1.fr?.Etage | translateValeur : data?.sJsonData?.Bail1?.an?.Etage }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">Allocation ($ / {{ '@pi2' | translate }})</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.Bail1?.fr?.Allocation | translateValeur : data?.sJsonData?.Bail1?.an?.Allocation }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@loyer.additionnel.estime' }} ($/ {{ '@pi2' | translate }})</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.Bail1?.fr?.LoyerAditionel | translateValeur : data?.sJsonData?.Bail1?.an?.LoyerAditionel }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@disponibilite' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.Bail1?.fr?.Disponibilite | translateValeur : data?.sJsonData?.Bail1?.Disponibilite }}</td>
                        </tr>
                    </table>
                </div>

            </div>

            <div class="col-xl-6 secl d-flex flex-column align-items-start justify-content-start" >
                <div class="evaluation-municipale w-100 ">
                    <h3>10 {{ '@ans' | translate }}</h3>
                    <table class="ordinateur" style="width: 100%">
                        <tr>
                          <td class="table-title">#</td>
                          <td class="table-value">{{ data?.sJsonData?.Bail2?.fr?.Numero | translateValeur : data?.sJsonData?.Bail2?.an?.Numero }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@superficie.disponible.sqft' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.Bail2?.fr?.Superficie | translateValeur : data?.sJsonData?.Bail2?.an?.Superficie }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@etage' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.Bail2.fr?.Etage | translateValeur : data?.sJsonData?.Bail2?.an?.Etage }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">Allocation ($ / {{ '@pi2' | translate }})</td>
                            <td class="table-value">{{ data?.sJsonData?.Bail2?.fr?.Allocation | translateValeur : data?.sJsonData?.Bail2?.an?.Allocation }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@loyer.additionnel.estime' }} ($/ {{ '@pi2' | translate }})</td>
                            <td class="table-value">{{ data?.sJsonData?.Bail2?.fr?.LoyerAditionel | translateValeur : data?.sJsonData?.Bail2?.an?.LoyerAditionel }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@disponibilite' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.Bail2?.fr?.Disponibilite | translateValeur : data?.sJsonData?.Bail2?.Disponibilite }}</td>
                        </tr>
                    </table>
                    <table class="mobile" style="width: 100%">
                        <tr class="m-tr-titre">
                          <td class="m-td-titre">#</td>
                        </tr>
                        <tr class="m-tr-valeur">
                          <td class="m-td-valeur">{{ data?.sJsonData?.Bail2?.fr?.Numero | translateValeur : data?.sJsonData?.Bail2?.an?.Numero }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@superficie.disponible.sqft' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.Bail2?.fr?.Superficie | translateValeur : data?.sJsonData?.Bail2?.an?.Superficie }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@etage' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.Bail2.fr?.Etage | translateValeur : data?.sJsonData?.Bail2?.an?.Etage }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">Allocation ($ / {{ '@pi2' | translate }})</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.Bail2?.fr?.Allocation | translateValeur : data?.sJsonData?.Bail2?.an?.Allocation }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@loyer.additionnel.estime' }} ($/ {{ '@pi2' | translate }})</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.Bail2?.fr?.LoyerAditionel | translateValeur : data?.sJsonData?.Bail2?.an?.LoyerAditionel }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@disponibilite' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.Bail2?.fr?.Disponibilite | translateValeur : data?.sJsonData?.Bail2?.Disponibilite }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
