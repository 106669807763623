import { Component, Input, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(...registerables, ChartDataLabels);

@Component({
  selector: 'app-stats-locatifs',
  templateUrl: './stats-locatifs.component.html',
  styleUrls: ['./stats-locatifs.component.scss']
})
export class StatsLocatifsComponent implements OnInit {

  @Input() data;

  constructor() { }

  ngOnInit(): void {
    // Donut Type de logement
    let pieDonneesChiffres = this.pieTypeLogement()
    new Chart((<any>document).getElementById('typeLogementDonut').getContext('2d'), {
      type: 'doughnut',
      data: {
        labels: ["Studio", "3 ½", "4 ½", "5 ½"],
        datasets: [{
          label: "Population (millions)",
          data: pieDonneesChiffres[1],
          backgroundColor: ["#a7aeb5", "#7393b3", "#b8cadb", "#90c4f5", "#6b7d8f"],
          datalabels: {
            align: 'end',
            anchor: 'end',
            borderRadius: 5,
            borderWidth: 1,
            offset: 2,
            color: 'black',
            font: {
              size: 13,
              weight: 'normal'
            },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex] + ': ' + value.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + '%';
            }
          }
        }]
      },
      options: {
        events: [],
        hover: { mode: null },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 40
        },
        plugins: {

          legend: {
            display: false,
            position: 'bottom',
          },
        }
      },
    });

    // Graph linéaire - Distribution des loyers
    this.distributionLoyers();
    let clocheMontantLabels = [`600 $`, `700 $`, `800 $`, `900 $`, `1 000 $`, `1 100 $`, `1 200$`, `1 300$`, `1 400$`, `1 500$`, `1 600$`];
    // Barre chart : Montant de loyer
    new Chart((<any>document).getElementById('clocheMontantLoyer').getContext('2d'), {
      type: 'line',
      data: {
        labels: clocheMontantLabels,
        datasets: [
          {
            fill: true,
            backgroundColor: '#759cc925',
            data: this.distributionLoyers(),
            borderColor: 'rgb(117, 156, 201)',
            borderWidth: 3,
            tension: 0.5,
            pointRadius: 0
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            grid: {
              display: true
            }
          },
          y: {
            display: false,
            grid: {
              display: false
            },
            ticks: {
              display: true,
            },
          }
        }
      }
    });

    // Graph à barre horizontale - Loyer moyen par année
    let loyerAnneeFormate = this.loyerMoyenParAnnee()
    let typesTableaux = [{ type: 'Studio', donnees: loyerAnneeFormate[0] },
    { type: 'TroisDemi', donnees: loyerAnneeFormate[1] },
    { type: 'QuatreDemi', donnees: loyerAnneeFormate[2] },
    { type: 'CinqDemi', donnees: loyerAnneeFormate[3] }];

    for (let donnee of typesTableaux) {
      new Chart((<any>document).getElementById(`horizontaleAnneeLoyerMoyen${donnee.type}`), {
        type: 'bar',
        data: {
          labels: ["< 1990", "1991 - 2010", "> 2010"],
          datasets: [
            {
              label: "Population (millions)",
              backgroundColor: '#7393b3',
              data: donnee.donnees,
              datalabels: {
                align: 'end',
                anchor: 'end',
                // borderRadius: false,
                borderWidth: 1,
                offset: 1,
                color: 'black',
                font: {
                  size: 11,
                },
                formatter: function (value, context) {
                  return value.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' $';
                }
              }
            }
          ]
        },
        options: {
          events: [],
          indexAxis: 'y',
          layout: {
            padding: 23
          },
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              display: false,
              // scaleLabel: {
              //   display: false
              // },
              grid: {
                display: false,
                borderWidth: 1
              }
            },
            y: {
              display: true,
              // scaleLabel: {
              //   display: true
              // },
              grid: {
                display: false
              },
              ticks: {
                display: true,
                // min: 200,
                stepSize: 300
              },
            }
          },
        },
        plugins: [ChartDataLabels],
      });
    }
  }

  /**
   * Manipulation de données pour le graphique : Type Logement
   */
  pieTypeLogement() {
    try {
      let donneesGraphPie = [[], []];
      let labels = ["Studio", "3 ½", "4 ½", "5 ½"];

      let i = 0;
      for (let donnee of this.data.sJsonData.StatsLocatives.parType ) {
        if (labels[i] == donnee.TailleUnites) {
          // Si est NaN ou Vide, supprime le label
          if (!donnee.Compteur) continue;
          else {
            donneesGraphPie[0].push(labels[i]);
            donneesGraphPie[1].push(parseFloat(((+donnee.Compteur / +this.data.sJsonData.StatsLocatives.total.Compteur) * 100).toFixed(1)));
          }
        }
        i++;
      }

      return donneesGraphPie;
    } catch (error) {
      console.error(error);
    }
  }

  /**
  * Manipulation de données pour le graphique : Distribution des loyers
  */
  distributionLoyers() {
    try {
      let donneesGraphCloche = [];
      let labels = [600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600];

      for (let donnee of this.data.sJsonData.StatsLocatives.segMontants.rows) {
        if (+donnee.Segment >= 600 && +donnee.Segment <= 1600) {
          for (let label of labels) {
            if (label == +donnee.Segment) {
              donneesGraphCloche.push(+donnee.Nombre);
            }
          }
        }
      }

      return donneesGraphCloche;
    } catch (error) {
      console.error(error);
    }
  }

  loyerMoyenParAnnee() {
    try {
      // 0 : Studio, 1 : Trois & Demi, 2 : Quatre & Demi, 3 : Cinq & Demi
      let donneesGraphique = [[], [], [], []];

      for (let donnee of this.data.sJsonData.StatsLocatives.segAnnees.rows) {
        if (+donnee.iNbChambresStd == 1) donneesGraphique[0].push(+donnee.Moyenne);
        else if (+donnee.iNbChambresStd == 3) donneesGraphique[1].push(+donnee.Moyenne);
        else if (+donnee.iNbChambresStd == 4) donneesGraphique[2].push(+donnee.Moyenne);
        else if (+donnee.iNbChambresStd == 5) donneesGraphique[3].push(+donnee.Moyenne);
      }

      // if (donneesRacine) {
      //   if (donneesGraphique[0].length !== 0) donneesRacine.graphAnneeStudio = true;
      //   if (donneesGraphique[1].length !== 0) donneesRacine.graphAnneeTroisDemi = true;
      //   if (donneesGraphique[2].length !== 0) donneesRacine.graphAnneeQuatreDemi = true;
      //   if (donneesGraphique[3].length !== 0) donneesRacine.graphAnneeCinqDemi = true;
      //   // donneesGraphique = donneesGraphique.filter(e => e.length);
      //   return donneesRacine;
      // }

      return donneesGraphique;
    } catch (error) {
      console.error(error);
    }
  }


}
