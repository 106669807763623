import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlCarte'
})
export class UrlCartePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if(!value) return null;

    return String(value || '').replace(/^https?\:\/\/(www\.)?(pmml\.ca|patricemenard\.com)\/img\//gi, 'https://img.pmml.ca/')
  }
}
