import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-favoris-informations',
  templateUrl: './favoris-informations.component.html',
  styleUrls: ['./favoris-informations.component.scss']
})
export class FavorisInformationsComponent implements OnInit {

  private readonly matDialogRef: MatDialogRef<FavorisInformationsComponent>;
  private readonly triggerElementRef;
  constructor(
    matDialogRef: MatDialogRef<FavorisInformationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { element, type }
  ) {
    this.matDialogRef = matDialogRef;
    this.triggerElementRef = data.element;
  }

  dialogWidth: number = 350;
  dialogHeight: number = 150;

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.triggerElementRef.getBoundingClientRect();
    // Modifie la position lorsque l'étoile est sur le menu d'une FD
    if (this.data.type == "menu") {
      matDialogConfig.position = { left: `${rect.left - this.dialogWidth + 380}px`, top: `${rect.bottom}px` };
    } else {
      // Position pour le listing des propriétés
      matDialogConfig.position = { left: `${rect.left - this.dialogWidth + 40}px`, top: `${rect.bottom}px` };
    }
    matDialogConfig.width = `${this.dialogWidth}px`;
    matDialogConfig.height = `${this.dialogHeight}px`;
    this.matDialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
    this.matDialogRef.updatePosition(matDialogConfig.position);
  }
}