<div class="background" style="background-image: url('http://localhost:8888/wp-content/uploads/2020/05/background.png');">
    <section class="overlay h-100 w-100 d-flex justify-content-center align-items-center">
        <div class="containeur">
            <div class="section">
                <div class="titre">
                    <h2>{{ '@acces' | translate }}</h2>
                </div>
                <div class="texte">
                    <p>{{ data?.sJsonData?.fr?.Acces | translateValeur : data?.sJsonData?.an?.Acces }}</p>
                </div>
            </div>
            <div class="section">
                <div class="titre d-flex justify-content-center align-items-center">
                    <span class="gras">{{ '@transports' | translate }}</span>
                </div>
                <div class="texte d-flex justify-content-center align-items-center">
                    <p class="w-75">{{ data?.sJsonData?.fr?.TransportsEnCommun | translateValeur : data?.sJsonData?.an?.TransportsEnCommun }}</p>
                </div>
            </div>
            <div class="section">
                <div class="titre d-flex justify-content-center align-items-center">
                    <span class="gras">{{ '@rangement.velos' | translate }}</span>
                </div>
                <div class="texte d-flex justify-content-center align-items-center">
                    <p class="w-75">{{ data?.sJsonData?.fr?.RangementVelo | translateValeur : data?.sJsonData?.an?.RangementVelo }}</p>
                </div>
            </div>
            <div class="row" style="border-bottom: 1px solid white;">
                <div class="col-xl-6">
                    <div class="section">
                        <div class="titre d-flex justify-content-center align-items-center">
                            <span class="gras">{{ '@stationnement.interieur' | translate }}</span>
                        </div>
                        <div class="texte d-flex justify-content-center align-items-center">
                            <p class="w-75">{{ data?.sJsonData?.fr?.StationnementInterieur | translateValeur : data?.sJsonData?.an?.StationnementExterieur }}</p>
                        </div>
                    </div>
                    <div class="section">
                        <div class="titre d-flex justify-content-center align-items-center">
                            <span class="gras">{{ '@stationnement.exterieur' | translate }}</span>
                        </div>
                        <div class="texte d-flex justify-content-center align-items-center">
                            <p class="w-75">{{ data?.sJsonData?.fr?.StationnementExterieur | translateValeur : data?.sJsonData?.an?.StationnementExterieur }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="section">
                        <div class="titre d-flex justify-content-center align-items-center">
                            <span class="gras">{{ '@prix.stationnement.reserve' | translate }}</span>
                        </div>
                        <div class="texte d-flex justify-content-center align-items-center">
                            <p class="w-75">{{ data?.sJsonData?.fr?.PrixStationnementReserve | translateValeur : data?.sJsonData?.an?.PrixStationnementReserve }}</p>
                        </div>
                    </div>
                    <div class="section">
                        <div class="titre d-flex justify-content-center align-items-center">
                            <span class="gras">{{ '@prix.stationnement.nonreserve' | translate }}</span>
                        </div>
                        <div class="texte d-flex justify-content-center align-items-center">
                            <p class="w-75">{{ data?.sJsonData?.fr?.PrixStationnementNonReserve | translateValeur : data?.sJsonData?.an?.PrixStationnementNonReserve }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="zonage">
                <div class="section">
                    <div class="titre d-flex justify-content-center align-items-center">
                        <h2>{{ '@zonage' | translate }}</h2>
                    </div>
                    <div class="texte d-flex justify-content-center align-items-center">
                        <span>
                            {{ data?.sJsonData?.fr?.Zonage | translateValeur : data?.sJsonData?.an?.Zonage }}
                        </span>
                    </div>
                </div>
                <div class="section">
                    <div class="titre d-flex justify-content-center align-items-center">
                        <span class="gras">{{ '@opportunite.signalisation' | translate }}</span>
                    </div>
                    <div class="texte d-flex justify-content-center align-items-center">
                        <p class="w-75">{{ data?.sJsonData?.fr?.PossibiliteSignalisation | translateValeur : data?.sJsonData?.an?.PossibiliteSignalisation }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>