import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AuthService } from 'src/app/auth.service';
import { FavorisInformationsComponent } from '../../liste-priorites/favoris-informations/favoris-informations.component';
import { ConnexionFavorisDialogComponent } from '../../liste-priorites/connexion-favoris-dialog/connexion-favoris-dialog.component';
import { ProprietesService } from './../../../api/proprietes.service';
import { environment } from '../../../../environments/environment'

// TODO: Lyes - Faire le ménage de ce component

@Component({
  selector: 'app-info-courtier-immeuble',
  templateUrl: './info-courtier-immeuble.component.html',
  styleUrls: ['./info-courtier-immeuble.component.scss']
})
export class InfoCourtierImmeubleComponent implements OnInit {
  environment = environment;
  sLien: string;
  jwt = '';
  shouldForceMobile: boolean;
  dialogRefFavorisTooltip;
  dialogRefConnexionFavoris;
  private dialogAlreadyOpen: boolean = false;

  constructor(private proprietes: ProprietesService, private auth: AuthService, public dialog: MatDialog) {
    this.setScrolled();
    this.sLien = String(window.location.pathname).replace(/\/$/, '').split('/').pop();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.shouldForceMobile = window.innerWidth < 1220 ? true : false;
  }

  scrolled = false;
  @HostListener('document:scroll', ['$event']) private scroll(ev: Event) {
    setTimeout(() => {
      this.setScrolled();
    }, 100);
  }

  @Input('compacte') compacte: any;
  @Input('data') data: any;
  @Input('fiche-locaux') ficheLocaux: string;

  ngOnInit() {
    this.shouldForceMobile = window.innerWidth < 1220 ? true : false;

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('a')) {
      this.jwt = `?a=${urlParams.get('a')}`;
    }
  }

  setScrolled() {
    if (window.document.getElementsByTagName('html')[0].classList.contains('cdk-global-scrollblock')) return;
    this.scrolled = window.scrollY != 0;
  }

  hideFavorisTooltip() {
    if (this.dialogRefConnexionFavoris && !this.estConnecte()) this.dialogRefFavorisTooltip.close();
    this.dialogRefFavorisTooltip?.close();
  }

  showFavorisTooltip(event) {
    // N'affiche pas le toggle d'information(MouseOver) en mobile
    if (!this.shouldForceMobile) {
      const x = event.clientX,
        y = event.clientY,
        elementMouseIsHover = document.elementFromPoint(x, y);

      // if (this.estConnecte()) return;
      // if (propriete.fav) return;
      this.dialogRefFavorisTooltip = this.dialog.open(FavorisInformationsComponent, {
        hasBackdrop: false,
        data: { element: elementMouseIsHover, type: 'menu' }
      });
    }
  }

  ajouterFavoris(propriete, event) {
    if (this.estConnecte()) {
      if (!propriete.fav) {
        this.hideFavorisTooltip();
        propriete.fav = true;
        this.proprietes.setFavori(propriete.sLien, true);
      } else {
        this.hideFavorisTooltip();
        propriete.fav = false;
        this.proprietes.setFavori(propriete.sLien, false);
      }
    } else {
      const target = new ElementRef(event.currentTarget);
      const dialogRef = this.dialog.open(ConnexionFavorisDialogComponent, {
        hasBackdrop: false,
        data: { trigger: target, type: 'menu' }
      });
    }
  }

  estConnecte() {
    return this.auth.token ? true : false;
  }
}
