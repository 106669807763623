<!-- Menu de navigation -->
<div class="container" [ngClass]="{ 'overflowDesactive': choixFiltre === 'carte'}" >
    <div class="row" [ngClass]="{ 'pt-3': choixFiltre !== 'carte' }">
        <div class="col-8">
            <div class="row pl-3" [ngClass]="{'filtreFerme': ( !filtreOuvert && choixFiltre == 'carte' ), 'filtreOuvert' : ( filtreOuvert && choixFiltre == 'carte' )}">
                    <a *ngIf="environment.modeTuiles" [ngClass]="{'boutonChoisi': choixFiltre == 'tuiles'}" (click)="afficherMode('tuiles')" class="buttonChoix ml-0 mr-2" ><i class="fas fa-th-large iconeTypeDonnees"></i><span class="ordinateur"> {{ '@listing.tuiles' | translate }}</span></a>
                    <a *ngIf="environment.modeCarte" [ngClass]="{'boutonChoisi': choixFiltre == 'carte'}" (click)="afficherMode('carte')" class="buttonChoix ml-0 mr-2"><i class="fal fa-map-marked-alt iconeTypeDonnees"></i><span class="ordinateur"> {{ '@listing.carte' | translate }}</span></a>
                    <a *ngIf="environment.modeListe"  [ngClass]="{'boutonChoisi': choixFiltre == 'tableau'}" (click)="afficherMode('tableau')" class="buttonChoix ml-0" ><i class="fas fa-bars iconeTypeDonnees"></i><span class="ordinateur"> {{ '@listing.tableau' | translate }}</span></a>

                    <a *ngIf="courtier" [ngClass]="{'boutonChoisi': choixFiltre == 'propsCourtier'}"
                        (click)="afficherMode('propsCourtier')" class="buttonChoixCarteAffaire ml-0 mr-2">
                        <span class="ordinateur"><i class="fas fa-user-tag"></i>&nbsp;</span><span style="overflow-wrap: break-word;">{{ '@proprietescourtier' | translate }}</span>
                    </a>
                    <a *ngIf="courtier" [ngClass]="{'boutonChoisi': choixFiltre == 'propsPMML'}"
                        (click)="afficherMode('propsPMML')" class="buttonChoixCarteAffaire ml-0">
                        <span class="ordinateur"><i class="fas fa-tags"></i>&nbsp;</span><span style="overflow-wrap: break-word;">{{ '@proprietespmml' | translate }}</span>
                    </a>

                <div class="col-auto pt-0 mt-0 pr-3 custom-fa filtre-favoris ml-auto d-none d-md-block">
                    <label for="filtre-favoris" class="mt-0" *ngIf="environment.masquerBarreRecherche">
                        <input *ngIf="estConnecte()" id="filtre-favoris" name="filtre-favoris" [(ngModel)]="filtre.favoris" (change)="filtrer()" type="checkbox">
                        <input *ngIf="!estConnecte()" id="filtre-favoris" name="filtre-favoris" type="checkbox" (click)="reactionDeconnecte()">
                        <i class="far fa-star pt-2 etoileDeconnecte" *ngIf="!estConnecte()" ></i>
                        <span *ngIf="estConnecte()" class="ne-pas-supprimer-ce-span-vide"></span>
                        <span class="filtre-texte"> {{ '@mesfavoris' | translate }}</span>
                    </label>
                </div>
            </div>
        </div>
        <!-- Mobile -->
        <div class="col-4 pl-4 d-flex pr-0 d-md-none" #btnFiltreRecherche *ngIf="!environment.masquerBarreRecherche">
            <div class="col-6" [ngClass]="{'filtreMobile': ( !filtreOuvert && choixFiltre == 'carte' ), 'filtreMobileOuvert' : ( filtreOuvert && choixFiltre == 'carte' )}">
                <button mat-raised-button class="pt-2 pb-2" (click)="ouvertureFiltre()">            
                    <i class="fal fa-search iconeFiltreRecherche" *ngIf="!filtreOuvert"></i>
                    <i class="fas fa-search iconeFiltreRecherche" *ngIf="filtreOuvert"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Filtre favoris PC -->
<div class="container" #container tabindex="-1" *ngIf="!environment.masquerBarreRecherche">
    <div class="row justify-content-center align-items-center ml-0 mr-0">
        <!-- Barre de recherche ORDINATEUR -->
        <div class="col pl-0 pr-0 pt-3 ordinateur">
            <div class="row" style="margin-left: -.5rem!important; margin-right: -.5rem!important;">
                <!-- Champ Recherche -->
                <div class="col pl-2 pr-2 acRecherche">
                    <mat-form-field appearance="fill" [style.width]="'100%'" (keyup.enter)="filtrer()">
                        <mat-label>{{ '@listeProprietes.rechercheDescriptif' | translate }}</mat-label>
                        <input matInput [(ngModel)]="filtre.q" name="q" [formControl]="campRecherche" #barreRecherche
                            [matAutocomplete]="auto" #autoTrigger="matAutocompleteTrigger">
                        <div class="btnEffacer">
                            <div class="fas fa-times" (click)="effacerAC()"></div>
                        </div>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="filtrer(true)">
                            <mat-option *ngFor="let option of optionsRechercheFiltres | async" [value]="option">{{option}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <!-- Type d'Immeuble -->
                <div class="col-auto pl-2 pr-2" *ngIf="!environment.masquerChoixListing">
                    <mat-form-field style="width: 190px;" appearance="fill">
                        <mat-label>{{ '@type.logements' | translate }}</mat-label>
                        <mat-select [(ngModel)]="filtre.sTypeLogement" name="type-propriete" id="type-propriete"
                            (selectionChange)="filtrerType()">
                            <mat-option value="tout">{{ '@propriete.type.tout' | translate }}</mat-option>
                            <mat-option *ngFor="let type of environment.typeProprietes" [value]="type.id"> {{ type.nom | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Type vente ou location -->
                <div *ngIf="!environment.masquerChoixListing && !environment.masquerVenteLocation" class="col-auto pl-2 pr-2 rm-min-width">
                    <mat-form-field style="width: 150px;" appearance="fill">
                        <mat-label>{{ '@type.venteLocation' | translate }}</mat-label>
                        <mat-select [(ngModel)]="filtre.venteLocation" name="vente-location" id="vente-location"
                            (selectionChange)="filtrer()">
                            <mat-option>{{ '@propriete.venteEtLocation' | translate }}</mat-option>
                            <mat-option value="0">{{ '@propriete.vente' | translate }}</mat-option>
                            <mat-option value="1">{{ '@propriete.location' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Commercial | Vente ou Location -->
                <!-- <div class="col-auto pl-2 pr-2" *ngIf="afficherVenteLocation">
                    <mat-form-field style="width: 150px;" appearance="fill">
                        <mat-label>{{ '@type.venteLocation' | translate }}</mat-label>
                        <mat-select [(ngModel)]="filtre.venteLocation" name="vente-location" id="vente-location"
                            (selectionChange)="filtrer()">
                            <mat-option>{{ '@propriete.venteEtLocation' | translate }}</mat-option>
                            <mat-option value="0">{{ '@propriete.vente' | translate }}</mat-option>
                            <mat-option value="1">{{ '@propriete.location' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->

                <!-- Nombre Unites -->
                <div class="col-auto pl-2 pr-2" *ngIf="afficheUnite && !environment.masquerNombreUnite">
                    <mat-form-field style="width: 165px;" appearance="fill">
                        <mat-label>{{ '@nombre.unites' | translate }}</mat-label>
                        <mat-select [(ngModel)]="filtre.nombresUnites" name="nombreUnites" id="nombreUnites"
                            (selectionChange)="filtrer()">
                            <mat-option value="tout">{{ '@listeProprietes.typeUnite.tout' | translate }}</mat-option>
                            <mat-option value="2-2">{{'@propriete.type.duplex'|translate}}</mat-option>
                            <mat-option value="3-3">{{'@propriete.type.triplex'|translate}}</mat-option>
                            <mat-option value="4-4">{{'@propriete.type.quadruplex'|translate}}</mat-option>
                            <mat-option value="5-11">5 - 11 {{ '@unites' | translate }}</mat-option>
                            <mat-option value="12-24">12 - 24 {{ '@unites' | translate }}</mat-option>
                            <mat-option value="25-49">25 - 49 {{ '@unites' | translate }}</mat-option>
                            <mat-option value="50-">50 {{ '@unites' | translate }} et +</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Superficie -->
                <div class="col-auto pl-2 pr-2" *ngIf="afficheSuperficie">
                    <mat-form-field style="width: 100px;" appearance="fill" (change)="filtrer()">
                        <mat-label>{{ 'superficie.min' | translate }}</mat-label>
                        <input [(ngModel)]="filtre.superficieMin" name="superficieMin" matInput>
                    </mat-form-field>
                </div>
                <div class="col-auto pl-2 pr-2" *ngIf="afficheSuperficie">
                    <mat-form-field style="width: 100px;" appearance="fill" *ngIf="afficheSuperficie" (change)="filtrer()">
                        <mat-label>{{ 'superficie.max' | translate }}</mat-label>
                        <input [(ngModel)]="filtre.superficieMax" name="superficieMax" matInput>
                    </mat-form-field>
                </div>

                <!-- Filtre favoris -->
                <div class="col-auto pt-0 mt-0 pl-2 pr-2 custom-fa filtre-favoris" >
                    <label for="filtre-favoris-desktop" *ngIf="!courtier">
                        <input *ngIf="estConnecte()" id="filtre-favoris-desktop" name="filtre-favoris" [(ngModel)]="filtre.favoris" (change)="filtrer()" type="checkbox">
                        <input *ngIf="!estConnecte()" id="filtre-favoris-desktop" name="filtre-favoris" type="checkbox" (click)="reactionDeconnecte()">
                        <i class="far fa-star pt-2 etoileDeconnecte" *ngIf="!estConnecte()" ></i>
                        <span *ngIf="estConnecte()" class="ne-pas-supprimer-ce-span-vide"></span>
                        <span class="filtre-texte"> {{ '@mesfavoris' | translate }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Barre de recherche Mobile -->
<div class="container" #container tabindex="-1" *ngIf="!environment.masquerBarreRecherche">
    <!-- Favoris + Ouverture filtre -->
    <div class="row d-md-none">
        <div class="col-6 pl-3 pr-4 custom-fa filtre-favoris ordinateur">
            <label for="filtre-favoris-desktop">
                <input *ngIf="estConnecte()" id="filtre-favoris-desktop" name="filtre-favoris" [(ngModel)]="filtre.favoris" (change)="filtrer()" type="checkbox">
                <input *ngIf="!estConnecte()" id="favoris" (change)="reactionDeconnecte()" id="filtre-favoris-desktop" name="filtre-favoris" type="checkbox" >
                <i class="far fa-star pt-1 etoileDeconnecte" *ngIf="!estConnecte()"></i>
                <span *ngIf="estConnecte()" class="ne-pas-supprimer-ce-span-vide"></span>
                <span class="filtre-texte"> {{ '@mesfavoris' | translate }}</span>
            </label>
        </div>
    </div>
    <!-- Filtres -->
    <div class="pt-3 row mobile popUpOuvertureFiltres" #toggleFiltreRecherche *ngIf="filtreOuvert" [ngClass]="{'popUpOuvertureFiltres': choixFiltre == 'carte'}">
        <div class="justify-content-center">
            <mat-form-field appearance="fill" class="col-sm pl-2" (keyup.enter)="filtrer()">
                <mat-label>{{ '@listeProprietes.rechercheDescriptif' | translate }}</mat-label>
                <input matInput [(ngModel)]="filtre.q" name="q" [formControl]="campRecherche" #barreRecherche [matAutocomplete]="auto">

                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="filtrer()">
                    <mat-option *ngFor="let option of optionsRechercheFiltres | async" [value]="option">{{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="d-flex justify-content-center" id="aOuvrir" *ngIf="!environment.masquerChoixListing">
            <!-- Type d'Immeuble -->
            <mat-form-field appearance="fill" class="col-sm pl-2 rm-min-width">
                <mat-label>{{ '@type.logements' | translate }}</mat-label>
                <mat-select [(ngModel)]="filtre.sTypeLogement" name="type-propriete" id="type-proprietes"
                    (selectionChange)="filtrerType()">
                    <mat-option *ngFor="let type of environment.typeProprietes" [value]="type.id"> {{ type.nom | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Type vente ou location -->
            <mat-form-field *ngIf="!environment.masquerChoixListing && !environment.masquerVenteLocation" class="col-sm pl-0 rm-min-width" appearance="fill" (keyup.enter)="filtrer()">
                <mat-label>{{ '@type.venteLocation' | translate }}</mat-label>
                <mat-select [(ngModel)]="filtre.venteLocation" name="vente-location" id="vente-location"
                    (selectionChange)="filtrer()">
                    <mat-option value="tout">{{ '@propriete.venteEtLocation' | translate }}</mat-option>
                    <mat-option value="0">{{ '@propriete.vente' | translate }}</mat-option>
                    <mat-option value="1">{{ '@propriete.location' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-flex justify-content-center" *ngIf="afficheSuperficie">
            <!-- Superficie MOBILE -->
            <mat-form-field class="col-sm pl-2 rm-min-width" appearance="fill" (keyup.enter)="filtrer()">
                <mat-label>{{ 'superficie.min' | translate }}</mat-label>
                <input [(ngModel)]="filtre.superficieMin" name="superficieMin" matInput>
            </mat-form-field>
            <mat-form-field class="col-sm pl-0 rm-min-width" appearance="fill" (keyup.enter)="filtrer()">
                <mat-label>{{ 'superficie.max' | translate }}</mat-label>
                <input [(ngModel)]="filtre.superficieMax" name="superficieMax" matInput>
            </mat-form-field>
            <!-- Filtre favoris -->
            <div class="col-auto pl-2 pr-4 custom-fa filtre-favoris" *ngIf="estConnecte()">
                <label for="filtre-favoris-desktop">
                    <input id="filtre-favoris-desktop" name="filtre-favoris" [(ngModel)]="filtre.favoris" (change)="filtrer()" type="checkbox">
                    <span class="ne-pas-supprimer-ce-span-vide"></span>
                    <span class="filtre-texte">{{ '@mesfavoris' | translate }}</span>
                </label>
            </div>
        </div>
        <div class="d-flex justify-content-center pl-0 ml-0" *ngIf="afficheUnite && !environment.masquerChoixListing && !environment.masquerNombreUnite">
            <!-- Unite -->
            <mat-form-field class="col-sm pl-0 ml-0 w-50" appearance="fill">
                <mat-label>{{ '@nombre.unites' | translate }}</mat-label>
                <mat-select [(ngModel)]="filtre.nombresUnites" name="nbUnites" id="nbUnites" (selectionChange)="filtrer()">
                    <mat-option>{{ '@listeProprietes.typeUnite.tout' | translate }}</mat-option>
                    <mat-option value="0-11">5 - 11 {{ '@unites' | translate }}</mat-option>
                    <mat-option value="12-24">12 - 24 {{ '@unites' | translate }}</mat-option>
                    <mat-option value="25-49">25 - 49 {{ '@unites' | translate }}</mat-option>
                    <mat-option value="50-">50 {{ '@unites' | translate }} et +</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
