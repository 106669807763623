<div class="container" id="matterport" *ngIf="anyMatterport">
    <h3 class="summary w-100 mb-4"><i class="fas fa-vr-cardboard"></i> {{ '@visite.virtuelle' | translate }}</h3>

    <div class="liste-suite">
        <ul>
            <li *ngFor="let proprieteEnfant of data?.ProprietesEnfants">
                <button *ngIf="proprieteEnfant?.sJsonData?.UrlMatterport" 
                    [ngClass]="{'-actif': proprieteEnfant?.sJsonData?.UrlMatterport === matterportActif}" 
                    (click)="setUrlMatterport(proprieteEnfant?.sJsonData?.UrlMatterport)">Suite {{proprieteEnfant?.sNumeroCivique}}</button>
            </li>
        </ul>
    </div>

    <iframe *ngIf="matterportActif" class="matterport" [src]="matterportActif | safe: 'url' " frameborder="0"
    allowfullscreen allow="xr-spatial-tracking"></iframe>
</div>
