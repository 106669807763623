<section class="descriptif d-flex justify-content-center align-items-center" *ngIf="!data?.iMasquerPrix">
    <div class="containeur w-100">
        <div class="row rangee">
            <div class="col-lg-6 col-xs-12">
                <div class="revenus">
                    <h2 class="titre-section"><i class="fas fa-usd-circle"></i> {{'@fd.descriptif2-com.revenus' | translate}}</h2>
                    <table class="table table-striped">
                        <thead>
                            <tr class="top">
                                <th class="top-titre"></th>
                                <th class="top-titre">{{ '@annuel' | translate  }}</th>
                                <th class="top-titre">$ / {{ '@pi2' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Commercial</th>
                                <td>{{ data?.fRevenusComm | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fRevenusComm, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@stationnement' | translate }}</th>
                                <td>{{ data?.fRevenusStationnement | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fRevenusStationnement, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@rangement' | translate }}</th>
                                <td>{{ data?.fRevenusLockers | pmmlArgent}}</td>
                                <td>{{ getPrice(data?.fRevenusLockers, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr *ngIf="data?.fRevenusAutres"> 
                                <th>{{ data?.sAutreRevText | translateValeur : data?.Traduction?.sAutreRevText}}</th>
                                <td>{{ data?.fRevenusAutres | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fRevenusAutres, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr *ngIf="data?.fRevenusAutres2"> 
                                <th>{{ data?.sAutreRevText2 | translateValeur : data?.Traduction?.sAutreRevText2}}</th>
                                <td>{{ data?.fRevenusAutres2 | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fRevenusAutres2, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr *ngIf="data?.fRevenusAutres3"> 
                                <th>{{ data?.sAutreRevText3 | translateValeur : data?.Traduction?.sAutreRevText3}}</th>
                                <td>{{ data?.fRevenusAutres3 | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fRevenusAutres3, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr> 
                                <th>{{ '@recup' | translate }}</th>
                                <td>{{ data?.fRevenusAutres4 | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fRevenusAutres4, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr class="blanc final final-bas">
                                <th>{{ '@revenus.brut.total' | translate }}</th>
                                <td class="gras">{{ data?.fTotalRevenu | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fTotalRevenu, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="pub">
                    <img src="https://dev.pmml.ca/wp-content/uploads/2020/06/PUB.jpg" alt="">
                </div> -->
            </div>
            <div class="col-lg-6 col-xs-12 pt-5 pt-lg-0">
                <div class="financement">
                    <h2 class="titre-section"><i class="fas fa-money-check-edit-alt"></i> {{ '@depenses' | translate }}</h2>
                    <table class="table table-striped">
                        <thead>
                            <tr class="top">
                                <th class="top-titre" style="width: 30%;"></th>
                                <th class="top-titre" style="width: 8%;"></th>
                                <th class="top-titre" style="width: 22%;">{{ '@annuel' | translate }}</th>
                                <th class="top-titre" style="width: 20%;">$ / {{ '@pi2' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{ 'fVacMauvaiseCHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fVacMauvaiseC">{{ data?.fVacMauvaiseCTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fVacMauvaiseC | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fVacMauvaiseC, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <td class="titre-groupement" colspan="4">
                                    Taxes
                                </td>
                            </tr>
                            <tr>
                                <th>{{ 'hy-p-taxes-m' | translate }}</th>
                                <td><span *ngIf="data?.fTaxesMunicipales">{{ data?.fTaxesMunicipalesTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fTaxesMunicipales | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fTaxesMunicipales, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'hy-p-taxes-s' | translate }}</th>
                                <td><span *ngIf="data?.fTaxesScolaires">{{ data?.fTaxesScolairesTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fTaxesScolaires | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fTaxesScolaires, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <td class="titre-groupement" colspan="4">
                                {{ '@frais.operations' | translate }}
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@frais.admin' | translate }}</th>
                                <td><span *ngIf="data?.fAdministration">{{ data?.fAdministrationTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fAdministration | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fAdministration, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@assurances' | translate }}</th>
                                <td><span *ngIf="data?.fAssurances">{{ data?.fAssurancesTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>      
                                <td>{{ data?.fAssurances | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fAssurances, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@electricite' | translate }}</th>
                                <td><span *ngIf="data?.fElectricite">{{ data?.fElectriciteTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fElectricite | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fElectricite, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@chauffage' | translate }}</th>
                                <td><span *ngIf="data?.fChauffage">{{ data?.fChauffageTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fChauffage | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fChauffage, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@entretien.reparation' | translate }}</th>
                                <td><span *ngIf="data?.fEntretien">{{ data?.fEntretienTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td >{{ data?.fEntretien | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fEntretien, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@deneigement' | translate }}</th>
                                <td><span *ngIf="data?.fDeneigement">{{ data?.fDeneigementTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fDeneigement | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fDeneigement, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@contrat.service' | translate }}</th>
                                <td><span *ngIf="data?.fGazon">{{ data?.fGazonTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fGazon | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fGazon, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@salaires' | translate }}</th>
                                <td><span *ngIf="data?.fConcierge">{{ data?.fConciergeTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fConcierge | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fConcierge, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr *ngIf="data?.fDepensesAutres"> 
                                <th>{{ data?.sAutreDepText | translateValeur : data?.Traduction?.sAutreDepText}}</th>
                                <td><span *ngIf="data?.fDepensesAutres">{{ data?.fDepensesAutresTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fDepensesAutres | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fDepensesAutres, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr *ngIf="data?.fDepensesAutres2"> 
                                <th>{{ data?.sAutreDepText2 | translateValeur : data?.Traduction?.sAutreDepText2}}</th>
                                <td><span *ngIf="data?.fDepensesAutres2">{{ data?.fDepensesAutres2TypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fDepensesAutres2 | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fDepensesAutres2, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr *ngIf="data?.fDepensesAutres3"> 
                                <th>{{ data?.sAutreDepText3 | translateValeur : data?.Traduction?.sAutreDepText3}}</th>
                                <td><span *ngIf="data?.sAutreDepText3">{{ data?.fDepensesAutres3TypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fDepensesAutres3 | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fDepensesAutres3, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <td class="titre-groupement" colspan="4">
                                    {{ '@depenses.non.recup' | translate }}
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@reserve.structurel' | translate }}</th>
                                <td><span *ngIf="data?.fElectromenagers">{{ data?.fElectromenagersTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fElectromenagers | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fElectromenagers, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@frais.gestion' | translate }}</th>
                                <td><span *ngIf="data?.fFraisGestion">{{ data?.fFraisGestionTypeCalc | proprieteTypeDep : 'Commerciale' | translate}}</span></td>
                                <td>{{ data?.fFraisGestion | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fFraisGestion, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                            <tr class="blanc final final-bas">
                                <th>{{ '@depenses.total' | translate }}</th>
                                <td></td>
                                <td class="gras">{{ data?.fTotalDepense | pmmlArgent }}</td>
                                <td>{{ getPrice(data?.fTotalDepense, data?.iPiedsCarreTotal) | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <table class="table table-striped mobile">
                        <tr class="top">
                            <th class="top-titre w-50"></th>
                            <th class="top-titre w-25">{{ '@annuel' | translate }}</th>
                        </tr>
                        <tr>
                            <td class="titre-groupement" colspan="2">
                                Taxes
                            </td>
                        </tr>
                        <tr>
                            <th>{{ 'hy-p-taxes-m' | translate }}</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>{{ 'hy-p-taxes-s' | translate }}</th>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="titre-groupement" colspan="2">
                                {{ '@frais.operation' | translate }}
                            </td>
                        </tr>
                        <tr>
                            <th>{{ '@assurances' | translate }}</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>{{ '@electricite' | translate }}</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>{{ '@gaz' | translate }}</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>{{ '@entretien.reparation' | translate }}</th>
                            <td >220 000$</td>
                        </tr>
                        <tr>
                            <th>{{ '@deneignement' | translate }}</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>{{ '@contrat.service' | translate }}</th>
                            <td >220 000$</td>
                        </tr>
                        <tr>
                            <th>{{ '@salaires' | translate }}</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>{{ '@frais.admin' | translate }}</th>
                            <td >220 000$</td>
                        </tr>
                        <tr>
                            <td class="titre-groupement" colspan="2">
                                {{ '@depenses.non.recup' | translate }}
                            </td>
                        </tr>
                        <tr>
                            <th>{{ '@reserve.structurel' | translate }}</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>{{ '@frais.gestion' | translate }}</th>
                            <td >220 000$</td>
                        </tr>
                        <tr>
                            <th>{{ '@depenses.total' | translate }}</th>
                            <td></td>
                        </tr>
                    </table> -->
                </div>
            </div>
        </div>
    </div>
</section>