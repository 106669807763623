import { Component, OnInit, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthService } from 'src/app/auth.service';
import { FavorisInformationsComponent } from '../../listings/liste-priorites/favoris-informations/favoris-informations.component';
import { ProprietesService } from './../../api/proprietes.service';

@Component({
  selector: 'app-liste-favoris',
  templateUrl: './liste-favoris.component.html',
  styleUrls: ['./liste-favoris.component.scss']
})
export class ListeFavorisComponent implements OnInit {

  private dialogAlreadyOpen: boolean = false;

  constructor(
    private auth: AuthService,
    private proprietes: ProprietesService,
    public dialog: MatDialog
  ) { }

  favoris: any[];
  nbrFavoris: number;
  showLoader: boolean = false;
  routeProprietes: string;

  ngOnInit(): void {
    this.routeProprietes = (<any>window).pmml_data.lang === 'fr' ? '/terrain' : '/en/land-for-sale';
    this.chargerFavoris();
  }

  async chargerFavoris() {
    this.proprietes.recupFavoris().then(res => {
      this.favoris = res;
      this.nbrFavoris = res?.length;
    });
  }

  showFavorisTooltip(event) {
    const x = event.clientX, y = event.clientY, elementMouseIsHover = document.elementFromPoint(x, y);

    const showFavorisTooltip = window.localStorage.getItem('showFavorisTooltip');
    if (!showFavorisTooltip) window.localStorage.setItem('showFavorisTooltip', '1');

    if(this.dialogAlreadyOpen || showFavorisTooltip === '0') return;
    else if (showFavorisTooltip === '1') {
      this.dialogAlreadyOpen = true;
      const dialogRef = this.dialog.open(FavorisInformationsComponent, {
        hasBackdrop: false,
        data: { element: elementMouseIsHover }
      });

      dialogRef.afterClosed().subscribe(() => {
        this.dialogAlreadyOpen = false;
      });
    }
  }

  ajouterFavoris(propriete, event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.estConnecte()) {
      if(propriete.fav){
        const favIndexToReplace = this.favoris.findIndex(x => x.sLien === propriete.sLien);
        this.favoris.splice(favIndexToReplace, 1);
        this.nbrFavoris = this.favoris.length;
        this.proprietes.setFavori(propriete.sLien, false);
      }
    }
  }

  estConnecte() {
    return this.auth.token ? true : false;
  }
}
