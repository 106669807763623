import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { from, Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {
  constructor(private http: HttpClient) {}

  listeArticles(categorieId?: number, page: number = 1, perPage: number = 10, provenanceExterne = false): any {
    if (!environment.production) {
      return from(
        import('./data-props-pmmltv').then((module: any) => {
          return [module.pmmltv, 12, 100];
        })
      );
    }
    // let url = `/wp-json/wp/v2/posts?_fields=id,featured_media,excerpt_text,title,link,fimg_url`;
    if (!categorieId) categorieId = environment.pmmlTvDefaultCat;
    let url = `/wp-json/wp/v2/posts?categories=${categorieId}&page=${page}&per_page=${perPage}&_fields=id,featured_media,excerpt_text,title,link,fimg_url,fimg_courriel_url,fyoutube`;
    if (provenanceExterne) url = `/pmml/wp-json/wp/v2/posts?categories=${categorieId}&page=${page}&per_page=${perPage}&_fields=id,featured_media,excerpt_text,title,link,fimg_url,fimg_courriel_url,fyoutube`;
    return this.http.get(url, { withCredentials: true, observe: 'response' }).pipe(
      map((res: HttpResponse<any>) => {
        return [res.body, res.headers.get('X-WP-TotalPages'), res.headers.get('X-WP-Total')];
      })
    );
  }
  //   return this.http.get(`/wp-json/wp/v2/posts?categories=${categorieId}&_fields=id,featured_media,excerpt,title,link?_embed`);

  listeChaines(): Observable<any> {
    if (!environment.production) {
      return from(
        import('./data-props-pmmltv-chaines').then((module: any) => {
          return module.pmmltvChaines;
        })
      );
    }
    const url = '/wp-json/wp/v2/categories?parent=2016&per_page=30&_fields=id,name,slug';
    return this.http.get(url, { withCredentials: true });
  }
}
