<div class="row align-items-center infoCourtier">
    <div class="col-auto photo" *ngIf="!versionMobile">
        <img [src]="(data?.[(location ? 'CourtierLoc' : 'Courtier') + '_Photo'] || data?.sCourtierPhotoSec || data?.sCourtierPhoto)" class="img-fluid round" style="border-radius: 100%;" alt="">
    </div>
    <div class="col contact">
        <div class="nom">
            <!-- CourtierLoc_Equipe -->
            <strong class="text-uppercase">{{ data?.[(location ? 'CourtierLoc' : 'Courtier') + '_Nom'] || data?.sCourtierNom }}</strong>
            <span class="equipe" *ngIf="data?.[(location ? 'CourtierLoc' : 'Courtier') + '_Equipe'] && data?.sCourtierNom !== 'Yelena Krutous'"> | {{ '@menu.equipeCourtier' | translate }} 
                {{ data?.[(location ? 'CourtierLoc' : 'Courtier') + '_Equipe'] || data?.sCourtierNom }}
            </span>
            <span class="equipe" *ngIf="!data?.[(location ? 'CourtierLoc' : 'Courtier') + '_Equipe'] && data?.sCourtierNom !== 'Yelena Krutous' && !data?.Courtier_TitreAlternatif"> | {{ '@menu.courtierSeul' | translate }}</span>
        </div>
        <div class="row justify-content-between align-items-center">
            <div class="col-auto">
                <div *ngIf="(data?.[(location ? 'CourtierLoc' : 'Courtier') + '_PosteFR'])" class="poste">
                    {{ (data?.[(location ? 'CourtierLoc' : 'Courtier') + '_PosteFR'] || '') | translateValeur : (data?.[(location ? 'CourtierLoc' : 'Courtier') + '_PosteAN'] || '')}}
                </div>
                <div class="titre">
                    {{ (data?.[(location ? 'CourtierLoc' : 'Courtier') + '_Titre'] || '') | translate }}
                </div>
            </div>
            <div class="col-auto">
                <a class="coordonneesCourtier tel ml-1" [href]="'tel:' + (data?.[(location ? 'CourtierLoc' : 'Courtier') + '_Tel'] || '514 360-3603')">
                    <i class="fas fa-phone"></i>
                    {{ data?.[(location ? 'CourtierLoc' : 'Courtier') + '_Tel'] || '514 360-3603' }}
                </a>
            </div>
            
        </div>
    </div>
</div>