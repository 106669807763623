<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-12 h-100">
      <div
        id="video-dialog-iframe"
        autoplay="1"
        frameborder="0"
        color="white"
        enablejsapi="1"
      ></div>
    </div>
  </div>
</div>
