<span class="prixDemande" *ngIf="(!data?.iLocal && !location) && data?.PrixTexteFr; else prixRegulier;">
    {{data?.PrixTexteFr | translateValeur : data?.PrixTexteAn}}
</span>
<ng-template #prixRegulier>
    <span class="prixDemande" *ngIf="!data?.iMasquerPrix && (!data?.iLocal && !data?.iGroupeLocaux) && data?.fPrixDemande">
        <span class="etiquette pr-1">{{ '@menu.multilogement.prixdemande' | translate }} : </span>
        {{ data?.fPrixDemande | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}}  {{ data?.sJsonData?.fr?.PrixPost | translateValeur: data?.sJsonData?.an?.PrixPost}}
        <span *ngIf="data?.sTypeLogement == 'iSemiCommercial'" style="text-transform: none; font-size: 0.7em;" class="ml-1">{{'@fiche.prix.plusTaxesSemi' | translate}} </span>
        <span *ngIf="data?.sTypeLogement == 'iCommercial' || data?.sTypeLogement == 'iPortfolioComm'" class="ml-1">{{'@fiche.prix.plusTaxes' | translate}} </span>
    </span>
</ng-template>