import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslatePipe } from "@ngx-translate/core";
import { fromEvent, map, startWith, take } from "rxjs";
import { ProprietesService } from "src/app/api/proprietes.service";
import { AuthService } from "src/app/auth.service";
import { ConnexionFavorisDialogComponent } from "src/app/listings/liste-priorites/connexion-favoris-dialog/connexion-favoris-dialog.component";
import { TranslateValeurPipe } from "src/app/translate-valeur.pipe";

@Component({
  selector: "app-inf-sup",
  templateUrl: "./inf-sup.component.html",
  styleUrls: ["./inf-sup.component.scss"],
})
export class InfSupComponent implements OnInit {
  constructor(
    private translate: TranslateValeurPipe,
    private sanitizer: DomSanitizer,
    private proprietes: ProprietesService,
    private auth: AuthService,
    private dialog: MatDialog
  ) {}

  @Input() data: any;
  @Input() location: any = false;
  @Input() noContainer: boolean = false;
  aParamCode = ''

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("a")) {
      this.aParamCode = `?a=${urlParams.get("a")}`;
    } else this.aParamCode = "";
  }

  get videoModePortrait() {
    return this.data?.iVideoPortrait == 1;

  }

  largeur$ = fromEvent(window, "resize").pipe(
    map(() => window.innerWidth),
    startWith(window.innerWidth)
  );

  ajouterFavoris(propriete, event) {
    this.auth.isAuthenticated$.pipe(take(1)).subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        const dialogRef = this.dialog.open(ConnexionFavorisDialogComponent, {
          hasBackdrop: false,
          data: { trigger: null, type: "menu" },
        });
        return;
      }
      propriete.fav = !propriete.fav;
      this.proprietes.setFavori(propriete.sLien, propriete.fav);
    });
    // if (this.estConnecte()) {
    //   if (!propriete.fav) {
    //     this.hideFavorisTooltip();
    //     propriete.fav = true;
    //     this.proprietes.setFavori(propriete.sLien, true);
    //   } else {
    //     this.hideFavorisTooltip();
    //     propriete.fav = false;
    //     this.proprietes.setFavori(propriete.sLien, false);
    //   }
    // } else {
    //   const target = new ElementRef(event.currentTarget);
    //   const dialogRef = this.dialog.open(ConnexionFavorisDialogComponent, {
    //     hasBackdrop: false,
    //     data: { trigger: target, type: "menu" },
    //   });
    // }
  }

  showVideo = false;
  embed: any;
  lireVideo() {
    this.showVideo = true;
    const videoId = this.translate.transform(
      this.data.sVideoYoutubeFr,
      this.data.sVideoYoutubeAn
    );
    this.embed = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1`
    );
  }
}
