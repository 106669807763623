<div class="sommaire-loyers d-flex flex-column justify-content-center align-items-center">
    <div class="container w-100">
        <h3>{{ 'titre.sommaire' | translate }}</h3>
        <div class="row">
            <div class="w-100 se-connecter d-flex flex-column justify-content-center align-items-center">
                <p class="texte">{{ 'pour.visionner.sommaire' | translate }}</p>
                <button class="connect" *ngIf="!connected" (click)="seConnecter()">{{ '@se.connecter' | translate }}</button>
            </div>
            <div *ngIf="connected" class="col-12">
                <table class="w-100 ordinateur">
                    <tr class="top-barre">
                        <th style="text-align: left;">{{ '@colLocataire' | translate }}</th>
                        <th class="txt-center">{{ '@superficie' | translate }}</th>
                        <th class="txt-center">{{ '@loyers.net' | translate }}</th>
                        <th class="txt-center">{{ '@loyers.additionnels' | translate }}</th>
                        <th class="txt-center">{{ '@location.price.sqft' | translate }}</th>
                    </tr>
                    <tr *ngFor="let loyer of loyers">
                        <td class="table-unit" style="font-family: acumin-pro, sans-serif; font-weight: 700; text-align: left;">{{ loyer?.sLocataire }}</td>
                        <td class=" txt-center unit-table-summary">{{ loyer?.iPiedsCarre }}</td>
                        <td class=" txt-center unit-table-summary"></td>
                        <td class=" txt-center unit-table-summary"></td>
                        <td class=" txt-center unit-table-summary"></td>
                    </tr>
                </table>
                <table class="w-100 mobile">
                    <tr class="top-barre">
                        <th style="text-align: left;">{{ '@colLocataire' | translate }}</th>
                        <th class="txt-center">{{ '@superficie' | translate }}</th>
                        <th class="txt-center">{{ '@loyers.net' | translate }}</th>
                    </tr>
                    <tr *ngFor="let loyer of loyers">
                        <td class="table-unit" style="font-family: acumin-pro, sans-serif; font-weight: 700; text-align: left;"></td>
                        <td class=" txt-center unit-table-summary"></td>
                        <td class=" txt-center unit-table-summary"></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div  *ngIf="connected" class="containeur w-100">
        <div class="row">
            <div class="col-12">
                <h2 class="summary w-100 mb-3">{{ '@echeance.baux' | translate }}</h2>
                <table class="w-100 ordinateur">
                    <tr class="top-barre">
                        <th class="w-25 txt-center">0-6 {{ '@mois' | translate }}</th>
                        <th class="w-25 txt-center">6-12 {{ '@mois' | translate }}</th>
                        <th class="w-25 txt-center">12-36 {{ '@mois' | translate }}</th>
                        <th class="w-25 txt-center">36 {{ '@mois' | translate }} et +</th>
                    </tr>
                    <tr style="border-top: 4px solid black; border-bottom: 4px solid black; background-color: white;">
                        <td class="table-unit" style="font-family: acumin-pro, sans-serif; font-weight: 700; text-align: left;"></td>
                        <td class=" txt-center unit-table-summary"></td>
                        <td class=" txt-center unit-table-summary"></td>
                        <td class=" txt-center unit-table-summary"></td>
                    </tr>

                </table>
                <table class="w-100 mobile">
                    <tr style="border-top: 4px solid black; background-color: white;">
                        <td class="txt-center gras">0-6 {{ '@mois' | translate }}</td>
                    </tr>
                    <tr style="border-bottom: 4px solid black; background-color: white;">
                        <td class="txt-center"></td>
                    </tr>
                    <tr style="border-top: 4px solid black; background-color: white;">
                        <td class="txt-center gras">6-12 {{ '@mois' | translate }}</td>
                    </tr>
                    <tr style="border-bottom: 4px solid black; background-color: white;">
                        <td class="txt-center"></td>
                    </tr>
                    <tr style="border-top: 4px solid black; background-color: white;">
                        <td class="txt-center gras">12-36 {{ '@mois' | translate }}</td>
                    </tr>
                    <tr style="border-bottom: 4px solid black; background-color: white;">
                        <td class="txt-center"></td>
                    </tr>
                    <tr style="border-top: 4px solid black; background-color: white;">
                        <td class="txt-center gras">36 {{ '@mois' | translate }} +</td>
                    </tr>
                    <tr style="border-bottom: 4px solid black; background-color: white;">
                        <td class="txt-center"></td>
                    </tr>
                    
                </table>
            </div>
        </div>
    </div>
</div>