<section class="d-flex justify-content-center align-items-center">
    <div class="containeur w-100">
        <div class="titre w-100">
            <h2>{{ '@conditions.location' | translate }}</h2>
            <h3>{{ '@terme.bail' | translate }}</h3>
        </div>
        <div class="row pad">
            <div class="col-xl-6 d-flex justify-content-center align-items-center">
                <div class="w-auto h-100 section d-flex justify-content-center align-items-center">
                    <div class="texte h-100 d-flex justify-content-center align-items-start">
                        <span class="w-100 h-100">5 {{ '@ans' | translate }}</span>
                    </div>
                    <table>
                        <tr>
                            <td class="table-titre">#</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail1?.fr?.Numero | translateValeur : data?.sJsonData?.Bail1?.an?.Numero }}</td>
                        </tr>
                        <tr><td></td></tr>
                        <tr>
                            <td class="table-titre">{{ '@superficie.disponible.sqft' | translate }}</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail1?.fr?.Superficie | translateValeur : data?.sJsonData?.Bail1?.an?.Superficie }}</td>
                        </tr>
                        <tr><td></td></tr>
                        <tr>
                            <td class="table-titre">{{ '@etage' | translate }}</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail1.fr?.Etage | translateValeur : data?.sJsonData?.Bail1?.an?.Etage }}</td>
                        </tr>
                        <tr><td></td></tr>
                        <tr>
                            <td class="table-titre">Allocation ( $ / {{ '@pi2' | translate }} )</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail1?.fr?.Allocation | translateValeur : data?.sJsonData?.Bail1?.an?.Allocation }}</td>
                        </tr>
                        <tr><td></td></tr>
                        <tr>
                            <td class="table-titre">{{ 'loyer.additionnel.estime' | translate }} ($/Pi2)</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail1?.fr?.LoyerAditionel | translateValeur : data?.sJsonData?.Bail1?.an?.LoyerAditionel }}</td>
                        </tr>
                        <tr><td></td></tr>
                        <tr>
                            <td class="table-titre">{{ '@disponibilite' | translate }}</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail1?.fr?.Disponibilite | translateValeur : data?.sJsonData?.Bail1?.Disponibilite }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-xl-6 d-flex justify-content-center align-items-center">
                <div class="w-auto h-100 section d-flex justify-content-center align-items-center">
                    <div class="texte h-100 d-flex justify-content-center align-items-start">
                        <span class="h-100 w-100">10 {{ '@ans' | translate }}</span>
                    </div>
                    <table>
                        <tr>
                            <td class="table-titre">#</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail2?.fr?.Numero | translateValeur : data?.sJsonData?.Bail2?.an?.Numero }}</td>
                        </tr>
                        <tr><td></td></tr>
                        <tr>
                            <td class="table-titre">{{ '@superficie.disponible.sqft' | translate }}</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail2?.fr?.Superficie | translateValeur : data?.sJsonData?.Bail2?.an?.Superficie }}</td>
                        </tr>
                        <tr><td></td></tr>
                        <tr>
                            <td class="table-titre">{{ '@etage' | translate }}</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail2.fr?.Etage | translateValeur : data?.sJsonData?.Bail2?.an?.Etage }}r</td>
                        </tr>
                        <tr><td></td></tr>
                        <tr>
                            <td class="table-titre">Allocation ( $ / {{ '@pi2' | translate }} )</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail2?.fr?.Allocation | translateValeur : data?.sJsonData?.Bail2?.an?.Allocation }}</td>
                        </tr>
                        <tr><td></td></tr>
                        <tr>
                            <td class="table-titre">{{ '@loyer.additionnel.estime' }} ($/ {{ '@pi2' | translate }})</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail2?.fr?.LoyerAditionel | translateValeur : data?.sJsonData?.Bail2?.an?.LoyerAditionel }}</td>
                        </tr>
                        <tr><td></td></tr>
                        <tr>
                            <td class="table-titre">{{ '@disponibilite' | translate }}</td>
                        </tr>
                        <tr>
                            <td class="table-valeur">{{ data?.sJsonData?.Bail2?.fr?.Disponibilite | translateValeur : data?.sJsonData?.Bail2?.Disponibilite }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>