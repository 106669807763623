import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "src/environments/environment";

@Pipe({
  name: "cfImg",
})
export class CfImgPipe implements PipeTransform {
  transform(srcOriginal: string, taille = 'public'): string {
    if (typeof srcOriginal !== "string") return srcOriginal;
    return srcOriginal.replace(
      "https://pmml.ca/img/",
      environment.cloudflaresImages.baseUrl
    ).replace(
      "https://img.pmml.ca/",
      environment.cloudflaresImages.baseUrl
    ).replace(/\/[a-z0-9]+$/i, '')
    + `/${taille || 'public'}`;
  }
}
