
<div class="descriptif-section d-flex align-items-center justify-content-center">
    <div class="containeur w-100">
        <div class="row">
            <div class="titre-section w-100" style="text-align: center">
                <h2 style="font-family: acumin-pro, sans-serif; font-weight: 700; font-size: 32px;">{{ '@descriptif.titre' | translate }}</h2>
            </div>
        </div>
        <div class="row marge">
            <div class="col-xl-6 secr d-flex flex-column align-items-end justify-content-start" >
                <div class="general w-100">
                    <table class="ordinateur" style="width: 100%">
                        <tr>
                          <td class="table-title">{{ '@annee.renovation' | translate }}</td>
                          <td class="table-value">{{ data?.iAnneeConstruction | translateValeur : data?.Traduction?.iAnneeConstruction }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@amenagement.actuel' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.AmenagementActuel | translateValeur : data?.sJsonData?.an?.AmenagementActuel }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@hauteurlibre' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.HauteurLibre | translateValeur : data?.sJsonData?.an?.HauteurLibre }}</td>
                        </tr>
                        
                    </table>
                    <table class="mobile" style="width: 100%">
                        <tr class="m-tr-titre">
                          <td class="m-td-titre">{{ '@annee.renovation' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                          <td class="m-td-valeur">{{ data?.iAnneeConstruction | translateValeur : data?.Traduction?.iAnneeConstruction }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@amenagement.actuel' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.AmenagementActuel | translateValeur : data?.sJsonData?.an?.AmenagementActuel }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@hauteur.libre' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.HauteurLibre | translateValeur : data?.sJsonData?.an?.HauteurLibre }}</td>
                        </tr>
                    </table>
                </div>
                <div class="construction w-100 mt-4">
                    <table class="ordinateur" style="width: 100%;">
                        <tr>
                            <td class="table-title">{{ '@aire.reception' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.AireReception | translateValeur : data?.sJonData?.an?.AireReception }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@salle.reunion' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.SalleReunion | translateValeur : data?.sJsonData?.an?.SalleReunion }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@cuisinette' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.Cuisinette | translateValeur : data?.sJsonData?.an?.Cuisinette }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@toilettes' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.Toilettes | translateValeur : data?.sJsonData?.an?.Toilettes }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@internet.telecom' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.Internet | translateValeur : data?.sJsonData?.an?.Internet }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@chauffage' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.Chauffage | translateValeur : data?.sJsonData?.an?.Chauffage }}</td>
                        </tr>
                    </table>
                    <table class="mobile" style="width: 100%">
                        <tr class="m-tr-titre">
                          <td class="m-td-titre">{{ '@aire.reception' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                          <td class="m-td-valeur">{{ data?.sJsonData?.fr?.AireReception | translateValeur : data?.sJonData?.an?.AireReception }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@salle.reunion' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.SalleReunion | translateValeur : data?.sJsonData?.an?.SalleReunion }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@cuisinette' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.Cuisinette | translateValeur : data?.sJsonData?.an?.Cuisinette }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ 'toilettes' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.Toilettes | translateValeur : data?.sJsonData?.an?.Toilettes }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@internet.telecom' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.Internet | translateValeur : data?.sJsonData?.an?.Internet }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@chauffage' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.Chauffage | translateValeur : data?.sJsonData?.an?.Chauffage }}</td>
                        </tr>
                    </table>
                </div>
                
            </div>

            <div class="col-xl-6 secl d-flex flex-column align-items-start justify-content-start" >
               
                <div class="type-immeuble w-100">
                    <table class="ordinateur" style="width: 100%;">
                        <tr>
                            <td class="table-title">{{ '@climatisation' | translate }}/td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.Climatisation | translateValeur : data?.sJsonData?.an?.Climatisation }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@electricite' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.Electricite | translateValeur : data?.sJsonData?.an?.Electricite }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@ascenseur' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.Ascenceur | translateValeur : data?.sJsonData?.an?.Anscenceur }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ 'titreSecurite' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.Securite | translateValeur : data?.sJsonData?.an?.Securite }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@bureau.gestionnaire' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.BurauGestionnaire | translateValeur : data?.sJsonData?.an?.BurauGestionnaire }}</td>
                        </tr>
                        <tr>
                            <td class="table-title">{{ '@nettoyage.quotidien' | translate }}</td>
                            <td class="table-value">{{ data?.sJsonData?.fr?.ServiceConcierge | translateValeur : data?.sJsonData?.an?.ServiceConcierge }}</td>
                        </tr>
                    </table>
                    <table class="mobile" style="width: 100%">
                        <tr class="m-tr-titre">
                          <td class="m-td-titre">{{ '@climatisation' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.Climatisation | translateValeur : data?.sJsonData?.an?.Climatisation }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                          <td class="m-td-titre">{{ '@electricite' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.Electricite | translateValeur : data?.sJsonData?.an?.Electricite }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@ascenseur' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.Ascenceur | translateValeur : data?.sJsonData?.an?.Anscenceur }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ 'titreSecurite' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.Securite | translateValeur : data?.sJsonData?.an?.Securite }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@bureau.gestionnaire' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.BurauGestionnaire | translateValeur : data?.sJsonData?.an?.BurauGestionnaire }}</td>
                        </tr>
                        <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@nettoyge.quotidien' | translate }}</td>
                        </tr>
                        <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.sJsonData?.fr?.ServiceConcierge | translateValeur : data?.sJsonData?.an?.ServiceConcierge }}</td>
                        </tr>
                    </table>
                </div>
               
            </div>
            
        </div>
    </div>
</div>
