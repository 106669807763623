import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-desc-appartement',
  templateUrl: './desc-appartement.component.html',
  styleUrls: ['./desc-appartement.component.scss']
})
export class DescAppartementComponent implements OnInit {

  @Input() data: any;
  constructor() { }

  filtreAppartement = [
    { valeur: 0, texte: "Numéro d'appartement"},
    { valeur: 1, texte: "Grandeur du logement"},
    { valeur: 2, texte: "Montant du loyer"},
    { valeur: 2, texte: "Date d'échéance"}
  ];

  ngOnInit(): void {
  }

}
