import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-goplex',
  templateUrl: './info-goplex.component.html',
  styleUrls: ['./info-goplex.component.scss']
})
export class InfoGoplexComponent implements OnInit {

  constructor() { }

  LONGUEUR_REPARTITION = 22;
 
  @Input() data: any;

  ngOnInit(): void {
  }

  limiteRepartition(repartition) {
    try {
      if(!repartition) return;
      return repartition?.substring(0, this.LONGUEUR_REPARTITION) + '...';      
    } catch (error) {
      console.error(error);
    }
  }

}
