<div class="container-fluid bootstrapImportation qr-overlay" [class.p-0]="afficheForm">

    <div class="position-absolute" [style.zIndex]="1" [style.right.rem]="1" [style.top.rem]="1">
        <button type="button" class="boutonSendLink" (click)="close.emit()">
            <i class="fas fa-times" style="font-size: 0.7em; padding: 15px;"></i>
        </button>
    </div>

    <div class="col-12 mx-auto mt-5 justify-content-center pt-2" [class.p-0]="afficheForm">
        <div class="d-flex pb-j">
            <img class="mx-auto" src="https://pmml.ca/wp-content/uploads/2020/04/pmml_logo_white_small.png"
                width="200px" alt="">
        </div>
        <ng-container *ngIf="!afficheForm">

            <div class="my-4 d-flex">
                <qr-code class="qr-wrapper" [value]="url" size="150"></qr-code>
            </div>
            <div class="qr-guide">
                <span>{{'@qr-instructions'|translate}}</span>
            </div>

            <div class="container-fluid" style="padding: 0px;">
                <div class="row">
                    <div class="col" style="padding-right: 2px;">
                        <app-share-btn *ngIf="modeCourtier" [url]="url" icon="fas fa-sticky-note" network="custom" label="Note"
                            (click)="afficheForm = true">
                        </app-share-btn>
                    </div>
                    <div class="col-2">
                        <mat-checkbox color="warn" (change)="activerFormulaireDefaut()" [(ngModel)]="checked"></mat-checkbox>
                    </div>
                </div>
            </div>

            <app-share-btn [url]="url" icon="fas fa-link" network="custom" label="{{'@copierlien'|translate}}"
                (click)="copieLien()">
            </app-share-btn>

            <app-share-btn [url]="url" icon="fas fa-comment-dots" network="sms"
                label="{{'@partagerpartext'|translate}}">
            </app-share-btn>

            <app-share-btn [url]="url" icon="fas fa-at" network="email"
                emailSubject="{{'@sujetemail'|translate}} {{courtier?.sNomUser}} | PMML"
                label="{{'@partagerparemail'|translate}}"></app-share-btn>

            <app-share-btn [url]="url" icon="fab fa-facebook" network="facebook"
                label="{{'@partagerparfacebook'|translate}}">
            </app-share-btn>

            <app-share-btn [url]="url" icon="fab fa-linkedin" network="linkedin"
                label="{{'@partagerparlinkedin'|translate}}">
            </app-share-btn>
        </ng-container>

        <ng-container *ngIf="modeCourtier">
            <div class="mt-4">
                <app-contact-form *ngIf="afficheForm" [courtier]="courtier" (done)="close.emit()"></app-contact-form>
            </div>
        </ng-container>
    </div>
</div>