<section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-3 section bg-gris d-flex flex-column align-items-center justify-content-center">
                <h2>{{ '@disponibilite' | translate }}</h2>
                <span>{{ data?.sJsonData?.Bail1?.fr?.Disponibilite | translateValeur : data?.sJsonData?.Bail1?.an?.Disponibilite }}</span>
            </div> 
            <div class="col-xl-9 p-0 ">
                <iframe [src]="'https://www.youtube.com/embed/' + data?.sVideoYoutubeFr" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3 bas d-flex flex-column align-items-center justify-content-center">
                <h2 style="text-align: center;">{{ '@info.supericie.louable' | translate }}</h2>
                <span>{{ data?.iPedsCarreTotal }}</span>
            </div>
            <div class="col-xl-9 bg-gris bas d-flex flex-column justify-content-center align-items-center">
                <h2 style="text-align: center;">{{ '@superficie.disponible.contigue' | translate }}</h2>
                <span>{{ data?.sJsonData?.Bail1?.fr?.Superficie | translateValeur : data?.sJsonData?.Bail1?.an?.Superficie }}</span>
            </div>
        </div>
    </div>
</section>