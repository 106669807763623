
<div class="titrePrincipale">
    <h3 class="summary w-100">{{ 'titre_stats_sociodemo' | translate }}</h3>
    <div class="sousTitreGeneral text-uppercase" style="margin-top: 0rem !important; padding-left: 1rem; padding-right: 1rem;">
        <i class="fas fa-map-marker-alt"></i> {{ data?.StatsSocioDemoRegional?.sNomRegion }}
    </div>
</div>


<!-- SECTION 1 -->
<div class="row sectionBackgroundBlanc" style="margin-top: 0rem !important;">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
        <div style="margin-top: 0rem !important;">
            <div class="sousContainer">
                <h3 class="titre-tableau sousTitreGeneral" style="margin-top: 0rem !important;">
                    {{ 'population_totale' | translate }}
                </h3>
                <div class="nombreHabitants">
                    {{data?.StatsSocioDemoRegional.iPopTotale[0].valeur | number }} <span class="sousTitreGeneral">{{ 'habitants' | translate }}</span>
                </div>
            </div>
            <div class="sousContainer ageMoyen">
                <h3 class="titre-tableau sousTitreGeneral">
                    {{ 'age_moyen' | translate }}
                </h3>
                <div class="indicateurs">
                    <div style="margin-right: 1.5rem;">
                        <div class="section d-flex flex-column justify-content-center align-items-center">
                            <h4>{{ 'region_admin' | translate }}</h4>
                            <strong>{{data?.StatsSocioDemoRegional.iAgeMoyen}}</strong>
                        </div>
                    </div>
                    <div style="margin-left: 1.5rem;">
                        <div class="section d-flex flex-column justify-content-center align-items-center">
                            <h4>{{ 'province' | translate }}</h4>
                            <strong>{{data?.StatsSocioDemoProvincial.iAgeMoyen}}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
        <div class="sousContainer">
            <h3 class="titre-tableau sousTitreGeneral">
                {{ 'age_proportion' | translate }}
            </h3>
            <div>
                <canvas id="myDoughnut" width="350" height="250"></canvas>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
        <div class="sousContainer">
            <h3 class="titre-tableau sousTitreGeneral">
                {{ 'croissance_population' | translate }}
            </h3>
            <div class="text-center pt-2">
                <canvas id="myLineChart" width="340" height="240"></canvas>
            </div>
        </div>
    </div>
</div>


<!-- SECTION 2 -->
<div class="sectionBackgroundGris">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                <div class="sousContainer">
                    <h3 class="titre-tableau sousTitreGeneral">
                        {{ 'population_active' | translate }}
                    </h3>
                    <div class="text-center">
                        <canvas id="myBarChart" width="340" height="240"></canvas>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8">
                <div class="sousContainer statsEcono">
                    <div class="titreStatsEconoMobile">
                        <h3 class="titre-tableau sousTitreGeneral">
                            {{ 'indicateurs_economique' | translate }}
                        </h3>
                    </div>
                    <table class="table table-striped table-hover tableau text-center">
                        <thead>
                            <tr>
                                <th style="padding-top: 0rem !important;">
                                    <div class="titreStatsEcono">
                                        <h3 class="titre-tableau sousTitreGeneral">
                                            {{ 'indicateurs_economique' | translate }}
                                        </h3>
                                    </div>
                                </th>
                                <th>{{ 'region_admin' | translate }}</th>
                                <th>{{ 'province' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{ 'taux_emploi' | translate }}</th>
                                <td>{{data?.StatsSocioDemoRegional.iTauxEmploi}}%</td>
                                <td>{{data?.StatsSocioDemoProvincial.iTauxEmploi}}%</td>
                            </tr>
                            <tr>
                                <th>{{ 'chomage' | translate }}</th>
                                <td>{{data?.StatsSocioDemoRegional.iTauxChomage}}%</td>
                                <td>{{data?.StatsSocioDemoProvincial.iTauxChomage}}%</td>
                            </tr>
                            <tr>
                                <th>{{ 'revenu_disponible' | translate }}</th>
                                <td>{{data?.StatsSocioDemoRegional.iRevenuDispo.toLocaleString()}} {{ 'dollar_paran' | translate }}</td>
                                <td>{{data?.StatsSocioDemoProvincial.iRevenuDispo.toLocaleString()}} {{ 'dollar_paran' | translate }}</td>
                            </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

