import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// import { AuthService } from "../auth.service";
// import { take } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SegmentService {
  analytics: any;
  clientApiBaseUrl = `/api-client`;

  constructor(
    private http: HttpClient,
    private lang: TranslateService,
    // private auth: AuthService
  ) {
    const analytics = (window as any).analytics;
    this.analytics = analytics ? analytics : null;
  }

  // async getContactID(token: string): Promise<string> {
  //   const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` })
  //   return await this.http.get<string>(`${this.clientApiBaseUrl}/identifier`, { headers: headers }).toPromise()
  // }

  // async identifierUser(token: string) {
  //  const contactID = await this.getContactID(token)
  //   this.analytics.identify(contactID)
  // }

  async getSegmentMethods() {
    const analytics = (window as any).analytics;
    if (typeof analytics?.user === "function") this.analytics = analytics;
  }

  getAnonID(): string {
    const analytics = (window as any).analytics;
    this.analytics = analytics ? analytics : null;
    if (!this.analytics) return "n/a";
    if (typeof this.analytics?.user !== "function") return "n/a";
    return this.analytics?.user()?.anonymousId();
  }

  async track(event: string, data: any) {
    try {
      if (!this.analytics) await this.getSegmentMethods();

      console.log("Tracking event: ", event, data);

      return new Promise((resolve, reject) => {
        try {
          this.analytics.track(event, data, () => {
            resolve(true);
          });
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  getCommunParams() {
    return {
      source: "pmml-ca",
      sourceUrl: location.href,
      lang: this.lang.currentLang,
    };
  }

  contactAction(type: string, extra = {} as any) {
    const data = {
      ...extra,
      ...this.getCommunParams(),
      type,
    };

    this.analytics.track("contactAction", data);
  }

  consultation(type: string, extra = {} as any) {
    const data = {
      ...extra,
      ...this.getCommunParams(),
      type,
    };

    this.analytics.track("consultation", data);
  }
}
