import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstValue'
})
export class FirstValuePipe implements PipeTransform {

  transform(obj?: any, field: '' | 'key' | 'value' = ''): unknown {
    if (!obj || typeof obj !== 'object') return obj;

    const key = Object.keys(obj)[0]
    const value = obj[key]

    const result = { key, value }

    console.log(field);
    if (!field) return { key, value }

    return result[field]
  }

}
