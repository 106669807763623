<div class="container pl-0 pr-0 mat-elevation-z3 revenusMarge">
    <app-carrousel-complet *ngIf="data" [data]="data"></app-carrousel-complet>

    <div class="row">
        <div #content class="col-lg-9 col-xs-12 pr-lg-0  pr-xl-0">
            <div *ngIf="environment?.modeTeasing && !connected">
                <app-inf-sup [data]="data"></app-inf-sup>
                <app-informations [data]="data"></app-informations>
                <div class="se-connecter text-center pt-5" *ngIf="!connected">
                    <h3>{{ "connection-afficher-fiche" | translate }}</h3>
                    <button class="connect" *ngIf="!connected" (click)="seConnecter()">{{'@se.connecter' | translate}}</button>
                </div>
            </div>
            
            <div *ngIf="!environment?.modeTeasing || connected">
                <div #banniereEvaluation></div>
                <app-info-goplex  [data]="data"></app-info-goplex>
                <!-- <app-informations [data]="data"></app-informations> -->
                <!-- <app-desc-appartement [data]="data"></app-desc-appartement> -->

                <app-map [data]="data" [afficherSatellite]="false" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-map> 
                
                <!-- <app-thumbnails [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-thumbnails>  -->
                <!-- <app-descriptif [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-descriptif>  -->
                
                <div class="banniere d-xs-block d-md-none">
                    <a [href]="'https://pmml.ca/evaluation' | translateValeur: 'https://pmml.ca/en/evaluation-services/'" target="_blank">
                        <img [src]="'/wp-content/uploads/2022/11/evaluation-marchande-ho-' + ('fr' | translateValeur : 'an') + '.jpg'" alt="">
                    </a>
                </div>
                <app-caracteristiques-goplex [data]="data"></app-caracteristiques-goplex>
                <app-rr-goplex [data]="data" *ngIf="data?.Loyers"></app-rr-goplex>
                <div #banniereFinancement></div>
                <div class="banniere d-xs-block d-md-none">
                    <a [href]="'https://pmml.ca/financement/' | translateValeur: 'https://pmml.ca/en/financing/'" target="_blank">
                        <img [src]="'/wp-content/uploads/2022/11/financement-ho-' + ('fr' | translateValeur : 'an') + '.jpg'" alt="">
                    </a>
                </div>
                <app-info-fin-plex [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-info-fin-plex>
                <div class="banniere d-xs-block d-md-none">
                    <a [href]="'https://pmml.ca/rapports/' | translateValeur: 'https://pmml.ca/en/reports/'" target="_blank">
                        <img [src]="'/wp-content/uploads/2022/11/rapport-ho-' + ('fr' | translateValeur : 'an') + '.jpg'" alt="">
                    </a>
                </div>
                <!-- <app-stats-locatifs [data]="data"></app-stats-locatifs> -->
                <div #banniereRapport></div>
                <app-stats-regional [data]="data" *ngIf="data?.StatsSocioDemoRegional"></app-stats-regional>
            
                <!-- <app-photos [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-photos> -->
            </div>
        </div>
        <div class="col-lg-3 colDroite d-none  d-md-block" #colDroite>
            <div></div>
            <div class="pos-fixed" [ngClass]="{scrolled: scrolled, scrolledMax:scrolledMax}" [style.top.px]="menuOffsetHaut" [style.width.px]="largeurBloqueCourtier">
                <!-- <app-info-courtier-immeuble [data]="data" [compacte]="scrolled"
                    ></app-info-courtier-immeuble> -->
                <div class="zone-pub">
                    <a [href]="'https://pmml.ca/evaluation' | translateValeur: 'https://pmml.ca/en/evaluation-services/'" target="_blank" [hidden]="!afficherBanniere.evaluation">
                        <img [src]="'/wp-content/uploads/2022/11/evaluation-marchande-ve-' + ('fr' | translateValeur : 'an') + '.jpg'" alt="">
                    </a>
                    <a [href]="'https://pmml.ca/financement/' | translateValeur: 'https://pmml.ca/en/financing/'" target="_blank" [hidden]="!afficherBanniere.financement">
                        <img [src]="'/wp-content/uploads/2022/11/financement-ve-' + ('fr' | translateValeur : 'an') + '.jpg'" alt="">
                    </a>
                    <a [href]="'https://pmml.ca/rapports/' | translateValeur: 'https://pmml.ca/en/reports/'" target="_blank" [hidden]="!afficherBanniere.rapport">
                        <img [src]="'/wp-content/uploads/2022/11/rapport-ve-' + ('fr' | translateValeur : 'an') + '.jpg'" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
