<div class="bootstrapImportation contact-form-gap contact-form">
    <form [formGroup]="form" class="d-flex flex-column p-4">
        <div>
            <mat-label
                [class.cf-erreurs]="!form.get('prenom')?.pristine && form.get('prenom').errors|firstValue:'value'">
                {{'@contact.sPrenom'|translate}}*</mat-label>
            <input formControlName="prenom" type="text">
            <ng-container [ngTemplateOutlet]="error" [ngTemplateOutletContext]="{$implicit:form.get('prenom')}">
            </ng-container>
        </div>

        <div>
            <mat-label [class.cf-erreurs]="!form.get('nom')?.pristine && form.get('nom').errors|firstValue:'value'">
                {{'@contact.sNom'|translate}}*</mat-label>
            <input formControlName="nom" type="text">
            <ng-container [ngTemplateOutlet]="error" [ngTemplateOutletContext]="{$implicit:form.get('nom')}">
            </ng-container>
        </div>

        <div>
            <mat-label
                [class.cf-erreurs]="!form.get('courriel')?.pristine && form.get('courriel').errors|firstValue:'value'">
                {{'@contact.sCourriel'|translate}}*</mat-label>
            <input formControlName="courriel" type="email">
            <ng-container [ngTemplateOutlet]="error" [ngTemplateOutletContext]="{$implicit:form.get('courriel')}">
            </ng-container>
        </div>

        <div>
            <mat-label
                [class.cf-erreurs]="!form.get('telephone')?.pristine && form.get('telephone').errors|firstValue:'value'">
                {{'@contact.sTelephone1'|translate}}*</mat-label>
            <input formControlName="telephone" type="tel">
            <ng-container [ngTemplateOutlet]="error" [ngTemplateOutletContext]="{$implicit:form.get('telephone')}">
            </ng-container>
        </div>

        <div>
            <mat-label>{{'@contact.commentaires'|translate}}</mat-label>
            <textarea formControlName="commentaires" rows="4" [style.resize]="'none'" type="text"></textarea>
            <ng-container [ngTemplateOutlet]="error" [ngTemplateOutletContext]="{$implicit:form.get('commentaires')}">
            </ng-container>
        </div>

        <div class="d-flex justify-content-center">
            <button *ngIf="!loading" (click)="submit()" class="cf-envoyer-btn">{{'@envoyer'|translate}}</button>
            <mat-spinner *ngIf="loading" [diameter]="32"></mat-spinner>
        </div>
    </form>
</div>

<ng-template #error let-control>
    <span class="cf-erreurs" *ngIf="!control.pristine">
        <ng-container [ngSwitch]="control.errors|firstValue: 'key'">
            <!-- <span *ngSwitchCase="'required'">{{"@required"|translate}}</span> -->
            <span *ngSwitchCase="'email'">{{"@courriel.valide"|translate}}</span>
            <span *ngSwitchCase="'pattern'">{{"@telephone.valide"|translate}}</span>
        </ng-container>
    </span>
</ng-template>