import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth0/auth0-angular';
import { ProprietesService } from '../api/proprietes.service';
import { SegmentService } from '../api/segment.service';
import * as AuthV1 from '../auth.service';
import { CompleteRegistrationComponent } from '../complete-registration/complete-registration.component';
import { AuthHelperService } from '../general/auth-helper.service';
import { OuvreLienComponentComponent } from '../general/ouvre-lien-component/ouvre-lien-component.component';

@Component({
  selector: 'app-page-rapports',
  templateUrl: './page-rapports.component.html',
  styleUrls: ['./page-rapports.component.scss']
})
export class PageRapportsComponent implements OnInit {
  TYPES_RAPPORTS = [
    {titre: '@propriete.type.multilogement', id: 'multilogement', imageFr: 'https://pmml.ca/wp-content/uploads/2022/11/sommaire-executif-multilogements-fr.jpg', imageAn: 'https://pmml.ca/wp-content/uploads/2022/11/sommaire-executif-multilogements-en.jpg'},
    {titre: '@propriete.type.industriel', id: 'industriel', imageFr: 'https://pmml.ca/wp-content/uploads/2022/11/sommaire-executif-industriel-fr.jpg', imageAn: 'https://pmml.ca/wp-content/uploads/2022/11/sommaire-executif-industriel-en.jpg'},
    // {titre: '@propriete.type.terrains', id: 'terrain', imageFr: 'https://pmml.ca/wp-content/uploads/2022/11/Sommaire-executif-cover-terrains.png', imageAn: ''},
    // {titre: '@propriete.type.commerceDetail', id: 'commerceDetail', imageFr: 'https://pmml.ca/wp-content/uploads/2022/11/Sommaire-executif-cover-terrains.png', imageAn: ''},
    // {titre: '@propriete.type.bureau', id: 'bureau', imageFr: 'https://pmml.ca/wp-content/uploads/2022/11/Sommaire-executif-cover-bureau.png', imageAn: ''},
    // {titre: '@rapports.hypothecaire', id: 'hypothecaire', imageFr: 'https://pmml.ca/wp-content/uploads/2022/11/Sommaire-executif-cover-marché-hypothécaire.png', imageAn: ''},
    // {titre: '@rapports.marche-capiteaux', id: 'marche-capitaux', imageFr: 'https://pmml.ca/wp-content/uploads/2022/11/Sommaire-executif-cover-marché-hypothécaire.png', imageAn: ''},
  ];
  REGIONS = this.proprietes.listeRegionsRapport()

  typeRapportSel: string;

  constructor(
    private dialog: MatDialog,
    private auth: AuthV1.AuthService,
    private authService: AuthService,
    private proprietes: ProprietesService, 
    private segment: SegmentService, 
  ) { }

  ngOnInit(): void {
  }

  choixTypeRapport(id: string) {
    this.typeRapportSel = id;
  }

  async afficherDialogeuConnexion(): Promise<any> {
    const dialogRef = this.dialog.open(CompleteRegistrationComponent, {
      maxWidth: '450px',
      width: '100vw',
      data: {},
    });

    const resultat = await dialogRef.afterClosed().toPromise();
    return resultat;
  }

  estAuth() {
    return new Promise((resolve, reject) => {
      if(this.auth.token) return resolve(true);
      const subscription = this.authService.isAuthenticated$.subscribe((isAuth) => {
          resolve(isAuth);
      });

      setTimeout(() => {
        subscription.unsubscribe();
        resolve(false);
      }, 300);
    });
  }

  afficheOuvreLienDialog(lien) {
    const dialogRef = this.dialog.open(OuvreLienComponentComponent, {
      maxWidth: '450px',
      width: '100vw',
      data: {
        titre: '@rapports.ouvrirLien',
        bouton: '@rapports.ouvrirLienMessage',
        lien,
      },
    });
  }

  async telechargerRapport(type, region) {
    try {
      let isAuth = await this.estAuth();
      if(!isAuth) {
        isAuth = await this.afficherDialogeuConnexion();
      }
      if(!isAuth) return;

      const idRapport = `${type}_${region}`;
      
      this.segment.track(`rapport`, {idRapport});

      const download = await this.auth.getReportDownload(idRapport, true);
      if(!download) return alert('Erreur lors du téléchargement. Veuillez nous contacter pour plus de détails.');

      this.afficheOuvreLienDialog(download);
    } catch (error) {
      console.error(error);
    }
  }
}
