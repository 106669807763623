import { RouteService } from './../route.service';
import { Component, OnInit } from '@angular/core';
import { WordpressService } from '../api/wordpress.service';
import { PmmltvInteractionService } from '../pmmltv-interaction.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pmml-chaines',
  templateUrl: './chaines.component.html',
  styleUrls: ['./chaines.component.scss']
})
export class ChainesComponent implements OnInit {
  chaines: any[];
  chaineChoisi: number;

  constructor(
    private wp: WordpressService,
    private pmmlTvInteraction: PmmltvInteractionService,
    private translate: TranslateService,
    private routeService: RouteService
  ) {}

  ngOnInit(): void {
    this.recupChaines();
    this.chaineChoisi = 29;
  }

  choix(chaine: string | number): void {
    this.pmmlTvInteraction.setChaine(chaine);
  }

  recupChaines(): void {
    this.wp.listeChaines().subscribe((chaines) => {
      if (window.location.hash) {
        const slug: any = window.location.hash || 'tout';
        if (this.routeService.hashExists(slug)) this.chaineChoisi = this.routeService.getCategorieID(slug);
      }
      const chainesTraduites = chaines.map((chaine) => ({
        id: chaine.id,
        name: this.translate.instant('@pmmltv.chaine.' + chaine.id)
      }));
      this.chaines = [{ id: 2016, name: this.translate.instant('@pmmltv.chaines.tout'), slug: 'tout' }, ...chainesTraduites];
    });
  }
}
