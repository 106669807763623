import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PhotoDialogComponent } from './photo-dialog.component';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  @Input() data: any;

  ngOnInit() {
  }

  ouvririPhoto(photo, index) {
    const dialogRef = this.dialog.open(PhotoDialogComponent, {
      width: '95vw',
      maxWidth: '1595px',
      data: {photo, index, listePhotos: this.data?.Photos},
      panelClass: 'photos',
    });
  }

}
