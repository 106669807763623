<div class="container p-3 p-md-0">
    <h3 class="pt-5 mb-4">{{ 'donneesLocatif' | translate }}</h3>

    <div class="col pt-3 pb-2 backgroundGris">
        <h5><i class="far fa-calendar"></i> Selon : les 12 derniers mois, dans la région de {{data.sNomVille}}</h5>
    </div>

    <!-- Tableau principal -->
    <div class="col">
        <table class="table table-striped pt-5 mt-5 w-80" >
            <thead>
                <tr>
                    <th class=""></th>
                    <th>Construction</th>
                    <th>{{ 'moyenne' | translate }}</th>
                    <th>{{ 'medianne' | translate }} </th>
                    <th>{{ 'loyerMin' | translate }} </th>
                    <th>{{ 'loyerMax' | translate }} </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Studio</th>
                    <td>1900 - 2021</td>
                    <td>578 $</td>
                    <td>600 $</td>
                    <td>900 $</td>
                    <td>1500 $</td>

                </tr>
                <tr>
                    <th>1 ½</th>
                    <td>1900 - 2021</td>
                    <td>578 $</td>
                    <td>600 $</td>
                    <td>900 $</td>
                    <td>1500 $</td>

                </tr>
                <tr>
                    <th>2 ½</th>
                    <td>1900 - 2021</td>
                    <td>578 $</td>
                    <td>600 $</td>
                    <td>900 $</td>
                    <td>1500 $</td>

                </tr>
                <tr>
                    <th>3 ½</th>
                    <td>1900 - 2021</td>
                    <td>578 $</td>
                    <td>600 $</td>
                    <td>900 $</td>
                    <td>1500 $</td>

                </tr>
                <tr>
                    <th>4 ½</th>
                    <td>1900 - 2021</td>
                    <td>578 $</td>
                    <td>600 $</td>
                    <td>900 $</td>
                    <td>1500 $</td>

                </tr>
                <tr>
                    <th>5 ½</th>
                    <td>1900 - 2021</td>
                    <td>578 $</td>
                    <td>600 $</td>
                    <td>900 $</td>
                    <td>1500 $</td>

                </tr>
            </tbody>
            <tfoot>
                <tr class="blanc final final-bas">
                    <th>Total </th>
                    <td>1900 - 2021</td>
                    <td>578 $</td>
                    <td>600 $</td>
                    <td>900 $</td>
                    <td>1500 $</td>
                </tr>
            </tfoot>
        </table>

    </div>

    <div class="row pt-5">
        <div class="col-sm-12 col-md-6">
            <!-- Type de logement -->
            <h3 class="titreNiveauDeux">{{ 'typeDelogement' | translate }}</h3>

            <div class="pt-3 text-center" >
                <canvas id="typeLogementDonut" width="300" ></canvas>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <!-- Moyenne de la région -->
            <h3 class="titreNiveauDeux">{{ 'moyenneRegion' | translate }}</h3>

            <table class="table table-striped pt-5 mt-5">
                <thead>
                    <tr>
                        <th class="w-25"></th>
                        <th>{{ 'moyenne' | translate }}</th>
                        <th>{{ 'medianne' | translate }} </th>
                        <th>{{ 'loyerMin' | translate }} </th>
                        <th>{{ 'loyerMax' | translate }} </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Studio</th>
                        <td>578 $</td>
                        <td>600 $</td>
                        <td>900 $</td>
                        <td>1500 $</td>

                    </tr>
                    <tr>
                        <th>1 ½</th>
                        <td>578 $</td>
                        <td>600 $</td>
                        <td>900 $</td>
                        <td>1500 $</td>

                    </tr>
                    <tr>
                        <th>2 ½</th>
                        <td>578 $</td>
                        <td>600 $</td>
                        <td>900 $</td>
                        <td>1500 $</td>

                    </tr>
                    <tr>
                        <th>3 ½</th>
                        <td>578 $</td>
                        <td>600 $</td>
                        <td>900 $</td>
                        <td>1500 $</td>

                    </tr>
                    <tr>
                        <th>4 ½</th>
                        <td>578 $</td>
                        <td>600 $</td>
                        <td>900 $</td>
                        <td>1500 $</td>

                    </tr>
                    <tr>
                        <th>5 ½</th>
                        <td>578 $</td>
                        <td>600 $</td>
                        <td>900 $</td>
                        <td>1500 $</td>

                    </tr>
                </tbody>
                <tfoot>
                    <tr class="blanc final final-bas">
                        <th>Total </th>
                        <td>578 $</td>
                        <td>600 $</td>
                        <td>900 $</td>
                        <td>1500 $</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>

    <div class="row pt-3 mt-5 backgroundGris">
        <div class="col-sm-12 col-md-6">
            <!-- Distribution des loyers -->
            <h3 class="titreNiveauDeux">{{ 'distributionLoyers' | translate }}</h3>

            <div class="text-center pt-5 mt-2">
                <canvas id="clocheMontantLoyer" width="420"></canvas>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <!-- Loyer moyen par année -->
            <h3 class="titreNiveauDeux">{{ 'loyerMoyenAnnee' | translate }}</h3>

            <div class="row d-flex justify-content-center">
                <div class="col">
                    <h5 class="text-center pb-0 mb-0">Studio</h5>
                    <canvas id="horizontaleAnneeLoyerMoyenStudio" width="250" height="120" ></canvas>
                </div>
                <div class="col">
                    <h5 class="text-center pb-0 mb-0">3 ½</h5>
                    <canvas id="horizontaleAnneeLoyerMoyenTroisDemi" width="250" height="120" ></canvas>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <h5 class="text-center pb-0 mb-0">4 ½</h5>
                    <canvas id="horizontaleAnneeLoyerMoyenQuatreDemi" width="250" height="120" ></canvas>
                </div>
                <div class="col">
                    <h5 class="text-center pb-0 mb-0">5 ½</h5>
                    <canvas id="horizontaleAnneeLoyerMoyenCinqDemi" width="250" height="120" ></canvas>
                </div>
            </div>
        </div>
    </div>


</div>