<div id="inf-sup" class="container -cache-overflow p-0 pt-lg-3 pb-3">
    <div class="row">

        <div class="col-auto" *ngIf="videoModePortrait && (largeur$|async) > 666">
            <div class="mx-auto">
                <ng-container *ngTemplateOutlet="videoPortraitTemplate"></ng-container>
            </div>
        </div>

        <div class="col">
            <div class="row px-0" *ngIf="videoModePortrait && (largeur$|async) <= 666">
                <div class="col">
                    <div class="mx-auto">
                        <ng-container *ngTemplateOutlet="videoPortraitTemplate"></ng-container>
                    </div>
                </div>
            </div>

            <div *ngIf="videoModePortrait && (largeur$|async) > 666" class="row"
                style="background: #343a40; color: white">
                <div class="col p-3 px-4 px-lg-3">
                    <h1>
                        {{data | pmmlProprieteTitre}}
                    </h1>
                    <h2>
                        <!-- Tout sauf iTerrain & iCommercial -->
                        <span class="unitesTotaux"
                            *ngIf="data?.sTypeLogement !== 'iTerrain' && data?.sTypeLogement !== 'iCommercial' && data?.sTypeLogement !== 'iSemiCommercial'">
                            <span *ngIf="data?.IDPropriete == 813299 && data?.iFlyer">700 + </span>
                            <span *ngIf="data?.IDPropriete == 813299 && !data?.iFlyer">{{ data?.iUnitesTotal }}
                            </span>
                            <span *ngIf="data?.IDPropriete !== 813299">{{ data?.iUnitesTotal }} </span>
                            <span
                                *ngIf="data?.sTypeLogement !== 'iHotel' && data?.sTypeLogement !== 'iUnitesChambre'">{{
                                '@menu.multilogement.unites' | translate }}</span>
                            <span *ngIf="data?.sTypeLogement == 'iHotel' || data?.sTypeLogement == 'iUnitesChambre'">{{
                                'chambres' | translate }}</span>
                        </span>

                        <!-- Semi-Commercial -->
                        <span class="unitesTotaux" *ngIf="data?.sTypeLogement == 'iSemiCommercial'">
                            {{ data?.iUnitesTotal }} {{ '@menu.multilogement.unites' | translate }}, {{
                            '@menu.terrain.superficie' | translate }} : {{ data?.iPiedsCarreTotal | number :
                            '1.0-0' }}
                            {{ '@pi2' | translate }}
                        </span>

                        <!-- Location -->
                        <span class="unitesTotaux" *ngIf="data?.sTypeLogement == 'iLocal' && data?.iPiedsCarreTotal">
                            <!-- {{'@menu.commercial.superficie' | translate}} :  -->
                            {{ data?.iPiedsCarreTotal | number : '1.0-0' }} {{ '@pi2' | translate }}
                        </span>

                        <!-- Terrain & Commercial -->
                        <span class="unitesTotaux"
                            *ngIf="(data?.sTypeLogement == 'iTerrain' || data?.sTypeLogement == 'iCommercial') && data?.iPiedsCarreTotal">
                            <!-- {{ '@menu.terrain.superficie' | translate }} : -->
                            {{ data?.iPiedsCarreTotal | number : '1.0-0' }} {{ '@pi2' | translate }}
                        </span>
                    </h2>
                </div>
                <div class="col-auto d-flex justify-content-center align-items-center px-3 pr-4">
                    <a [href]="('/' | translateValeur : '/en/') + 'location/' + data?.sLien  + aParamCode">
                        <i class="fal fa-file-pdf icone fa-lg"></i>
                    </a>
                    &nbsp; &nbsp;
                    <i (click)="ajouterFavoris(data, $event)" [ngClass]="{'fal': !data?.fav, 'fas': data?.fav}"
                        class="fa-lg fa-star cursor-pointer"></i>
                </div>
            </div>
            <div class="row align-items-center pl-xl-5">
                <div class="col-xs-12 section col-lg-6 mt-4">
                    <!-- Affichage du Prix -->
                    <div class="col pb-2" *ngIf="!data?.iMasquerPrix">
                        <h3 *ngIf="data?.sTypeLogement === 'iLocal'">{{ '@ilocal.superficie' | translate }}</h3>
                        <h4 *ngIf="data?.sTypeLogement === 'iLocal'">{{ data?.iPiedsCarreTotal | number : '1.0-0' }}
                            {{'@pi2' | translate}}</h4>

                        <h3 *ngIf="data?.sTypeLogement !== 'iLocal'">{{ '@menu.multilogement.prixdemande' | translate }}
                        </h3>

                        <h4 class="pb-4 pb-md-4 pb-lg-0" *ngIf=" data?.sTypeLogement !=='iLocal'">
                            {{ data?.fPrixDemande | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}}
                            <small *ngIf="data?.sTypeLogement === 'iSemiCommercial'">{{'@fiche.prix.plusTaxesSemi' |
                                translate}}</small>
                            <small *ngIf="data?.sTypeLogement === 'iCommercial' ">{{'@fiche.prix.plusTaxes' |
                                translate}}</small>
                            <small
                                *ngIf="data?.sTypeLogement !== 'iHotel' && data?.sTypeLogement !== 'iSemiCommercial' && data?.sTypeLogement !== 'iCommercial'"><br />{{'@fiche.prix.plusTaxes'
                                | translate}}</small>
                            <span style="font-size: 25px;"> <br> {{data?.sJsonData?.fr?.PrixPost | translateValeur :
                                data?.sJsonData?.an?.PrixPost}}</span>
                        </h4>
                    </div>

                    <!-- Information supplémentaire + Description / Desktop / Non Flyer-->
                    <div class="col d-none d-lg-block" *ngIf="!data.iFlyer">
                        <h3 *ngIf="data?.sTypeLogement === 'iTerrain'">{{ '@info.cadastre' | translate }}</h3>
                        <h4 *ngIf="data?.sTypeLogement === 'iTerrain'">{{ data?.sCadastre | translateValeur :
                            data?.Traduction?.sCadastre }}</h4>


                        <h3
                            *ngIf="(data?.sTypeLogement !== 'iLocal' && data?.sTypeLogement !== 'iTerrain' && data?.sTypeLogement !== 'iPortfolio' && !data?.iFlyer)">
                            {{ '@description.propriete' | translate }}
                        </h3>
                        <h3 *ngIf="data?.sTypeLogement === 'iLocal' && !data?.iFlyer ">
                            {{ '@description.local' | translate }}
                        </h3>
                        <h3 *ngIf="data?.sTypeLogement === 'iTerrain' && !data?.iFlyer">
                            {{ '@description.terrain' | translate }}
                        </h3>
                        <h3 *ngIf="data?.sTypeLogement === 'iPortfolio' && !data?.iFlyer">
                            {{ '@description.portfolio' | translate }}
                        </h3>
                        <!-- Description texte -->
                        <p class="pb-4 pb-md-4 pb-lg-0 text-center" *ngIf="!data?.iFlyer"
                            [innerHTML]="(data?.sRemarques | translateValeur : data?.Traduction?.sRemarques) | urlifyFromText">
                        </p>
                    </div>

                    <!-- Description All -->
                    <h3 *ngIf="data?.iFlyer">
                        {{ '@description.propriete' | translate }}
                    </h3>
                    <h3 *ngIf="data?.sTypeLogement === 'iLocal'">
                        {{ '@description.local' | translate }}
                    </h3>
                    <h3 *ngIf="data?.sTypeLogement === 'iTerrain'">
                        {{ '@description.terrain' | translate }}
                    </h3>
                    <h3 *ngIf="data?.sTypeLogement === 'iPortfolio'">
                        {{ '@description.portfolio' | translate }}
                    </h3>
                    <p class="pb-4 pb-md-4 pb-lg-0 text-center" *ngIf="data?.iFlyer"
                        [innerHTML]="(data?.sRemarques | translateValeur : data?.Traduction?.sRemarques) | urlifyFromText">
                    </p>

                    <!-- Information supplémentaire + Remarques / Desktop -->
                    <div class="col d-lg-none">
                        <h3 *ngIf="data?.sTypeLogement === 'iTerrain'">{{ '@info.cadastre' | translate }}</h3>
                        <h4 *ngIf="data?.sTypeLogement === 'iTerrain'">{{ data?.sCadastre | translateValeur :
                            data?.Traduction?.sCadastre }}</h4>


                        <h3
                            *ngIf="(data?.sTypeLogement !== 'iLocal' && data?.sTypeLogement !== 'iTerrain' && data?.sTypeLogement !== 'iPortfolio' && !data?.iFlyer)">
                            {{ '@description.propriete' | translate }}
                        </h3>
                        <h3 *ngIf="data?.sTypeLogement === 'iLocal' && !data?.iFlyer ">
                            {{ '@description.local' | translate }}
                        </h3>
                        <h3 *ngIf="data?.sTypeLogement === 'iTerrain' && !data?.iFlyer">
                            {{ '@description.terrain' | translate }}
                        </h3>
                        <h3 *ngIf="data?.sTypeLogement === 'iPortfolio' && !data?.iFlyer">
                            {{ '@description.portfolio' | translate }}
                        </h3>
                        <!-- Description texte -->
                        <p class="pb-4 pb-md-4 pb-lg-0 text-center" *ngIf="!data?.iFlyer"
                            [innerHTML]="(data?.sRemarques | translateValeur : data?.Traduction?.sRemarques) | urlifyFromText">
                        </p>

                        <h3
                            *ngIf="data?.sTypeLogement === 'iCommercial' && (data?.sAutre5 || data?.Traduction?.sAutre5)">
                            {{ 'titreInformationsSup' | translate }}</h3>
                        <p *ngIf="data?.sTypeLogement === 'iCommercial' && (data?.sAutre5 || data?.Traduction?.sAutre5)"
                            class="pb-4 pb-md-4 pb-lg-0 text-center"
                            [innerHTML]="(data?.sAutre5 | translateValeur : data?.Traduction?.sAutre5) | urlifyFromText">
                        </p>
                    </div>

                </div>

                <div class="col-xs-12 section col-lg-6">
                    <!-- Type d'immeuble -->
                    <div class="col pb-2" *ngIf="data?.sTypeBatiment">
                        <h3>{{ '@info.commercial.type' | translate }}</h3>
                        <h4 class="pb-4 pb-4 pb-md-4 pb-lg-0">{{ data?.sTypeBatiment | translateValeur :
                            data?.Traduction?.sTypeBatiment }}</h4>
                    </div>

                    <!-- Catégorie de l'immeuble -->
                    <div class="col" *ngIf="data?.sTypeLogement != 'iHotel'">
                        <h3>{{ '@info.categorie.immeuble' | translate }}</h3>
                        <h4 class="pb-md-4 pb-lg-0">{{ '@categorie.commerce.' + data?.IDCategorie | translate }}</h4>
                    </div>

                    <!-- Informations Supplémentaire / Desktop / Non Flyer -->
                    <div class="d-none d-lg-block" *ngIf="!data.iFlyer">
                        <h3
                            *ngIf="data?.sTypeLogement === 'iCommercial' && (data?.sAutre5 || data?.Traduction?.sAutre5)">
                            {{ 'titreInformationsSup' | translate }}</h3>
                        <p *ngIf="data?.sTypeLogement === 'iCommercial' && (data?.sAutre5 || data?.Traduction?.sAutre5)"
                            class="pb-4 pb-md-4 pb-lg-0 text-center"
                            [innerHTML]="(data?.sAutre5 | translateValeur : data?.Traduction?.sAutre5) | urlifyFromText">
                        </p>
                    </div>

                    <!-- Information supplémentaire + Description / Desktop / Flyer -->
                    <div class="col d-none d-lg-block" *ngIf="data.iFlyer">
                        <h3 *ngIf="data?.sTypeLogement === 'iTerrain'">{{ '@info.cadastre' | translate }}</h3>
                        <h4 *ngIf="data?.sTypeLogement === 'iTerrain'">{{ data?.sCadastre | translateValeur :
                            data?.Traduction?.sCadastre }}</h4>


                        <h3
                            *ngIf="(data?.sTypeLogement !== 'iLocal' && data?.sTypeLogement !== 'iTerrain' && data?.sTypeLogement !== 'iPortfolio' && !data?.iFlyer)">
                            {{ '@description.propriete' | translate }}
                        </h3>
                        <h3 *ngIf="data?.sTypeLogement === 'iLocal' && !data?.iFlyer ">
                            {{ '@description.local' | translate }}
                        </h3>
                        <h3 *ngIf="data?.sTypeLogement === 'iTerrain' && !data?.iFlyer">
                            {{ '@description.terrain' | translate }}
                        </h3>
                        <h3 *ngIf="data?.sTypeLogement === 'iPortfolio' && !data?.iFlyer">
                            {{ '@description.portfolio' | translate }}
                        </h3>
                        <!-- Description texte -->
                        <p class="pb-4 pb-md-4 pb-lg-0 text-center" *ngIf="!data?.iFlyer"
                            [innerHTML]="(data?.sRemarques | translateValeur : data?.Traduction?.sRemarques) | urlifyFromText">
                        </p>

                        <h3
                            *ngIf="data?.sTypeLogement === 'iCommercial' && (data?.sAutre5 || data?.Traduction?.sAutre5)">
                            {{ 'titreInformationsSup' | translate }}</h3>
                        <p *ngIf="data?.sTypeLogement === 'iCommercial' && (data?.sAutre5 || data?.Traduction?.sAutre5)"
                            class="pb-4 pb-md-4 pb-lg-0 text-center"
                            [innerHTML]="(data?.sAutre5 | translateValeur : data?.Traduction?.sAutre5) | urlifyFromText">
                        </p>
                    </div>

                </div>

                <div class="col text-center pt-5" *ngIf="data?.iFlyer == 1 && data?.iAbbrege != 1">
                    <h3>{{ "fiche.confidentiel.text" | translate }}</h3>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #videoPortraitTemplate>
    <div class="position-relative d-flex justify-content-center">
        <img *ngIf="!showVideo" (click)="lireVideo()" class="cursor-pointer overlay-content"
            [src]="data?.sVideoCouverture | translateValeur: data?.sVideoCouvertureAn" alt="">

        <div *ngIf="!showVideo" (click)="lireVideo()"
            class="cursor-pointer overlay-content d-flex justify-content-center align-items-center">
            <img width="80px" src="https://pmml.ca/images/shorts-logo.svg" alt="">
        </div>

        <iframe style="width: 100%; aspect-ratio: 9/16;" [src]="embed" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
    </div>
</ng-template>