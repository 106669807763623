import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { timer } from 'rxjs';
import { SegmentService } from 'src/app/api/segment.service';
import { AuthHelperService } from '../general/auth-helper.service';
import { CodeInputComponent } from '../general/code-input/code-input.component';
import * as AuthV1 from '../auth.service';

interface DialogData {
  idToken: string;
}

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss'],
})
export class CompleteRegistrationComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private authV1: AuthV1.AuthService,
    private authHelp: AuthHelperService,
    private router: Router,
    private analytics: SegmentService,
    @Optional() private ref: MatDialogRef<CompleteRegistrationComponent>
  ) {}

  @Input() generique = false;
  @Input() redirect = '';

  @ViewChild('codeEl') codeEl!: CodeInputComponent;

  get prefix() {
    if (this.generique) return '@verifier.generique';

    return `@verifier`;
  }

  step = 'loading';
  invalid = false;
  failed = false;
  idToken: string | null = '';
  user: any;

  // Inputs
  formInformationsContact = new FormGroup({
    phone: new FormControl('', [Validators.required]),
    confirm: new FormControl(false, [Validators.requiredTrue]),
    prenom: new FormControl('', [Validators.required]),
    nom: new FormControl('', [Validators.required]),
    courriel: new FormControl('', [Validators.required, Validators.email]),
  });
  code: any = '';
  validating = false;

  async ngOnInit() {
    this.user = await this.auth.getUser().toPromise()

    this.idToken = await this.authHelp.idToken$.toPromise();

    if (
      /^\+1[0-9]{10}$/.test(this.user?.[`PMML:phone_number`]) &&
      this.user?.[`PMML:phone_number_verified`] &&
      !this.generique
    ) {
      this.verified(true);
    } else {
      this.step = 'phone';
    }
  }

  verifiePhone() {
    if (!/^\d{10}$/.test(this.formInformationsContact.get('phone').value)) {
      this.invalid = true;
      return;
    }
    if (!this.formInformationsContact.get('confirm').value || this.formInformationsContact.invalid) return;

    this.http
      .post<any>(
        '/api/verif/phone?lang=' + this.lang,
        { 
          confirmRecevoir: this.formInformationsContact.get('phone').value, 
          ...this.formInformationsContact.value, 
          phone: '+1' + this.formInformationsContact.get('phone').value 
        },
        // { headers: { Authorization: `Bearer ${this.idToken}` } }
      )
      .subscribe(() => {
        this.step = 'code';

        this.analytics.track('validationSms', {
          user: this.user?.email,
          phone: this.formInformationsContact.get('phone').value,
        });

        setTimeout(() => {
          this.codeEl.input.nativeElement.focus();
        }, 100);
      });
  }

  lang = location.href.includes('/en') ? 'en' : 'fr';

  async verifieCode() {
    this.failed = false;

    if (this.validating) return;
    this.validating = true;

    const result = await 
      this.http.post<any>(
        '/api/verif/code',
        {
          code: this.code,
          generique: this.generique,
          redirect: this.redirect,
          ...this.formInformationsContact.value, 
          phone: '+1' + this.formInformationsContact.get('phone').value 
        },
        { headers: { Authorization: `Bearer ${this.idToken}` } }
      )
      .toPromise();

    this.validating = false;

    if (!result.success) {
      this.failed = true;
      this.codeEl.input.nativeElement.focus();
      return;
    }

    if(result.jwtAuth) {
      this.authV1.token = result.jwtAuth;
    }

    this.verified();
  }

  verified(dejaVerifie = false): any {
    this.step = 'verified';
    if (!dejaVerifie) {
      this.analytics.track('validationSmsReussie', {
        user: this.user?.email,
        phone: this.formInformationsContact.get('phone').value,
      });
    }

    if (!this.ref && dejaVerifie) return this.router.navigate(['/']);

    timer(5000).subscribe(() => {
      // if (this.generique && this.redirect) {
      //   if (this.redirect.startsWith('/')) {
      //     window.location.href = this.redirect;
      //     return;
      //   }

      //   window.location.href = 'https://pmml.ca';
      // }
      if(this.ref) return this.ref.close(true);

      this.router.navigate(['/']);
    });
  }
}
