<div class="boutonPrincipal" *ngIf="langue == 'fr-CA'" [matBadge]="longueurChatMessage" matBadgeOverlap="true" matBadgePosition="before" matBadgeColor="warn" (click)="ouvrirFermerChat()">
    <i class="fal fa-comment-lines"></i>
</div>

<div class="chatContaineur boxOmbre" [@animationChatContaineur] [hidden]="!popUpOuvert" *ngIf="langue == 'fr-CA'">
    <!-- Chat header -->
    <div class="boxMenuHaut">
        <!-- <img src="https://pmml.ca/wp-content/uploads/2020/04/pmml_logo_white_small-1.png" class="logo-pmml"> -->
        <!-- <div class="row" style="padding-left: 55px;">
            <div class="troisPhotos">
                <div class="boxImage">
                    <img class="imageAdmin" style="width: 50px;" src="https://pmml.ca/wp-content/uploads/2020/05/Sarah-Dias-E%CC%81quipe-de-donne%CC%81es.png">
                </div>
                <div class="boxImage">
                    <img class="imageAdmin" style="width: 50px;" src="https://pmml.ca/wp-content/uploads/2020/05/Adam-Veilleux.png">
                </div>
                <div class="boxImage">
                    <img class="imageAdmin" style="width: 50px;" src="https://pmml.ca/wp-content/uploads/2020/05/william-chhun.png">
                </div>
            </div>
        </div> -->
        <div>
            <img class="titrePMML" src="https://pmml.ca/wp-content/uploads/2020/04/pmml_logo_white_small.png">
            <!-- <p class="titreSysteme">Système d'assistance intilligent</p> -->
        </div>
        <div class="boutonFermer"  (click)="ouvrirFermerChat()">
            <i class="fal fa-times"></i>
        </div>
    </div>

    <!-- Assistance intilligente -->
    <div class="chatBox" #sectionChat>
        <div *ngFor="let message of chatMessages; let i = index" #chatMessagesDivs [ngClass]="{doScroll: !message?.noScroll}">
            <!-- Message unique -->
            <div class="boxMessageSrv" *ngIf="message.type == 'text'">
                <p class="h5 bulleMessageSrv" *ngIf="message.text" [innerHTML]="(message.text | urlifyFromText | nl2br )"></p>
                <p class="h5 bulleMessageSrv" *ngIf="message.mediaSociaux">
                    <ng-container *ngFor="let media of mediaSociaux">
                        <a [href]="media.value" target="_blank">{{media.text}}</a> &nbsp;&nbsp;
                    </ng-container>
                </p>
            </div>

            <!-- Input -->
            <div class="col-12" class="inputClient" *ngIf="message.type == 'input'">
                <mat-form-field class="float-right" appearance="fill">
                    <mat-label>{{ message.text | translateValeur: message.textAn }}*</mat-label>
                    <input matInput [(ngModel)]="tempInput" (ngModelChange)="envoyerMessage(message, tempInput, i, 'input')"/>
                </mat-form-field>
            </div>

            <!-- Question avec 5 choix et - -->
            <ng-container *ngIf="message.choices">
                <p *ngIf="message.text && message.choices" class="h5 bulleMessageSrv">{{(message.text)}}</p>

                <div class="col-12" *ngFor="let button of (message?.choices || [])">
                    <a class="bulleMessageClient" [disabled]="message.disabled" (click)="envoyerMessage(message, button, i, false)" [ngClass]="button.type">{{button.title}}</a>
                </div>
            </ng-container>
            <!-- Question avec 5 choix et + (Dropdown) -->
            <!-- <ng-container *ngIf="message.isDropdown && message.choices.length >= 5">
                <div class="col-12" *ngIf="message.text">
                    <p class="h5 bulleMessageSrv">{{(message.text)}}</p>
                </div>
                <mat-form-field appearance="fill" class="boxDropdownClient">
                    <mat-label>{{message.dropdownPlaceholder || "Choix ..."}}</mat-label>

                    <mat-select>
                        <mat-option *ngFor="let button of (message?.choices || [])" [value]="button.value" (click)="envoyerMessage(message, button.value)">
                            {{button.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>    
            </ng-container> -->
            <!-- Assistance par courriel -->
            <div *ngIf="message.formulaire && !message.formulaireEnvoye" class="mt-2" style="margin-top: 1em;">
                <form [formGroup]="formulaireClient" (ngSubmit)="soumettreFormulaire(message, formulaireClient.value)" >

                    <mat-form-field style="width: 47%;" appearance="fill">
                        <mat-label>{{ '@contact.sPrenom' | translate }}</mat-label>
                        <input matInput formControlName="prenomClient" required/>
                        <mat-error *ngIf="f.prenomClient.errors">{{ '@required' | translate }}</mat-error>
                    </mat-form-field>
                    &nbsp;       
                    <mat-form-field style="width: 47%;" appearance="fill">
                        <mat-label>{{ '@contact.sNom' | translate }}</mat-label>
                        <input matInput formControlName="nomClient" required/>
                        <mat-error *ngIf="f.nomClient.errors">{{ '@required' | translate }}</mat-error>
                    </mat-form-field>

                    <div class="inputFull">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ '@contact.sCourriel' | translate }}</mat-label>
                            <input matInput formControlName="courrielClient" required/>
                            <mat-error *ngIf="f.courrielClient.errors">{{ '@courriel.valide' | translate }}, ex: info@pmml.ca</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="inputFull">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ '@contact.sTelephone1' | translate }}</mat-label>
                            <input matInput formControlName="numeroTelClient" required/>
                            <mat-error *ngIf="f.messageClient.errors">{{ '@telephone.valide' | translate }}, ex: 514-360-3603</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="inputFull">
                        <mat-form-field class="textArea" appearance="fill">
                            <mat-label>Message</mat-label>
                            <textarea matInput formControlName="messageClient" required></textarea>
                            <mat-error *ngIf="f.messageClient.errors">{{ '@required' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="btnEnvoyer">
                        <button mat-raised-button class="couleurBtn" *ngIf="!courrielRecu" type="submit">{{ "@envoyer" | translate }}</button>
                    </div>
                </form>
            </div>
        </div>
        <div [hidden]="!messageChargement" class="bulleMessageSrv">
            <div class='spinner'><div class='bounce1'></div><div class='bounce2'></div><div class='bounce3'></div></div>
        </div>
    </div>

    
</div>

<!-- Bas du chat -->
<!-- <div class="row boxEnvoieMessage">
    <mat-form-field>
        <input matInput type="text" placeholder="Envoyer un message...">
    </mat-form-field>
    <i class="fas fa-paper-plane w-25 boutonEnvoyer pl-5 pt-3" type="button"></i>
</div> -->