import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PhotoUrlPipe } from 'src/app/pipes/photo-url.pipe';

@Component({
  selector: 'app-photo-dialog',
  templateUrl: './photo-dialog.component.html',
  styleUrls: ['./photo-dialog.component.scss']
})
export class PhotoDialogComponent implements OnInit {

  constructor(
    private pmmlPhoto: PhotoUrlPipe,
    public dialogRef: MatDialogRef<PhotoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.photo = data?.photo;
    this.listePhotos = data?.listePhotos || false;
    if(data?.listePhotos?.length > 1) this.affichageListe = true;
    this.index = data?.index || null;
  }

  photo: any;
  listePhotos: any;
  affichageListe = false;
  index: number;

  get src() {
    if (this.photo?.srcDirecte) return this.photo.src;
    return this.pmmlPhoto.transform(this.photo, 1400, true)
  }

  ngOnInit(): void {
  }

  precedente() {
    if(this.index == 0) this.index = this.listePhotos.length;
    this.photo = this.listePhotos[--this.index];
  }

  suivante() {
    if(this.index == this.listePhotos.length - 1) this.index = -1;
    this.photo = this.listePhotos[++this.index];
  }
  
  fermer() {
    this.dialogRef.close();
  }

  @HostListener('window:keydown', ['$event'])
  onKeydown(event) {
    if (event.key === 'ArrowLeft') this.precedente()
    if (event.key === 'ArrowRight') this.suivante()
  }

}
