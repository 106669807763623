import { AuthService } from "../../../auth.service";
import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { MatDialog } from "@angular/material/dialog";
import { ConnexionDialogComponent } from "../../connexion-dialog/connexion-dialog.component";
import { SegmentService } from './../../../api/segment.service';
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'app-info-fin-plex',
  templateUrl: './info-fin-plex.component.html',
  styleUrls: ['./info-fin-plex.component.scss']
})
export class InfoFinPlexComponent implements OnInit, AfterViewInit {

  @ViewChild('radio') radio: ElementRef;
  @ViewChild('container') container: ElementRef;
  @ViewChild('bubble') bubble: ElementRef;

  constructor(private pmmlArgent: CurrencyPipe, 
              private auth: AuthService, 
              private dialog: MatDialog, 
              private segment: SegmentService) { }

  top = 250;

  DEBOUNCE_TIME_SEGMENT = 1000;

  segmentPrixVente: Subject<any> = new Subject<string>();
  segmentOccupantInvestisseur: Subject<any> = new Subject<string>();
  segmentRevDep: Subject<any> = new Subject<string>();

  ngOnInit(): void {
    this.segmentOccupantInvestisseur.pipe(debounceTime(this.DEBOUNCE_TIME_SEGMENT), distinctUntilChanged()).subscribe(() => this.triggerAppelSegment('proprieteFicheTypeAchat', {
       proprietaireOccupant: this.proprietaireOccupant || false,
    }));

    this.segmentPrixVente.pipe(debounceTime(this.DEBOUNCE_TIME_SEGMENT), distinctUntilChanged()).subscribe(() => this.triggerAppelSegment('proprieteFichePrix', {
      fPrixClient: this.nettoyagePrix(this.prixProprieteClient) || 0,
      fPrixDemande: this.data?.fPrixDemande || 0,
    }));

    this.segmentRevDep.pipe(debounceTime(this.DEBOUNCE_TIME_SEGMENT), distinctUntilChanged()).subscribe(() => this.triggerAppelSegment('proprieteFicheFin', {
      fPrixClient: this.nettoyagePrix(this.prixProprieteClient) || 0,
      fPrixDemande: this.data?.fPrixDemande || 0,
      Revenus: this.arrRevenus || 0,
      Depenses: this.arrDepenses || 0,
    }));
  }
  
  ngAfterViewInit(): void {
    const cRect = this.container.nativeElement.getBoundingClientRect()
    const rRect = this.radio.nativeElement.getBoundingClientRect()
    const bRect = this.bubble.nativeElement.getBoundingClientRect()

    this.top = rRect.y - cRect.y + (bRect.height / 2) - 10;
    this.showBubble = false;
  }

  // Initialisation Variables
  prixProprieteClient;
  prixMiseDeFonds = 100000;
  arrRevenus = [];
  arrDepenses = [];

  proprietaireOccupant = true;
  logementOccupe: any;

  revenuTotalClient = 0;
  revenuTotalPourcentageClient = 100;

  depenseTotalClient: any;
  depenseTotalPourcentageClient = 100;

  revenuNetClient = 0;
  revenuNetPourcentageClient = 100;
  showBubble: boolean = true;
  playedAudio = false;

  estMobile = false;

  simulateurFinancement = {
    fMontantFinancement: 0,
    fPaiementMensuel: 0,
    cpl: 0,
    fMRB: 0,
    fPaiementAnnuel: 0,
    fLiquiditeAnnuel: 0,
    fLiquiditeMensuel: 0,
    fRendLiquidite: 0,
    fRendLiquiditeCapital: 0,
    iTerme: 5,
    iAmortissementAns: 25,
    fTaux: 3
  }

  typesCalcules = ['Propriétaire Occupant', 'Rentabilité Investisseur']

  // 1 ,3 ,5
  termes = [
    { value: 1, texte: '1 Ans' },
    { value: 3, texte: '3 Ans' },
    { value: 5, texte: '5 Ans' },
  ]

  amortissements = [
    { value: 5, texte: '5 Ans' },
    { value: 10, texte: '10 Ans' },
    { value: 15, texte: '15 Ans' },
    { value: 20, texte: '20 Ans' },
    { value: 25, texte: '25 Ans' },
    { value: 30, texte: '30 Ans' },
  ]

  private _data: any;
  @Input('data')
  public get data(): any {
    return this._data;
  }
  public set data(data: any) {
    this.reset(data);
    this.checkEstMobile();
  }

  reset(data) {
    this.logementOccupe = null;
    this._data = data;
    this.prixProprieteClient = this.pmmlArgent.transform(data.fPrixDemande, 'CAD', '', '1.0-0') || 100000;

    // Merge le loyer dans les Revenus
    this.data?.Loyers?.map((Loyer) => {
      if (Loyer.iMontant) {
        // const montantAnnuelLoyer = (Loyer.iMontant || 0) * 12;
        this.arrRevenus.push({
          key: Loyer.sIdentifiant,
          montantInitial: Number(Loyer.iMontant),
          valeur: this.pmmlArgent.transform(Number(Loyer.iMontant), 'CAD', '', '1.0-0') || 0,
          valeurMensuel: Number(Loyer.iMontant) || 0,
          pourcenAjustements: 0,
          champFr: Loyer.sIdentifiant,
          champEn: Loyer.sIdentifiant,
          proprietaireOccupant: false,
          Loyer: true
        })
      }
    });
    // Revenus
    this.arrRevenus = [...this.arrRevenus, ...this.initialisationRevDep(data, 'sAutreRevText')];

    // Dépenses
    this.arrDepenses = this.initialisationRevDep(data, 'sAutreDepText');

    // Totaux
    this.revenuTotalClient = data.fTotalRevenu / 12;
    this.depenseTotalClient = data.fTotalDepense / 12;
    this.revenuNetClient = data.fRevenuNet;

    this.calculFinancement();

  }

  @HostListener('window:resize')
  checkEstMobile() {
    this.estMobile = window.innerWidth <= 996;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const radio = this.radio.nativeElement;
    const yOnScreen = radio.getBoundingClientRect().y - window.innerHeight
    const dismissed = localStorage.getItem("dismiss-bubble") === '1'

    if (yOnScreen <= -150 && !this.showBubble && !dismissed && !this.playedAudio && !this.estMobile) {
      this.playedAudio = true;
      try {
        const audio = new Audio();
        audio.src = "../../../../assets/popup.mp3";
        audio.load();
        audio.play();
      } catch { }
      setTimeout(() => {
        this.showBubble = true;
      }, 250)
    }
  }

  initialisationRevDep(data, titreAutres) {
    try {
      let typeFinancement = 'schl';
      if (!this.data.fin.infoFinancement.schl.bActiver) typeFinancement = 'conv';

      this.simulateurFinancement.iAmortissementAns = this.data.fin.infoFinancement[typeFinancement].iAmortissementAns;
      this.simulateurFinancement.iTerme = this.data.fin.infoFinancement[typeFinancement].iTerme;
      this.simulateurFinancement.fTaux = this.data.fin.infoFinancement[typeFinancement].fTaux;
      this.prixMiseDeFonds = this.data.fin.infoFinancement[typeFinancement].fMiseDeFond; 

      let arrDonnees = [];
      let indexDepenses = 1;
      let variableAutres = titreAutres == 'sAutreRevText' ? 'fRevenusAutres' : 'fDepensesAutres';
      let champsDonnees = titreAutres == 'sAutreRevText' ? this.data?.proprieteConfig?.champsRevenu : this.data?.proprieteConfig?.champsDepense;

      // Traitement des depenses, titres, min, max, pourcentage
      for (let [key, value] of Object.entries(champsDonnees)) {
        if (key == 'fRevenusRes') continue;
        if (!key.includes(variableAutres)) {
          arrDonnees.push({
            key,
            valeur: this.pmmlArgent.transform((Number(data[key]) / 12), 'CAD', '', '1.0-0') || 0,
            montantInitial: (Number(data[key]) / 12) || 0,
            pourcenAjustements: 0,
            champFr: value['fr'],
            champEn: value['en'],
            champMax: (Number(data[key]) / 12) * 2
          });
        }
        // Revenus/Depenses Autres
        else {
          let DepenseAutresTitreFr, DepenseAutresTitreEn;
          if (indexDepenses !== 1 && variableAutres == 'fDepensesAutres') DepenseAutresTitreFr = data[titreAutres + String(indexDepenses)];
          else DepenseAutresTitreFr = data[titreAutres];
          if (indexDepenses !== 1 && variableAutres == 'fDepensesAutres') DepenseAutresTitreEn = data[titreAutres + String(indexDepenses) + "En"];
          else DepenseAutresTitreEn = data[titreAutres + "En"] || data[titreAutres];

          arrDonnees.push({
            key,
            valeur: this.pmmlArgent.transform(Number(data[key]) / 12, 'CAD', '', '1.0-0') || 0,
            montantInitial: (Number(data[key]) / 12) || 0,
            pourcenAjustements: 0,
            champFr: DepenseAutresTitreFr || 'Autres',
            champEn: DepenseAutresTitreEn || 'Other',
            champMax: (Number(data[key]) / 12) * 2
          });
          
          indexDepenses++;
        }
      }
      return arrDonnees;
    } catch (error) {
      console.error(error);
    }
  }

  nettoyerMontant(valeur) {
    return Number(valeur)
  }
  nettoyerPourcent(valeur) {
    return Number(valeur)
  }

  calcMontantSelonPourcentage(_montant, _pourcentage) {
    const montant = this.nettoyerMontant(_montant)
      , pourcentage = this.nettoyerPourcent(_pourcentage);
    
    return this.pmmlArgent.transform(Math.round(montant + ((pourcentage / 100) * montant)), 'CAD', '', '1.0-0');
  }

  calcPourcentageSelonMontant(_montantBase, _montant) {
    const montant = this.nettoyerMontant(_montant)
      , montantBase = this.nettoyerMontant(_montantBase);

    return this.pmmlArgent.transform(Math.round((montant - montantBase) / montantBase * 1_000) / 10, 'CAD', '', '1.0-1');
  }

  /**
   * 
   * @param champ 
   * @param type 
   * @param typeCalcul 
   */
  modificationRevDep(champ, type, typeCalcul) {
    if(champ) {
      champ.valeur = Number(String(champ?.valeur).replace(/\D*/g, ''))
      champ.pourcenAjustements = Number(String(champ.pourcenAjustements).replace(/\D*/g, ''))
    }

    if (type == 'revenus') {
      // % d'ajustements ou montant
      if (typeCalcul == 'pourcentage') { for (let value of this.arrRevenus) if (value.key == champ.key) value.valeur = this.calcMontantSelonPourcentage(champ.montantInitial, champ.pourcenAjustements); }
      else { for (let value of this.arrRevenus) if (value.key == champ.key) value.pourcenAjustements = this.calcPourcentageSelonMontant(champ.montantInitial, champ.valeur);}
    }

    if (['loyerOccupe', 'revenus'].includes(type)) {
      // Calcul du total 
      this.revenuTotalClient = 0;
      for (let value of this.arrRevenus) {
        if (this.proprietaireOccupant && this.logementOccupe == value.key) continue;
        this.revenuTotalClient += (Number(`${(value?.valeur || 0)}`.replace(/\D*/g, '')));
      }

      // % d'ajustements total
      this.revenuTotalPourcentageClient = (this.revenuTotalClient / this.data.fTotalRevenu) * 100;
    }

    if (type == 'depenses') {
      this.depenseTotalClient = 0;

      // % d'ajustements ou chiffre
      if (typeCalcul == 'pourcentage') { for (let value of this.arrDepenses) if (value.key == champ.key) value.valeur = this.calcMontantSelonPourcentage(champ.montantInitial, champ.pourcenAjustements); }
      else { for (let value of this.arrDepenses) if (value.key == champ.key) value.pourcenAjustements = this.calcPourcentageSelonMontant(champ.montantInitial, champ.valeur); }
      
      // Calcul du total
      for (let value of this.arrDepenses) this.depenseTotalClient += Number(+value.valeur);

      // % d'ajustements total
      this.depenseTotalPourcentageClient = (this.depenseTotalClient / this.data.fTotalDepense) * 100;
    }

    // Calcul revenu net
    this.revenuNetClient = this.revenuTotalClient - this.depenseTotalClient;
    this.revenuNetPourcentageClient = (this.revenuNetClient / this.data.fRevenuNet) * 100;
    this.segmentRevDep.next([this.arrRevenus, this.arrDepenses]);
    this.calculFinancement();
  }

  nettoyagePrix(nombre) {
    return Number(String(nombre).replace(/\D*/g, ''))
  }

  /**
   * Calculs pour le financement
   */
  calculFinancement() {
    let sF = 'simulateurFinancement';
    // Segment
    this.segmentPrixVente.next(this.prixProprieteClient);

    const prix = this.nettoyagePrix(this.prixProprieteClient);

    let typeFinancement = 'schl';
    if (!this.data.fin.infoFinancement.schl.bActiver) typeFinancement = 'conv';

    // Mise de fonds
    this.prixMiseDeFonds = prix * (1 - this.data.fin.infoFinancement[typeFinancement].fPourcentFinancement);

    // Calcul du montant de financement
    this[sF].fMontantFinancement = prix * this.data.fin.infoFinancement[typeFinancement].fPourcentFinancement;
    
    // CPL
    this[sF].cpl = prix / this.data.iUnitesTotal;

    // MRB
    this[sF].fMRB = prix / (this.revenuTotalClient * 12);

    // Versement Mensuelle
    this[sF].fPaiementMensuel = this.calculPaiementMensuel((prix - this.prixMiseDeFonds), this[sF].fTaux, this[sF].iAmortissementAns)

    // Paiement hypothécaire Annuel
    this[sF].fPaiementAnnuel = this[sF].fPaiementMensuel * 12;

    // Liquidité annuel
    this[sF].fLiquiditeAnnuel = this.revenuNetClient - this[sF].fPaiementAnnuel;
    this[sF].fLiquiditeMensuel = this[sF].fLiquiditeAnnuel / 12;

    //Rendement Liquidite + Liquidite & Capital
    this[sF].fRendLiquidite = this[sF].fLiquiditeAnnuel / this.prixMiseDeFonds * 100;

    // Calcul du paiement en capital
    let fPaiementCapitalA1 = this.calculPaiementCapital(this[sF].fTaux, this[sF].fMontantFinancement, this[sF].fPaiementMensuel);

    this[sF].fRendLiquiditeCapital = (this[sF].fRendLiquidite + fPaiementCapitalA1) / this.prixMiseDeFonds * 100, 3;
  }

  calculPaiementCapital(fTaux, fMontantFinancement, fPaiementMensuel) {
    let fInteretEffectifMensuel = this.calculInteretEffectifMensuel(fTaux);

    let annee = 1
      , capitalAnnee = 0
      , interetAnnee = 0
      , clefChampCapital = {}
      , clefChampInteret = {}
      , fBalance = fMontantFinancement;


    for (let mois = 1; mois <= 72; mois++) {
      let fInteret = fBalance * fInteretEffectifMensuel
        , fCapital = fPaiementMensuel - fInteret;

      fBalance -= fCapital;
      capitalAnnee += fCapital;
      interetAnnee += fInteret;

      if (mois % 12 == 0) {
        clefChampCapital[annee] = capitalAnnee;
        clefChampInteret[annee] = interetAnnee;
        interetAnnee = 0, capitalAnnee = 0;
        annee++;
      }

    }
    // Retour de la première année de capital
    return clefChampCapital['6'];
  }

  calculPaiementMensuel(fPrixProprieteClient, fTaux, iAmortissement) {
    let fInteretEffectifMensuel = this.calculInteretEffectifMensuel(fTaux);
    return (fPrixProprieteClient * fInteretEffectifMensuel) / (1 - Math.pow(1 + fInteretEffectifMensuel, -(iAmortissement * 12) ));
  }

  calculInteretEffectifMensuel(fTaux) {
    if (+fTaux > 0.4) fTaux = +fTaux / 100;
    return Math.pow(1 + (fTaux / 2), 1 / 6) - 1;
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }
    return value;
  }

  returnZero() { return 0; }

  calculMontantFin(...actuels: any): any {
    let montant = 0;
    for (let i = 0; i < actuels.length; i++) {
      const element = actuels[i];
      if (element.bActiver === 1) {
        montant += element.fMontantFinancement;
      }
    }
    return montant;
  }

  dismissBubble() {
    this.showBubble = false;
    localStorage.setItem('dismiss-bubble', '1')
  }

  secure(dialog = false) {
    // Désavtivez pour phase 1
    return true;
    const estConnecte = !!this.auth.token

    if (dialog && !estConnecte) {
      this.dialog.open(ConnexionDialogComponent, { width: '50vw', maxWidth: '500px' })
    }

    return estConnecte;
  }

  setProprietaireOccupant(value) {
    if (!this.secure(true)) return;
    this.proprietaireOccupant = value
    this.segmentOccupantInvestisseur.next(this.proprietaireOccupant);
  }

  onRadioClick(key) {
    if (this.logementOccupe === key)
      setTimeout(() => this.logementOccupe = null);
  }
  
  triggerAppelSegment(typeModification: any, data: Partial<{fPrixClient:any, fPrixDemande:any, Revenus:any, Depenses:any, proprietaireOccupant:any}>) {
    let donneesEnvoyes = { 
      ...data,
      IDPropriete: this.data?.IDPropriete || null
    };


    this.segment.contactAction(typeModification, donneesEnvoyes);
  }
}
