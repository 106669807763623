<div class="d-flex flex-column align-items-center gap-4">
    <ng-container *ngIf="!generique">
        <img class="logo" src="https://pmml.ca/images/logo-pmml-noir.svg">
    </ng-container>

    <div class="d-flex flex-column gap-3 w-100" *ngIf="step !== 'loading'">
        <h3 class="mb-2 text-center header-msg">
            {{prefix + '.titre' | translate}}
        </h3>

        <span class="text-center header-sub">
            {{prefix + '.' + step + '.soustitre' | translate}}
            <br>
            <i *ngIf="step === 'code'"> +1 {{formInformationsContact.get('phone').value | mask : '(999) 999-9999'}}</i>
        </span>

        <!-- ENTRÉE DES INFORMATIONS -->
        <ng-container *ngIf="step === 'phone'">
            <form [formGroup]="formInformationsContact">
                <div class="row mt-2">
                    <!-- MOBILE -->
                    <mat-form-field class="col-12 mb-0" appearance="outline">
                        <mat-label>{{'@contact.sTelephoneSMS' | translate}}</mat-label>
                        <input [class.invalid]="invalid" matInput (ngModelChange)="invalid = false"
                        formControlName="phone" prefix="+1 " mask="(000) 000-0000">
                        <!-- <mat-error *ngIf="formInformationsContact.get('phone')?.invalid">ZZZ</mat-error> -->
                    </mat-form-field>
                        <!-- <i *ngIf="invalid" class="invalid">{{prefix + '.phone.invalid' | translate}}</i> -->
                    <div class="col-12 d-flex align-items-center gap-3 mt-0 mb-1">
                        <mat-checkbox formControlName="confirm" color="primary">
                            <div class="text-wrap pl-1" style="line-height: 1;">
                                <small>{{prefix + '.accept.sms'|translate}}</small>
                            </div>
                        </mat-checkbox>
                    </div>
                    <!-- PRÉNOM -->
                    <mat-form-field appearance="outline" class="col-xs-12 col-6">
                        <mat-label>{{'@contact.sPrenom' | translate}}</mat-label>
                        <input matInput formControlName="prenom">
                        <!-- <mat-error *ngIf="formInformationsContact.get('prenom')?.invalid">ZZZ</mat-error> -->
                    </mat-form-field>
                    <!-- NOM -->
                    <mat-form-field class="col-xs-12 col-6" appearance="outline">
                        <mat-label>{{'@contact.sNom' | translate}}</mat-label>
                        <input matInput formControlName="nom">
                        <!-- <mat-error *ngIf="formInformationsContact.get('nom')?.invalid">ZZZ</mat-error> -->
                    </mat-form-field>
                    <!-- COURRIEL -->
                    <mat-form-field appearance="outline" class="col-12 w-100">
                        <mat-label>{{'@contact.sCourriel' | translate}}</mat-label>
                        <input matInput formControlName="courriel">
                        <!-- <mat-error *ngIf="formInformationsContact.get('courriel')?.invalid">ZZZ</mat-error> -->
                    </mat-form-field>

                    <div class="col-12">
                        <button (click)="verifiePhone()" class="auth0-button" [disabled]="formInformationsContact?.invalid">
                            <i class="fal fa-mobile"></i>
                            {{prefix + '.phone.bouton'|translate}}
                        </button>
                    </div>
                </div>
            </form>
        </ng-container>

        <!-- CODE DE VALIDATION SMS -->
        <ng-container *ngIf="step === 'code'">
            <div class="mt-4 mb-4">
                <app-code-input #codeEl [(ngModel)]="code" (ngModelChange)="failed = false" [failed]="failed">
                </app-code-input>
            </div>
            <!-- todo: Handle invalid code entered -->

            <i class="text-center invalid" *ngIf="failed">{{prefix + '.code.invalid'|translate}}</i>

            <button (click)="verifieCode()" class="auth0-button">
                <i class="fal fa-check"></i>
                {{prefix + '.code.bouton'|translate}}
            </button>

            <a style="cursor: pointer; width: min-content; white-space: nowrap; margin: 0 auto; font-size: 0.75rem;"
                (click)="step = 'phone'; code = ''; failed = false">{{prefix + '.code.back'|translate}}</a>
        </ng-container>
    </div>

    <mat-spinner *ngIf="step === 'loading'" [diameter]="32"></mat-spinner>
</div>


<!-- @verifier.titre -->
<!-- @verifier.phone.soustitre -->
<!-- @verifier.phone.bouton -->
<!-- @verifier.code.soustitre -->
<!-- @verifier.code.bouton -->
<!-- @verifier.code.back -->