<div class="liste-suite">
  <ul>
    <li *ngFor="let proprieteEnfant of data?.ProprietesEnfants; let index = index">
      <button [ngClass]="{'-actif': boutonActif == proprieteEnfant?.sLien}"
        (click)="positionnementProprieteEnfant(index, proprieteEnfant?.sLien)">
        {{ proprieteEnfant?.sNumeroCivique }} {{ proprieteEnfant?.sRue }}, {{ proprieteEnfant?.sNomVille }}</button>
    </li>
  </ul>
</div>

<!-- Spinner -->
<div class="container pt-5 mt-5 pb-5" *ngIf="spinner == true">
  <div class="row justify-content-center">
    <div class="spinner-border" style="width: 3rem;height: 3rem;" role="status">
      <span class="sr-only">{{ '@general.loading' | translate }}</span>
    </div>
  </div>
</div>

<div *ngIf="spinner ==false">
  <div class="container evaluation-municipale pt-5 pb-4">
    <h3 class="summary w-100 mb-3"><i class="fas fa-usd-circle"></i> {{ '@evaluation.municipale' | translate }}</h3>
    <div class="row marge justify-content-center">
      <div class="col-11 col-sm-11 col-lg-11">
        <table class="table table-striped">
          <tbody>
            <tr>
              <th>{{ '@evaluation.municipale.terrain' | translate }}</th>
              <td>{{ proprieteActuel?.sEvaluationMunicipaleTerrain  }}</td>
            </tr>
            <tr>
              <th>{{ '@evaluation.municipale.batiment' | translate }}</th>
              <td>{{ proprieteActuel?.sEvaluationMunicipaleBatiment }}</td>
            </tr>
            <tr>
              <th>{{ '@evaluation.municipale.total' | translate }}</th>
              <td>{{ proprieteActuel?.sEvaluationMunicipale }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="container sommaire-loyers" id="sommaires0" *ngIf="data?.sTypeLogement === 'iPortfolio' && data?.iMasquerLoyers !== 1">
    <h3 class="summary w-100 mb-3"><i class="fas fa-building"></i> {{ '@loyers.titre' | translate }}</h3>
    <div class="row marge justify-content-center">
      <div class="col-11 col-sm-11 col-lg-11">
        <div class="se-connecter text-center" *ngIf="!connected">
          <p class="texte text-center">{{ '@loyers.pourVisionner' | translate }}</p>
          <button class="connect" *ngIf="!connected" (click)="seConnecter()">{{'@se.connecter' | translate}}</button>
        </div>
        <div *ngIf="connected">
          <table class="table table-striped">
            <thead>
              <tr class="top-barre">
                <th class="text-left" style="width: 100px">{{ '@loyers.colLogement' | translate }}</th>
                <th class="text-center" style="width: 150px">{{ '@loyers.colType' | translate }}</th>
                <th class="text-center" style="width: 120px">{{ '@loyers.colMontant' | translate }}</th>
                <th class="text-center d-none d-lg-table-cell" style="width: 150px">
                  {{ '@loyers.colEcheance' | translate }}</th>
                <th class="text-center d-none d-lg-table-cell">{{ '@loyers.colRemarque' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let loyer of data?.Loyers | trierSomloyers: proprieteActuel.IDPropriete:'portfolio'">
                <td>{{ loyer?.sIdentifiant }}</td>
                <td class="text-center">{{ loyer?.sType }}</td>
                <td class="text-center">{{ loyer?.iMontant | pmmlArgent }}</td>
                <td class="text-center d-none d-lg-table-cell">
                  <span [hidden]="loyer?.dEcheance != '0000-00-00'">{{ '@loyers.echeance.annuel' | translate }}</span>
                  <span [hidden]="loyer?.dEcheance != '1960-01-01'">{{ '@loyers.echeance.mensuel' | translate }}</span>
                  <span
                    [hidden]="loyer?.dEcheance == '0000-00-00' || loyer?.dEcheance == '0000-00-00' || !loyer?.dEcheance">{{ loyer?.dEcheance | date : 'shortDate' }}</span>
                  <span [hidden]="loyer?.dEcheance">{{ '@loyers.echeance.vancant' | translate }}</span>
                </td>
                <td class=" d-none d-lg-table-cell">{{ loyer?.sCommentaires }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr *ngIf="statsLoyers.totalLoyersRes">
                <th colspan="2">{{ '@loyers.totalLoyersRes' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalLoyersRes | pmmlArgent}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <tr *ngIf="statsLoyers.totalLoyersComm">
                <th colspan="2">{{ '@loyers.totalLoyersComm' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalLoyersComm | pmmlArgent}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <!-- <tr *ngIf="statsLoyers.totalLoyersCommRecup">
                            <th colspan="2">{{ '@loyers.totalLoyersCommRecup' | translate }}</th>
                            <th class="text-center">{{statsLoyers.totalLoyersCommRecup | pmmlArgent}}</th>
                            <th class=" d-none d-lg-table-cell"></th>
                            <th class=" d-none d-lg-table-cell"></th>
                        </tr> -->
              <tr *ngIf="statsLoyers.totalLoyersRang">
                <th colspan="2">{{ '@loyers.totalLoyersRang' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalLoyersRang | pmmlArgent}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <tr *ngIf="statsLoyers.totalSta">
                <th colspan="2">{{ '@loyers.totalSta' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalSta | pmmlArgent}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <tr *ngIf="statsLoyers.totalAutre">
                <th colspan="2">{{ '@loyers.totalAutre' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalAutre | pmmlArgent}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <tr>
                <th colspan="2">{{ '@loyers.totalMensuel' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalMensuel | pmmlArgent}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <tr>
                <th colspan="2">{{ '@loyers.totalAnnuel' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalAnnuel | pmmlArgent}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="container sommaire-loyers" id="sommaires" *ngIf="data?.sTypeLogement === 'iPortfolioComm' && data?.iMasquerLoyers !== 1">
    <h3 class="summary w-100 mb-3"><i class="fas fa-building"></i> {{ '@loyers.titre' | translate }}</h3>
    <div class="row marge justify-content-center">
      <div class="col-11 col-sm-11 col-lg-11">
        <div class="se-connecter text-center" *ngIf="!connected">
          <p class="texte text-center">{{ '@loyers.pourVisionner' | translate }}</p>
          <button class="connect" *ngIf="!connected" (click)="seConnecter()">{{'@se.connecter' | translate}}</button>
        </div>
        <div *ngIf="connected">
          <table class="table table-striped">
            <thead>
              <tr class="top-barre">
                <th class="text-left" style="width: 100px">{{ '@loyers.colUnite' | translate }}</th>
                <th class="text-center" style="width: 150px">{{ '@menu.terrain.superficie' | translate }}</th>
                <th class="text-center" style="width: 120px">{{ '@loyers.colMontant' | translate }}</th>
                <th class="text-center d-none d-lg-table-cell" style="width: 150px">
                  {{ '@loyers.colEcheance' | translate }}</th>
                <th class="text-center d-none d-lg-table-cell">{{ '@loyers.colRemarque' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let loyer of data?.Loyers  | trierSomloyers: proprieteActuel.IDPropriete">
                <td>{{ loyer?.sIdentifiant }}</td>
                <td class="text-center">{{ loyer?.iPiedsCarre }}</td>
                <td class="text-center">{{ loyer?.iMontant | currency : 'CAD' : 'symbol-narrow' : '0.2-2' }}</td>
                <td class="text-center d-none d-lg-table-cell">
                  <span>{{ loyer?.dEcheance | echeanceloyers }}</span>
                </td>
                <td class=" d-none d-lg-table-cell">{{ loyer?.sCommentaires }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr *ngIf="statsLoyers.totalLoyersRes">
                <th colspan="2">{{ '@loyers.totalLoyersRes' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalLoyersRes | currency : 'CAD' : 'symbol-narrow' : '0.2-2'}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <tr *ngIf="statsLoyers.totalLoyersComm">
                <th colspan="2">{{ '@loyers.totalLoyersComm' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalLoyersComm | currency : 'CAD' : 'symbol-narrow' : '0.2-2'}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <!-- <tr *ngIf="statsLoyers.totalLoyersCommRecup">
                            <th colspan="2">{{ '@loyers.totalLoyersCommRecup' | translate }}</th>
                            <th class="text-center">{{statsLoyers.totalLoyersCommRecup | pmmlArgent}}</th>
                            <th class=" d-none d-lg-table-cell"></th>
                            <th class=" d-none d-lg-table-cell"></th>
                        </tr> -->
              <tr *ngIf="statsLoyers.totalLoyersRang">
                <th colspan="2">{{ '@loyers.totalLoyersRang' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalLoyersRang | currency : 'CAD' : 'symbol-narrow' : '0.2-2'}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <tr *ngIf="statsLoyers.totalSta">
                <th colspan="2">{{ '@loyers.totalSta' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalSta | currency : 'CAD' : 'symbol-narrow' : '0.2-2'}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <tr *ngIf="statsLoyers.totalAutre">
                <th colspan="2">{{ '@loyers.totalAutre' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalAutre | currency : 'CAD' : 'symbol-narrow' : '0.2-2'}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <tr>
                <th colspan="2">{{ '@loyers.totalMensuel' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalMensuel | currency : 'CAD' : 'symbol-narrow' : '0.2-2'}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
              <tr>
                <th colspan="2">{{ '@loyers.totalAnnuel' | translate }}</th>
                <th class="text-center">{{statsLoyers.totalAnnuel | currency : 'CAD' : 'symbol-narrow' : '0.2-2'}}</th>
                <th class=" d-none d-lg-table-cell"></th>
                <th class=" d-none d-lg-table-cell"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>