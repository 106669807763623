import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-matterport',
  templateUrl: './matterport.component.html',
  styleUrls: ['./matterport.component.scss']
})
export class MatterportComponent implements OnInit {

  constructor() { }
  
  @Input() data: any;

  matterportActif: any;
  anyMatterport: boolean;

  ngOnInit(): void {
    this.anyMatterport = this.regardeSiMatterports(this.data?.ProprietesEnfants);
    for (let proprieteEnfant of this.data?.ProprietesEnfants) {
      if (proprieteEnfant.sJsonData.UrlMatterport) {
        this.setUrlMatterport(proprieteEnfant.sJsonData.UrlMatterport)
        break;
      }
    }    
  }

  public setUrlMatterport(url) {   
    this.matterportActif = url;
  }

  regardeSiMatterports(enfants) {
    return enfants.some(enfant => enfant.sJsonData.UrlMatterport);
  }
}
