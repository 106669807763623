<div class="container-fluid bootstrapImportation removeBoostrapPadding">

    <app-courtier-carte-overlay *ngIf="overlayActif" [url]="lienCarteCourtier" [courtier]="detailsCourtier"
        (close)="closeOverlay()" [modeCourtier]="true"></app-courtier-carte-overlay>

    <div class="row no-gutters">
        <div class="col-xs-12 col-lg-4 col-xl-3 viewBackground overflow-x-hidden"
            style="background-image: url('{{backGroundCourtier}}');">
            <div class="pt-5 contenu">

                <div class="infoCourtier">
                    <img class="photoCourtier d-lg-none" [src]="photoCourtier" (click)="openOverlay()">
                    <img class="photoCourtier d-none d-lg-block" [src]="photoCourtier">
                    <p class="p-0 mb-0" *ngIf="detailsCourtier?.sJsonSocial?.prenom" style="text-transform: uppercase;">
                        {{ detailsCourtier?.sJsonSocial?.prenom }}&nbsp;{{ detailsCourtier?.sJsonSocial?.nom }}</p>
                    <p class="p-0 mb-0" *ngIf="!detailsCourtier?.sJsonSocial?.prenom"
                        style="text-transform: uppercase;">{{ detailsCourtier?.sNomUser }}</p>

                    <span *ngIf="detailsCourtier?.sNomEquipe" style="font-size: 1.1rem; margin-top: 8px;">
                        {{'Équipe' | translateValeur: 'Team'}}&nbsp;{{detailsCourtier?.sNomEquipe}}
                    </span>

                    <span *ngIf="detailsCourtier?.sJsonSocial?.titreEquipe" style="font-size: 1.1rem; margin-top: 8px;">
                        {{ detailsCourtier?.sJsonSocial?.titreEquipe | translateValeur:
                        detailsCourtier?.sJsonSocial?.titreEquipeAn }}
                    </span>

                    <span *ngIf="!detailsCourtier?.sJsonSocial?.titreEquipe"
                        style="font-size: 1.1rem; margin-top: 8px;">
                        {{ detailsCourtier?.sTitre | translateValeur: detailsCourtier?.sTitreEn }}
                    </span>

                </div>

                <p *ngIf="detailsCourtier?.sCodeUsager === 'cidrik&fanny'"
                    style="font-size: 10pt; font-weight: normal; padding: 0 15%; text-align: center; line-height: 1.25;">
                    Vente, achat ou location d'immeubles industriels partout au Québec.
                    <br><br>
                    Présence provinciale et réseau international.
                    <br><br>
                    Mon vaste réseau de contact en immobilier industriel facilite la recherche
                    d'immeubles au Québec et à l'extérieur pour soutenir l'expansion et la croissance de votre
                    entreprise.
                </p>

                <a *ngIf="detailsCourtier?.sTelephone" href="tel:{{ detailsCourtier?.sTelephone }}"
                    (click)="segmentTrack('courtierTel')">
                    <p><i class="fal fa-phone-alt"></i>&nbsp;&nbsp;{{ detailsCourtier?.sTelephone }}</p>
                </a>
                <a *ngIf="!detailsCourtier?.sTelephone" href="tel:514-360-3603" (click)="segmentTrack('pmmlTel')">
                    <p><i class="fal fa-phone-alt"></i>&nbsp;&nbsp;514-360-3603</p>
                </a>

                <div class="row" style="margin-top: 12px; margin-bottom: 12px;">
                    <!-- LINKEDIN -->
                    <div *ngIf="detailsCourtier?.sJsonSocial?.linkedIn" class="col">
                        <a [href]="detailsCourtier?.sJsonSocial?.linkedIn" (click)="segmentTrack('courtierLinkedin')"
                            target="_blank"><span class="fontSizeIcon"><i class="fab fa-linkedin"></i></span></a>
                    </div>
                    <div *ngIf="!detailsCourtier?.sJsonSocial?.linkedIn" class="col">
                        <a href="https://www.linkedin.com/company/pmml/" (click)="segmentTrack('pmmlLinkedin')"
                            target="_blank"><span class="fontSizeIcon"><i class="fab fa-linkedin"></i></span></a>
                    </div>
                    <!-- FACEBOOK -->
                    <div *ngIf="detailsCourtier?.sJsonSocial?.facebook" class="col">
                        <a [href]="detailsCourtier?.sJsonSocial?.facebook" (click)="segmentTrack('courtierFacebook')"
                            target="_blank"><span class="fontSizeIcon"><i class="fab fa-facebook"></i></span></a>
                    </div>
                    <div *ngIf="!detailsCourtier?.sJsonSocial?.facebook" class="col">
                        <a href="https://www.facebook.com/pmml.ca" (click)="segmentTrack('pmmlFacebook')"
                            target="_blank"><span class="fontSizeIcon"><i class="fab fa-facebook"></i></span></a>
                    </div>
                    <!-- INSTAGRAM -->
                    <div *ngIf="detailsCourtier?.sJsonSocial?.instagram" class="col">
                        <a [href]="detailsCourtier?.sJsonSocial?.instagram" (click)="segmentTrack('courtierInstagram')"
                            target="_blank"><span class="fontSizeIcon"><i class="fab fa-instagram"></i></span></a>
                    </div>
                    <div *ngIf="!detailsCourtier?.sJsonSocial?.instagram" class="col">
                        <a href="https://www.instagram.com/pmml.ca" (click)="segmentTrack('pmmlInstagram')"
                            target="_blank"><span class="fontSizeIcon"><i class="fab fa-instagram"></i></span></a>
                    </div>
                    <!-- EMAIL -->
                    <div class="col">
                        <a href="mailto:{{detailsCourtier?.sCourriel}}" (click)="segmentTrack('courtierCourriel')"
                            target="_blank"><span class="fontSizeIcon"><i class="far fa-at"></i></span></a>
                    </div>
                </div>
                <div class="d-xl-none d-lg-none mt-3">
                    <div class="d-flex px-3 boutons w-100" [style.gap.rem]="1.2">
                        <button type="button" class="bouton-carte" (click)="getVCardCourtier()">
                            <i class="fal fa-user"></i>
                            <span>{{ '@ajoutercontact' | translate }}</span>
                        </button>
                        <button type="button" class="bouton-carte" (click)="openOverlay()">
                            <i class="fal fa-share"></i>
                            <span>{{ '@partagercontact' | translate }}</span>
                        </button>
                    </div>
                </div>
                <div class="d-xl-none d-lg-none mt-3">
                    <a (click)="scrollToListings()"><span style="font-size: 45px;"><i
                                class="fas fa-chevron-down"></i></span></a>
                </div>

            </div>
        </div>

        <div class="col-xs-12 col-md-12 col-lg-8 col-xl-9 removeBoostrapPadding p-3 max-lg-h-screen overflow-auto"
            [class.formulaireHypothecaire]="detailsCourtier?.sType == 'ch'">
            <!-- <app-proprietes *ngIf="detailsCourtier?.sCodeUsager && detailsCourtier?.sType == 'agent'"
                id="listingsCourtier" [courtier]="detailsCourtier?.sCodeUsager"
                [tuilesSeulement]="!!detailsCourtier?.sCodeUsager" [scroll]="true"></app-proprietes> -->


            <pmml-proprietes *ngIf="detailsCourtier?.sCodeUsager && detailsCourtier?.sType == 'agent' && courtier"
                [courtier]="courtier"></pmml-proprietes>

            <!-- AFFICHER FORMULAIRE SI COURTIER HYPOTHECAIRE -->
            <div id="formulaireHypothecaire"></div>
            <!-- AFFICHER LIENS VERS SERVICES SI USER PAS COURTIER -->
            <div class="carteUserGeneral" *ngIf="detailsCourtier?.sType != 'agent' && detailsCourtier?.sType != 'ch'">
                <a class="boutonLien"
                    [href]="'https://pmml.ca/proprietes/' | translateValeur: 'https://pmml.ca/en/properties/'"
                    target="_blank">
                    <span>{{ '@proprietespmml' | translate }}</span>
                </a>
                <a class="boutonLien"
                    [href]="'https://pmml.ca/rapports/' | translateValeur: 'https://pmml.ca/en/reports/'"
                    target="_blank">
                    <span>{{ '@rapportsmarche' | translate }}</span>
                </a>
                <a class="boutonLien"
                    [href]="'https://pmml.ca/demande-devaluation-financement/' | translateValeur: 'https://pmml.ca/en/financing-evaluation-request/'"
                    target="_blank">
                    <span>{{ '@evaluationfin' | translate }}</span>
                </a>
                <a class="boutonLien"
                    [href]="'https://pmml.ca/evaluation-marchande/' | translateValeur: 'https://pmml.ca/en/market-evaluation/'"
                    target="_blank">
                    <span>{{ '@evaluationmarchande' | translate }}</span>
                </a>
                <a class="boutonLien"
                    [href]="'https://valx.ai/services/' | translateValeur: 'https://valx.ai/en/services-en/'"
                    target="_blank">
                    <span>{{ '@evaluationimmobilier' | translate }}</span>
                </a>
            </div>
        </div>

    </div>
</div>