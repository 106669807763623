import { DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as mapboxgl from 'mapbox-gl';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { GestureHandlingMobile } from 'src/app/gesture-handler-mobile.js';
import { GestureHandling } from 'src/app/mbgl-gesture-handling.js';
import { ProprieteTitrePipe } from 'src/app/pipes/propriete-titre.pipe';
import { environment } from 'src/environments/environment';
import { ProprietesService } from "../../../api/proprietes.service";
import { ConnexionFavorisDialogComponent } from "../../liste-priorites/connexion-favoris-dialog/connexion-favoris-dialog.component";
import { FavorisInformationsComponent } from "../../liste-priorites/favoris-informations/favoris-informations.component";
import { AuthService } from './../../../auth.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  environment = environment;
  donneesMap = new Subject<any>();
  mapHasInit = new Subject<any>();
  debounceEmitApi: Subject<string> = new Subject<string>();

  constructor(
    private zone: NgZone,
    private proprietes: ProprietesService,
    private auth: AuthService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private decimalPipe: DecimalPipe,
    private proprieteTitre: ProprieteTitrePipe,
  ) {}
  
  initQueryBounds = [];
  toggleMobile: any;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.toggleMobile = window.innerWidth < 765 ? true : false;
    this.calculHauteurMap();
  }

  @Output() recherche = new EventEmitter<any>();
  @Input('fiche-locaux') ficheLocaux: boolean = false;
  @Input('style') style: string = 'mapbox://styles/mapbox/streets-v11';

  @Input() affichageDetailPropriete: Boolean = true;
  @Input() afficherSatellite: Boolean = true;
  @Input() affichageDepuisListings: Boolean = false;
  @Input() marqueurFinal: any = [];
  @Input() filtresRecherche: any;
  map: mapboxgl.Map;
  arrPositions: any;
  marqueurActif: any;
  zoom: number[];
  minZoom: number;
  maxZoom: number;
  center: mapboxgl.LngLatLike;
  bounds = new mapboxgl.LngLatBounds();
  indexCarte: any;

  estPortfolio: boolean;
  mapListings: boolean = false;
  nbChargement = 0;

  optionsGestureMapMobile = {
    modifierKey: 'alt',
    textMessage: this.translate.instant('@message-mapbox'),
    textMessageMobile: this.translate.instant('@message-mapbox-mobile')
  }

  // Données recu par le listing de carte
  filtres: any;
  private _marqueurFinalCarte: any;
  @Input('marqueurFinal')
  public get marqueurFinalCarte(): any {
    return this._marqueurFinalCarte;
  }
  public set marqueurFinalCarte(val: any) {
    try {
      this.zone.run(() => {
        if (!val) return;
        this.filtres = val.pop();
        this._marqueurFinalCarte = val;
        this.arrPositions = this._marqueurFinalCarte;
        
        this.estPortfolio = true;
        this.mapListings = true;

        this.marqueurMapBox = {
          type: "FeatureCollection",
          features: this.arrPositions
        };

        if (this.nbChargement > 1) {
          (<any>this.map?.getSource('donnees'))?.setData(this.marqueurMapBox);
          // if (this.map?.getZoom() > 12) this.map?.zoomTo(this.map?.getZoom() - 4);
        }
        this.nbChargement++;

        if (this.arrPositions.length !== 0) this.donneesMap.next(this.marqueurMapBox)
        
        this.verificationDonneesMap();
        this.map?.resize();
      });
    } catch (error) {
      console.log(error);
    }
  }

  private _data: any;
  @Input('data')
  public get data(): any {
    return this._data;
  }
  public set data(val: any) {
    if (!val) return;
    this._data = val;
    this.estPortfolio = this.data?.sTypeLogement == 'iPortfolio' || this.data?.sTypeLogement == 'iPortfolioComm';
    if (this.data?.sTypeLogement == 'iLocal') {
      this.arrPositions = {
        lat: this._data?.ProprieteParent?.dPosLat,
        lng: this._data?.ProprieteParent?.dPosLong
      };
    } else if (this.estPortfolio) {
      this.arrPositions = this._data?.ProprietesEnfants;
    } else {
      this.arrPositions = {
        lat: this._data?.dPosLat,
        lng: this._data?.dPosLong
      };
    }
  }

  location: any;
  ngOnInit() {
    this.location = window.location.host;
    this.calculHauteurMap();
    this.toggleMobile = window.innerWidth < 765 ? true : false;
    if (!this.estPortfolio && !this.mapListings) {
      this.zoom = [15];
      this.minZoom = 8;
      this.maxZoom = 19;
      this.center = {
        lat: this.arrPositions?.lat || {},
        lng: this.arrPositions?.lng || {}
      };
    } else if (this.affichageDepuisListings) {
      this.zoom = [5.3];
    } else {
      this.zoom = [12];
      this.minZoom = 5;
      this.maxZoom = 17;
      for (const mm of this.arrPositions) {
        this.bounds.extend(new mapboxgl.LngLat(mm.dPosLong, mm.dPosLat));
      }
    }
    if (this.estConnecte()) this.chargerFavoris();
    this.verificationDonneesMap();
  }

  ngAfterViewInit() {
    combineLatest([this.donneesMap, this.mapHasInit]).subscribe(() => {
      if (this.mapListings) {
        this.initAggregationCluster();
        this.interactionZoom(true);
        this.map.resize();
      }
    });
  }

  arrayToutesPositions: any[] = [];
  positionDonneesMaps() {
    for (let position of this.arrPositions) {
      this.arrayToutesPositions.push([position.properties.dPosLong, position.properties.dPosLat]);
    }
  }

  // Mode cartographie pour Listings
  marqueurMapBox: any;
  dansChampsVision: any = [];
  toutPointsMap: any = [];
  donneesMisEnPlace = false;
  ancienInputRecherche = '';
  emplacementRechercheEffectue = false;
  initAggregationCluster() {
    try {
      // DataSet pour le geoJSON (Addsource)
      this.positionDonneesMaps();
      
      // Ne load plus le fitbounds avec les points après le deuxième chargement
      if (this.nbChargement <= 2) {
        (<any>this.map.fitBounds)(this.arrayToutesPositions, {
          maxZoom: 6,
          linear: true
        });
      }

      if (this.filtresRecherche.q && this.filtresRecherche.q !== this.ancienInputRecherche) {

        (<any>this.map.fitBounds)(this.getBoundsArray(this.arrPositions), {
          maxZoom: 11,
          linear: true
        });

        this.recherche.emit({ emplacementRechercheEffectue: false, q: this.filtresRecherche.q });

      }
      this.ancienInputRecherche = this.filtresRecherche.q || undefined;
      
      // Supression des doublons propriété parent/enfant en comparant les IDPropriete
      this.arrPositions = this.arrPositions.filter((v, i, a) => a.findIndex(t => (t.properties.id === v.properties.id)) === i)

      this.marqueurMapBox = {
        type: "FeatureCollection",
        features: this.arrPositions
      }

      if (this.marqueurMapBox.length == 0) {
        console.log("Aucune données de listing traitée");
        return;
      }
      // Insertion des données dans data
      this.map.addSource('donnees', {
        type: 'geojson',
        data: this.marqueurMapBox,
        cluster: true,
        clusterMaxZoom: 11, // Max zoom vers le cluster
        clusterRadius: 50 // Radius des clusters
      });

      // Création du layer pour les clusters (Les points)
      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'donnees',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            environment.couleurPrimaire,
            3,
            environment.couleurPrimaire,
            5,
            environment.couleurPrimaire,
            30000,
            environment.couleurPrimaire,
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      }
      );

      this.map.touchPitch.disable();
      this.map.doubleClickZoom.disable();
      this.map.dragRotate.disable();

      this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

      // if(this.toggleMobile) {
        // new GestureHandling(this.optionsGestureMapMobile).addTo(this.map);
        // new GestureHandlingMobile(this.optionsGestureMapMobile).addTo(this.map);
      // }

      // Paramètre cluster
      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'donnees',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 15,
        },
        paint: {
          "text-color": "#ffffff"
        }
      });

      // Points unique
      this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'donnees',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': environment.couleurPrimaire,
          'circle-radius': 8,
          'circle-stroke-width': 0,
          'circle-stroke-color': '#fff'
        }
      });

      this.map.on('touchmove', (e) => {
        this.interactionZoom();
      });
      this.map.on('zoom', (e) => {
        this.interactionZoom();
      });

      this.map.on('moveend', () => {
        if (!this.marqueurActif) this.interactionZoom(true, true);
        this.debounceEmitApi.next(undefined);
      });

      this.map.on('click', 'unclustered-point', (e) => {
        this.map.getCanvas().style.cursor = 'pointer';

        var coordinates = (<any>e.features[0].geometry).coordinates.slice();
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        let zoomActuel = this.map?.getZoom();
        this.map.easeTo({
          center: (<any>e.features[0].geometry).coordinates,
          zoom: zoomActuel + 2
        });
      });

      // inspect a cluster on click
      this.map.on('click', 'clusters', (e) => {
        var features = this.map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        var clusterId = features[0].properties.cluster_id;

        (<any>this.map.getSource('donnees')).getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            this.map.getCanvas().style.cursor = 'pointer';

            if (err) return;
            this.map.easeTo({
              center: (<any>features[0].geometry).coordinates,
              zoom: zoom + 2
            });
          }
        );
      });

      this.map.on('mouseenter', 'clusters', () => {
        this.map.getCanvas().style.cursor = 'pointer';

      });
      this.map.on('mouseleave', 'clusters', () => {
        this.map.getCanvas().style.cursor = '';
      }); 

      // let dPosLatNe = Number(sessionStorage.getItem('dPosLatNe')),
      //   dPosLongNe = Number(sessionStorage.getItem('dPosLongNe')),
      //   dPosLatSw = Number(sessionStorage.getItem('dPosLatSw')),
      //   dPosLongSw = Number(sessionStorage.getItem('dPosLongSw'));
      let dPosLatNe: any, dPosLongNe: any, dPosLatSw: any, dPosLongSw: any;

      this.route.queryParams
        .subscribe(params => {
          dPosLongSw = params.long1
          dPosLongNe = params.long2
          dPosLatSw = params.lat1
          dPosLatNe = params.lat2
      });
      
      if (dPosLatNe && dPosLatSw) {
        this.map.fitBounds([[dPosLongSw, dPosLatSw], [dPosLongNe, dPosLatNe]], { duration: 0 });
      }

    } catch (error) {
    }   
  }

  donneesDisponible: Boolean = true;
  verificationDonneesMap() {
    setTimeout(() => {
      if(this.arrPositions.length >= 1) this.donneesDisponible = true;
      else this.donneesDisponible = false;
    }, 1000);
  }

  interactionZoom(updateTuile = false, filtrePosition = false) {
    try {
      let bounds = this.map.getBounds();
      this.ancienInputRecherche = this.filtresRecherche.q

      // Listing de droite    
      if (updateTuile) {
        this.listingTuile = []
        this.dansChampsVision = [];
        this.arrPositions.forEach(position => { this.miseEnPlaceDonneesMap(position, bounds); });
      }

      // Filtre de position
      if (filtrePosition) {
        this.debounceEmitApi.pipe(debounceTime(1500), distinctUntilChanged()).subscribe(() => {
          let bounds = this.map.getBounds();

          let _swLng = (bounds as any)._sw.lng,
              _neLng = (bounds as any)._ne.lng,
              _swLat = (bounds as any)._sw.lat,
              _neLat = (bounds as any)._ne.lat;
          
          if (_swLng > _neLng) {
            _swLng = (bounds as any)._ne.lng;
            _neLng = (bounds as any)._sw.lng;
          }
          if (_swLat < _neLat) {
            _swLat = (bounds as any)._ne.lat;
            _neLat = (bounds as any)._sw.lat
          }
          
          if (this.filtresRecherche.q) this.emplacementRechercheEffectue = true;

          this.recherche.emit({ _swLng, _neLng, _swLat, _neLat, emplacementRechercheEffectue: this.emplacementRechercheEffectue });
          this.emplacementRechercheEffectue = false;
          delete this.filtresRecherche.q;
          this.router.navigate(['.'], { relativeTo: this.route, queryParams: { 'long1': _swLng, 'long2': _neLng, 'lat1': _swLat, 'lat2': _neLat } })          

        });
        
      }

      // Système de point sur la map
      let zoomActuel = this.map?.getZoom();

      // Logique pour les clusters vs points vs user
      this.zone.run(() => {
        // DataSet pour les points Zoom apres clusters
        if (zoomActuel > 3) this.marqueurActif = false;
        if (zoomActuel > 11.98 && !this.donneesMisEnPlace) {
          this.dansChampsVision = [];
          this.arrPositions.forEach(position => {
            this.miseEnPlaceDonneesMap(position, bounds);
          });
          this.map.setLayoutProperty('unclustered-point', 'visibility', 'none')
          this.donneesMisEnPlace = true;
        }
        if (zoomActuel < 11.98) {
          this.dansChampsVision = [];
          this.map.setLayoutProperty('unclustered-point', 'visibility', 'visible')

          this.donneesMisEnPlace = false;

        } else {
          this.map.boxZoom.disable;
        }
      });      
    } catch (error) {
    }
  }

  initCarteListings() {
    try {
      this.mapHasInit.next(true);
    } catch (error) {}
  }

  initCarte() { 
    try {
      if (this.toggleMobile) {
        new GestureHandling(this.optionsGestureMapMobile).addTo(this.map);
        new GestureHandlingMobile(this.optionsGestureMapMobile).addTo(this.map);
      }

      this.map.touchPitch.disable();
      this.map.doubleClickZoom.disable();
      this.map.dragRotate.disable();

      if (!this.estPortfolio) {
        this.map.setCenter(this.center);
        this.positionnerCarte();
      } else {
        this.map.fitBounds(this.bounds, { padding: 100, duration: 0 });
        if (this.data.IDPropriete == 813299) this.map?.zoomTo(this.map?.getZoom() - 2.5);
        if (this.data.IDPropriete == 817063) {
          this.map?.fitBounds([
            [-73.735026542377, 45.35897539833997], // southwestern corner of the bounds
            [-73.10240808828725, 45.59401041398247] // northeastern corner of the bounds
          ]);
        }
        this.positionnerCartePortfolio();
      }         
    } catch (error) {
    }
  }

  listingTuile = [];
  miseEnPlaceDonneesMap(position, bounds) {
    try {
      // Affichage des propriétés dans le cadre de l'utilisateur
      if ((Math.abs(position.properties.dPosLong) < Math.abs((bounds as any)._sw.lng) && Math.abs(position.properties.dPosLat) < Math.abs((bounds as any)._ne.lat))
        && (Math.abs(position.properties.dPosLong) > Math.abs((bounds as any)._ne.lng) && Math.abs(position.properties.dPosLat) > Math.abs((bounds as any)._sw.lat))) {}
      else return;
      
      // Modification "" en iMultiLogements
      let sTypeLogement = position?.properties?.sTypeLogement;
      if (position?.properties?.sTypeLogement == "") sTypeLogement = "iMultiLogements"

      // Mise en place des favoris dans le listing
      let propFavoris;
      if (this.listeFavoris !== undefined) {
        for (let propFav of this.listeFavoris) {
          if (propFav.idPropriete == position?.properties?.idPropriete) propFavoris = true;
          else propFavoris = false;
        }
      }

      let lienPropriete = this.proprietes.redirectionVersFiche(position?.properties?.idPropriete, position?.properties?.sTypeLogement, position?.properties?.iUnitesTotal);
      
      // Mise en place des données en points
      this.dansChampsVision.push({
        position: {
          lng: (<any>position.geometry).coordinates[0],
          lat: (<any>position.geometry).coordinates[1]
        },
        urlFiche: lienPropriete,
        sTypeLogement: sTypeLogement,
        favoris: propFavoris, 
        ...(position?.properties ?? {}),
      });
      this.listingTuile.push({
        position: {
          lng: (<any>position.geometry).coordinates[0],
          lat: (<any>position.geometry).coordinates[1]
        },
        urlFiche: lienPropriete,
        sRue: position?.sRue,
        sNomVille: position?.sNomVille,
        sTypeLogement: sTypeLogement,
        favoris: propFavoris,
        ...(position?.properties ?? {}),
      });

    } catch (error) {
    }
  }

  getBoundsArray(arrPositions) {
    let LngList = [], LatList = [];
    for (let position of arrPositions) {
      LatList.push(position.properties.dPosLat);
      LngList.push(position.properties.dPosLong);
    }
    let lngMax = Math.max(...LngList),
      latMax = Math.max(...LatList),
      lngMin = Math.min(...LngList),
      latMin = Math.min(...LatList);

    return [[lngMin, latMin], [lngMax, latMax]];
  }
  
  // Choix des données pour les tuiles sur le côté
  choixTuiles(donneesZoomOut, donneesZoomIn) {
    if (donneesZoomIn.length >= 1) return donneesZoomIn;
    return donneesZoomOut;
  }

  positionnerCarte() {   
    this.marqueurFinal = [
      {
        position: this.arrPositions,
        label: this.proprieteTitre.transform(this.data),
        iUnitesTotal: this.data?.iUnitesTotal,
        iPiedsCarreTotal: this.data?.iPiedsCarreTotal,
        sTypeLogement: this.data?.sTypeLogement
      }
    ];
  }

  positionnerCartePortfolio() {
    for (let positions of this.arrPositions) {
      this.marqueurFinal.push({
        position: {
          lat: positions?.dPosLat,
          lng: positions?.dPosLong
        },
        sImagePrincipale: positions.sImagePrincipale,
        label: this.proprieteTitre.transform(positions),
        idPropriete: positions?.sLien,
        sTypeLogement: positions?.sTypeLogement,
        iUnitesTotal: positions?.iUnitesTotal,
        iPiedsCarreTotal: positions?.iPiedsCarreTotal
      });
    }
  }

  openPropri(marqueur: any, index, marqueurInfo) {
    try {
      this.zone.run(() => {
        this.indexCarte = index;
        this.marqueurActif = marqueur;
        // Centre la map selon le point dans la carte
        this.map.easeTo({
          center:{
            lng: marqueurInfo.lng, 
            lat: marqueurInfo.lat
          },
          // padding: 10
        })
        this.map.once('moveend', () => {
          const bounds = this.map.getBounds() as any;
          const diff = marqueurInfo.lng - bounds._sw.lng 

          const x = (document.querySelector('.popupContainer')?.getBoundingClientRect().x)
          if (x < 0) {
            this.map.easeTo({center: {
              lng: bounds._sw.lng + (diff / 2),
              lat: marqueurInfo.lat
            }})
          }
        })
      });
    } catch (error) {}
  }

  directionMonProfil() {
    if (document.documentElement.lang !== 'fr-CA') window.open(`/en/mon-profil`, '_blank');
    window.open('/mon-profil', '_blank');
  }

  openPropriFD(marqueur: any, indexCarte) {
    this.indexCarte = indexCarte;
    this.marqueurActif = marqueur;
  }

  markerActuelleHover = null;
  tuileActuelleHover = null;
  survolMarqueur(position) {
    position.marker = true;
    // Point dans la map
    this.markerActuelleHover = position;
    // Tuile dans la map
    this.tuileActuelleHover = position;
  }

  neSurvolMarqueur() {
    if (this.markerActuelleHover) this.markerActuelleHover.marker = false;
    if (this.tuileActuelleHover) this.tuileActuelleHover.marker = false;
  }

  // Partie connexion favoris
  dialogRefFavorisTooltip;
  dialogRefConnexionFavoris;

  estConnecte() {
    return this.auth.token ? true : false;
  }

  favoris: boolean = false;
  ajoutSupressionFavoris(idPropriete) {
    this.proprietes.setFavori(idPropriete, this.favoris);
  }

  listeFavoris: any;
  async chargerFavoris() {
    this.proprietes.recupFavoris().then(res => {
      this.listeFavoris = res;
    });
  }

  reactionDeconnecte() {
    try {
      // Pop up pour la connexion : Utilisation de l'onglet favoris
      if (!this.estConnecte()) {
        let target: any;

        target = new ElementRef("");
        // target = new ElementRef(event.currentTarget);
        this.dialogRefConnexionFavoris = this.dialog.open(ConnexionFavorisDialogComponent, {
          hasBackdrop: false,
          data: { trigger: target }
        });
      } else {
        this.chargerFavoris();
      }      
    } catch (error) { }
  }


  hideFavorisTooltip() {
    if (this.dialogRefConnexionFavoris && !this.estConnecte()) this.dialogRefFavorisTooltip.close();
    this.dialogRefFavorisTooltip?.close();
  }

  showFavorisTooltip(event, propriete) {
    // N'affiche pas le toggle d'information(MouseOver) en mobile
    if (!this.toggleMobile) {
      const x = event.clientX, y = event.clientY, elementMouseIsHover = document.elementFromPoint(x, y);

      // if (this.estConnecte()) return;
      if (propriete.fav) return;
      this.dialogRefFavorisTooltip = this.dialog.open(FavorisInformationsComponent, {
        hasBackdrop: false,
        data: { element: elementMouseIsHover }
      });
    }
  }

  ajouterFavoris(propriete, event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.estConnecte()) {
      if (!propriete.fav) {
        this.hideFavorisTooltip();
        propriete.fav = true;
        this.proprietes.setFavori(propriete.sLien, true);

        // Ajouts aux favoris marketing
        (window as any)?.fbq('track', 'AddToCart', {
          url: propriete.sLien,
          content_ids: '1'
        });
      } else {
        this.hideFavorisTooltip();
        propriete.fav = false;
        this.proprietes.setFavori(propriete.sLien, false);
      }
    } else {
      const target = new ElementRef(event.currentTarget);
      this.dialogRefConnexionFavoris = this.dialog.open(ConnexionFavorisDialogComponent, {
        hasBackdrop: false,
        data: { trigger: target }
      });
    }
  }

  calculHauteurMap() {
    let vh = window.innerHeight * 0.00895;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  // Meta tag photos
  PI_CARRE_LOGEMENT = ['iCommercial', 'iIndustriel'];
  GOPLEX_TYPES_UNITES = { 2: 'duplex', 3: 'triplex', 4: 'quadruplex', 5: 'cinqplex', 6: 'sixplex' };
  TXT_TRADUCTION = {
    "fr-CA": {
      "logements": "logements",
      "piedscarre": "piedscarre",
      "chambres": "chambres",
      "terrain": "terrain"
    },
    "en-CA": {
      "logements": "units",
      "piedscarre": "squarefoot",
      "chambres": "rooms",
      "terrain": "land"
    }
  }
  titreFormat(listing) {
    if(!listing) return;
    let langue = document.documentElement.lang
    
    // Proprietes avec pi2
    if (this.PI_CARRE_LOGEMENT.includes(listing?.sTypeLogement)) return `${this.decimalPipe.transform(listing?.iPiedsCarreTotal, '3.0-0')?.replace(/\s/g, "")}-${this.TXT_TRADUCTION[langue]['piedscarre']}-${this.formatMetaTxt(listing?.sNumeroCivique)}-${this.formatMetaTxt(listing?.label)}-pmml`;
    // Terrains
    if (listing?.sTypeLogement == 'iTerrain') return `${this.TXT_TRADUCTION[langue]['terrain']}-${this.decimalPipe.transform(listing?.iPiedsCarreTotal, '3.0-0')?.replace(/\s/g, "")}-${this.TXT_TRADUCTION[langue]['piedscarre']}-${this.formatMetaTxt(listing?.sNumeroCivique)}-${this.formatMetaTxt(listing?.label)}-landev`;
    // Goplex
    if (listing?.sTypeLogement == 'iGoplex') return `${this.GOPLEX_TYPES_UNITES[listing?.iUnitesTotal] || (listing?.iUnitesTotal + `-${this.TXT_TRADUCTION[langue]['logements']}`)}-${this.formatMetaTxt(listing?.sNumeroCivique)}-${this.formatMetaTxt(listing?.label)}-goplex`;
    // Chambres
    if (listing?.sTypeLogement == 'iUnitesChambres') return `${listing?.iUnitesTotal}-${this.TXT_TRADUCTION[langue]['chambres']}-${this.formatMetaTxt(listing?.sNumeroCivique)}-${this.formatMetaTxt(listing?.label)}-pmml`;

    // Autres/par defaut
    return `${listing?.iUnitesTotal}-${this.TXT_TRADUCTION[langue]['logements']}-${this.formatMetaTxt(listing?.sNumeroCivique)}-${this.formatMetaTxt(listing?.label)}-pmml`;
  }

  formatMetaTxt(texte) {
    if (!texte) return "";

    return texte.replace(/\s/g, "-").toLowerCase();
  }
}

(Number as any).prototype.toRad = function () {
  return this * Math.PI / 180;
}