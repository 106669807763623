import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateValeur'
})
export class TranslateValeurPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if((<any>window).pmml_data.lang !== 'fr') return args[0];
    return value;
  }

}
