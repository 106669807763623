<section class="container">
    <!-- [style.background]="'url(' + data?.sImagePrincipale + ')'"
    [style.backgroundSize]="'cover'" -->
    <div class="overlay d-flex justify-content-center align-items-center flex-column">
        <div class="section">
            <h2>{{ '@zonage' | translate }}</h2>
            <ul>
                <li>{{ data?.sJsonData?.fr?.CodeZonage }}</li>
            </ul>
        </div>
        <div class="section">
            <h3>{{ '@services.publics.disponibles' | translate }}</h3>
            <p>{{ data?.sJsonData?.fr?.ServicesPublics | translateValeur : data?.sJsonData?.an?.ServicesPublics }}</p>
        </div>
        <div class="section">
            <h3>{{ '@usages.particuliers' | translate }}</h3>
            <ul>
                <li>{{ data?.sJsonData?.fr?.UsagesParticuliers | translateValeur : data?.sJsonData?.an?.UsagesParticuliers }}</li>
            </ul>
        </div>
        <div class="section">
            <h3>{{ '@dispositions.particulieres' | translate }}</h3>
            <ul>
                <li>{{ data?.sJsonData?.fr?.DispositionsParticulieres | translateValeur : data?.sJsonData?.an?.DispositionsParticulieres }}</li>
            </ul>
        </div>
        <div class="section">
            <h3>PPCMOI</h3>
            <ul>
                <li>{{ data?.sJsonData?.fr?.PPCMOI | translateValeur : data?.sJsonData?.an?.PPCMOI }}</li>
            </ul>
        </div>
        <div class="container w-100 white">
            <h3><i class="fas fa-usd-circle"></i> {{ '@evaluation.municipale' | translate }}</h3>
            <div class="row justify-content-center">
                <div class="col-xl-4 d-flex flex-column align-items-center">
                    <h4>{{ '@evaluation.municipale.terrain' | translate }}</h4>
                    <span>{{ data?.sEvaluationMunicipaleTerrain }}</span>
                </div>
                <div class="col-xl-4 d-flex flex-column align-items-center">
                    <h4>{{ '@evaluation.municipale.batiment' | translate }}</h4>
                    <span>{{ data?.sEvaluationMunicipaleBatiment }}</span>
                </div>
                <div class="col-xl-4 d-flex flex-column align-items-center">
                    <h4>{{ '@evaluation.municipale.total' | translate }}</h4>
                    <span>{{ data?.sEvaluationMunicipale }}</span>
                </div>
            </div>
        </div>
    </div>
</section>