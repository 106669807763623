import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import createAuth0Client, { Auth0ClientOptions } from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import {
  BehaviorSubject,
  combineLatest,
  from,
  fromEvent,
  Observable,
  of,
  throwError,
} from "rxjs";
import { catchError, concatMap, debounce, debounceTime, filter, shareReplay, tap } from "rxjs/operators";
import { SegmentService } from "./api/segment.service";

import { datadogRum } from "@datadog/browser-rum";
import { environment } from "../environments/environment";
import { TranslateValeurPipe } from "./translate-valeur.pipe";

const COOKIE_REDIRECTION = "pmmlLoginRedirect";
const REDIRECT_WAIT = 100;

const OPTIONS_AUTH: Auth0ClientOptions = {
  domain: environment.auth0.domain,
  client_id: environment.auth0.clientId,
  redirect_uri: environment.auth0.redirectUri,
  // , prompt: 'login'
  responseType: "code",
  cacheLocation: "localstorage",
};

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // window.postMessage({ type: "connexion", action: "connecte" }, "*");

  onConnexion$ = fromEvent(window, "message").pipe(
    filter((event: any) => event.data?.type === "connexion"),
    debounceTime(100)
  );

  // Create an observable of Auth0 instance of client
  auth0Client$ = (
    from(
      createAuth0Client(
        Object.assign(
          { useRefreshTokens: true },
          OPTIONS_AUTH
        ) as Auth0ClientOptions
      )
    ) as Observable<Auth0Client>
  ).pipe(
    catchError(async (err) => {
      console.log(err);
      try {
        const client = await createAuth0Client(
          Object.assign(
            { useRefreshTokens: false },
            OPTIONS_AUTH
          ) as Auth0ClientOptions
        );
        await client.logout();
        return client;
      } catch (e) {
        console.log(e);
      }
    }),
    shareReplay(1) // Every subscription receives the same shared value
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable

  user$ = this.http.get<any>("/api/auth/usager").pipe(shareReplay(1));
  isAuthenticated$ = this.user$.pipe(
    tap((user) => !!user),
    shareReplay(1)
  );

  // isAuthenticated$ = this.auth0Client$.pipe(
  //   tap((res: Auth0Client) => {
  //     return res;
  //   }),
  //   concatMap((client: Auth0Client) => from(client.isAuthenticated())),
  //   tap((res) => {
  //     this.loggedIn = res;
  //   })
  // );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;

  constructor(
    // private router: Router
    // private contacts: ContactsService,
    private http: HttpClient,
    private translatePipe: TranslateValeurPipe
  ) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();

    this._token = this.getCookie("pmmlAuth") || null;

    this.onConnexion$.subscribe((event: any) => {
      location.reload()
    })
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  // getUser$(options?): Observable<any> {
  //   return this.auth0Client$.pipe(
  //     concatMap((client: Auth0Client) => from(client.getUser(options))),
  //     tap(user => this.userProfileSubject$.next(user))
  //   );
  // }

  getToken$(options?): Observable<any> {
    return of(this.getCookie("pmmlAuth")).pipe(
      tap((token) => {
        this._token = token;
      })
    );
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) =>
        from(client.getIdTokenClaims(options))
      ),
      tap((user) => {
        try {
          datadogRum.setUser({ email: user?.email, id: user?.id });
        } catch (e) {
          console.log(e);
        }
        if (user?.__raw) this.token = user?.__raw;
      }),
      tap((user) => this.userProfileSubject$.next(user))
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          // this.getUser$();
          return this.getToken$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  // param extra pour appstate
  login(
    redirectPath: string = "/",
    connection = null,
    appStateExtra = null,
    redirectPage: string | boolean = false
  ) {
    // @ts-ignore
    if (window.pmmlNouveauFormulaire && window.afficheSeConnecter) {
      // @ts-ignore
      return window.afficheSeConnecter.set(true);
    }

    window.location.href = "/api/auth/login";
    // // A desired redirect path can be passed to login method
    // // (e.g., from a route guard)
    // // Ensure Auth0 client instance exists
    // if (redirectPath != '/') this.setCookie(COOKIE_REDIRECTION, redirectPath);

    // let redirect_uri = `${window.location.origin}`;

    // let { pathname } = location;
    // if (pathname == '/') pathname = this.translatePipe.transform('/redirection/', '/en/redirect/') as string;
    // if (pathname == '/en/home/') pathname = this.translatePipe.transform('/redirection/', '/en/redirect/') as string;

    // if (redirectPage === true) redirect_uri = `${window.location.origin}${pathname}`;
    // else if (redirectPage) redirect_uri = `${window.location.origin}${redirectPage}`;
    // else
    //   redirect_uri =
    //     `${window.location.origin}` + (this.translatePipe.transform('/redirection/', '/en/redirect/') as string);

    // this.auth0Client$.subscribe((client: Auth0Client) => {
    //   let options: any = {
    //     redirect_uri,
    //     // mettre toutes les clees
    //     appState: { target: redirectPath },
    //     ui_locales: this.translatePipe.transform('fr', 'en')
    //   };
    //   //
    //   if (appStateExtra) {
    //     options.appState = { ...options.appState, ...appStateExtra };
    //   }
    //   if (connection !== null) options.connection = connection;
    //   // Call method to log in
    //   client.loginWithRedirect(options);
    // });
  }

  private handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    if (params.includes("code=") && params.includes("state=")) {
      let targetRoute: string; // Path to redirect to after login processsed
      let requestedReport;
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap((cbRes) => {
          // Get and set target redirect route from callback results
          requestedReport = cbRes.appState.report || null;
          targetRoute =
            cbRes.appState && cbRes.appState.target
              ? cbRes.appState.target
              : "/";
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([
            // this.getUser$(),
            this.getToken$(),
            this.isAuthenticated$,
          ]);
        })
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(async ([token, loggedIn]) => {
        // Redirect to target route after callback processing
        // this.router.navigate([targetRoute]);
        if (token?.__raw) this.token = token.__raw;
        if (loggedIn && requestedReport) {
          return this.getReportDownload(requestedReport);
        }
        window.location.href = targetRoute;
      });
    }
  }

  async getReportDownload(requestedReport: string, retourneUrl = false) {
    try {
      const headers = new HttpHeaders({ type: requestedReport });
      const req = await this.http
        .get<any>(`/api/liens/rapport/${requestedReport}`, { headers: headers })
        .toPromise();

      const reportID = req.lien || false;
      if (reportID) {
        if (typeof (window as any)?.ga === "function") {
          (window as any).gtag(
            "event",
            "rapport",
            {
              eventCategory: "rapport",
              eventAction: "get",
              eventLabel: reportID,
              eventValue: null,
            },
            "get"
          );
        }
        const urlRapport = `https://${
          document.location.host
        }/${this.translatePipe.transform("", "en/")}${reportID}`;

        if (retourneUrl) return urlRapport;

        window.location.assign(urlRapport);

        return true;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: environment.auth0.clientId,
        returnTo: `${window.location.origin}`,
      });
    });
  }

  setCookie(name: string, val: string) {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

    // Set it
    document.cookie =
      name +
      "=" +
      value +
      "; expires=" +
      date.toUTCString() +
      "; path=/; SameSite=Lax";
  }

  getCookie(name) {
    const match = document.cookie.match(
      new RegExp("(^| )" + name + "=([^;]+)")
    );
    if (match) return match[2];
  }

  private _token: string;
  public get token(): string {
    return this._token;
  }
  public set token(v: string) {
    this._token = v;
  }
}
