<div class="descriptif-section">
    <div class="container">
        <h3>{{ '@descriptif.titre' | translate }}</h3>
        <div class="row marge justify-content-center">
            <div class="col-11 col-sm-11 col-lg-6">
                <div class="informationsgenerales">
                    <h4 class="titre-tableau"><i class="fas fa-comment-exclamation"></i> {{ '@informations.generales' | translate}}</h4>
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th>{{ 'iAnneeConstruction' | translate }}</th>
                                <td>{{ data?.iAnneeConstruction | translateValeur : data?.Traduction?.an?.iAnneeConstruction }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'iEtages' | translate }}</th>
                                <td>{{ data?.iEtages | translateValeur : data?.Traduction?.an?.iEtages}}</td>
                            </tr>
                            <tr>
                                <th>{{ '@ascenseur' | translate }}</th>
                                <td>{{ data?.fAscenceur | translateValeur : data?.Traduction?.an?.fAscenceur }}</td>
                            </tr>
                            <tr>
                                <th><i class="fas fa-question-circle"></i> {{ 'sAutre2' | translate }}</th>
                                <td>{{ data?.sAutre | translateValeur : data?.Traduction?.an?.sAutre }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div class="accessibilite">
                    <h4 class="titre-tableau"><i class="fas fa-parking"></i> Accessiblité</h4>
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th>{{ '@stationnement.interieur' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.StationnementInterieur | translateValeur : data?.sJsonData?.an?.StationnementExterieur }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@stationnement.exterieur' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.StationnementExterieur | translateValeur : data?.sJsonData?.an?.StationnementExterieur }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@acces' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.acces | translateValeur : data?.sJsonData?.an?.acces }}
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@transports' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.sTransportsEnCommun | translateValeur : data?.sJsonData?.an?.sTransportsEnCommun }}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-11 col-sm-11 col-lg-6">
                <div class="information-superficie">
                    <h4 class="titre-tableau"><i class="fas fa-chart-area"></i> {{ 'superficie' | translate }}</h4>
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th>{{ '@superficie.louable.sqft' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.fSuperficieLouable | translateValeur : data?.sJsonData?.an?.fSuperficieLouable }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@superficie.batiment' | translate }}</th>
                                <td>{{ data?.fSuperficieBatiment }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sAutre2' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.sAutre2 | translateValeur : data?.sJsonData?.an?.sAutre2 }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>