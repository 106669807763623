<div class="descriptif-section pt-5 pb-5">
  <div class="container w-100">
    <h3 *ngIf="(data?.sTypeLogement !=='iPortfolio')">{{ '@descriptif.titre' | translate }}</h3>
    <h3 *ngIf="(data?.sTypeLogement ==='iPortfolio')">{{ '@description.portfolio' | translate }}</h3>
    <div class="row marge justify-content-center">
      <div class="col-11 col-sm-11 col-lg-6">
        <div class="general">
          <h4 class="titre-tableau"><i class="fas fa-info-circle"></i> {{ '@informations.generales' | translate }}</h4>
          <table class="table table-striped">
            <tbody>
              <tr>
                <th>{{ '@info.cadastre' | translate }}</th>
                <td>{{ data?.sCadastre | translateValeur : data?.Traduction?.sCadastre }}</td>
              </tr>
              <tr>
                <th>{{ '@superficie.terrain' | translate }}</th>
                <td>{{ data?.fSuperficieTerrain | number : '1.0-0' }} {{ '@pi2' | translate }} </td>
              </tr>
              <tr>
                <th>{{ 'sTypeConstruction' | translate }}</th>
                <td>{{ data?.sTypeConstruction | translateValeur : data?.Traduction?.sTypeConstruction }}</td>
              </tr>
              <tr>
                <th>{{ 'sAutre2' | translate }}</th>
                <td>{{ data?.sAutre5 | translateValeur : data?.Traduction?.sAutre5 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-11 col-sm-11 col-lg-6">
        <div class="evaluation-municipale ">
          <h4 class="titre-tableau"><i class="fas fa-usd-circle"></i> {{ '@evaluation.municipale' | translate }}</h4>
          <table class="table table-striped">
            <tbody>
              <tr>
                <th>{{ '@evaluation.municipale.terrain' | translate }}</th>
                <td>{{ data?.sEvaluationMunicipaleTerrain | pmmlArgent }}</td>
              </tr>
              <tr>
                <th>{{ '@evaluation.municipale.batiment' | translate }}</th>
                <td>{{ data?.sEvaluationMunicipaleBatiment | pmmlArgent }}</td>
              </tr>
              <tr>
                <th>{{ '@evaluation.municipale.total' | translate }}</th>
                <td>{{ data?.sEvaluationMunicipale | pmmlArgent }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
