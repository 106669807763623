import { Component, OnInit, Input } from '@angular/core';
import { WordpressService } from "../../../api/wordpress.service";
import { ProprietesService } from "../../../api/proprietes.service";
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-portfolio-commercial',
  templateUrl: './portfolio-commercial.component.html',
  styleUrls: ['./portfolio-commercial.component.scss']
})
export class PortfolioCommercialComponent implements OnInit {

  constructor(
    private wp: WordpressService,
    private proprietes: ProprietesService,
  ) { }

  environment = environment;
  @Input() data: any;

  ngOnInit(): void {
  }

}
