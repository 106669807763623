import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { TranslateValeurPipe } from '../translate-valeur.pipe';

@Pipe({
  name: 'pmmlProprieteTitre'
})
export class ProprieteTitrePipe implements PipeTransform {

  constructor(
    private translate: TranslatePipe,
    private translateVal: TranslateValeurPipe,
  ) {}

  transform(data: any, titreAlt = false): unknown {
    if(data?.TitreAltFr && data?.TitreAltAn) return this.translateVal.transform(data?.TitreAltFr, data?.TitreAltAn)
    if(titreAlt && data?.optTitreAltFr && data?.optTitreAltAn) return this.translateVal.transform(data?.optTitreAltFr, data?.optTitreAltAn)
    if(data?.iFlyer) {
      return `${data?.sRue ?? ''}, ${data?.sNomVille ?? ''}`; 
    }
    if(data?.sTypeLogement === 'iCommercial' || data?.sTypeLogement === 'iSemiCommercial') {
      return `${data?.sNumeroCivique ?? ''} ${data?.sRue ?? ''}, ${data?.sNomVille ?? ''}`; 
    }
    if(data?.sTypeLogement === 'iLocal') {
      return `${this.translate.transform('@adresse.local')}: ${data?.sNumeroCivique ?? ''} | ${data?.ProprieteParent?.sNumeroCivique ?? ''} ${data?.ProprieteParent?.sRue ?? ''}, ${data?.ProprieteParent?.sNomVille ?? ''}`; 
    }
    if(data?.sTypeLogement === 'iTerrain') {
      return `${ data?.sRue ?? ''}, ${ data?.sNomVille ?? ''}`;
    }

    return `${data?.sNumeroCivique ?? ''} ${data?.sRue ?? ''}, ${data?.sNomVille ?? ''}`; 
  }

}
