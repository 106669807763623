<span *ngIf="environment?.modeTeasing">
    <app-inf-sup [data]="data"></app-inf-sup>
</span>

<span>
    <app-inf-sup [data]="data"></app-inf-sup>
    <app-informations [data]="data"></app-informations>
    <app-map [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-map>
    <!-- <app-portfolio-descriptif [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-portfolio-descriptif> -->
    <!-- <app-info-fin-multi [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-info-fin-multi> -->
    <!-- <app-descriptif2-com [data]="data"></app-descriptif2-com>
    <app-somloyers-evmunicipale [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-somloyers-evmunicipale> -->
    <!-- <app-photos [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-photos> -->
</span>
