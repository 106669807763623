import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss']
})
export class InformationsComponent implements OnInit {

  constructor() { }

  @Input() data: any;
  aParamsPresent: Boolean;
  aParamCode: any;

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('a')) {
      this.aParamsPresent = true
      this.aParamCode = `?a=${urlParams.get('a')}`;
    } else {
      this.aParamCode = '';
    }
  }

}
