<div class="container-fluid bootstrapImportation">
    <div class="row">
        <div class="col-12 order-1 order-md-0 col-md-6 d-flex align-items-center">
            <p class="descriptif-chaine">{{ '@pmmltv.chaine.' + chaineChoisi + '.texte' | translate }}</p>
        </div>
        <div class="col-12 order-0 order-md-1 col-md-6">
            <mat-form-field>
                <mat-label>{{ '@pmmltv.chaines.choisir' | translate }}</mat-label>
                <mat-select [(ngModel)]="chaineChoisi" (selectionChange)="choix(chaineChoisi)">  
                    <mat-option *ngFor="let chaine of chaines" [value]="chaine.id">
                        {{ chaine.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
