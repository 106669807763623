<!-- <div class="modal-mask" [ngClass]="{hidden: !afficherPopup}" (click)="fermer(false, $event)"> -->
<div class="modal-mask" *ngIf="afficherPopup" (click)="fermer(false, $event)">
    <div class="modal-container">
        <div class="modal-header">
            <h2>
                {{'@popup-inscription.component.titre' | translate}}
            </h2>
            <div class="btnFermer">
                <a (click)="fermer(false)"><i class="fa fa-times fa-lg"></i></a>
            </div>
        </div>
        <div class="modal-body">
            <!-- MESSAGES -->
            <div class="row">
                <div class="message message-succes" [hidden]="!sauvegardeReussie">
                    {{'@formulaire-alertes.component.succes' | translate}}
                </div>
                <div class="message message-erreur" [hidden]="!sauvegardeErreur">
                    {{'@formulaire-alertes.component.erreur' | translate}}
                </div>
                <div class="col-12">
                    <p>
                        {{'@popup-inscription.component.explicatif' | translate}} <br><br>
                        {{ '@popup-inscription.component.explicatif.suite' | translate }}
                    </p>
                </div>
                <div class="col-12 connexion-social" [hidden]="connexionCourriel">
                    <button type="button" class="block pale center" (click)="inscriptionSocial('google-oauth2')" style="justify-content: space-between;">
                        <i class="fab fa-google fa-lg icon"></i>
                        Google
                    </button>
                    <button type="button" class="block pale center" (click)="inscriptionSocial('facebook')" style="justify-content: space-between;">
                        <i class="fab fa-facebook-square fa-lg icon"></i>
                        Facebook
                    </button>
                    <button type="button" class="block pale center" (click)="inscriptionSocial('linkedin')" style="justify-content: space-between;">
                        <i class="fab fa-linkedin fa-lg icon"></i>
                        Linkedin
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="fermer" type="button" (click)="fermer(true)">
                {{'@ne.plus.afficher.message' | translate}}
            </button>
        </div>
    </div>
</div>