import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { map, pluck } from 'rxjs/operators';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss'],
})
export class AuthPageComponent implements OnInit {
  constructor(private auth: AuthService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.auth.user$.subscribe(async (user) => {});
  }

  generique$ = this.route.queryParams.pipe(
    pluck('g'),
    map((g) => g === '1')
  );

  redirect$ = this.route.queryParams.pipe(pluck('r'));
}
