import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from "../../../auth.service";

@Component({
  selector: 'app-caracteristiques-goplex',
  templateUrl: './caracteristiques-goplex.component.html',
  styleUrls: ['./caracteristiques-goplex.component.scss']
})
export class CaracteristiquesGoplexComponent implements OnInit {

  constructor(private auth: AuthService) { }

  @Input() data: any;
  connected: boolean = false;
  
  ngOnInit(): void {
    this.auth.user$
    .subscribe((user) => {
      this.connected = user ? true : false;
    });
  }

  seConnecter() {
    return this.auth.login(window.location.pathname + '#sommaires');
  }
}
