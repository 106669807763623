<div class="descriptif">
<div class="container">
    <div class="row">
        <section class="col-12">
            <div class="row justify-content-center position-relative proprietaire-occupant">
                <div class="col-auto">
                    <button class="btnTypeCalcul mr-1" [color]="(proprietaireOccupant ? 'primary' : '')" 
                        (click)="setProprietaireOccupant(true)" mat-raised-button>{{'@revDep.proprietaireOccupant' | translate}}</button>
                </div>
                <div class="col-auto">
                    <button class="btnTypeCalcul mr-1" [color]="(proprietaireOccupant ? '' : 'primary')"
                        (click)="setProprietaireOccupant(false)" mat-raised-button>{{'@revDep.Investisseur' | translate}}</button>
                </div>
                <button class="position-absolute" id="calculs-reset" (click)="reset(data)">
                    <i class="fas fa-redo"></i>
                </button>
            </div>
            <!-- Propriétaire occupant -->
            <!-- Tableaux -->
            <div #container class="container pt-3">

                <div #bubble class="info-bubble" [style.top.px]="top" [hidden]="!showBubble || estMobile">
                    <span class="info-bubble-content">{{'@revenues.bubble' | translate}}</span>
                    <i class="dismiss fas fa-times" (click)="dismissBubble()"></i>
                </div> 

                <!-- Revenus -->
                <div class="col-12 col-sm-12 col-lg-12">
                    <div class="revenus">
                        <h4 class="titre-tableau ligne-sous">
                            <i class="fas fa-usd-circle"></i>
                            {{ 'titreRevenus' | translate }}
                        </h4>
                        <mat-radio-group [(ngModel)]="logementOccupe" (change)="modificationRevDep(null, 'loyerOccupe', null)">
                        <table class="table table-striped table-hover table-inputs">
                            <colgroup>
                                <col style="width: 30%;"/>
                                <col style="width: 25%;"/>
                                <col style="width: 25%;" class="d-none d-lg-table-cell"/>
                                <col style="width: 25%;" class="d-none d-lg-table-cell"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="text-center">{{ 'montant.actuel' | translate }}</th>
                                    <th class="text-center d-none d-lg-table-cell">{{ 'montant.ajuste' | translate }}</th>
                                    <th class="text-center d-none d-lg-table-cell">{{ 'pourcen.ajustement' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let champRev of this.arrRevenus; let i = index ">
                                    <!-- Tout revenus -->
                                    <th>
                                        <div class="row h-100 align-items-center position-relative">
                                            <div #radio class="col-auto pr-0 d-none d-lg-block" *ngIf="champRev.Loyer && proprietaireOccupant" (click)="secure(true)">
                                                <mat-radio-button [disabled]="!secure()" [value]="champRev.key" (click)="onRadioClick(champRev.key)" ></mat-radio-button>
                                            </div>
                                            <div class="col">
                                                {{ champRev.champFr | translateValeur: champRev.champEn}}
                                            </div>

                                        </div> 
                                    </th>

                                    <td class="text-right">
                                        <!-- <div class="d-flex align-items-center justify-content-end pb-1"> 
                                            <div> -->
                                                {{ champRev.montantInitial | pmmlArgent : false : false}} <span class="text-lowercase" *ngIf="champRev.montantInitial"> /  {{ '@mois' | translate }}</span>
                                            <!-- </div>
                                        </div> -->
                                    </td>

                                    <td class="d-none d-lg-table-cell">
                                        <div class="row align-items-center" *ngIf="!proprietaireOccupant || champRev.key != logementOccupe">
                                            <div class="col col-input pr-1">
                                                <mat-form-field class="w-100 text-right" appearance="standard" [shouldLabelFloat]="false" floatLabel="never" (click)="secure(true)" >
                                                    <input matInput numberFormat [disabled]="!secure()" placeholder="{{champRev.valeur}}" [(ngModel)]="champRev.valeur" (ngModelChange)="modificationRevDep(champRev, 'revenus')"> 
                                                </mat-form-field>
                                            </div>
                                            <div class="col-auto col-addon pl-0 pb-1"><span class="text-lowercase">$ /  {{ '@mois' | translate }}</span></div>
                                        </div>
                                        <div class="pr-input-addon text-right pt-2 pb-2" *ngIf="proprietaireOccupant && champRev.key == logementOccupe">
                                            0 $
                                        </div>
                                    </td>

                                    <td class="d-none d-lg-table-cell">
                                        <div class="row align-items-center" *ngIf="!proprietaireOccupant || champRev.key != logementOccupe">
                                            <div class="col col-input pr-1">
                                                <mat-form-field class="w-100 text-right" appearance="standard" [shouldLabelFloat]="false" floatLabel="never" (click)="secure(true)">
                                                    <input matInput [disabled]="!secure()" [(ngModel)]="champRev.pourcenAjustements" (ngModelChange)="modificationRevDep(champRev, 'revenus', 'pourcentage')"> 
                                                </mat-form-field> 
                                            </div>
                                            <div class="col-auto col-addon pl-0 pb-1">%</div>
                                        </div>
                                    </td>
                                </tr>
                                <!-- Mensuel -->
                                <tr class="final final-bas">
                                    <th>{{ 'fTotalRevenus' | translate }}</th>
                                    <td class="text-right pr-input-addon">{{ data.fTotalRevenu / 12 | pmmlArgent}}<span class="text-lowercase"> /  {{ '@mois' | translate }}</span></td>
                                    <td class="text-right pr-input-addon d-none d-lg-table-cell">{{ revenuTotalClient | pmmlArgent}}<span class="text-lowercase"> /  {{ '@mois' | translate }}</span></td>
                                    <td class="text-right pr-input-addon d-none d-lg-table-cell">{{ revenuTotalPourcentageClient | number : '1.0-0' }} %</td>
                                </tr>
                                <!-- Annuel -->
                                <tr class="bg-white">
                                    <th>{{ 'fTotalRevenus' | translate }} {{ '@annuel' | translate }}</th>
                                    <td class="text-right pr-input-addon">{{ data.fTotalRevenu | pmmlArgent}}</td>
                                    <td class="text-right pr-input-addon d-none d-lg-table-cell">{{ revenuTotalClient * 12| pmmlArgent}}</td>
                                    <td class="text-right pr-input-addon d-none d-lg-table-cell">{{ revenuTotalPourcentageClient | number : '1.0-0' }} %</td>
                                </tr>
                            </tbody>

                        </table>
                        </mat-radio-group>
                    </div>
                </div>

                <!-- Dépenses -->
                <div class="col-12 col-sm-12 col-lg-12">
                    <div class="depenses">
                        <h4 class="titre-tableau ligne-sous">
                            <i class="fas fa-money-check-edit-alt"></i>
                            {{ '@depenses' | translate }}
                        </h4>
                        <table class="table table-striped table-hover table-inputs" >
                            <colgroup>
                                <col style="width: 30%;"/>
                                <col style="width: 25%;"/>
                                <col style="width: 25%;" class="d-none d-lg-table-cell"/>
                                <col style="width: 25%;" class="d-none d-lg-table-cell"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="text-center">{{ 'montant.actuel' | translate }}</th>
                                    <th class="d-none d-lg-table-cell text-center">{{ 'montant.ajuste' | translate }}</th>
                                    <th class="d-none d-lg-table-cell text-center">{{ 'pourcen.ajustement' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let champDepense of this.arrDepenses; let i = index ">
                                    <tr>
                                        <!-- Tout Depenses -->
                                        <th>{{ champDepense.champFr | translateValeur: champDepense.champEn }} </th>
                                        
                                        <td class="text-right font-weight-light">
                                            {{ champDepense.montantInitial | pmmlArgent }} <span class="text-lowercase" *ngIf="champDepense.montantInitial">/  {{ '@mois' | translate }}</span>
                                        </td>

                                        <td class="d-none d-lg-table-cell">
                                            <div class="row align-items-center">
                                                <div class="col col-input pr-1">
                                                    <mat-form-field class="w-100 text-right" appearance="standard" [shouldLabelFloat]="false" floatLabel="never" (click)="secure(true)">
                                                        <input matInput [disabled]="!secure()" numberFormat placeholder="{{champDepense.valeur | pmmlArgent}}" [(ngModel)]="champDepense.valeur" (ngModelChange)="modificationRevDep(champDepense, 'depenses')"> 
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-auto col-addon pl-0 pb-1">$ / <span class="text-lowercase"> {{ '@mois' | translate }}</span></div>
                                            </div>
                                        </td>

                                        <td class="d-none d-lg-table-cell">
                                            <div class="row align-items-center">
                                                <div class="col col-input pr-1">
                                                    <mat-form-field class="w-100 text-right" appearance="standard" [shouldLabelFloat]="false" floatLabel="never" (click)="secure(true)">
                                                        <input matInput [disabled]="!secure()" [(ngModel)]="champDepense.pourcenAjustements" (ngModelChange)="modificationRevDep(champDepense, 'depenses', 'pourcentage')"> 
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-auto col-addon pl-0 pb-1">%</div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                                <!-- Depense total -->
                                <tr class="bgGrisFooter final-bas">
                                    <th>{{ '@depenses.total' | translate }}</th>
                                    <!-- <td ></td> -->
                                    <td class="text-right">{{ data?.fTotalDepense / 12 | pmmlArgent }} / <span class="text-lowercase"> {{ '@mois' | translate }}</span></td>
                                    <td class="text-right pr-input-addon d-none d-lg-table-cell">{{ depenseTotalClient | pmmlArgent }} / <span class="text-lowercase"> {{ '@mois' | translate }}</span></td>
                                    <td class="text-right pr-input-addon d-none d-lg-table-cell">{{ depenseTotalPourcentageClient | number : '1.0-0' }} %</td>
                                </tr>
                                <!-- Annuel -->
                                <tr class="bg-white">
                                    <th>{{ '@depenses.total.annuel' | translate }}</th>
                                    <!-- <td ></td> -->
                                    <td class="text-right">{{ data?.fTotalDepense | pmmlArgent }}</td>
                                    <td class="text-right pr-input-addon d-none d-lg-table-cell">{{ depenseTotalClient * 12 | pmmlArgent }}</td>
                                    <td class="text-right pr-input-addon d-none d-lg-table-cell">{{ depenseTotalPourcentageClient| number : '1.0-0' }} %</td>
                                </tr>
                                <!-- Revenus net -->
                                <tr class="bgGrisFooter ">
                                    <th>{{ '@revenus.net' | translate }}</th>
                                    <!-- <td></td> -->
                                    <td class="text-right">{{ data?.fRevenuNet | pmmlArgent }}</td>
                                    <td class="text-right pr-input-addon d-none d-lg-table-cell">{{ revenuNetClient | pmmlArgent }}</td>
                                    <td class="text-right pr-input-addon d-none d-lg-table-cell">{{ revenuNetPourcentageClient | number : '1.0-0' }} %</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Calculatrice PMML -->
                <div class="row d-flex justify-content-end pt-5 pl-2 mr-4">
                    <div class="col pb-4 pt-2">
                        <h3>{{ 'simulateur.financement' | translate }}</h3>
                    </div>
                </div>
                <!-- Slide prix de la propriété et mise de fonds nécessaire -->
                <div class="pl-2 pr-4 pt-4">
                    <div class="row justify-content-center">
                        <div class="col-auto text-center pl-4 pr-4">
                            <div class="h4 text-center mb-0">{{ 'prix.propriete' | translate }}</div>
                            
                            <!-- <span class="h5 mt-5">&nbsp;&nbsp;{{ prixProprieteClient | pmmlArgent }}</span> -->
                            <div class="row inputs pt-0 align-items-center justify-content-center prixMdfPrincipal" style="margin-top: -1rem; margin-bottom: -1.2em;">
                                <div class="col-auto col-input input-lg pr-1" style="width: 140px">
                                    <mat-form-field class="w-100" appearance="standard" floatLabel="never" (click)="secure(true)">
                                        <input matInput numberFormat class="text-right w-100 input-lg" 
                                            [placeholder]="prixProprieteClient | pmmlArgent" 
                                            [disabled]="!secure()"
                                            [(ngModel)]="prixProprieteClient" (ngModelChange)="calculFinancement()"> 
                                    </mat-form-field>
                                </div>
                                <div class="col-auto col-addon pl-0 pb-1">$</div>
                            </div>
                            <!-- <mat-slider
                                class="sliderCalcultatrice"
                                [(ngModel)]="prixProprieteClient"
                                (ngModelChange)="calculFinancement()"
                                [ngModelOptions]="{updateOn: 'change'}"
                                tickInterval="1000"
                                step="1000"
                                [min]="(data?.fPrixDemande - 100000) || 100000"
                                [max]="(data?.fPrixDemande + 100000) || 100000"
                                aria-label="$"></mat-slider> -->

                        </div>
                        <div class="col-12 d-block d-lg-none"></div>
                        <div class="col-auto text-center pl-4 pr-4">
                            <span class="h4">{{ 'mise.de.fonds.necessaire' | translate }} </span><br>
                            <!-- <mat-slider  
                                class="sliderCalcultatrice"
                                [(ngModel)]="prixMiseDeFonds"
                                (ngModelChange)="calculFinancement()"
                                [ngModelOptions]="{updateOn: 'change'}"
                                tickInterval="500"
                                step="1000"
                                [min]="((data.fin.infoFinancement.conv.fMiseDeFond || data.fin.infoFinancement.schl.fMiseDeFond) - 100000 ) || 100000"
                                [max]="((data.fin.infoFinancement.conv.fMiseDeFond || data.fin.infoFinancement.schl.fMiseDeFond) + 100000 ) || 100000"
                                aria-label="$"></mat-slider> -->
                            <div class="row inputs pt-0 align-items-center justify-content-center prixMdfPrincipal" style="margin-top: -1rem; margin-bottom: -1.2em;">
                                <div class="col-auto col-input input-lg pr-1" style="width: 140px">
                                    <mat-form-field class="w-100" appearance="standard" floatLabel="never">
                                        <input matInput numberFormat class="text-right w-100 input-lg h5" 
                                            [placeholder]="prixMiseDeFonds | pmmlArgent" [disabled]="true"
                                            (ngModelChange)="calculFinancement()"> 
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-auto col-addon pl-0 pb-1">$</div> -->
                            </div>
                        </div>
                    </div>

                    <!-- Dropdown Terme, Amortissement, Taux d'interet -->
                    <div class="row inputs pt-4 align-items-center justify-content-center">
                        <!-- TERME -->
                        <!-- <div class="col-auto pr-0">{{'@terme' | translate}}</div>
                        <div class="col-auto col-input ans pr-1" style="width: 110px">
                            <mat-form-field class="w-100" appearance="standard" floatLabel="never">
                                <input matInput class="text-right w-100" [(ngModel)]="simulateurFinancement.iTerme" 
                                    (ngModelChange)="calculFinancement()"> 
                            </mat-form-field>
                        </div>
                        <div class="col-auto col-addon pl-0 pb-1">{{'@ans' | translate}}</div> -->
                        <!-- AMORTISSEMENT -->
                        <div class="col-auto pr-0">
                            <span class="h4">{{'@amortissement' | translate}}</span>
                        </div>
                        <div class="col-auto col-input ans pr-1" style="width: 110px">
                            <mat-form-field class="w-100" appearance="standard" floatLabel="never" (click)="secure(true)">
                                <input matInput [disabled]="!secure()" class="text-right w-100" [(ngModel)]="simulateurFinancement.iAmortissementAns" 
                                    (ngModelChange)="calculFinancement()"> 
                            </mat-form-field>
                        </div>
                        <div class="col-auto col-addon pl-0 pb-1">{{'@ans' | translate}}</div>
                        <!-- TAUX -->
                        <div class="col-auto pr-0">
                            <span class="h4">{{'fTaux' | translate}}</span>
                        </div>
                        <div class="col-auto col-input pr-1" style="width: 100px">
                            <mat-form-field class="w-100" appearance="standard" floatLabel="never" (click)="secure(true)">
                                <input matInput [disabled]="!secure()" class="text-right w-100" [(ngModel)]="simulateurFinancement.fTaux" 
                                    (ngModelChange)="calculFinancement()"> 
                            </mat-form-field>
                        </div>
                        <div class="col-auto col-addon pl-0 pb-1">%</div>

                        <!-- <div class="col-auto d-flex">
                            <h5 class="pt-4">Terme</h5> &nbsp;&nbsp;
                            <mat-form-field class="pt-1" >
                                <mat-select [(ngModel)]="simulateurFinancement.iTerme" (ngModelChange)="calculFinancement()">
                                    <mat-option *ngFor="let terme of termes" [value]="terme.value">
                                        {{terme.texte}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-auto d-flex">
                            <h5 class="pt-4">Amortissement</h5> &nbsp;&nbsp;
                            <mat-form-field class="pt-1">
                                <mat-select [(ngModel)]="simulateurFinancement.iAmortissementAns" (ngModelChange)="calculFinancement()">
                                    <mat-option *ngFor="let amortissement of amortissements" [value]="amortissement.value">
                                        {{amortissement.texte}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-auto d-flex">
                            <h5 class="pt-4">Taux d'interêt</h5> &nbsp;&nbsp;
                            <mat-form-field class="pt-3" appearance="fill" style="width: 100px;">
                                <input matInput class="pr-2" placeholder="{{ simulateurFinancement.fTaux }}" [(ngModel)]="simulateurFinancement.fTaux" (ngModelChange)="calculFinancement()">
                            </mat-form-field> <span style="padding-top: 28px;">%</span>
                        </div> -->
                    </div>

                    <div class="row pt-4 justify-content-center indicateurs">
                        <div class="col-lg-5 pl-1 pr-0">
                            <div class="section d-flex flex-column justify-content-center align-items-center p-2">
                                <h4 class="h4">{{ 'montant.financement.hypo' | translate}}</h4>
                                <strong>{{ simulateurFinancement.fMontantFinancement | pmmlArgent }}</strong>
                            </div>
                        </div>
                        <div class="col-lg-5 pl-1 pr-0 ">
                            <div class="section d-flex flex-column justify-content-center align-items-center p-2">
                                <h4 class="h4">{{ 'versement.mensuel' | translate }}</h4>
                                <strong>{{ simulateurFinancement.fPaiementMensuel | pmmlArgent }}</strong>
                            </div>
                        </div>
                    </div>
                    <!-- CPL + MRB -->
                    <div class="row pt-4 justify-content-center indicateurs">
                        <div class="col-lg-5 pl-1 pr-0">
                            <div class="section d-flex flex-column justify-content-center align-items-center p-2">
                                <h4 class="h4">{{ 'CPL' | translate }}</h4>
                                <strong>{{ simulateurFinancement.cpl | pmmlArgent : true }}</strong>
                            </div>
                        </div>
                        <div class="col-lg-5 pl-1 pr-0 " *ngIf="!proprietaireOccupant">
                            <div class="section d-flex flex-column justify-content-center align-items-center p-2">
                                <h4 class="h4">{{ 'MRB' | translate }}</h4>
                                <strong>{{ simulateurFinancement.fMRB | number : '0.1-1' }}</strong>
                            </div>
                        </div>
                        <div class="col-lg-5 pl-1 pr-0 " *ngIf="proprietaireOccupant">
                            <div class="section d-flex flex-column justify-content-center align-items-center p-2">
                                <h4 class="h4" *ngIf="simulateurFinancement.fLiquiditeMensuel > 0">{{ '@liquidite.mensuel' | translate }}</h4>
                                <h4 class="h4" *ngIf="simulateurFinancement.fLiquiditeMensuel <= 0">{{ '@cout.occupation' | translate }}</h4>
                                <strong *ngIf="simulateurFinancement.fLiquiditeMensuel > 0">{{ simulateurFinancement.fLiquiditeMensuel | pmmlArgent }}</strong>
                                <strong *ngIf="simulateurFinancement.fLiquiditeMensuel <= 0">{{ simulateurFinancement.fLiquiditeMensuel * -1 | pmmlArgent }}</strong>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Tresorerie -->
                <div class="col-12 col-sm-12 col-lg-12" *ngIf="!proprietaireOccupant">
                    <div class="tresorerie">
                        <h4 class="titre-tableau ligne-sous">
                            <i class="fas fa-treasure-chest"></i>
                            {{ '@tresorerie' | translate }}
                        </h4>
                        <table class="table table-striped table-hover" >
                            <thead>
                                <tr>
                                    <th style="width: 35%;"></th>
                                    <th class="text-center"><span>{{ 'montants' | translate }}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{{ '@revenus.net.fd' | translate }}</th>
                                    <td><span>{{ revenuNetClient | pmmlArgent }}</span></td>
                                </tr>
                                <tr>
                                    <th class="pt-3">{{ '@paiement.hyp.annuel' | translate }}</th>
                                    <td class="pb-3">{{ simulateurFinancement.fPaiementAnnuel | pmmlArgent }}</td>
                                </tr>
                                <tr class="bg-gray">
                                    <th>{{ 'LiquiditePret' | translate }}</th>
                                    <td><span>{{ simulateurFinancement.fLiquiditeMensuel | pmmlArgent }}</span></td>
                                </tr>
                        </table>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-lg-12 d-lg-block" *ngIf="!proprietaireOccupant">
                    <div class="indicateurs pr-0 mr-0" *ngIf="data?.fPrixDemande != 0 && data?.fPrixDemande != null">
                        <!-- Rendement sur Liquidite & + Capitalisation -->
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 pl-1 pr-0">
                                <div class="section d-flex flex-column justify-content-center align-items-center p-2">
                                    <h4>{{ '@rendement.sur.liquidite' | translate }}</h4>
                                    <strong>{{ simulateurFinancement.fRendLiquidite | number : '1.1-1' }} %</strong>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 pl-1 pr-0">
                                <div class="section d-flex flex-column justify-content-center align-items-center p-2">
                                    <h4>{{ '@rendement.sur.liquidite.capitalisation' | translate }}</h4>
                                    <strong>{{ simulateurFinancement.fRendLiquiditeCapital | number : '1.1-1' }} %</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
</div>