import { Component, OnInit, Input } from '@angular/core';

import { TranslateValeurPipe } from './../../../translate-valeur.pipe';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  videoId: any;
  @Input() data: any;
  @Input() bGroupeLocation: boolean;

  constructor(private translatePipe: TranslateValeurPipe) { }

  showVideo: boolean = false;
  player: any;
  YT: any;
  reframed: Boolean = false;

  init() {
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
  
  ngOnInit() {
    this.videoId = this.translatePipe.transform(this.data.sVideoYoutubeFr, this.data.sVideoYoutubeAn);
    this.init();

    window['onYouTubeIframeAPIReady'] = () => {
      this.YT = window['YT'];
      this.player = new window['YT'].Player('video-iframe', {
        videoId: this.videoId,
        playerVars: { 'rel': 0 },
      });
    };
  }

  lireVideo() {
    this.showVideo = true;
    this.player.playVideo();
  }
}
