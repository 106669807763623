import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth0/auth0-angular';
import { of } from 'rxjs';
import { pluck, shareReplay, switchMap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { SegmentService } from '../api/segment.service';
import { CompleteRegistrationComponent } from '../complete-registration/complete-registration.component';

@Injectable({
  providedIn: 'root',
})
export class AuthHelperService {
  constructor(
    private dialog: MatDialog,
    private auth: AuthService,
    private analytics: SegmentService,
    private http: HttpClient,
  ) {}

  jwtV1: string | null = null;
  dialogRef: any;

  idToken$ = this.auth.getUser().pipe(
    switchMap((u) =>
      !u
        ? of(null)
        : this.auth
            .getAccessTokenSilently({ detailedResponse: true })
            .pipe(pluck('id_token'), shareReplay(1))
    )
  );

  isValidPhone(phone: string) {
    return /^\+1[0-9]{10}$/.test(phone);
  }

  async assurezNumeroDeTelephone(user: any) {
    const isVerified = user?.['PMML:phone_number_verified'];
    const isValid = this.isValidPhone(user?.['PMML:phone_number']);
    const shouldVerify = !isVerified || !isValid;

    if (shouldVerify && !this.dialogRef) {
      this.dialogRef = this.dialog.open(CompleteRegistrationComponent, {
        width: '400px',
        height: '540px',
        disableClose: true,
        backdropClass: 'custom-backdrop',
        panelClass: 'fullscreen-dialog',
        data: {},
      });

      this.dialogRef.afterClosed().subscribe(() => {
        delete this.dialogRef;
      });
    }
  }

  async login() {
    const lang = window.location.href.includes('/en') ? 'en' : 'fr';
    const id = v4();
    localStorage.setItem('pmml:affiche-accueil', id);
    this.analytics.track('connexion', { id, langue: lang, temp: new Date() });

    const end = lang === 'fr' ? '/auth' : '/en/auth';

    await this.auth.loginWithRedirect({
      redirect_uri: window.location.origin + '/indice',
      max_age: 14 * 24 * 60 * 60,
      appState: {
        target: end,
      },
    });
  }

  loginV1(token: string) {
    return this.http.get('/api/usager-auth', {params: {token}})
      .toPromise()
      .then((data: any) => {
        if(data.jwt) this.jwtV1 = data.jwt;
      });
  }
}
