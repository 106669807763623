import { WordpressService } from './api/wordpress.service';
import { map } from 'rxjs/operators';
import { fromEvent, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  hashList: any[];
  constructor(private wp: WordpressService) {
    this.wp.listeChaines().subscribe(
      (res) => {
        (this.hashList = res.map((e) => {
          return { id: e.id, slug: e.slug };
        }))
        this.hashList.push({ id: 2016, slug: 'tout'});
      }
    );
  }

  hashExists(hash: string) {
    if (hash[0] === '#') hash = hash.substring(1);
    return this.hashList.find((e) => e.slug === hash) ? true : false;
  }

  getCategorieID(hash: string) {
    if (hash[0] === '#') hash = hash.substring(1);
    return this.hashList.find((e) => e.slug === hash).id;
  }

  hashChanges(): Observable<string> {
    return fromEvent(window, 'hashchange').pipe(map(() => window.location.hash.substring(1)));
  }
  setHash(id: number) {
    if (!this.hashList) return;
    window.location.hash = this.hashList.find((e) => e.id == id)?.slug || 'tout';
  }
}
