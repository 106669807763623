import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslatePipe } from "@ngx-translate/core";

const PHONE_NUMBER =
  /^(\+?[01])?[-.\s]?\(?[1-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}/;

const WP_FORM_ID = 23975;
const WP_FORM_ID_AN = 24268;
const WP_FORMS_CHAMPS = {
  PRENOM_CONTACT: "wpforms[fields][8][first]",
  NOM_CONTACT: "wpforms[fields][8][last]",

  COURRIEL: "wpforms[fields][1]",
  TELEPHONE: "wpforms[fields][13]",

  COMMENTAIRES: "wpforms[fields][12]",

  // Pas afficher
  COURRIEL_COURTIER: "wpforms[fields][14]",
  CODE_COURTIER: "wpforms[fields][15]",
  NOM_COURTIER: "wpforms[fields][16]",
  JSON_FORM: "wpforms[fields][17]",
};

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"],
})
export class ContactFormComponent implements OnInit {
  loading = false;
  @Output() done = new EventEmitter();
  @Input() courtier;

  form = this.builder.group({
    prenom: this.builder.control("", [Validators.required]),
    nom: this.builder.control("", [Validators.required]),

    courriel: this.builder.control("", [Validators.required, Validators.email]),
    telephone: this.builder.control("", [
      Validators.required,
      Validators.pattern(PHONE_NUMBER),
    ]),

    commentaires: this.builder.control(""),
  });

  constructor(
    private builder: FormBuilder,
    private toastr: MatSnackBar,
    private translate: TranslatePipe
  ) {}

  ngOnInit(): void {}

  async submit() {
    for (const control in this.form.controls) {
      this.form.get(control).markAsDirty();
    }

    const form = new FormData();

    const phone = (str) => {
      const matches = str.trim().replace(/^\+1/, "").match(/\d/g);

      if (!matches) return "";

      const num = "+1" + matches.join("").substring(0, 10);
      return num;
    };

    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      return;
    }

    const value = this.form.value;
    const num = phone(value.telephone);

    form.append(WP_FORMS_CHAMPS.PRENOM_CONTACT, value.prenom);
    form.append(WP_FORMS_CHAMPS.NOM_CONTACT, value.nom);
    form.append(WP_FORMS_CHAMPS.COURRIEL, value.courriel);
    form.append(WP_FORMS_CHAMPS.TELEPHONE, num ? num : value.telephone);
    form.append(WP_FORMS_CHAMPS.COMMENTAIRES, value.commentaires);

    form.append(WP_FORMS_CHAMPS.COURRIEL_COURTIER, this.courtier.sCourriel);
    form.append(WP_FORMS_CHAMPS.CODE_COURTIER, this.courtier.sCodeUsager);
    form.append(WP_FORMS_CHAMPS.NOM_COURTIER, this.courtier.sNomUser);
    form.append(WP_FORMS_CHAMPS.JSON_FORM, btoa(JSON.stringify(value)));

    form.append(
      "wpforms[id]",
      this.translate.transform(
        "@wpformid" + ((window as any)?.pmmlNouveauFormulaire ? ".nouveau" : "")
      )
    );
    form.append("wpforms[submit]", "wpforms-submit");
    form.append("action", "wpforms_submit");
    form.append("page_url", location.href);

    this.loading = true;

    await fetch("https://pmml.ca/wp-admin/admin-ajax.php", {
      body: form,
      method: "POST",
      mode: "cors",
    })
      .then(() => {
        this.form.reset();
        this.done.emit();
      })
      .catch(() =>
        this.toastr.open(this.translate.transform("@contact.erreur"))
      )
      .finally(() => {
        this.loading = false;
      });
  }
}

// (async () => {

//   const form = new FormData()
//   form.append('wpforms[fields][8][first]', 'test')
//   form.append('wpforms[fields][8][last]', Date.now().toString())
//   form.append('wpforms[fields][1]', 'nathan.russell@pmml.ca')
//   form.append('wpforms[fields][7]', 'position')
//   form.append('wpforms[fields][9]', 'company')
//   form.append('wpforms[fields][12]', 'comments')
//   form.append('wpforms[fields][13]', '+14384964494')

//   form.append('wpforms[id]', '23975')
//   form.append('wpforms[submit]', 'wpforms-submit')
//   form.append('action', 'wpforms_submit')
//   form.append('page_url', 'https://pmml.ca/?wpforms_form_preview=23975&new_window=1')

//   await fetch("https://pmml.ca/wp-admin/admin-ajax.php", {
//     'body': form,
//     "method": "POST",
//   }).then(r => r.json()).then(console.log);
// })()
