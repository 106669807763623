<div class="descriptif-section pb-5 pt-5">
    <div class="container w-100">
        <h3>{{ '@descriptif.titre' | translate }}</h3>
        <div class="row marge justify-content-center">
            <div class="col-12 col-lg-6 pl-xs-2 pr-xs-2 pl-lg-2 pr-lg-2">
                <div class="caracteristiques">
                    <h4 class="titre-tableau"><i class="fas fa-file-contract"></i> {{ 'titreCaracteristiques' | translate }}</h4>
                    <table class="table table-striped">
                        <tbody>
                            <tr *ngIf="'!data?.sTypeLogementtt'">
                                <th style="width: 60%;">{{ '@zonage' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.sZonage | translateValeur : data?.sJsonData?.an?.sZonage  }}</td>
                            </tr>
                            <tr *ngIf="'!data?.sTypeLogementtt'">
                                <th>{{ 'topographie' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.sTopographie | translateValeur : data?.sJsonData?.an?.sTopographie  }}</td>
                            </tr>
                            <tr *ngIf="'!data?.sTypeLogementtt'">
                                <th>{{ 'cloture' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.sCloture | translateValeur : data?.sJsonData?.an?.sCloture  }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRespElectricite' | translate }}</th>
                                <td>{{ data?.sRespElectricite | translateValeur : data?.Traduction?.sRespElectricite }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRespEauChaude' | translate }}</th>
                                <td>{{ data?.sRespEauChaude | translateValeur : data?.Traduction?.sRespEauChaude }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRespElectros' | translate }}</th>
                                <td>{{ data?.sRespElectros | translateValeur : data?.Traduction?.sRespElectros }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sLaveuseSecheuse' | translate }}</th>
                                <td>{{ data?.sLaveuseSecheuse | translateValeur : data?.Traduction?.sLaveuseSecheuse }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sBuanderie' | translate }}</th>
                                <td>{{ data?.sBuanderie | translateValeur : data?.Traduction?.sBuanderie }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sSysChauffage' | translate }}</th>
                                <td>{{ data?.sSysChauffage | translateValeur : data?.Traduction?.sSysChauffage }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRespChaufeEau' | translate }}</th>
                                <td>{{ data?.sRespChaufeEau | translateValeur : data?.Traduction?.sRespChaufeEau }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sPaneauElectrique' | translate }}</th>
                                <td>{{ data?.sPaneauElectrique | translateValeur : data?.Traduction?.sPaneauElectrique }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sPlomberie' | translate }}</th>
                                <td>{{ data?.sPlomberie | translateValeur : data?.Traduction?.sPlomberie }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sEtatCuisines' | translate }}</th>
                                <td>{{ data?.sEtatCuisines | translateValeur : data?.Traduction?.sEtatCuisines }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sEtatCB' | translate }}</th>
                                <td>{{ data?.sEtatCB | translateValeur : data?.Traduction?.sEtatCB }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sPlanchers' | translate }}</th>
                                <td>{{ data?.sPlanchers | translateValeur : data?.Traduction?.sPlanchers }}</td>
                            </tr>
                            <tr>
                                <th><i class="fas fa-question-circle"></i> {{ 'sAutre2' | translate }}</th>
                                <td>{{ data?.sAutre | translateValeur : data?.Traduction?.sAutre }}</td>
                            </tr>                       
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-12 col-lg-6 pl-xs-2 pr-xs-2 pl-lg-2 pr-lg-2">
                <div class="caracteristiques-suite">
                    <h4 class="titre-tableau"><i class="fas fa-file-contract"></i> {{ 'titreCaracteristiques' | translate }}</h4>
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th>{{ 'sEtudeEnviro' | translate }}</th>
                                <td>{{ data?.sEtudeEnviro | translateValeur : data?.Traduction?.sEtudeEnviro }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sAnneeToit' | translate }}</th>
                                <td>{{ data?.sAnneeToit | translateValeur: data?.Traduction?.sAnneeToit }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRevetementExt' | translate }}</th>
                                <td>{{ data?.sRevetementExt | translateValeur : data?.Traduction?.sRevetementExt }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sBalcons' | translate }}</th>
                                <td>{{ data?.sBalcons | translateValeur : data?.Traduction?.sBalcons }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sTypePortes' | translate }}</th>
                                <td>{{ data?.sTypePortes | translateValeur : data?.Traduction?.sTypePortes }}</td>
                            </tr>
                            <tr>
                                <th style="width: 60%;">{{ 'sFenetres' | translate }}</th>
                                <td>{{ data?.sFenetres | translateValeur : data?.Traduction?.sFenetres }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sStationnements' | translate }}</th>
                                <td>{{ data?.sStationnements | translateValeur : data?.Traduction?.sStationnements }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRevetStationnement' | translate }}</th>
                                <td>{{ data?.sRevetStationnement | translateValeur : data?.Traduction?.sRevetStationnement }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sSonette' | translate }}</th>
                                <td>{{ data?.sSonette | translateValeur : data?.Traduction?.sSonette }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sSysIncendie' | translate }}</th>
                                <td>{{ data?.sSysIncendie | translateValeur : data?.Traduction?.sSysIncendie }}</td>
                            </tr>
                            <tr >
                                <th>{{ '@internet.telecom' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.sInternet | translateValeur : data?.sJsonData?.an?.sInternet }}</td>
                            </tr>
                            <tr>
                                <!-- Concierge -->
                                <th>{{ 'sAutre4' | translate }}</th>
                                <td>{{ data?.sAutre4 | translateValeur : data?.Traduction?.sAutre4 }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="evaluation-municipale ">
                    <h4 class="titre-tableau"><i class="fas fa-usd-circle"></i> {{ '@evaluation.municipale' | translate }}</h4>
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th style="width: 60%;">{{ '@evaluation.municipale.terrain' | translate }}</th>
                                <td>{{ data?.sEvaluationMunicipaleTerrain  }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@evaluation.municipale.batiment' | translate }}</th>
                                <td>{{ data?.sEvaluationMunicipaleBatiment }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@evaluation.municipale.total' | translate }}</th>
                                <td>{{ data?.sEvaluationMunicipale }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row pt-5">
            <div class="col-12 col-lg-6 caracteristique mb-0">
                <h4>{{ 'titreInformationsSup' | translate }}</h4>
            </div>
            <div class="col-12 col-lg-6 caracteristique mb-0">
                <h4>{{ 'sRenoMajeur10Ans' | translate }}</h4>
            </div>
        </div>
        <div class="col-12"></div>
        <div class="row">
            <div class="col-12 col-lg-6 caracteristique text-center">
                {{ data?.sAutre5 | translateValeur : data?.Traduction?.sAutre5}}
            </div>
            <div class="col-12 col-lg-6 caracteristique text-center">
                {{ data?.sRenoMajeur10Ans | translateValeur : data?.Traduction?.sRenoMajeur10Ans}}
            </div>
        </div>

    </div>
</div>
