<div class="d-flex flex-column text-center position-relative">
    <h3>{{data.titre|translate}}</h3>


    <div class="d-flex mx-auto align-items-center mt-2">
        <a [href]="data.lien" target="_blank" mat-flat-button color="accent" label="abc"
            style="width: min-content; margin: 0 auto;" (click)="dialogRef.close()">
            <i class="fas fa-external-link"></i>
            {{data.bouton|translate}}
        </a>

    </div>

    <button mat-icon-button class="position-absolute closer" (click)="dialogRef.close()">
        <i class="fas fa-times"></i>
    </button>
</div>