import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PhotoDialogComponent } from '../photos/photo-dialog.component';

@Component({
  selector: 'app-thumbnails',
  templateUrl: './thumbnails.component.html',
  styleUrls: ['./thumbnails.component.scss']
})
export class ThumbnailsComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  @Input() data: any;

  ngOnInit() {
  }

  ouvririPhoto(photo) {
    const dialogRef = this.dialog.open(PhotoDialogComponent, {
      width: '90vw',
      maxWidth: '1595px',
      data: {photo},
      panelClass: 'photos',
    });
  }

}
