<!-- Partie Portfolio -->
<div class="container">

    <div class="row pl-md-5 ml-md-5 justify-content-start pb-3">
        <div class="col-6 col-md">
            <!-- <h3 class="mb-4">{{'description.appartements' | translate}}</h3> -->
        </div>

        <span class="pt-3">Filtre sur les appartements : </span>
        <div class="col-6 pt-2 pt-md-0 col-md-auto justify-content-end">
            <mat-form-field style="width: 190px;" appearance="fill">
                <mat-label>Trier</mat-label>
                <mat-select>
                    <mat-option *ngFor="let filtre of filtreAppartement" [value]="filtre.valeur">
                        {{filtre.texte}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>


    <div *ngFor="let immeuble of data?.ProprietesEnfants; index as i">

        <!-- LIGNE 1 GAUCHE -->
        <div class="row no-gutters bg-white" *ngIf="i  % 2 === 1">
            <div class="col-xs-12 odrer-xs-2 col-lg-7 order-lg-1 padding p-5">

                <!-- Titre de l'immeuble, échéance, loyer  -->
                <div class="row d-flex">
                    <div class="col">
                        <h3 class="h3portfolio">2505 DES TEXTILES, #203</h3>
                    </div>

                    <div class="col-auto justify-content-end"> <br class="d-md-none d-lg-none"><br
                            class="d-md-none d-lg-none">
                        <h5 class="moisEcheance text-lowercase">650$ /mois &nbsp; 3 ½ &nbsp;&nbsp; <i
                                class="fal fa-calendar-alt"></i> 2022-06-01</h5>
                    </div>
                </div>

                <!-- Description de l'appartement -->
                <div class="row">
                    <div class="col-12 col-md-9">
                        <table class="table table-striped pb-2 mb-2">
                            <thead>
                                <tr>
                                    <th class="w-50"></th>
                                    <th class="w-50"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{{ 'superficie' | translate }}</th>
                                    <td>650 {{ '@pi2' | translate}}</td>
                                </tr>
                                <tr>
                                    <th>{{ 'nombre.de.pieces' | translate }}</th>
                                    <td>3 {{ 'chambres' | translate }}</td>
                                </tr>
                                <tr>
                                    <th>{{ 'dimension.chambres' | translate }}</th>
                                    <td>2x Chambres, 1x Cuisine, 1x Buandrie</td>
                                </tr>
                                <tr>
                                    <th>{{ 'dimension.salon' | translate }}</th>
                                    <td>300 {{ '@pi2' | translate}}</td>
                                </tr>
                                <tr>
                                    <th>{{ 'dimension.cuisine' | translate }}</th>
                                    <td>300 {{ '@pi2' | translate}}</td>
                                </tr>
                                <tr>
                                    <th>{{ 'sLaveuseSecheuse' | translate }}</th>
                                    <td>Oui</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 col-md-3 pt-3 d-none d-md-block">
                        <b>Inclusions</b> <br>
                        • {{ '@chauffage' | translate }} <br>
                        • {{ 'fElectriciteHarmo' | translate }} <br>
                        • {{ '@climatisation' | translate }} <br>
                    </div>
                </div>
                <div class="row">
                    <div class="col pt-md-2" style="padding-left: 20px;">
                        <!-- Remarques concernant l'immeuble -->
                        <b class="mt-5">{{ '@loyers.colRemarque' | translate }} :</b>
                        <div class="text-left"
                            [innerHTML]="immeuble?.sRemarques | translateValeur : immeuble?.sRemarques"> </div>
                    </div>
                    <div class="col-5 d-md-none">
                        <b>Inclusions</b> <br>
                        • {{ '@chauffage' | translate }} <br>
                        • {{ 'fElectriciteHarmo' | translate }} <br>
                        • {{ '@climatisation' | translate }} <br>
                    </div>
                </div>
            </div>


            <!-- Image immeuble -->
            <div class="col-xs-12 order-first order-xs-1 col-lg-5 order-lg-2 image">
                <img class="media h-100 w-100 imageDesc" [src]="immeuble?.sImagePrincipale | resizePhotoPrincipale">
            </div>

        </div>

        <!-- LIGNE 2 DROITE -->
        <div class="row no-gutters bg-gris-2" *ngIf="i  % 2 === 0">

            <div class="col-xs-12 order-xs-3 col-lg-5 order-lg-3 image">
                <img class="media h-100 w-100 imageDesc" [src]=" immeuble?.sImagePrincipale | resizePhotoPrincipale">
            </div>

            <div class="col-xs-12 col-lg-7 order-lg-4 bg-gris padding pb-4 p-5">
                <!-- Titre de l'immeuble, échéance, loyer  -->
                <div class="row d-flex">
                    <div class="col">
                        <h3 class="h3portfolio">2505 DES TEXTILES, #203</h3>
                    </div>

                    <div class="col-auto justify-content-end"> <br class="d-md-none d-lg-none"><br
                            class="d-md-none d-lg-none">
                        <h5 class="moisEcheance text-lowercase">650$ /mois &nbsp; 4 ½ &nbsp;&nbsp; <i
                                class="fal fa-calendar-alt"></i> 2022-06-01</h5>
                    </div>
                </div>
                <!-- Description de l'appartement -->
                <div class="row">
                    <div class="col-12 col-md-9">
                        <table class="table table-striped pb-2 mb-2">
                            <thead>
                                <tr>
                                    <th class="w-50"></th>
                                    <th class="w-50"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{{ 'superficie' | translate }}</th>
                                    <td>650 {{ '@pi2' | translate}}</td>
                                </tr>
                                <tr>
                                    <th>{{ 'nombre.de.pieces' | translate }}</th>
                                    <td>3 {{ 'chambres' | translate }}</td>
                                </tr>
                                <tr>
                                    <th>{{ 'dimension.chambres' | translate }}</th>
                                    <td>2x Chambres, 1x Cuisine, 1x Buandrie</td>
                                </tr>
                                <tr>
                                    <th>{{ 'dimension.salon' | translate }}</th>
                                    <td>300 {{ '@pi2' | translate }}</td>
                                </tr>
                                <tr>
                                    <th>{{ 'dimension.cuisine' | translate }}</th>
                                    <td>300 {{ '@pi2' | translate }}</td>
                                </tr>
                                <tr>
                                    <th>{{ 'sLaveuseSecheuse' | translate }}</th>
                                    <td>Oui</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 col-md-3 pt-3 d-none d-md-block">
                        <b>Inclusions</b> <br>
                        • {{ '@chauffage' | translate }} <br>
                        • {{ 'fElectriciteHarmo' | translate }} <br>
                        • {{ '@climatisation' | translate }} <br>
                    </div>
                </div>
                <div class="row">
                    <div class="col" style="padding-left: 20px;">
                        <!-- Remarques concernant l'immeuble -->
                        <b class="mt-5">{{ '@loyers.colRemarque' | translate }} :</b>
                        <div class="text-left"
                            [innerHTML]="immeuble?.sRemarques | translateValeur : immeuble?.sRemarques"> </div>
                    </div>
                    <div class="col-5 d-md-none">
                        <b>Inclusions</b> <br>
                        • {{ '@chauffage' | translate }} <br>
                        • {{ 'fElectriciteHarmo' | translate }} <br>
                        • {{ '@climatisation' | translate }} <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>