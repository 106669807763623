<div class="row indicateurs">
    <div class="col-xs-12" [ngClass]="['col-lg-' + colLg]">
        <div class="section d-flex flex-column justify-content-center align-items-center">
            <h4>{{ 'CPL' | translate }}</h4>
            <strong *ngIf="data?.sJsonData?.fr?.masquerCpl != true">{{ data?.fCPL | pmmlArgent : true}}</strong>
            <strong *ngIf="data?.sJsonData?.fr?.masquerCpl == true">{{ '@nondisponible' | translate }}</strong>
        </div>
    </div>
    <div class="col-xs-12" [ngClass]="['col-lg-' + colLg]">
        <div class="section d-flex flex-column justify-content-center align-items-center">
            <h4>{{ 'MRB' | translate}}</h4>
            <strong>{{ data?.fMRB | number : '0.1-1'}}</strong>
        </div>
    </div>
    <div class="col-xs-12" [ngClass]="['col-lg-' + colLg]">
        <div class="section d-flex flex-column justify-content-center align-items-center">
            <h4>{{ 'MRN' | translate }}</h4>
            <strong>{{ data?.fMRN | number : '0.1-1' }}</strong>
        </div>
    </div>
    <div class="col-xs-12" [ngClass]="['col-lg-' + colLg]">
        <div class="section d-flex flex-column justify-content-center align-items-center">
            <h4>{{ 'TGA' | translate }}</h4>
            <strong>{{ data?.fTGA | number : '0.1-1' }} % </strong>
        </div>
    </div>
</div>