<div class="thumbnails-section container d-none d-lg-block">
    <div class="row align-items-center">
        <div class="col-sm-12 col-md-6 col-lg-4">
            <a class="photo" (click)="ouvririPhoto(data?.Photos[0])"        
                [style.background-image]="data?.Photos[0] | pmmlPhotoUrl : 600"></a>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
            <a class="photo" (click)="ouvririPhoto(data?.Photos[1])"        
                [style.background-image]="data?.Photos[1] | pmmlPhotoUrl : 600"></a>
        </div>
        <div class="d-sm-none d-lg-block col-lg-4">
            <a class="photo" (click)="ouvririPhoto(data?.Photos[2])"        
                [style.background-image]="data?.Photos[2] | pmmlPhotoUrl : 600"></a>
        </div>
    </div>
</div>