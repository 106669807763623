import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { TranslateValeurPipe } from './../../../translate-valeur.pipe';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(...registerables, ChartDataLabels);
@Component({
  selector: 'app-stats-regional',
  templateUrl: './stats-regional.component.html',
  styleUrls: ['./stats-regional.component.scss']
})
export class StatsRegionalComponent implements OnInit {

  constructor(
    private translate: TranslateValeurPipe
  ) {}

  @Input() data: any;

  dataCroissancePop: any[];

  ngOnInit(): void {


    // *******************************************//
    // *** CHART PROPORTION PAR TRANCHE D’ÂGE ***//
    // *****************************************//
    if(!this.data?.StatsSocioDemoRegional) return;
    var trancheA = this.data?.StatsSocioDemoRegional.iPctAgeA;
    var trancheB = this.data?.StatsSocioDemoRegional.iPctAgeB;
    var trancheC = this.data?.StatsSocioDemoRegional.iPctAgeC;

    var labelTrancheA = <string>this.translate.transform('0-19 ANS', '0-19 YRS');
    var labelTrancheB = <string>this.translate.transform('20-64 ANS', '20-64 YRS');
    var labelTrancheC = <string>this.translate.transform('65 ET PLUS', '65 & OVER');

    var ctx = (<any>document).getElementById('myDoughnut').getContext('2d');
    var myDoughnut = new Chart(ctx, {
        type: 'doughnut',
        data: {
            labels: [labelTrancheA,labelTrancheB,labelTrancheC],
            datasets: [
                {
                    backgroundColor: ["#a78ae3", "#734ebf", "#755da8"],
                    data: [trancheA, trancheB, trancheC],
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        borderRadius: 5,
                        borderWidth: 1,
                        offset: 2,
                        color: 'black',
                        font: {
                            size: 16,
                            weight: 'normal'
                        },
                        formatter: function(value, context) {
                            return  context.chart.data.labels[context.dataIndex];
                        }
                    }
                }
            ]
        },
        options: {
            responsive: false,
            maintainAspectRatio: true,
            layout: {
                padding: 40
            },
            plugins: {
                legend: {
                    display: false,
                    position: 'bottom',
                },
                tooltip: {
                    backgroundColor: 'rgb(0, 0, 0, 0.8)',
                    bodyFont: {
                        size: 15
                    },
                    displayColors: false,
                    bodyAlign: 'center',
                    callbacks: {
                        label: function(context) {
                            const label = `${context.chart.data.datasets[0].data[context.dataIndex]}%`;
                            return label;
                        }
                    }
                }
            }
        },
        plugins: [ChartDataLabels],
    });


    // ******************************************//
    // *** CHART CROISSANCE DE LA POPULATION ***//
    // ****************************************//

    function croissancePopulation (donneesStats) {
        try {

            if(!donneesStats) return 'ND';

            donneesStats.length = 10;
    
            let datesComplete = [];
    
            let statsPopulationTotale = [[], []];
    
            for(let donnees of donneesStats) {
                datesComplete.push(donnees.date)
                statsPopulationTotale[1].push(donnees.valeur)
            }
    
            for (let date of datesComplete) {
                statsPopulationTotale[0].push(date.substring(0, 4))
            }
    
            return JSON.stringify(statsPopulationTotale);
        } catch (error) {
            console.error(error);
        }
    };

    var statsPopulationTotale = JSON.parse(croissancePopulation(this.data.StatsSocioDemoRegional.iPopTotale));
    var labelsCroissancePop = statsPopulationTotale[0].reverse();
    var dataCroissancePop = statsPopulationTotale[1].reverse();

    var ctx = (<any>document).getElementById('myLineChart').getContext('2d');
    var myLineChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: labelsCroissancePop,
            datasets: [{
                fill: true,
                backgroundColor: '#8b73bf',
                data: dataCroissancePop,
                borderColor: '#41257B',
                borderWidth: 3,
                pointRadius: 2,
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                datalabels: {
                    display: false
                },
                legend: {
                    display: false
                },
                tooltip: {
                    titleFont: {
                        size: 15
                    },
                    bodyFont: {
                        size: 14
                    },
                    displayColors: false
                }
            },
            scales: {
                x: {
                    grid: {
                    display: false,
                    }
                },
                y: {
                    grid: {
                    display: false
                    }
                }
            }
        }
    });


    // ********************************//
    // *** CHART POPULATION ACTIVE ***//
    // ******************************//

    function barPopulationActive(donneesStats) {
        try {

            if(!donneesStats) return 'ND';
            
            donneesStats.length = 10;
    
            let datesComplete = [];
    
            let statsPopulationActive = [[], [], []];
    
            for(let donnees of donneesStats) {
                datesComplete.push(donnees.date)
                statsPopulationActive[1].push(+donnees.valeur * 1000)
            }
    
            for (let date of datesComplete) {
                statsPopulationActive[0].push(date.substring(0, 4))
            }

            statsPopulationActive[2].push(Math.min(...statsPopulationActive[1]));
            statsPopulationActive[2].push(Math.max(...statsPopulationActive[1]));
    
            return JSON.stringify(statsPopulationActive);
        } catch (error) {
            console.error(error);
        }
    };

    var statsPopulationActive = JSON.parse(barPopulationActive(this.data.StatsSocioDemoRegional.iPopActive));
    var labelsPopulationActive = statsPopulationActive[0].reverse();
    var dataPopulationActive = statsPopulationActive[1].reverse();
    var rangeDonnees = statsPopulationActive[2][1]-statsPopulationActive[2][0];
    var yMinAxis = Math.round((statsPopulationActive[2][0] - rangeDonnees)/10000)*10000;
    
    var ctx = (<any>document).getElementById('myBarChart').getContext('2d');
    var myBarChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: labelsPopulationActive,
            datasets: [{
                data: dataPopulationActive,
                backgroundColor: "#8b73bf",
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    titleFont: {
                        size: 15
                    },
                    bodyFont: {
                        size: 14
                    },
                    displayColors: false
                }
            },
            scales: {
                x: {
                    grid: {
                    display: false,
                    }
                },
                y: {
                    min: yMinAxis
                }
            }
        }
    });
  }
}



//VERSION POPULATION ACTIVE PAR MOIS//

// function barPopulationActive(donneesStats) {
    //     try {
    //         if(!donneesStats) return 'ND';
    
    //         let datesComplete = [];
    
    //         let statsPopulationActive = [[], [], [], []];
    
    //         for(let donnees of donneesStats) {
    //             datesComplete.push(donnees.date)
    //             statsPopulationActive[1].push(+donnees.valeur * 1000)
    //         }
    
    //         for(let date of datesComplete) {
    //             var dateFormatter = moment(date, 'YYYY-MM-DD', 'fr', true);
    //             statsPopulationActive[0].push(dateFormatter.locale('fr').format('MMM YY'));
    //         }

    //         for(let date of datesComplete) {
    //             var dateFormatter = moment(date, 'YYYY-MM-DD', 'fr', true);
    //             statsPopulationActive[3].push(dateFormatter.locale('en').format('MMM YY'));
    //         }
    
    //         statsPopulationActive[2].push(Math.min(...statsPopulationActive[1]) - 1500);
    //         statsPopulationActive[2].push(Math.max(...statsPopulationActive[1]) + 1000);
            
    //         return JSON.stringify(statsPopulationActive);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // var statsPopulationActive = JSON.parse(barPopulationActive(this.data.StatsSocioDemoRegional.iPopActive));
    // var labelsPopulationActive = <string[]>this.translate.transform(statsPopulationActive[0].reverse(), statsPopulationActive[3].reverse());
    // var dataPopulationActive = statsPopulationActive[1].reverse();
    // var yAxisStepSize = Math.floor(((statsPopulationActive[2][1] - statsPopulationActive[2][0]) / 10));
    