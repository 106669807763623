import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ProprietesService } from "../../api/proprietes.service";
import { AuthService } from "../../auth.service";

@Component({
  selector: "app-terrain",
  templateUrl: "./terrain.component.html",
  styleUrls: ["./terrain.component.scss"],
})
export class LandComponent implements OnInit {
  connected: boolean = false;

  environment = environment;
  @Input() data: any;

  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    this.auth.user$
    .subscribe((user) => {
      this.connected = user ? true : false;
    });
  }

  get aValidPhotos() {
    if (!this.data) return false;
    return this.data.Photos.some((photo) => photo.IDImage);
  }

  seConnecter() {
    return this.auth.login(window.location.pathname + "#sommaires");
  }
}
