import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[numberFormat]",
})
export class NumberFormatDirective {
  @HostListener("blur", ["$event"])
  onBlur(event) {
    const input = event.target as HTMLInputElement;
    const numbersOnly = input.value.replace(/(\D*)/g, "");
    let currentGroup = "";
    const numberGroups = [];

    for (let i = numbersOnly.length - 1; i >= 0; i--) {
      const char = numbersOnly[i];
      currentGroup = char + currentGroup;

      if (currentGroup.length === 3) {
        numberGroups.unshift(currentGroup);
        currentGroup = "";
      }
    }
    if (currentGroup.length) {
      numberGroups.unshift(currentGroup);
    }

    const newval = numberGroups.join(" ");
    input.value = newval;
  }

  constructor() {}
}
