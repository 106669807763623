<fb-video *ngIf="matchUrl && (urlFacebook == true)"
    width="1400" 
    height="789"
    [attr.data-href]="matchUrl"
    allowfullscreen="true">
</fb-video>

<div *ngIf="matchUrl && (urlYoutube == true)">
    <iframe
        class="videoYoutube"
        [src]="matchUrl | safe: 'url' " title="PMML" frameborder="0" 
        width="1400" height="789" 
        target="_top" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
    </iframe>
    
</div>
