import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trierSomloyers'
})
export class TrierSomloyersPipe implements PipeTransform {

  transform(list: any[], value: string, type?: any) {
    let rentrollPropriete =  value ? list.filter(item => item.IDPropriete === value) : list;
    return rentrollPropriete.sort((a, b) => parseFloat(a.sIdentifiant) - parseFloat(b.sIdentifiant));
  }

}
