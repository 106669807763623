<div class="container-fluid bootstrapImportation">
    <div class="row">
        <div *ngFor="let article of articles" class="col-xl-4 col-lg-4 col-md-6 pb-3">
            <div class="article">
                <a class="photo" 
                    [href]="article?.fyoutube" target="_blank"
                    [style.background]="'url(' + article.fimg_courriel_url + ')'"
                    [style.backgroundSize]="'cover'">
                    <div class="informations">
                        <h4 [innerHTML]="article?.title.rendered"></h4>
                        <p [innerHTML]="article?.excerpt_text | extrait"></p>
                        <button type="button">{{ '@en.savoir.plus' | translate }}</button>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-auto">
            <mat-spinner [hidden]="!scrollChangement" color="wharm"></mat-spinner>
            <!-- <a (click)="voirPlus()" class="voir-plus">{{ '@pmmltv.voir-plus' | translate}}</a> -->
        </div>
    </div>
</div>

