import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-liste-locaux',
  templateUrl: './liste-locaux.component.html',
  styleUrls: ['./liste-locaux.component.scss']
})
export class ListeLocauxComponent implements OnInit {
  jwt = '';

  constructor() { }

  dateAujourdhui: Date = new Date();

  @Input() data: any;

  @Output() urlMatterport = new EventEmitter<string>();

  proprieteEnfant: any;

  ngOnInit(): void {
    this.urlMatterport.emit(this.data?.ProprietesEnfants[0]?.sJsonData?.UrlMatterport);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('a')) {
      this.jwt = `?a=${urlParams.get('a')}`;
    }
  }

  clickVisite3D(matterport) {
    this.urlMatterport.emit(matterport);

    const vueMatterport = document.querySelector('#matterport');
    vueMatterport.scrollIntoView({ behavior: 'smooth' });
  }

}
