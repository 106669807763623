import { Component, Input, OnInit } from '@angular/core';
import { TranslateValeurPipe } from 'src/app/translate-valeur.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listing-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {

  environment = environment;
  aParamsPresent = false;
  aParamCode: string = '';

  @Input('data') data: any;
  @Input('location') location = false;

  constructor(
    private translatePipe: TranslateValeurPipe,
  ) { }

  videoId: any;
  showVideo: boolean = false;
  player: any;
  YT: any;
  reframed: Boolean = false;

  initVideo() {
    this.videoId = this.translatePipe.transform(this.data.sVideoYoutubeFr, this.data.sVideoYoutubeAn);

    window['onYouTubeIframeAPIReady'] = () => {
      this.YT = window['YT'];
      this.player = new window['YT'].Player('video-iframe', {
        videoId: this.videoId,
        playerVars: { 'rel': 0 },
      });
    };

    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  ngOnInit(): void {
    this.initVideo()
    
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('a')) {
      this.aParamsPresent = true
      this.aParamCode = `?a=${urlParams.get('a')}`;
    } else this.aParamCode = '';
  }

  lireVideo() {
    this.showVideo = true;
    this.player.playVideo();
  }
}
