<button *ngIf="network === 'custom'" class="share-btn st-custom-button">
    <i class="share-icon" [class]="icon"></i>
    <span>{{label}}</span>
</button>

<button  *ngIf="network !== 'custom'"
  class="share-btn st-custom-button" 
  [attr.data-network]="network"
  [attr.data-url]="url"
  [attr.data-short-url]="shortUrl"
  [attr.data-title]="title"
  [attr.data-image]="image"
  [attr.data-description]="description"
  [attr.data-username]="username"
  [attr.data-message]="message"
  [attr.data-email-subject]="emailSubject"
>
    <i class="share-icon" [class]="icon"></i>
    <span>{{label}}</span>
</button>