import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-btn',
  templateUrl: './share-btn.component.html',
  styleUrls: ['./share-btn.component.scss']
})
export class ShareBtnComponent implements OnInit {
  @Input() label;
  @Input() icon;

  // Data Attribs - https://sharethis.com/support/customization/how-to-set-custom-buttons/
  @Input() network;       // Specifies to which social platform the content will be shared
  @Input() url = location.href.split('?')[0]           // Specifies URL (can be shortened URL) that you would like shared
  @Input() shortUrl       // Specifies a shortened URL that can be used in conjunction with the data-url
  @Input() title          // Specifies title that you would like shared
  @Input() image          // Specifies link to image you would like displayed in the shared content
  @Input() description    // Specifies summary text/description you wish to share
  @Input() username       // Specifies your Twitter username
  @Input() message        // Specifies the body copy of e-mail shares
  @Input() emailSubject   // Specifies the email’s subject

  constructor() { }

  ngOnInit(): void { }

}
