<section class="container">
    <div class="row">
        <div class="col-xs-12 col-md-8 offset-md-2 ">
            <h3 *ngIf="(data?.sTypeLogement === 'iCommercial' || data?.sTypeLogement === 'iMultiLogement' || data?.sTypeLogement === '' || data?.sTypeLogement === 'iUnitesGrand' || data?.sTypeLogement === 'iUnitesChambre')">
                {{ '@description.propriete' | translate }}
            </h3>
            <h3 *ngIf="data?.sTypeLogement === 'iLocal'">
                {{ '@description.local' | translate }}
            </h3>
            <h3 *ngIf="data?.sTypeLogement === 'iTerrain'">
                {{ '@description.terrain' | translate }}
            </h3>
            <p class="mt-3">{{ data?.sRemarques | translateValeur : data?.Traduction?.sRemarques }}</p>
        </div>
    </div>
</section>