import { RouteService } from './route.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PmmltvInteractionService {
  constructor(private routeService: RouteService) {}

  public changementChaine: Subject<any> = new Subject<any>();

  public get chaine(): Observable<any> {
    return this.changementChaine.asObservable();
  }

  setChaine(chaine: any) {
    this.routeService.setHash(chaine);
    this.changementChaine.next(chaine);
  }
}
