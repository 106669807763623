import { Component, OnInit } from '@angular/core';
import { WordpressService } from '../../api/wordpress.service';

@Component({
  selector: 'app-pmml-tv-nouveautes',
  templateUrl: './pmml-tv-nouveautes.component.html',
  styleUrls: ['./pmml-tv-nouveautes.component.scss']
})
export class PmmlTvNouveautesComponent implements OnInit {

  constructor(private wp: WordpressService) { }

  ngOnInit(): void {
    this.recupArticles();

    let post = [{ "id": 14369, "link": "https:\/\/pmml.ca\/le-projet-agora\/", "title": { "rendered": "Le projet AGORA" }, "featured_media": 14329, "excerpt_text": "Abbas Saad nous pr\u00e9sente le projet AGORA en Outaouais, en compagnie de Am\u00e9lie Larocque directrice du d\u00e9veloppement commercial.\r\nAvec ses nombreux commerces, services, activit\u00e9s et ses sentiers multifonctionnels, l\u2019AGORA offre un environnement in\u00e9gal\u00e9 et unique. Ayant plus de 600 unit\u00e9s r\u00e9sidentielles int\u00e9gr\u00e9es au projet, le projet sera sans aucun doute la destination pris\u00e9e pour toute entreprise d\u00e9sirant s\u2019\u00e9tablir ou agrandir sa pr\u00e9sence dans la r\u00e9gion.\r\nPour en apprendre davantage sur le projet, visitez \u27a1\ufe0f https:\/\/agora-plateau.com\/", "fimg_url": "https:\/\/pmml.ca\/wp-content\/uploads\/2021\/04\/Projet-Agora-Abbas-Saad-300x169.jpg", "fimg_courriel_url": "https:\/\/pmml.ca\/wp-content\/uploads\/2021\/04\/Projet-Agora-Abbas-Saad-1024x576.jpg" }]
    this.article = {
      lien: post[0].link,
      titre: post[0].title.rendered,
      description: post[0].excerpt_text,
      image: post[0].fimg_courriel_url
    }  
  }

  chaine: number = 1637;
  nbParPages: number = 1;
  article: any;

  recupArticles(noPage = 1): void {   
    // Pour débug seulement
    // this.wp.listeArticles(this.chaine, noPage, this.nbParPages).then(([pmmltv]) => this.articles.push(...pmmltv));
    this.wp.listeArticles(this.chaine, noPage, this.nbParPages, true).subscribe(([posts, pages]) => {
      this.article = {
        lien: posts[0].link,
        titre: posts[0].title.rendered,
        description: posts[0].excerpt_text,
        image: posts[0].fimg_courriel_url
      }
    });
  }

}
