import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from "../../../auth.service";

@Component({
  selector: 'app-loyers-com',
  templateUrl: './loyers-com.component.html',
  styleUrls: ['./loyers-com.component.scss']
})
export class LoyersComComponent implements OnInit {

  connected : boolean = false;

  constructor(
    private auth: AuthService,
  ) { }

  @Input() data: any;
  loyers: any;

  ngOnInit(): void {
    this.auth.user$
    .subscribe((user) => {
      this.connected = user ? true : false;
    });
  }

  seConnecter(){
    this.auth.login(window.location.pathname);
  }


}
