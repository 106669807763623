import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dispo-superficie',
  templateUrl: './dispo-superficie.component.html',
  styleUrls: ['./dispo-superficie.component.scss']
})
export class DispoSuperficieComponent implements OnInit {

  constructor() { }

  @Input() data: any;

  ngOnInit(): void {
  }

}
