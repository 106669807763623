import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from "../../../auth.service";

@Component({
  selector: 'app-voir-plus',
  templateUrl: './voir-plus.component.html',
  styleUrls: ['./voir-plus.component.scss']
})
export class VoirPlusComponent implements OnInit {

  @Input() data: any;

  constructor(private auth: AuthService,) { }

  connected: boolean = false;


  ngOnInit(): void {
    this.auth.user$
    .subscribe((user) => {
      this.connected = user ? true : false;
    });
  }

  seConnecter() {
    return this.auth.login(window.location.pathname);
  }

}
