<div class="descriptif-section d-flex align-items-center justify-content-center">
    <div class="container w-100">
        <div class="row mt-5">
            <div class="titre-section w-100" style="text-align: center">
                <h2 style="font-family: acumin-pro, sans-serif; font-weight: 700; font-size: 32px;">{{ 'batisse' | translate }}</h2>
            </div>
        </div>
        <div class="row marge">
            <div class="col-xl-6 secr d-flex flex-column align-items-end justify-content-start" >
                <div class="general w-100">
                    <h3>{{ 'superficie' | translate }}</h3>
                    <table class="table table-striped ordinateur" style="width: 100%">
                        <tbody>
                            <tr>
                            <td class="table-title">{{ '@superficie.totale.brut.sqft' | translate }}</td>
                            <td class="table-value">{{ data?.iPiedsCarreTotal }}</td>
                            </tr>
                            <tr>
                                <td class="table-title">{{ '@superficie.louable.sqft' | translate }}</td>
                                <td class="table-value">{{ data?.sJsonData?.fr?.fSuperficieLouable | translateValeur : data?.sJsonData?.an?.fSuperficieLouable }}</td>
                            </tr>
                            <tr>
                                <td class="table-title">{{ '@superficie.moyenne.unite.sqft' | translate }}</td>
                                <td class="table-value">{{ data?.iPiedsCarreTotal / data?.iUnitesTotal }} {{ '@pi2' | translate }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-striped mobile" style="width: 100%">
                        <tbody>
                            <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@superficie.totale.brut.sqft' | translate }}</td>
                            </tr>
                            <tr class="m-tr-valeur">
                            <td class="m-td-valeur">{{ data?.iPiedCarreTotal }}</td>
                            </tr>
                            <tr class="m-tr-titre">
                                <td class="m-td-titre">{{ '@superficie.louable.sqft' | translate }}</td>
                            </tr>
                            <tr class="m-tr-valeur">
                                <td class="m-td-valeur">{{ data?.sJsonData?.fr?.fSuperficieLouable | translateValeur : data?.sJsonData?.an?.fSuperficieLouable }}</td>
                            </tr>
                            <tr class="m-tr-titre">
                                <td class="m-td-titre">{{ '@superficie.moyenne.unite.sqft' | translate }}</td>
                            </tr>
                            <tr class="m-tr-valeur">
                                <td class="m-td-valeur">{{ data?.iPiedsCarreTotal / data?.iUnitesTotal }} {{ '@pi2' | translate }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xl-6 secl d-flex flex-column align-items-start justify-content-start" >
                <div class="evaluation-municipale w-100 ">
                    <h3><i class="fas fa-usd-circle"></i> {{ '@evaluation.municipale' | translate}}</h3>
                    <table class="table table-striped ordinateur" style="width: 100%;">
                        <tbody>
                            <tr>
                                <td class="table-title">{{ '@terrain' | translate }}</td>
                                <td class="table-value">{{ data?.sEvaluationMunicipaleTerrain }}</td>
                            </tr>
                            <tr>
                                <td class="table-title">{{ '@batiment' | translate }}</td>
                                <td class="table-value">{{ data?.sEvaluationMunicipaleBatiment }}</td>
                            </tr>
                            <tr>
                                <td class="table-title">Total</td>
                                <td class="table-value">{{ data?.sEvaluationMunicipale }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-striped mobile" style="width: 100%">
                        <tbody>
                            <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@terrain' | translate }}</td>
                            </tr>
                            <tr class="m-tr-valeur">
                                <td class="m-td-valeur">{{ data?.sEvaluationMunicipaleTerrain }}</td>
                            </tr>
                            <tr class="m-tr-titre">
                            <td class="m-td-titre">{{ '@batiment' | translate }}</td>
                            </tr>
                            <tr class="m-tr-valeur">
                                <td class="m-td-valeur">{{ data?.sEvaluationMunicipaleBatiment }}</td>
                            </tr>
                            <tr class="m-tr-titre">
                                <td class="m-td-titre">total</td>
                            </tr>
                            <tr class="m-tr-valeur">
                                <td class="m-td-valeur">{{ data?.sEvaluationMunicipale }}</td>
                            </tr>
                          </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
