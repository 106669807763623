import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, DEFAULT_CURRENCY_CODE, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader, TranslateService, TranslatePipe } from '@ngx-translate/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { FacebookModule } from 'ngx-facebook';
import { RouterModule, Routes } from '@angular/router';
import { QRCodeModule } from 'angular2-qrcode';

// Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CustomTranslateLoader } from './traductions/loader';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSnackBarModule } from '@angular/material/snack-bar'

import { registerLocaleData, CurrencyPipe, DecimalPipe, DOCUMENT, DatePipe } from '@angular/common';
import localeFrCa from '@angular/common/locales/fr-CA';
registerLocaleData(localeFrCa, 'fr-CA');

// Services
import * as AuthV1 from './auth.service';
import { AuthInterceptorService } from './auth-interceptor.service';
import { ContactsService } from './api/contacts.service';
import { ProprietesService } from './api/proprietes.service';
import { PmmltvInteractionService } from './pmmltv-interaction.service';
import { RouteService } from './route.service';
import { SegmentService } from './api/segment.service';

// Components
import { PopupInscriptionComponent } from './popup-inscription/popup-inscription.component';
// import { FormulaireAlertesComponent } from './formulaire-alertes/formulaire-alertes.component';
// import { BoutonLoginComponent } from './auth/bouton-login.component';
import { ConnexionComponent } from './auth/connexion/connexion.component';
import { BoutonDownloadRapportComponent } from './auth/bouton-download-rapport.component';
import { CarrouselComponent } from './listings/general/carrousel/carrousel.component';
import { CarrouselCompletComponent } from './listings/general/carrousel-complet/carrousel-complet.component';
import { ChatPmmlComponent } from './chat-pmml/chat-pmml.component';
// Components - PMMLTV
import { PmmlTvComponent } from './pmml-tv/pmml-tv.component';
import { SliderPMMLTVComponent } from './components/slider-pmmltv/slider-pmmltv.component';
import { ChainesComponent } from './chaines/chaines.component';

// Components - Propriétés en vente
import { ListeProprietesComponent } from './listings/liste-priorites/liste-proprietes/liste-proprietes.component';
import { RechercheComponent } from './listings/liste-priorites/recherche/recherche.component';
import { ProprietesComponent } from './listings/liste-priorites/proprietes/proprietes.component';
import { TopbarComponent } from './listings/non-utilisee/topbar/topbar.component';
import { ConnexionFavorisDialogComponent } from './listings/liste-priorites/connexion-favoris-dialog/connexion-favoris-dialog.component';
import { FavorisInformationsComponent } from './listings/liste-priorites/favoris-informations/favoris-informations.component';

// Components - dialogs
import { PhotoDialogComponent } from './listings/general/photos/photo-dialog.component';

// Listings
import { PrioriteDetailsComponent } from './listings/general/propriete-details/propriete-details.component';
import { providesListings } from './listings';

// Pipes
import { ProprieteTypeDepPipe } from './api/propriete-type-dep.pipe';
import { Nl2brPipe } from './nl2br.pipe';
import { PhotoUrlPipe } from './pipes/photo-url.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ArrondiPipe } from './pipes/arrondi.pipe';
import { Nl2liPipe } from './pipes/nl2li.pipe';
import { PhotoPrincipaleUrlPipe } from './pipes/photo-principale-url.pipe';
import { ExtraitPipe } from './pipes/extrait.pipe';
import { TranslateValeurPipe } from './translate-valeur.pipe';
import { UrlCartePipe } from './pipes/url-carte.pipe';
import { GroupeLocationComponent } from './listings/groupe-location/groupe-location.component';
import { DescriptifBurComponent } from './listings/groupe-location/descriptif-bur/descriptif-bur.component';
import { ListeLocauxComponent } from './listings/groupe-location/liste-locaux/liste-locaux.component';
import { MatterportComponent } from './listings/general/matterport/matterport.component';
import { VoirPlusComponent } from './listings/general/voir-plus/voir-plus.component';
import { ResizePhotoPrincipalePipe } from './pipes/resize-photo-principale.pipe';
import { DescriptifLocauxComponent } from './listings/groupe-location/descriptif-locaux/descriptif-locaux.component';
import { UrlifyFromTextPipe } from './pipes/urlify-from-text.pipe';
import { SommairesComComponent } from './listings/commercial/sommaires-com/sommaires-com.component';
import { PortfolioComponent } from './listings/portfolio/portfolio.component';
// import { MenuMonCompteComponent } from './menu-mon-compte/menu-mon-compte.component';
// import { MenuMonProfilComponent } from './menu-mon-profil/menu-mon-profil.component';
// import { MonProfilComponent } from './mon-profil/mon-profil.component';
import { ListeFavorisComponent } from './formulaire-alertes/liste-favoris/liste-favoris.component';
import { PortfolioDescriptifComponent } from './listings/portfolio/portfolio-descriptif/portfolio-descriptif.component';
import { SomloyersEvmunicipaleComponent } from './listings/portfolio/somloyers-evmunicipale/somloyers-evmunicipale.component';
import { TrierSomloyersPipe } from './listings/portfolio/trier-somloyers.pipe';
import { EcheanceloyersPipe } from './pipes/echeanceloyers.pipe';
import { PortfolioCommercialComponent } from './listings/portfolio/portfolio-commercial/portfolio-commercial.component';
import { InfSupComComponent } from './listings/commercial/inf-sup-com/inf-sup-com.component';
import { PiedComponent } from './listings/general/pied/pied.component';

// Datadog
import { datadogRum } from '@datadog/browser-rum';
import { ListeProprietesTableauComponent } from './listings/liste-priorites/liste-proprietes-tableau/liste-proprietes-tableau.component';
import { PmmlTvNouveautesComponent } from './pmml-tv/pmml-tv-nouveautes/pmml-tv-nouveautes.component';
import { VideoPmmlTvComponent } from './pmml-tv/video-pmml-tv/video-pmml-tv.component';
import { MultiGoPlexComponent } from './listings/multi-go-plex/multi-go-plex.component';
import { DescAppartementComponent } from './listings/multi-go-plex/desc-appartement/desc-appartement.component';
import { InfoFinPlexComponent } from './listings/multi-go-plex/info-fin-plex/info-fin-plex.component';
import { MenuDeuxComponent } from './listings/general/menu-deux/menu-deux.component';
import { InfoCourtierImmeubleComponent } from './listings/general/info-courtier-immeuble/info-courtier-immeuble.component';
import { StatsLocatifsComponent } from './listings/stats-locatifs/stats-locatifs.component';
import { StatsRegionalComponent } from './listings/general/stats-regional/stats-regional.component';
import { InfoGoplexComponent } from './listings/multi-go-plex/info-goplex/info-goplex.component';
import { CaracteristiquesGoplexComponent } from './listings/multi-go-plex/caracteristiques-goplex/caracteristiques-goplex.component';
import { RrGoplexComponent } from './listings/multi-go-plex/rr-goplex/rr-goplex.component';
import { VideoDialogComponent } from './listings/general/videos/video-dialog/video-dialog.component';
import { RrFormatColPipe } from './pipes/rr-format-col.pipe';
// import { ChatlioComponent } from './chatlio/chatlio.component';
import { NumberFormatDirective } from './directives/number-format.directive';
import { ConnexionDialogComponent } from './listings/connexion-dialog/connexion-dialog.component';
import { APP_BASE_HREF } from '@angular/common';
import { CartesDetailsCourtiersComponent } from './cartes-details-courtiers/cartes-details-courtiers.component';
import { ProprieteTitrePipe } from './pipes/propriete-titre.pipe';
import { ShareBtnComponent } from './components/share-btn/share-btn.component';
import { CourtierCarteOverlayComponent } from './components/courtier-carte-overlay/courtier-carte-overlay.component';
import { ContactFormComponent } from './general/contact-form/contact-form.component';
import { FirstValuePipe } from './first-value.pipe';
import { BloquesStatsComponent } from './listings/general/bloques-stats/bloques-stats.component';
import { PageRapportsComponent } from './page-rapports/page-rapports.component';
import { AuthPageComponent } from './general/auth-page/auth-page.component';
import { CompleteRegistrationComponent } from './complete-registration/complete-registration.component';
import { CodeInputComponent } from './general/code-input/code-input.component';
import { AuthModule } from '@auth0/auth0-angular';
import { AuthService } from '@auth0/auth0-angular';
import { AuthHelperService } from './general/auth-helper.service';
import { OuvreLienComponentComponent } from './general/ouvre-lien-component/ouvre-lien-component.component';
import { PdfComponent } from './listings/pdf/pdf.component';
import { InfosCourtierComponent } from './listings/partials/infos-courtier/infos-courtier.component';
import { PrixDemandeComponent } from './listings/partials/prix-demande/prix-demande.component';
import { DemandeDataroomComponent } from './demande-dataroom/demande-dataroom.component';
import { CfImgPipe } from './cf-img.pipe';

datadogRum.init({
  applicationId: '6b4908ef-51c9-4be3-83c3-2b8210f7f96f',
  clientToken: 'pube2dc225442fe2f12fdbc3a1d27022b5f',
  site: 'datadoghq.com',
  service: 'Site Web',
  env: 'production',
  version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true
});

const providers = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  { provide: LOCALE_ID, useValue: 'fr-CA' },
  { provide: DEFAULT_CURRENCY_CODE, useValue: 'CAD' },
  { provide: APP_BASE_HREF, useValue: '/' },
  AuthService,
  ProprietesService,
  ContactsService,
  PmmltvInteractionService,
  SegmentService,
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  TranslatePipe,
  TranslateValeurPipe,
  ProprieteTitrePipe,
  ResizePhotoPrincipalePipe,
  TrierSomloyersPipe,
  RouteService,
  PhotoUrlPipe,
  {
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: "primary" },
  },
];

const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    PopupInscriptionComponent,
    // FormulaireAlertesComponent,
    // ListeProprietesComponent,
    SliderPMMLTVComponent,
    RechercheComponent,
    ProprietesComponent,
    PmmlTvComponent,
    ExtraitPipe,
    ChainesComponent,
    TopbarComponent,
    SafePipe,
    // BoutonLoginComponent,
    BoutonDownloadRapportComponent,
    ConnexionComponent,
    PhotoDialogComponent,
    // Pipes
    Nl2brPipe,
    TranslateValeurPipe,
    PhotoUrlPipe,
    ProprieteTypeDepPipe,
    ArrondiPipe,
    Nl2liPipe,
    PhotoPrincipaleUrlPipe,
    PiedComponent,
    UrlCartePipe,
    GroupeLocationComponent,
    DescriptifBurComponent,
    ListeLocauxComponent,
    MatterportComponent,
    VoirPlusComponent,
    ResizePhotoPrincipalePipe,
    DescriptifLocauxComponent,
    UrlifyFromTextPipe,
    ListeFavorisComponent,
    // MonProfilComponent,
    // MenuMonProfilComponent,
    SommairesComComponent,
    // MenuMonCompteComponent,
    PortfolioComponent,
    PortfolioDescriptifComponent,
    SomloyersEvmunicipaleComponent,
    TrierSomloyersPipe,
    ConnexionFavorisDialogComponent,
    FavorisInformationsComponent,
    EcheanceloyersPipe,
    PortfolioCommercialComponent,
    InfSupComComponent,
    ChatPmmlComponent,
    ListeProprietesTableauComponent,
    PmmlTvNouveautesComponent,
    VideoPmmlTvComponent,
    MultiGoPlexComponent,
    DescAppartementComponent,
    CarrouselComponent,
    CarrouselCompletComponent,
    InfoFinPlexComponent,
    MenuDeuxComponent,
    InfoCourtierImmeubleComponent,
    StatsLocatifsComponent,
    StatsRegionalComponent,
    InfoGoplexComponent,
    CaracteristiquesGoplexComponent,
    RrGoplexComponent,
    VideoDialogComponent,
    RrFormatColPipe,
    // ChatlioComponent,
    NumberFormatDirective,
    ConnexionDialogComponent,
    ...providesListings,
    CartesDetailsCourtiersComponent,
    ProprieteTitrePipe,
    ShareBtnComponent,
    CourtierCarteOverlayComponent,
    ContactFormComponent,
    FirstValuePipe,
    BloquesStatsComponent,
    PageRapportsComponent,
    AuthPageComponent,
    CompleteRegistrationComponent,
    CodeInputComponent,
    OuvreLienComponentComponent,
    PdfComponent,
    InfosCourtierComponent,
    PrixDemandeComponent,
    DemandeDataroomComponent,
    CfImgPipe,
  ],
  imports: [
    RouterModule.forRoot([], { useHash: true }),
    AuthModule.forRoot(environment.auth0),
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatSortModule,
    MatTableModule,
    MatSidenavModule,
    MatTabsModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatBadgeModule,
    MatDividerModule,
    QRCodeModule,
    ScrollingModule,
    MatSnackBarModule,
    FacebookModule.forRoot(),
    NgxMaskModule.forRoot(options),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader
      }
    }),
    NgxMapboxGLModule.withConfig({
      accessToken: environment?.mapbox?.accessToken
    })
  ],
  providers,
  entryComponents: [
    PopupInscriptionComponent,
    // FormulaireAlertesComponent,
    // ListeProprietesComponent,
    SliderPMMLTVComponent,
    ProprietesComponent,
    ConnexionComponent,
    PhotoDialogComponent,
  ], 
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ]
})
export class AppModule {
  constructor(
    private injector: Injector, 
    private auth: AuthV1.AuthService, 
    translate: TranslateService,
    authHelper: AuthHelperService,
  ) {
    let lang = 'fr';
    if ((<any>window).pmml_data != undefined) lang = (<any>window).pmml_data.lang || 'fr';
    translate.setDefaultLang(lang);
  }

  ngDoBootstrap() {
    try {
      this.auth.getToken$()
        .subscribe(() => console.log('OK!'));
    } catch (error) {

    }
    // Chatbot
    const ePmmlChat = createCustomElement(ChatPmmlComponent, { injector: this.injector });
    customElements.define('pmml-chat', ePmmlChat);

    // Infolettre
    const elPopupInscription = createCustomElement(PopupInscriptionComponent, { injector: this.injector });
    customElements.define('pmml-popup-inscription', elPopupInscription);

    // const elFormulaireAlertes = createCustomElement(FormulaireAlertesComponent, { injector: this.injector });
    // customElements.define('pmml-formulaire-alertes', elFormulaireAlertes);

    const elListeFavoris = createCustomElement(ListeFavorisComponent, { injector: this.injector });
    customElements.define('pmml-liste-favoris', elListeFavoris);

    // // const elMonProfil = createCustomElement(MonProfilComponent, { injector: this.injector });
    // customElements.define('pmml-mon-profil', elMonProfil);

    // PMML.tv
    const ePmmlTv = createCustomElement(PmmlTvComponent, { injector: this.injector });
    customElements.define('pmml-tv-articles', ePmmlTv);

    const ePmmlTvVideo = createCustomElement(VideoPmmlTvComponent, { injector: this.injector });
    customElements.define('pmml-tv-articles-video', ePmmlTvVideo);

    const ePmmlTvNouveautes = createCustomElement(PmmlTvNouveautesComponent, { injector: this.injector });
    customElements.define('pmml-tv-nouveautes', ePmmlTvNouveautes);

    const ePmmlTvChoixChaine = createCustomElement(ChainesComponent, { injector: this.injector });
    customElements.define('pmml-tv-choix-chaines', ePmmlTvChoixChaine);

    const eSliderPmmlTv = createCustomElement(SliderPMMLTVComponent, { injector: this.injector });
    customElements.define('pmml-slider-tv', eSliderPmmlTv);

    // Fiches descriptives
    const eProprieteDetails = createCustomElement(PrioriteDetailsComponent, { injector: this.injector });
    customElements.define('pmml-propriete-details', eProprieteDetails);

    const eTypesProprietes = createCustomElement(ProprietesComponent, { injector: this.injector });
    customElements.define('pmml-types-proprietes', eTypesProprietes);

    const ePied = createCustomElement(PiedComponent, { injector: this.injector });
    customElements.define('pmml-pied-page', ePied);

    // Cartes Affaires Electronique
    const eCartesAffaires = createCustomElement(CartesDetailsCourtiersComponent, { injector: this.injector });
    customElements.define('pmml-cartes-affaires', eCartesAffaires);

    // Connexion
    // const eBoutonLogin = createCustomElement(BoutonLoginComponent, { injector: this.injector });
    // customElements.define('pmml-bouton-login', eBoutonLogin);

    const eBoutonDownload = createCustomElement(BoutonDownloadRapportComponent, { injector: this.injector });
    customElements.define('pmml-bouton-download', eBoutonDownload);

    const eConnexion = createCustomElement(ConnexionComponent, { injector: this.injector });
    customElements.define('pmml-connexion', eConnexion);

    // const eMonCompte = createCustomElement(MenuMonCompteComponent, { injector: this.injector });
    // customElements.define('pmml-menu-compte', eMonCompte);

    const ePageRapports = createCustomElement(PageRapportsComponent, { injector: this.injector });
    customElements.define('pmml-page-rapports', ePageRapports);

    console.log('Initialisation des composants OK.')
  }
}
