import { Component, OnInit, Input } from '@angular/core';
import { WordpressService } from '../../api/wordpress.service';
import { ProprietesService } from "../../api/proprietes.service";
import { environment } from '../../../environments/environment'


@Component({
  selector: 'app-commercial',
  templateUrl: './commercial.component.html',
  styleUrls: ['./commercial.component.scss']
})
export class CommercialComponent{

  environment = environment;
  @Input() data: any;

  constructor(
    private wp: WordpressService,
    private proprietes: ProprietesService
  ) { }



}
