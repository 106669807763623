import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SegmentService } from "./api/segment.service";

import { AuthService as V1AuthService } from "./auth.service";

import { ContactsService } from "./api/contacts.service";
import { AuthHelperService } from "./general/auth-helper.service";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService {
  a0Token: string;

  constructor(
    // private auth: V1AuthService,
    private segmentService: SegmentService
  ) {}

  getLangue() {
    return ((<any>window).pmml_data || {}).lang || "fr";
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const token = this.auth.token || this.a0Token;
    const anonUserID = this.segmentService.getAnonID();
    // if (req.url.startsWith("/wp-json")) {
    //   return next.handle(req);
    // }

    const authReq = req.clone({
      setHeaders: {
        "x-lang": this.getLangue(),
        "x-segment-id": anonUserID || null,
      },
    });

    return next.handle(authReq);
  }
}
