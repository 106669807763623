import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bail-bur-loc',
  templateUrl: './bail-bur-loc.component.html',
  styleUrls: ['./bail-bur-loc.component.scss']
})
export class BailBurLocComponent implements OnInit {

  constructor() { }

  @Input() data: any;

  ngOnInit(): void {
  }

}
