import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-courtier-carte-overlay',
  templateUrl: './courtier-carte-overlay.component.html',
  styleUrls: ['./courtier-carte-overlay.component.scss']
})
export class CourtierCarteOverlayComponent implements OnInit, OnDestroy {
  @Input() url;
  urlSansHash: any;
  @Input() courtier;
  @Input() modeCourtier;

  checked: any;

  @Output() close = new EventEmitter()

  constructor(private snackBar: MatSnackBar, private transformPipe: TranslatePipe) { }

  ref = null;
  afficheForm = false;

  ngAfterViewInit() {
    this.urlSansHash = this.url;
    this.afficheForm = location.hash.includes('ouvre')
    location.hash = ''

    document.body.style.overflow = 'hidden';
    const property = '6298c59c703b77001ade9c7b'
    const src = `https://platform-api.sharethis.com/js/sharethis.js#property=${property}&product=custom-share-buttons`;

    const shareThisScript = document.createElement("script");

    shareThisScript.type = "text/javascript";
    shareThisScript.src = src;

    this.ref = shareThisScript

    document.body.appendChild(shareThisScript)
  }

  ngOnDestroy(): void {
    document.body.style.overflow = 'auto';
    if (this.ref) {
      document.body.removeChild(this.ref)
      // @ts-ignore
      window.st = undefined
      // @ts-ignore
      window.__sharethis__ = undefined
    }
  }

  copieLien() {
    if (!navigator.clipboard) return alert('On peut pas copier')
    this.snackBar.open(this.transformPipe.transform('@liencopie'), '', { verticalPosition: 'bottom', horizontalPosition: 'center', duration: 1000 })
    navigator.clipboard.writeText(this.url);
  }

  activerFormulaireDefaut() {
    if(this.checked == true) {
      this.url = this.url + '#ouvre'
    }
    if(this.checked == false) {
      this.url = this.urlSansHash
    }
  }

  ngOnInit(): void { }
}
