<section>
    <div class="container w-100" >
        <div class="row">
            <!-- Ligne 1 -->
            <div class="col-xl-6 p-0 m-0 section d-flex flex-column justify-content-center align-items-center texte">
                <h3>{{ '@nb.etages.permis' | translate }}</h3>
                <span>{{ data?.sJsonData?.fr?.NombreEtagesPermis | translateValeur : data?.sJsonData?.an?.NombreEtagesPermis }}</span>
            </div>
            <div class="col-xl-6 p-0 m-0 section-photos">
                <a class="photo" (click)="ouvririPhoto(data.Photos[0], 0)">
                        <div class="interne" [style.background-image]="data.Photos[0] | pmmlPhotoUrl : 600"></div>
                    </a>
            </div>
        </div>
        <!-- Ligne 2 -->
        <div *ngIf="data?.IDPropriete !== 850673" class="row">
            <div class="col-xl-6 p-0 m-0 section-photos" id="photo">
                <a class="photo" (click)="ouvririPhoto(data.Photos[1], 1)">
                    <div class="interne" [style.background-image]="data.Photos[1] | pmmlPhotoUrl : 600"></div>
                </a>
            </div>
            <div class="col-xl-6 p-0 m-0 section d-flex flex-column justify-content-center align-items-center texte" *ngIf="data?.fPrixDemande" id="text-photo">
                <h3>{{ '@prix.sqft' | translate }}</h3>
                <span>{{ data?.fPrixDemande / data?.iPiedsCarreTotal | currency : 'CAD'  }} /
                    {{ 'pi2' | translate }}</span>
            </div>
            <div class="col-xl-6 p-0 m-0 section d-flex flex-column justify-content-center align-items-center texte" *ngIf="!data?.fPrixDemande" id="text-photo">
                <h3>{{ '@prix.sqft' | translate }}</h3>
                <span>{{'@surdemande' | translate}}</span>
            </div>
        </div>
        <!-- Ligne 3 -->
        <div class="row">
            <div class="col-xl-6 p-0 m-0 section d-flex flex-column justify-content-center align-items-center texte">
                <h3>{{ '@densite' | translate }}</h3>
                <span>{{ data?.sJsonData?.fr?.DensitePermise | translateValeur : data?.sJsonData?.an?.DensitePermise }}</span>
            </div>
            <div class="col-xl-6 p-0 m-0 section-photos">
                <a class="photo" (click)="ouvririPhoto(data.Photos[2], 2)">
                    <div class="interne" [style.background-image]="data.Photos[2] | pmmlPhotoUrl : 600"></div>
                </a>
            </div>
        </div>
    </div>
</section>