import { AuthConfig } from "@auth0/auth0-angular";

export const environment = {
  masqueDataroom: true,
  couleurPrimaire: '#cc0000',
  couleurPrimaireTransparent: 'rgba(165, 1, 1, 0.75)',
  production: true,
  afficherTerrain: false,
  afficherGoplex: false,
  modeTeasing: false,
  masquerChoixListing: false,
  masquerNombreUnite: false,
  masquerVenteLocation: false,
  modeCarte: true,
  modeListe: true,
  modeTuiles: true,
  masquerBarreRecherche: false,
  masquerMenuFD: true,
  logoHeaderFr: "https://pmml.ca/images/logo-pmml-blanc.svg",
  logoHeaderAn: "https://pmml.ca/images/logo-pmml-blanc.svg",
  auth0: <AuthConfig>{
    clientId: 'XmDO46cOyUZmoFBN9RhRcEoUnlnEJTR7',
    domain: 'auth.pmml.ca',
    redirectUri: window.location.origin
  },
  pmmlTvDefaultCat: 29,
  mapbox: {
    accessToken: "pk.eyJ1IjoicG1tbCIsImEiOiJja21uc2IwYXQxeHNpMm5zN3NvdTB2bGR5In0.z17bZO6x9nhw1pnVN01xFw",
  },
  typeProprietes: [
    { id: 'iMarchesCapitaux', nom: '@propriete.type.marchesCapitaux', texte: 'marches-capitaux' },
    { id: "iPortfolio", nom: "@propriete.type.portfolio", texte: "portfolio" },
    { id: "iMultilogement", nom: "@propriete.type.multilogement", texte: "multi-logements", },
    { id: "iRecent", nom: "@propriete.type.recent", texte: "construction-recente", },
    { id: "iIndustriel", nom: "@propriete.type.industriel", texte: "industriel", },
    { id: "iSemiCommercial", nom: "@propriete.type.semiCommercial", texte: "semi-commercial", },
    { id: "iTerrain", nom: "@propriete.type.terrains", texte: "terrain" },
    { id: "iRPA", nom: "@propriete.type.rpa", texte: "rpa" },
    { id: "iHotel", nom: "@propriete.type.hotel", texte: "hotel" },
    { id: "iMedical", nom: "@propriete.type.medical", texte: "medical" },
    { id: "iCommercial", nom: "@propriete.type.commerceDetail", texte: "commerce-de-detail", },
    { id: "iBureaux", nom: "@propriete.type.bureaux", texte: "bureaux" },
    { id: "iDivertissement", nom: "@propriete.type.divertissement", texte: "divertissement", },
    { id: "iGoplex", nom: "Plex", texte: "plex" },
    { id: "iFondCommerce", nom: "@propriete.type.fondCommerce", texte: "fondCommerce" },
    // { id: "nouveautes", nom: "@nouveautes", texte: "nouveautes" },
  ]
  ,
  typeProprietesEn: {
      "multi-family": "iMultilogement",
      "semi-commercial": "iSemiCommercial",
      plex: "iGoplex",
      retail: "iCommercial",
      "new-construction": "iRecent",
      industrial: "iIndustrial",
      offices: "iBureaux",
      land: "iTerrain",
      "private-seniors-residence": "iRPA",
      portfolio: "iPortfolio",
      medical: "iMedical",
      hotel: "iHotel",
      entertainment: "iDivertissement",
      'marches-capitaux': 'iMarchesCapitaux',
      recent: "nouveautes",
  },
  affichageGoplex: false,
  dev: false,
  cloudflaresImages: {
    baseUrl: "https://imagedelivery.net/Ib8i86zlIx8nXrDfZbOMtA/",
    tailles: [
      "1920", 
      "1400", 
      "900", 
      "600", 
      "320", 
      "public" // Défaut?
    ]
  }
}
