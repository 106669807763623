<span *ngIf="environment?.modeTeasing">
    <app-inf-sup-com [data]="data"></app-inf-sup-com>
</span>

<span *ngIf="!environment?.modeTeasing">
    <!-- <app-inf-sup [data]="data"></app-inf-sup> -->
    <app-inf-sup-com [data]="data"></app-inf-sup-com>
    <app-map [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-map> 
    <app-informations-com [data]="data"></app-informations-com>
    <app-thumbnails [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-thumbnails>
    <app-descriptif-com [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-descriptif-com>
    <app-descriptif2-com [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix && !data.bVacant"></app-descriptif2-com>
    <app-sommaires-com [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-sommaires-com>
    <app-photos [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-photos>
</span>
