<div class="container description-bureaux">
    <!-- <div class="row justify-content-center align-items-center mb-5">
        <div class="col-lg-7 col-xs-12">
            <img class="media h-100 w-100 imageDesc" src="{{ data?.sImagePrincipale | resizePhotoPrincipale}}">
        </div>
    </div> -->
    <div class="row justify-content-center align-items-center">
        <div class="col-xs-12 col-lg-6">
            <h3 *ngIf="(data?.sTypeLogement !== 'iLocal' && data?.sTypeLogement !== 'iTerrain')">
                {{ '@description.propriete' | translate }}
            </h3>
            <p class="pb-4 pb-md-4 pb-lg-0">
              {{ data?.sRemarques | translateValeur : data?.Traduction?.sRemarques }}</p>
        </div>
    </div>
</div>
