import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PhotoDialogComponent } from '../../general/photos/photo-dialog.component';

@Component({
  selector: 'app-features-ter',
  templateUrl: './features-ter.component.html',
  styleUrls: ['./features-ter.component.scss']
})
export class FeaturesTerComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  @Input() data: any;

  ngOnInit(): void {
    if (this.data.Photos[1] == undefined) {
      this.data.Photos[1] = this.data.Photos[0];
    }
    if (this.data.Photos[2] == undefined) {
      this.data.Photos[2] = this.data.Photos[0]
    }
  }

  ouvririPhoto(photo, index) {
    const dialogRef = this.dialog.open(PhotoDialogComponent, {
      width: '95vw',
      maxWidth: '1595px',
      data: { photo, index, listePhotos: this.data?.Photos },
      panelClass: 'photos',
    });
  }

}
