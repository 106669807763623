import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
@Pipe({
  name: "pmmlPhotoUrl",
})
export class PhotoUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, taille: number = 600, src = false): SafeUrl {
    // if (taille === 1600)
    if (!environment.cloudflaresImages.tailles.includes(taille.toString())) {
      console.error(
        `Invalide taille ${taille}, les tailles valides sont ${environment.cloudflaresImages.tailles.join(
          ", "
        )}`
      );
    }

    if (!value?.IDImage) return null;

    let nomFichier = String(value.sNomFichier || '');

    const url = `${environment.cloudflaresImages.baseUrl}${value.IDImage}_${nomFichier}/${taille}`;
    // const url = `https://img.pmml.ca/${value.IDImage}_${nomFichier}?q=90&w=${taille}`;

    if (src) return this.sanitizer.bypassSecurityTrustUrl(url);
    return this.sanitizer.bypassSecurityTrustStyle(`url('${url.replace(/\'/g, `\\'`)}')`);
  }
}
