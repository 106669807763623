import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-terrain-info',
  templateUrl: './terrain-info.component.html',
  styleUrls: ['./terrain-info.component.scss']
})
export class TerrainInfoComponent implements OnInit {

  constructor() { }

  @Input() data: any;

  ngOnInit(): void {
    
  }

}
