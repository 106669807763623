import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'photoPrincipaleUrl'
})
export class PhotoPrincipaleUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){

  }

  transform(url: string, taille: number = 600, src = false): unknown {
    if(!url) return null;
    

    if(src) return this.sanitizer.bypassSecurityTrustUrl(`${url}/600`);
    return this.sanitizer.bypassSecurityTrustUrl(`url('${url}/600`);
  }

}
