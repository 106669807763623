import { DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'rrFormatCol'
})
export class RrFormatColPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private translatePipe: TranslatePipe,

  ) {}

  transform(value: any, type: string): unknown {
    if(type == 'texte') return value;
    if(type == 'date') {
      if(value == 'vacant' || !value) return 'Vacant';
      if (value == '0000-00-00') return 'Mensuel';
      return this.datePipe.transform(value, 'yyyy-MM');
    }

    let post = '';
    if(type == 'montant') post = '$';
    if(type == 'pi2') post = this.translatePipe.transform('@pi2');

    if (!value) return;

    return this.decimalPipe.transform(value, '1.0-0') + ' ' + post;
  }

}
