import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-batisse',
  templateUrl: './batisse.component.html',
  styleUrls: ['./batisse.component.scss']
})
export class BatisseComponent implements OnInit {

  constructor() { }

  @Input() data: any;

  ngOnInit(): void {
  }

}
