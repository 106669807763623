import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FBVideoComponent } from 'ngx-facebook';
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-video-pmml-tv',
  templateUrl: './video-pmml-tv.component.html',
  styleUrls: ['./video-pmml-tv.component.scss']
})
export class VideoPmmlTvComponent implements OnInit {
  @ViewChild(FBVideoComponent) video: FBVideoComponent;

  constructor(
    private http: HttpClient,
    private fb: FacebookService
  ) { }

  initParams: InitParams = {
    appId: '121919538237614',
    xfbml: true,
    version: 'v2.8'
  };

  rgxVideoYt = /youtube/i;
  rgxIDYoutube = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;

  matchUrl: any = false;
  urlYoutube: boolean = false;
  urlFacebook: boolean = false;

  ngOnInit(): void {
    this.getVideoArticle();
  }

  async getVideoArticle() {
    let slugPage = window.location.pathname;
    let slugArticle = slugPage.replace('/', '');

    await this.http.get(`https://pmml.ca/wp-json/wp/v2/posts?slug=${slugArticle}`)
      .toPromise()
      .then((res) => {
        if (!res) return;
        // Detection de url youtube sinon url facebook
        let matchYoutube = this.rgxVideoYt.exec(res[0]?.youtube_link);
        if (matchYoutube) {
          this.urlYoutube = true;
          // Match le ID et Assignation de l'URL
          let IDYt = this.rgxIDYoutube.exec(res[0]?.youtube_link)
          this.matchUrl = `https://www.youtube.com/embed/${IDYt[1]}`;
        }
        else {
          this.urlFacebook = true;
          // Assignation de l'URL
          this.matchUrl = res[0]?.youtube_link;
          this.fb.init(this.initParams);
        }
      });
  }
}
