import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'pmml-connexion',
  template:`<ng-content></ng-content>`,
})
export class ConnexionComponent implements OnInit {

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    if(!this.auth.token) {
      this.auth.login(window.location.pathname);
    }
  }

}
