import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-descriptif2-com',
  templateUrl: './descriptif2-com.component.html',
  styleUrls: ['./descriptif2-com.component.scss']
})
export class Descriptif2ComComponent implements OnInit {

  constructor() { }

  @Input() data: any;

  ngOnInit(): void {
  }

  getPrice(revenus, sqft){
    return revenus / sqft; 
  }

}
