<div class="container"
    *ngIf="(data?.sTypeLogement !== 'iPortfolio' && data?.sTypeLogement !== 'iPortfolioComm' || data?.sJsonData?.bPortfolioCommeMulti) || (data?.sTypeLogement == 'iPortfolio' || data?.sTypeLogement == 'iPortfolioComm') && (data?.iFlyer == 1)">
    <!-- LIGNE 1 -->
    <div class="row align-items-center no-gutters bg-gris-1">
        <div class="col-xs-12 odrer-xs-2 col-lg-6 order-lg-1 padding">
            <!-- <div *ngIf="(data?.sTypeLogement === 'iMultiLogement' || data?.sTypeLogement === '' || data?.sTypeLogement === 'iUniteGrand' || data?.sTypeLogement === 'iUnitesChambre' || data?.sTypeLogement === 'iRPA' )"> -->
            <h3 class="pt-3"><i class="fas fa-comment-exclamation"></i> {{'@info.faitssaillants' | translate}}</h3>
            <div class="text-left pb-4 li-custom pr-4 pl-4 pl-md-5 pr-md-5"
                *ngIf="!data?.iFlyer && (!data?.sUrlFlyerExtFr  && !data?.sUrlFlyerExtAn)"
                [innerHTML]="data?.sJsonData?.fr?.FaitsSaillants | translateValeur : data?.sJsonData?.an?.FaitsSaillants | nl2li">
            </div>
            <div class="text-left pb-4 li-custom pr-4 pl-4 pl-md-5 pr-md-5"
                *ngIf="data?.iFlyer || (data?.sUrlFlyerExtFr  || data?.sUrlFlyerExtAn)"
                [innerHTML]="data?.FaitsSaillantsFr | translateValeur : data?.FaitsSaillantsAn | nl2li">
            </div>
            <!-- </div> -->
        </div>
        <div class="col-xs-12 order-first order-xs-1 col-lg-6 order-lg-2 image">
            <div class="media h-100 w-100" [style.background-image]="data.Photos[0] | pmmlPhotoUrl : 600"
                [style.backgroundSize]="'cover'" [style.background-position]="'center center'">
            </div>
            <br>
        </div>
    </div>
    <!-- LIGNE 2 -->
    <div class="row align-items-center no-gutters bg-gris-2">
        <div class="col-xs-12 order-xs-3 col-lg-6 order-lg-3 image">
            <div class="media h-100 w-100" [style.background-image]="data.Photos[1] | pmmlPhotoUrl : 600"
                [style.backgroundSize]="'cover'" [style.background-position]="'center center'">
            </div>
        </div>
        <div class="col-xs-12 col-lg-6 order-lg-4 bg-gris padding pb-4">
            <h3 class="pt-3"><i class="fas fa-usd-circle"></i> {{ 'sRenoMajeur10Ans' | translate }}</h3>
            <div class="text-left li-custom pr-4 pl-4 pl-md-5 pr-md-5 "
                [innerHTML]="data?.sRenoMajeur10Ans | translateValeur : data?.Traduction.sRenoMajeur10Ans | nl2li : 'Reno'">
                <i class="fas fa-usd-circle"></i></div>
        </div>

    </div>
    <!-- LIGNE 3 -->
    <div class="row align-items-center no-gutters bg-gris-1">
        <div class="col-xs-12 order-xs-6 col-lg-6 order-lg-5 bg-gris padding">
            <!-- <div *ngIf="(data?.sTypeLogement === 'iMultiLogement' || data?.sTypeLogement === '' || data?.sTypeLogement === 'iUniteGrand' || data?.sTypeLogement === 'iUnitesChambre' || data?.sTypeLogement === 'iRPA' )"> -->
            <h3 class="pt-3"><i class="fas fa-question-circle"></i> {{ 'sAutre2' | translate}}</h3>
            <div class="text-left li-custom pr-4 pl-4 pl-md-5 pr-md-5 pb-3"
                [innerHTML]="data?.sAutre5 | translateValeur : data?.Traduction.sAutre5 | urlifyFromText | nl2li"></div>
            <!-- </div> -->
        </div>
        <div class="col-xs-12 order-first order-xs-5 col-lg-6  order-lg-6 image">
            <div class="media h-100 w-100" [style.background-image]="data.Photos[2] | pmmlPhotoUrl : 600"
                [style.backgroundSize]="'cover'" [style.background-position]="'center center'">
            </div>
        </div>
    </div>
    <div *ngIf="data?.sUrlFlyerExtFr || data?.sUrlFlyerExtAn"
        class="w-100 pb-3 pt-3 se-connecter d-flex flex-column justify-content-center align-items-center">
        <a [href]="( data?.sUrlFlyerExtFr | translateValeur : data?.sUrlFlyerExtAn)" target="_blank">
            <button class="connect">{{ '@voir.fiche.complete' | translate }}</button>
        </a>
    </div>
</div>

<!-- Partie Portfolio -->
<div class="container"
    *ngIf="(data?.sTypeLogement == 'iPortfolio' || data?.sTypeLogement == 'iPortfolioComm') && (data?.iFlyer == 0) && (!data?.sJsonData?.bPortfolioCommeMulti)">
    <div *ngFor="let immeuble of data?.ProprietesEnfants; index as i">

        <!-- LIGNE 1 GAUCHE -->
        <div class="row align-items-center no-gutters bg-gris-1" *ngIf="i  % 2 === 1">
            <div class="col-xs-12 odrer-xs-2 col-lg-6 order-lg-1 padding">
                <a class="hrefImmeubleDesc" [href]="('/' | translateValeur : '/en/') + immeuble?.sLien + aParamCode"
                    target="_blank">
                    <h3 class="pt-3 h3portfolio">{{ immeuble?.sNumeroCivique }} {{ immeuble?.sRue }}, {{
                        immeuble?.sNomVille }}
                        <span *ngIf="immeuble?.iUnitesTotal && data?.sTypeLogement !== 'iPortfolioComm'"> - {{
                            immeuble?.iUnitesTotal }} {{ '@menu.multilogement.unites' | translate }}</span>
                    </h3>
                </a>

                <div class="text-left pb-4 li-custom pr-4 pl-4 pl-md-5 pr-md-5"
                    [innerHTML]="immeuble?.sCommentaire | translateValeur : immeuble?.sCommentaire | nl2li">
                </div>

                <div class="text-left pt-5 pl-5 ml-5">
                    <button class="voirImmeuble">
                        <a *ngIf="aParamsPresent" class="nonA"
                            [href]="('/' | translateValeur : '/en/') + immeuble?.sLien + aParamCode " target="_blank">{{
                            '@voir.immeuble' | translate }}</a>

                        <a *ngIf="!aParamsPresent" class="nonA"
                            [href]="('/' | translateValeur : '/en/') + immeuble?.sLien" target="_blank">{{
                            '@voir.immeuble' | translate }}</a>
                    </button>
                </div>
            </div>
            <div class="col-xs-12 order-first order-xs-1 col-lg-6 order-lg-2 image">
                <a class="hrefImmeubleDesc" [href]="('/' | translateValeur : '/en/') + immeuble?.sLien + aParamCode"
                    target="_blank">
                    <img class="media h-100 w-100 imageDesc"
                        [src]=" immeuble?.sImagePrincipale | resizePhotoPrincipale">
                </a>
            </div>

        </div>

        <!-- LIGNE 2 DROITE -->
        <div class="row align-items-center no-gutters bg-gris-2" *ngIf="i  % 2 === 0">

            <div class="col-xs-12 order-xs-3 col-lg-6 order-lg-3 image">
                <a class="hrefImmeubleDesc" [href]="('/' | translateValeur : '/en/') + immeuble?.sLien + aParamCode"
                    target="_blank">
                    <img class="media h-100 w-100 imageDesc"
                        [src]="immeuble?.sImagePrincipale | resizePhotoPrincipale">
                </a>
            </div>

            <div class="col-xs-12 col-lg-6 order-lg-4 bg-gris padding pb-4">
                <a class="hrefImmeubleDesc" [href]="('/' | translateValeur : '/en/') + immeuble?.sLien + aParamCode"
                    target="_blank">
                    <h3 class="pt-3 h3portfolio">{{ immeuble?.sNumeroCivique }} {{ immeuble?.sRue }}, {{
                        immeuble?.sNomVille }}
                        <span *ngIf="immeuble?.iUnitesTotal && data?.sTypeLogement !== 'iPortfolioComm'"> - {{
                            immeuble?.iUnitesTotal }} {{ '@menu.multilogement.unites' | translate }}</span>
                    </h3>
                </a>

                <div class="text-left li-custom pr-4 pl-4 pl-md-5 pr-md-5 "
                    [innerHTML]="immeuble?.sCommentaire | translateValeur : immeuble?.sCommentaire | nl2li">

                </div>
                <div class="text-left pt-5 pl-5 ml-5">
                    <button class="voirImmeuble">
                        <a *ngIf="aParamsPresent" class="nonA"
                            [href]="('/' | translateValeur : '/en/') + immeuble?.sLien + aParamCode " target="_blank">{{
                            '@voir.immeuble' | translate }}</a>

                        <a *ngIf="!aParamsPresent" class="nonA"
                            [href]="('/' | translateValeur : '/en/') + immeuble?.sLien" target="_blank">{{
                            '@voir.immeuble' | translate }}</a>
                    </button>
                </div>
            </div>
        </div>
    </div>