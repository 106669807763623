import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-fin-multi',
  templateUrl: './info-fin-multi.component.html',
  styleUrls: ['./info-fin-multi.component.scss']
})
export class InfoFinMultiComponent implements OnInit {

  constructor() { }

  @Input() data: any;

  ngOnInit() {
  }


  calculMontantFin(...actuels: any): any{
    let montant = 0;
    for (let i = 0; i < actuels.length; i++) {
      const element = actuels[i];
      if(element.bActiver === 1){
        montant += element.fMontantFinancement; 
      }
    }
    return montant;
  }

}
