<div class="container affichageMenuDesktop">
    <div class="row justify-content-center">
        <div class="col-9 mr-0 pr-0">
            <app-carrousel [data]="data"></app-carrousel>
        </div>
        <!-- <div class="col-3 pl-0 ml-0">
            <app-info-courtier-immeuble [data]="data"></app-info-courtier-immeuble>
        </div> -->
    </div>
</div>
<div class="container affichageMenuMobile">
    <div class="col">
        <app-carrousel [data]="data"></app-carrousel>
    </div>
    <!-- <div class="col">
        <app-info-courtier-immeuble [data]="data"></app-info-courtier-immeuble>
    </div> -->
</div>


