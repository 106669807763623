<app-categorie-bur-ven
    [data]="data"
></app-categorie-bur-ven>
<app-description
    [data]="data"
></app-description>
<app-inf-sup
    [data]="data"
></app-inf-sup>
<app-satellite
    [data]="data"
></app-satellite>
<app-dispo-superficie
    [data]="data"
></app-dispo-superficie>
<app-features-com-loc
    [data]="data"
></app-features-com-loc>
<app-roadmap
    [data]="data"
></app-roadmap>
<app-thumbnails-com
    [data]="data"
></app-thumbnails-com>
<app-descriptif-bur-loc
    [data]="data"
></app-descriptif-bur-loc>
<app-batisse
    [data]="data"
></app-batisse>
<app-acces-bur-loc
    [data]="data"
></app-acces-bur-loc>
<app-loyers-com
    [data]="data"
></app-loyers-com>
<app-descriptif2-com
    [data]="data"
></app-descriptif2-com>
<app-photos
    [data]="data"
></app-photos>