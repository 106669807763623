<div class="container espacement">
    <h3 *ngIf="!connected" class="summary w-100 mb-3 pt-3"><i class="fas fa-building"></i> {{ '@voir.fiche.complete' | translate }}</h3>
    <!-- Partie non connecté -->
    <div class="se-connecter text-center" *ngIf="!connected">
        <p class="texte text-center">{{ '@voir.fiche.complete.desc' | translate }}</p>
        <button class="connect" *ngIf="!connected" (click)="seConnecter()">{{'@se.connecter' | translate}}</button>
    </div>
    <!-- Partie connecté -->
    <div class="se-connecter pt-2 text-center" *ngIf="connected">
        <button class="connect"><i class="far fa-file-pdf" style="font-size: 25px;"></i><a class="pl-2"
            [href]="('/' | translateValeur : '/en/') + 'location/' + data.sLien ">{{'@voir.fiche.complete' | translate}}</a></button>
    </div>
</div>