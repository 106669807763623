<!-- <app-inf-sup [data]="data" [location]="true"></app-inf-sup> -->

<app-descriptif-locaux
    [data]="data">
</app-descriptif-locaux>
<app-liste-locaux
    [data]="data"
    (urlMatterport)="matterport.setUrlMatterport($event)">
</app-liste-locaux>
<app-matterport
    [data]="data"
    #matterport>
</app-matterport>
<app-map
    [data]="data"
    fiche-locaux=true>
</app-map>