<div class="section-fin mobileCenter" style="margin-top: 5px;">
    <div class="pied-page" *ngIf="!data?.bFlyerExterne">
        <div class="container-fluid" style="background-color: black;">
            <div class="container p-4 mainContainer">
                <p style="color: white; padding-bottom: 30px">{{ '@goplex.footer.disclaimer' | translate }}</p>
                <div class="row">
                    <div class="col-xs-12 col-lg-3 footerColumn">
                        <div style="font-size: 20px;">
                            <a [href]="('/' | translateValeur : '/en/')" class="logo" [style.background-image]="'url(' + (environment.logoHeaderFr | translateValeur : environment.logoHeaderAn) + ')'"></a>
                        </div>
                        <div style="margin-top: 10px;">
                            <i class="fas fa-phone-alt" style="font-size: 12px;"></i><span style="font-size: 16px; font-weight: normal;"> &nbsp; 514-360-3603</span>
                        </div>
                        <div style="margin-top: 10px;">
                            <i class="fas fa-phone-alt" style="font-size: 12px;"></i><span style="font-size: 16px; font-weight: normal;"> &nbsp; 418-476-0186</span>
                        </div>
                        <div style="margin-top: 10px;">
                            <i class="fas fa-phone-alt" style="font-size: 12px;"></i><span style="font-size: 16px; font-weight: normal;"> &nbsp; 819-569-2537</span>
                        </div>
                        <div style="margin-top: 10px;">
                            <i class="far fa-calendar-alt" style="font-size: 12px;"></i><span style="font-size: 16px; font-weight: normal;"> &nbsp; {{ '@goplex.footer.rendezvous' | translate }}</span>
                        </div>
                        <div style="margin-top: 20px;">
                            <div class="col-xs-12 col-lg-auto">
                                <ul class="list-inline">
                                    <li class="list-inline-item">
                                        <a target="_blank" href="https://www.facebook.com/pmml.ca/"><i class="fab fa-facebook-f" style="font-size: 16px;"></i></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a target="_blank" href="https://www.instagram.com/pmml.ca/"><i class="fab fa-instagram" style="font-size: 16px;"></i></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a target="_blank" href="https://www.linkedin.com/company/pmml/"><i class="fab fa-linkedin-in" style="font-size: 16px;"></i></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a target="_blank" href="https://www.youtube.com/channel/UCJCTqgUX8u5UtnDPdE8OmPA"><i class="fab fa-youtube" style="font-size: 16px;"></i></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a target="_blank" href="https://open.spotify.com/show/0uHkvJ2ZoBcHdhvOpaNUkK"><i class="fab fa-spotify" style="font-size: 16px;"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <a class="boutonPmml" [href]="('/' | translateValeur : '/en/')">PMML.CA</a>
                        </div>
                    </div>
                    <div class="col-xs-12 col-lg-5 footerColumn premierItem">
                        <div>
                            <span class="adresseBureauPmml">{{ '@goplex.footer.centrevillemtl' | translate }}</span>
                            <div>
                                <a href="https://goo.gl/maps/JzWwtnfSij3bscEw8" target="_blank">774 Rue Saint-Paul O. Montréal, Québec, H3C 1M4</a>
                            </div>
                        </div>
                        <div style="margin-top: 22px;">
                            <span class="adresseBureauPmml">{{ '@goplex.footer.montreal' | translate }}</span>
                            <div>
                                <a href="https://goo.gl/maps/UxQy5EfW2kJmJ7hf9" target="_blank">5420 Ch. Queen Mary, Montréal, Québec, H3X 1V3</a>
                            </div>
                        </div>
                        <div style="margin-top: 22px;">
                            <span class="adresseBureauPmml">{{ '@goplex.footer.quebec' | translate }}</span>
                            <div>
                                <a href="https://goo.gl/maps/2ngcZ9bmwgX7Ljj27" target="_blank">1255 Boul. Lebourgneuf, suite 250, Québec, G2K 2E4</a>
                            </div>
                        </div>
                        <div style="margin-top: 22px;">
                            <span class="adresseBureauPmml">GATINEAU</span>
                            <div>
                                <a href="https://goo.gl/maps/8pSsohbQwz5R9d217" target="_blank">20 allée de Hambourg, suite 245, Agora, Gatineau, Québec, J9J 4K3</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-lg-4 footerColumnAlt premierItem">
                        <div>
                            <span class="adresseBureauPmml">{{ '@goplex.footer.rivenord' | translate }}</span>
                            <div>
                                <a href="https://goo.gl/maps/RSYEchE9Xb3PBsud6" target="_blank">225 Promenade du Centropolis, suite 200, Laval, Québec, H7T 0B3</a>
                            </div>
                        </div>

                        <div style="margin-top: 22px;">
                            <span class="adresseBureauPmml">{{ '@goplex.footer.rivesud' | translate }}</span>
                            <div>
                                <a href="https://goo.gl/maps/m4abe1mEt5Wc836b8" target="_blank">9180 boul. Leduc, suite 280, Quartier Dix30, Brossard, J4Y 0L1</a>
                            </div>
                        </div>

                        <div style="margin-top: 22px;">
                            <span class="adresseBureauPmml">SHERBROOKE</span>
                            <div>
                                <a href="https://goo.gl/maps/27e5SVR1WeZWjVkz6" target="_blank">805 Galt Ouest, suite 245, Sherbrooke, J1H 1Z1</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="sectionCopyright">
                <div style="font-size: 12px;">{{ '@goplex.footer.pmmldescriptif' | translate }}</div>
                <div style="text-transform: uppercase; font-size: 10px;">© Copyrights 2021 PMML - Tous Droits Réservés</div>
            </div>
        </div>
    </div>
</div>
