<div class="section-photos d-flex justify-content-center align-items-center">
    <div class="container">
        <div class="row">
            <div *ngFor="let photo of data?.Photos; let i=index" class="col-xl-4 col-md-6 col-xs-12 col-12 section">
                <a class="photo" (click)="ouvririPhoto(photo, i)">
                    <div class="interne" [style.background-image]="photo | pmmlPhotoUrl : 600"></div>
                    <div class="titre d-flex justify-content-center align-items-center">
                        <span>{{ photo?.sJsonData?.fr?.sDescription | translateValeur : data?.sJsonData?.an?.Description }}</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>