<div [class.videoPortrait]="data?.iVideoPortrait == '1'">
    <header #menuWrapper [ngClass]="{'scrolled': scrolled}">
        <ng-template #selecteurLangue>
            <div class="col"></div>
            <div class="col-auto lienExtra">
                <a *ngIf="!nouveauLiens" [href]="('/en/' | translateValeur : '/') + data?.sLien  + aParamCode">
                    <span class="text-uppercase h5">{{'langue' | translate}}</span>
                </a>
                <a *ngIf="nouveauLiens" [href]="(data.slugAn | translateValeur : data.slugFr) + aParamCode">
                    <span class="text-uppercase h5">{{'langue' | translate}}</span>
                </a>
            </div>
        </ng-template>
        <!-- DESKTOP -->
        <div class="container-fluid ligne-1 d-none d-md-block" *ngIf="!data?.bFlyerExterne">
            <div class="container">
                <div class="row align-items-center justyfy-items-end">
                    <div *ngIf="!environment.masquerMenuFD" class="col-auto pl-4 ml-2 ml-xl-0">
                        <i (click)="masquerMenu = !masquerMenu" class="fas fa-bars fa-2x pointer"
                            style="font-size: 1.8rem;"></i>
                    </div>
                    <!-- Logo -->
                    <div class="col-auto" style="margin-right: auto;">
                        <a [href]="('/' | translateValeur : '/en/')" class="logo"
                            [style.background-image]="'url(' + (environment.logoHeaderFr | translateValeur : environment.logoHeaderAn) + ')'"></a>
                    </div>
                    <ng-container *ngIf="!data?.bListingPDF; else selecteurLangue">
                        <div class="col-auto" style="max-width: 660px">
                            <app-infos-courtier [data]="data" [location]="ficheLocaux == 1"></app-infos-courtier>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <!-- MOBILE -->
        <div class="container-fluid ligne-1 d-md-none" *ngIf="!data?.bFlyerExterne">
            <div class="row ">
                <!-- Logo -->
                <div class="col-auto pb-2">
                    <a [href]="('/' | translateValeur : '/en/')" class="mx-auto ml-2">
                        <img height="25px" [src]="environment.logoHeaderFr | translateValeur : environment.logoHeaderAn"
                            alt="" class="ml-3" style="width: auto; height: 25px;">
                    </a>
                </div>
                <ng-container *ngIf="!data?.bListingPDF; else selecteurLangue">
                    <div class="col-auto pl-4">
                        <div class="pl-3 pb-2">
                            <app-infos-courtier [data]="data" [location]="location"
                                [versionMobile]="true"></app-infos-courtier>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="container-fluid ligne-2 mat-elevation-z3" *ngIf="!data?.bListingPDF && afficherFavoris">
            <div class="container">
                <div class="row align-items-center justify-content-start">
                    <!-- FAVORI -->
                    <div class="col-auto pr-0">
                        <div class="favoris" (mouseenter)="showFavorisTooltip($event)"
                            (mouseleave)="hideFavorisTooltip()" (click)="ajouterFavoris(data, $event)">
                            <i [ngClass]="{'far': !data?.fav, 'fas': data?.fav}" class="fa-star icone"></i>
                        </div>
                    </div>
                    <!-- ADRESSE / UNITÉS / PRIX -->
                    <div class="col-auto pl-2 d-none d-lg-block">
                        <div class="adresse">
                            {{data | pmmlProprieteTitre}}
                        </div>
                        <div class="row align-items-center">
                            <!-- Tout sauf iTerrain & iCommercial -->
                            <div class="col-auto pr-0 unitesTotaux"
                                *ngIf="data?.sTypeLogement !== 'iTerrain' && data?.sTypeLogement !== 'iCommercial' && data?.sTypeLogement !== 'iPortfolioComm' && data?.sTypeLogement !== 'iSemiCommercial'">
                                <span *ngIf="data?.IDPropriete == 813299 && data?.iFlyer">700 + </span>
                                <span *ngIf="data?.IDPropriete == 813299 && !data?.iFlyer">{{ data?.iUnitesTotal }}
                                </span>
                                <span *ngIf="data?.IDPropriete !== 813299">{{ data?.iUnitesTotal }} </span>
                                <span
                                    *ngIf="data?.sTypeLogement !== 'iHotel' && data?.sTypeLogement !== 'iUnitesChambre'">{{
                                    '@menu.multilogement.unites' | translate }}</span>
                                <span
                                    *ngIf="data?.sTypeLogement == 'iHotel' || data?.sTypeLogement == 'iUnitesChambre'">{{
                                    'chambres' | translate }}</span>
                            </div>

                            <!-- Semi-Commercial -->
                            <div class="col-auto pr-0 unitesTotaux"
                                *ngIf="data?.sTypeLogement == 'iSemiCommercial' && !ficheLocaux">
                                {{ data?.iUnitesTotal }} {{ '@menu.multilogement.unites' | translate }}
                                <span *ngIf="data?.iPiedsCarreTotal">, {{ '@menu.terrain.superficie' | translate }} : {{
                                    data?.iPiedsCarreTotal | number : '1.0-0' }} {{ '@pi2' | translate }}</span>
                            </div>

                            <!-- Location -->
                            <div class="col-auto pr-0 unitesTotaux"
                                *ngIf="data?.sTypeLogement == 'iLocal' && data?.iPiedsCarreTotal">
                                {{'@menu.commercial.superficie' | translate}} : {{ data?.iPiedsCarreTotal | number :
                                '1.0-0' }} {{ '@pi2' | translate }}
                            </div>
                            <!-- Terrain & Commercial -->
                            <div class="col-auto pr-0 unitesTotaux"
                                *ngIf="(data?.sTypeLogement == 'iTerrain' || data?.sTypeLogement == 'iCommercial' || data?.sTypeLogement == 'iPortfolioComm') && ficheLocaux != 1 && data?.iPiedsCarreTotal > 0">
                                {{ '@menu.terrain.superficie' | translate }} : {{ data?.iPiedsCarreTotal | number :
                                '1.0-0' }} {{ '@pi2' | translate }}
                            </div>
                            <div class="col-auto pl-0 d-flex">
                                <!-- Prix demande -->
                                <app-prix-demande [data]="data" [noBefore]="true"></app-prix-demande>
                            </div>
                        </div>

                    </div>
                    <div class="col d-none d-lg-block"></div>
                    <div class="col pl-1 d-block d-lg-none">
                        <!-- <div class="adresse pr-0" *ngIf="data?.IDPropriete !== 816251 && data?.IDPropriete !== 145647 && data?.IDPropriete !== 223692 || !data?.iFlyer">
                            {{ data?.sNumeroCivique }} {{ data?.sRue }}
                            <span *ngIf="data?.sTypeLogement != 'iPortfolio'">, {{ data?.sNomVille }}</span>
                        </div>
                        <div class="adresse pr-0" *ngIf="data?.IDPropriete == 816251 && data?.iFlyer">
                            {{'RPA avec soins, Rive-Sud de Montréal' | translateValeur:  'Senior\'s residence with care, South Shore of Montreal' }}
                        </div>
                        <div class="adresse pr-0" *ngIf="data?.IDPropriete == 145647 && data?.iFlyer">
                            {{'RPA pour autonome et semi autonome, Laurentides' | translateValeur:  'Senior home for autonomous and semi-autonomous, Laurentides'}}
                        </div>
                        <div class="adresse pr-0" *ngIf="data?.IDPropriete == 223692 && data?.iFlyer">
                            {{'Industriel, Mont-Laurier / Rivière-Rouge' | translateValeur:  'Industrial, Mont-Laurier / Rivière-Rouge' }}
                        </div> -->
                        <div class="adresse pr-0">
                            {{data | pmmlProprieteTitre}}
                        </div>
                        <div>
                            <span class="unitesTotaux"
                                *ngIf="data?.sTypeLogement !== 'iTerrain' && data?.sTypeLogement !== 'iCommercial'">
                                <span *ngIf="data?.IDPropriete == 813299 && data?.iFlyer">700 + </span>
                                <span *ngIf="data?.IDPropriete == 813299 && !data?.iFlyer">{{ data?.iUnitesTotal }}
                                </span>
                                <span *ngIf="data?.IDPropriete !== 813299">{{ data?.iUnitesTotal }} </span>
                                <span
                                    *ngIf="data?.sTypeLogement !== 'iHotel' && data?.sTypeLogement !== 'iUnitesChambre'">{{
                                    '@menu.multilogement.unites' | translate }}</span>
                                <span
                                    *ngIf="data?.sTypeLogement == 'iHotel' || data?.sTypeLogement == 'iUnitesChambre'">{{
                                    'chambres' | translate }}</span>
                            </span>
                            <span class="prixDemande noPipe"
                                *ngIf="data?.fPrixDemande && data.sTypeLien !== 'ficheLocaux'">
                                {{ data?.fPrixDemande | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}} {{
                                data?.sJsonData?.fr?.PrixPost | translateValeur: data?.sJsonData?.an?.PrixPost}}
                            </span>
                        </div>
                    </div>
                    <!-- PDF -->
                    <div class="col-auto lienExtra"
                        *ngIf="(data?.iUnitesTotal >= 5 || data?.sTypeLogement !== '') && (data?.sTypeLogement !== 'iGoplex' || data?.iUnitesTotal >= 6) && data?.sTypeLogement !== 'iPortfolio' && data?.sTypeLogement !== 'iPortfolioComm' && !data?.iLocal && (!data?.iGroupeLocaux || ficheLocaux == 0) && !data?.iFlyer">
                        <a [href]="('/' | translateValeur : '/en/') + 'location/' + data?.sLien  + aParamCode">
                            <i [ngClass]="{'far': !data?.fav, 'fas': data?.fav}" class="far fa-file-pdf icone"></i>
                        </a>
                    </div>
                    <!-- Langue -->
                    <div class="col-auto lienExtra">
                        <a *ngIf="!nouveauLiens" [href]="('/en/' | translateValeur : '/') + data?.sLien  + aParamCode">
                            <span class="text-uppercase h5">{{'langue' | translate}}</span>
                        </a>
                        <a *ngIf="nouveauLiens" [href]="(data.slugAn | translateValeur : data.slugFr) + aParamCode">
                            <span class="text-uppercase h5">{{'langue' | translate}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <nav class="pmml-drawer" [hidden]="masquerMenu">
        <ul class="pmml-menu">
            <li *ngFor="let item of menu">
                <input [id]="item.text + '-input'" type="checkbox" hidden>
                <div>
                    <a *ngIf="!item.children; else plain" [href]="item.url">
                        {{ item.text }}
                    </a>
                    <ng-template #plain>
                        <label [for]="item.text + '-input'">
                            {{ item.text }}
                            <i class="submenu-icon fas fa-caret-down"></i>
                        </label>
                    </ng-template>
                </div>
                <ul class="menu-enfant">
                    <li *ngFor="let enfant of item?.children || []">
                        <div>
                            <a [href]="enfant.url">
                                {{ enfant.text }}
                            </a>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
        <div class="pmml-drawer-blocker" (click)="masquerMenu = true"></div>
    </nav>
</div>