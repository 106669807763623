<div class="wrapper bootstrapImportation" [style.height]="courtier ? 'calc(100vh - 2rem)' : ''" style="overflow: auto;">
  <div class="container mx-auto position-relative h-100 d-flex flex-column" [class]="'mode-' + state.value.mode"
    [formGroup]="state">
    <!-- Mode selection -->
    <section class="mode-select" [class.mb-3]="barreRecherche != '1'">
      <ng-container *ngIf="courtier && !queryParams?.promo">
        <div class="mode-btn" [class.mode-active]="state.get('courtierMode').value === 'courtier'"
          (click)="state.patchValue({ courtierMode: 'courtier' })">
          <i class="fas fa-user-tag"></i>
          Mes propriétés
        </div>
        <div class="mode-btn" [class.mode-active]="state.get('courtierMode').value === 'pmml'"
          (click)="state.patchValue({ courtierMode: 'pmml' })">
          <div class="fas fa-tags"></div>
          Propriétés PMML
        </div>
        <!-- <a class="mode-btn" href="https://pmml.ca/indice" target="_blank">
          <div class="fas fa-chart-line"></div>
          Indice PMML
        </a> -->
      </ng-container>
      <ng-container *ngIf="!tuilesSeulement">
        <div class="mode-btn" *ngFor="let mode of MODES" [class.mode-active]="mode.value === state.get('mode').value"
          (click)="state.patchValue({ mode: mode.value })">
          <i [class]="mode.icon"></i>
          <span class="select-label">{{ mode.label | translate }}</span>
        </div>

        <div class="desktop-seul">
          <ng-container *ngTemplateOutlet="favorisToggleBtnTemplate"></ng-container>
        </div>
      </ng-container>

      <div class="mode-btn mobile-filter position-relative" (click)="toggleMobileFiltres()">
        <i class="fas fa-filter"></i>
      </div>

      <div class="overlay-panel" *ngIf="mobileFiltres">
        <ng-container *ngTemplateOutlet="favorisToggleBtnTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="filtresTemplate"></ng-container>
      </div>
    </section>

    <!-- Filtres -->
    <div class="filtre-wrapper" *ngIf="barreRecherche == '1'" [class.flex-nowrap]="courtier">
      <ng-container *ngTemplateOutlet="filtresTemplate"></ng-container>
    </div>

    <!-- Mode tuiles -->
    <div *ngIf="state.value.mode === 'tuiles'" class="tuiles-wrapper">
      <ng-container *ngIf="loadingListings" diameter="32">
        <div *ngFor="let i of [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]" class="ng-pulse"
          style="width: 100%">
          <div style="background: #dedede; width: 100%; aspect-ratio: 16/9"></div>
          <div style="padding: 1.8rem; background: black"></div>
        </div>
      </ng-container>
      <strong class="text-center" *ngIf="(listings$ | async)?.length === 0">
        {{ "@propriete.non.dispo.critere" | translate }}
      </strong>

      <ng-container *ngFor="let propriete of listings$ | async; trackBy: trackByFn" [ngTemplateOutlet]="proprieteTuile"
        [ngTemplateOutletContext]="{ $implicit: propriete }"></ng-container>
    </div>

    <!-- Mode liste -->
    <ng-container *ngIf="state.value.mode === 'liste'">
      <table mat-table style="width: 100%" [dataSource]="listings$ | async" class="mat-elevation-z8">
        <ng-container matColumnDef="test">
          <th mat-header-cell *matHeaderCellDef>{{ "adresse" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <a href="/{{ element.sLien }}" target="_blank">
              <i class="fas fa-external-link"></i>
              <span *ngIf="!element.TitreAltFr">
                {{ element.sNumeroCivique }} {{ element.sRue }}
              </span>
              <span *ngIf="element.TitreAltFr">
                {{ element.TitreAltFr | translateValeur : element.sTitreAltAn }}
              </span>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="secteur">
          <th mat-header-cell *matHeaderCellDef>
            {{ "@secteur" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.sNomVille }}
          </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef>
            <span>
              {{ "@menu.multilogement.unites" | translate }}
              / {{ "@pi2" | translate }}
            </span>
          </th>
          <td mat-cell *matCellDef="let element">
          </td>
        </ng-container>

        <ng-container matColumnDef="prix">
          <th mat-header-cell *matHeaderCellDef>
            {{ "@menu.multilogement.prixdemande" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.fPrixDemande | currency : "CAD" : "symbol-narrow" : "0.0-0" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="favoris">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <i (click)="ajouterFavoris(element, $event)" *ngIf="!element.fav" class="pointer far fa-star"></i>
            <i (click)="ajouterFavoris(element, $event)" *ngIf="element.fav" class="pointer fas fa-star"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['test', 'secteur', 'detail', 'prix', 'favoris']"></tr>
        <tr mat-row *matRowDef="
            let row;
            columns: ['test', 'secteur', 'detail', 'prix', 'favoris']
          "></tr>
      </table>
      <center class="my-5">
        <mat-spinner *ngIf="loadingListings" diameter="32"></mat-spinner>
        <strong class="text-center" *ngIf="(listings$ | async)?.length === 0">
          {{ "@propriete.non.dispo.critere" | translate }}
        </strong>
      </center>
    </ng-container>
  </div>

  <!-- Mode carte -->
  <div *ngIf="state.value.mode === 'carte'" class="map-tuiles-wrapper">
    <mgl-map width="200px" height="200px" [style]="'mapbox://styles/mapbox/streets-v11'" [zoom]="7" [minZoom]="6"
      [center]="[-72.77, 46.377254]" (load)="mapLoad($event)">
      <mgl-control class="test" mglScale unit="metric" position="bottom-right"></mgl-control>
      <mgl-control class="test" mglNavigation [showZoom]="true" [showCompass]="true" position="top-right"></mgl-control>
      <mgl-control class="test" mglGeolocate [trackUserLocation]="true" position="top-left"></mgl-control>

      <ng-container *ngFor="
          let listing of listings$ | async;
          let i = index;
          trackBy: trackByFn
        ">
        <mgl-marker #marqueur [lngLat]="[listing.dPosLong, listing.dPosLat]">
          <i *ngIf=" nonClusteredFeatures[listing.IDPropriete] || (zoom$ | async) >= displayMarkersAtZoom"
            class="fas fa-map-marker-alt marker" style="transform: translateY(-50%)"></i>
        </mgl-marker>

        <mgl-popup [marker]="marqueur" [closeButton]="false">
          <div class="img-wrapper">
            <img [src]="listing.sImagePrincipale + '?h=160'" alt="" loading="lazy" />
            <div class="overlay-section">
              <button class="overlay-button" (click)="ajouterFavoris(listing, $event)">
                <i [class.fas]="listing.fav" [class.far]="!listing.fav" class="fa-star"></i>
              </button>

              <div class="overlay-button type-listing">
                <span *ngIf="listing.iLocation === 0">
                  {{ "@propriete.aVendre" | translate }}
                </span>
                <span *ngIf="listing.iLocation === 1">
                  {{ "@propriete.aLouer" | translate }}
                </span>
              </div>
            </div>
          </div>

          <div class="content">
            <span class="adresse">
              {{ listing.sNumeroCivique }}
              {{ listing.sRue }},
              {{ listing.sNomVille }}
            </span>

            <span *ngIf="getModeAffichage(listing) === 'unites'" class="detail">
              {{ listing.iUnitesTotal }} {{ "@unites" | translate }}
            </span>
            <span *ngIf="getModeAffichage(listing) === 'pieds'" class="detail">
              {{ listing.iPiedsCarreTotal | number : "1.0-0" }}
              {{ "@pi2" | translate }}
            </span>
          </div>

          <a href="/{{ listing.sLien }}" target="_blank" class="fd-button">
            {{ "@voir.fiche.descriptive" | translate }}
          </a>
        </mgl-popup>
      </ng-container>
    </mgl-map>

    <div class="map-tuiles">
      <center *ngIf="loadingListings" class="my-5">
        <mat-spinner diameter="32"></mat-spinner>
      </center>
      <strong class="text-center" *ngIf="(listings$ | async)?.length === 0">
        {{ "@propriete.non.dispo.critere" | translate }}
      </strong>

      <ng-container *ngFor="let propriete of listings$ | async; trackBy: trackByFn" [ngTemplateOutlet]="proprieteTuile"
        [ngTemplateOutletContext]="{ $implicit: propriete }"></ng-container>
    </div>
  </div>
</div>

<ng-template #proprieteTuile let-propriete>
  <a href="/{{ propriete.sLien }}" class="tuile-wrapper" target="_blank">
    <div class="tuile-wrapper-zoom">
      <img [src]="propriete.sImagePrincipale | cfImg : '600'" alt="" loading="lazy" />
      <!-- <img [src]="propriete.sImagePrincipale + '?w=640'" alt="" loading="lazy" /> -->

      <div class="tuile-details">
        <button class="ajouter-favoris text-white" (click)="ajouterFavoris(propriete, $event)">
          <i *ngIf="!propriete.fav" class="far fa-star"></i>
          <i *ngIf="propriete.fav" class="fas fa-star"></i>
        </button>

        <strong *ngIf="!propriete.TitreAltFr && !propriete.sJsonData?.fr?.TitreAlt" class="adresse">
          {{ propriete.sNumeroCivique }}
          {{ propriete.sRue }},
          {{ propriete.sNomVille }}
        </strong>

        <strong *ngIf="propriete.TitreAltFr" ngClass="adresse">
          {{ propriete.TitreAltFr | translateValeur : propriete.TitreAltAn }}
        </strong>

        <strong *ngIf="propriete.sJsonData?.fr?.TitreAlt" ngClass="adresse">
          {{ propriete.sJsonData?.fr?.TitreAlt | translateValeur : propriete.sJsonData?.en?.TitreAlt }}
        </strong>

        <ng-container *ngTemplateOutlet="uniteListing; context: {$implicit: propriete}"></ng-container>
      </div>
    </div>
  </a>
</ng-template>

<ng-template #filtresTemplate>
  <ng-container [formGroup]="state">
    <mat-form-field class="w-grow" appearance="fill">
      <mat-label>{{
        "@listeProprietes.rechercheDescriptif" | translate
        }}</mat-label>
      <input type="text" matInput formControlName="region" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of regionsAutocomplete$ | async" [value]="option">
          {{ option.sNomSecteur }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="pmml-select-wrapper">
      <div class="pmml-fill-select" (click)="typeImmeubleControl.focus()">
        <i class="fas fa-caret-down drop-down"></i>
        <label for="typeImmeuble">{{ "@type.logements" | translate }}</label>

        <select #typeImmeubleControl id="typeImmeuble" formControlName="typeImmeuble">
          <option value="">
            {{ "@propriete.type.tout" | translate }}
          </option>
          <option *ngFor="let type of TYPES_IMMEUBLES" [value]="type.id">
            {{ type.nom | translate }}
          </option>
        </select>
      </div>
    </div>

    <!-- <mat-form-field appearance="fill">
      <mat-label>{{ "@type.logements" | translate }}</mat-label>
      <select matNativeControl formControlName="typeImmeuble">
        <option value="">
          {{ "@propriete.type.tout" | translate }}
        </option>
        <option *ngFor="let type of TYPES_IMMEUBLES" [value]="type.id">
          {{ type.nom | translate }}
        </option>
      </select>
    </mat-form-field> -->

    <div class="pmml-select-wrapper">
      <div class="pmml-fill-select w-sm" (click)="typeListingControl.focus()">
        <i class="fas fa-caret-down drop-down"></i>
        <label>{{ "@type.venteLocation" | translate }}</label>
        <select formControlName="typeListing" #typeListingControl>
          <option value="">
            {{ "@propriete.venteEtLocation" | translate }}
          </option>
          <option *ngFor="let type of TYPES_LISTING" [value]="type.value">
            {{ type.label | translate }}
          </option>
        </select>
      </div>
    </div>

    <!-- <mat-form-field appearance="fill">
      <mat-label>{{ "@type.venteLocation" | translate }}</mat-label>
      <mat-select formControlName="typeListing">
        <mat-option value="">
          {{ "@propriete.venteEtLocation" | translate }}
        </mat-option>
        <mat-option *ngFor="let type of TYPES_LISTING" [value]="type.value">
          {{ type.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <div class="pmml-select-wrapper" *ngIf="UNITES_TYPES.includes(state.value.typeImmeuble)">
      <div class="pmml-fill-select w-sm" (click)="typeUnitControl.focus()">
        <i class="fas fa-caret-down drop-down"></i>
        <!-- <ng-container *ngIf="UNITES_TYPES.includes(state.value.typeImmeuble)"> -->
        <!-- <mat-form-field appearance="fill"> -->
        <label>{{ "@nombre.unites" | translate }}</label>
        <select #typeUnitControl formControlName="nombreUnites">
          <option value="">
            {{ "@listeProprietes.typeUnite.tout" | translate }}
          </option>
          <option *ngFor="let type of NOMBRE_UNITES" [value]="type.value">
            {{ type.label | translate }} {{ type.fin | translate }}
            {{
            type.label === "50"
            ? ("et +" | translateValeur : " and more")
            : ""
            }}
          </option>
        </select>
        <!-- </mat-form-field> -->
        <!-- </ng-container> -->
      </div>
    </div>

    <ng-container *ngIf="!UNITES_TYPES.includes(state.value.typeImmeuble)">
      <mat-form-field appearance="fill">
        <mat-label>{{ "superficie.min" | translate }}</mat-label>
        <input type="number" matInput formControlName="superficieMin" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ "superficie.max" | translate }}</mat-label>
        <input type="number" matInput formControlName="superficieMax" />
      </mat-form-field>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #favorisToggleBtnTemplate>
  <button (click)="state.patchValue({ favoris: !state.value.favoris })" class="favoris-toggle">
    <i class="favoris-icon" *ngIf="!state.get('favoris').value" class="fal fa-star"></i>
    <i class="favoris-icon" *ngIf="state.get('favoris').value" class="fas fa-star"></i>

    {{ "@mesfavoris" | translate }}
  </button>
</ng-template>

<ng-template let-element #uniteListing>
  <span *ngIf="element.iLocation && element?.SuperficieMin && element?.SuperficieMax; else defautUnites">

    <span *ngIf="+element?.SuperficieMax > +element?.SuperficieMin">
      {{element?.SuperficieMin | number}} {{ '@pi2' | translate }} à {{element?.SuperficieMax | number}} {{ '@pi2' |
      translate }}
    </span>
    <span *ngIf="+element?.SuperficieMax <= +element?.SuperficieMin">
      {{element?.SuperficieMax | number}} {{ '@pi2' | translate }} à {{element?.SuperficieMin | number}} {{ '@pi2' |
      translate }}
    </span>
  </span>

  <ng-template #defautUnites>
    <span *ngIf="getModeAffichage(element) === 'pieds' && element.iPiedsCarreTotal">
      {{ element.iPiedsCarreTotal | number : "1.0-0" }}
      {{ "@pi2" | translate }}
    </span>
    <span *ngIf="getModeAffichage(element) === 'unites' && element.iUnitesTotal">
      {{ element.iUnitesTotal }}
      {{ "@unites" | translate }}
    </span>
  </ng-template>
</ng-template>