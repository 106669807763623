<div style="background-color: whitesmoke;" class="thumbnails-section pt-5 pb-5 d-flex justify-content-center align-items-center">
    <div class="containeur">
        <div class="row p-0 m-0 w-100 d-flex justify-content-center">
            <div class="col-xl-4 mt-3 col-lg-6 d-flex justify-content-center align-items-center">
                <img [src]="env.cloudflaresImages.baseUrl + data?.Photos[0].IDImage + '_' + data?.Photos[0].sNomFichier + '/600'" alt="">
            </div>
            <div class="col-xl-4 mt-3 col-lg-6 d-flex justify-content-center align-items-center">
                <img [src]="env.cloudflaresImages.baseUrl + data?.Photos[1].IDImage + '_' + data?.Photos[0].sNomFichier + '/600'" alt="">
            </div>
            <div class="col-xl-4 mt-3 col-lg-6 d-flex  justify-content-center align-items-center">
                <img [src]="env.cloudflaresImages.baseUrl + data?.Photos[2].IDImage + '_' + data?.Photos[0].sNomFichier + '/600'" alt="">
            </div>
        </div>
    </div>
</div>