import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
// import { ChatlioService } from 'src/app/chatlio/chatlio.service';
import { SegmentService } from "src/app/api/segment.service";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../auth.service";

@Component({
  selector: "app-multi-go-plex",
  templateUrl: "./multi-go-plex.component.html",
  styleUrls: ["./multi-go-plex.component.scss"],
})
export class MultiGoPlexComponent implements OnInit, AfterViewInit {
  connected: boolean = false;

  constructor(
    private auth: AuthService,
    // private chatlio: ChatlioService,
    private segment: SegmentService
  ) {}

  @Input() menuOffsetHaut: number;
  @Input() data: any;

  @ViewChild("colDroite") colDroite: ElementRef;
  // Bannières
  @ViewChild("content", { read: ElementRef }) content: ElementRef;
  @ViewChild("banniereEvaluation", { read: ElementRef })
  banniere_evaluation: ElementRef;
  @ViewChild("banniereFinancement", { read: ElementRef })
  banniere_financement: ElementRef;
  @ViewChild("banniereRapport", { read: ElementRef })
  banniere_rapport: ElementRef;
  onScroll = new Subject<number>();
  @HostListener("document:scroll", ["$event"]) private scroll(ev: Event) {
    this.onScroll.next(window.scrollY);
  }

  afficherBanniere = {
    evaluation: true,
    financement: false,
    rapport: false,
  };

  DEBOUNCE_TIME_SEGMENT = 1000;

  scrolled = false;
  scrolledMax = false;
  largeurBloqueCourtier = 150;

  setScrolled() {
    let nValeur = false;
    // console.log(window.scrollY, window.innerHeight, this.colDroite.nativeElement.offsetHeight);
    const { scrollY } = window;

    if (
      scrollY + this.menuOffsetHaut + 5 >
      this.colDroite?.nativeElement?.offsetTop
    )
      nValeur = true;

    // this.colDroite.nativeElement.offsetTop - this.colDroite.nativeElement.offsetTop - 500 / 3
    let diffHauteur = Infinity,
      banniereAffichee = "evaluation";
    for (const nomBanniere of Object.keys(this.afficherBanniere || {})) {
      this.afficherBanniere[nomBanniere] = false;
      const elBanniere = this[`banniere_${nomBanniere}`];
      if (!elBanniere) continue;
      if (scrollY < elBanniere.nativeElement.offsetTop) continue;

      if (scrollY - elBanniere.nativeElement.offsetTop < diffHauteur) {
        banniereAffichee = nomBanniere;
        diffHauteur = scrollY - elBanniere.nativeElement.offsetTop;
      }
    }

    this.afficherBanniere[banniereAffichee] = true;

    const contentRect = this.content.nativeElement.getBoundingClientRect();
    this.scrolledMax = scrollY + 15 - contentRect.height > 0;

    if (this.scrolledMax) this.segmentSlideFin.next(this.scrolledMax);

    this.scrolled = nValeur;
  }

  environment = environment;
  segmentSlideFin: Subject<any> = new Subject<string>();

  ngOnInit() {
    const proprieteNom = `${this.data?.sNumeroCivique} ${this.data?.sRue}`;
    // this.chatlio.init(proprieteNom,this.data.IDPropriete);
    this.auth.user$.subscribe((user) => {
      this.connected = user ? true : false;
    });
  }

  ngAfterViewInit() {
    this.largeurBloqueCourtier =
      this.colDroite?.nativeElement?.firstChild?.offsetWidth;
    this.onScroll.pipe(debounceTime(10)).subscribe(() => this.setScrolled());

    this.onScroll.next(undefined);
  }

  seConnecter() {
    return this.auth.login(window.location.pathname + "#sommaires");
  }
}
