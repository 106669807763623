import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import Contact from "./models/contact";

import { AuthService } from "../auth.service";
import { environment } from "../../environments/environment";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ContactsService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  // ajaxUrl: string = (<any>window).ajaxurl;

  inscriptionInfolettre(contact: Contact, source: number = null) {
    let data = Object.assign({ source }, contact);

    return this.http
      .post("/api/inscription", data)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  configurationAlertes(donneesAlertes: any) {
    return this.http
      .post("/api/usager", donneesAlertes)
      .toPromise()
      .then((res) => {
        return true;
      });
  }

  modifierCourriel(nouveauCourriel: any) {
    return this.http
      .post("/api/usager", { nouveauCourriel })
      .toPromise()
      .then((res) => {
        return true;
      });
  }

  getListes() {
    return ["nouveautes", "pmmlTv", "constructionsNeuves", "centLogements"];
  }

  detailsContact() {
    // TODO: Obtenir les détails du contact en provenance du serveur
  }

  getDetails() {
    // if (!environment.production) {
    //   return import('./data-props-contact')
    //     .then((module: any) => {
    //       return module.contact;
    //     })
    // }

    return this.http
      .get("/api/usager")
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  getDetailsCourtier(sCodeUsager) {
    return firstValueFrom(this.http.get(`/api/courtiers/${sCodeUsager}`));
  }

  getVCardCourtier() {
    return this.http
      .get(`/api/courtiers/vCard`)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  estConnecte() {
    return this.auth.token ? true : false;
  }
  estInscrit() {
    return ((<any>window).pmml_data || {}).inscrit == 1;
  }
  getTags() {
    return ((<any>window).pmml_data || {}).tags;
  }
  getLangue() {
    return ((<any>window).pmml_data || {}).contact_lang;
  }
  getVip() {
    return ((<any>window).pmml_data || {}).vip == 1;
  }
  getListeRegions() {
    return ((<any>window).pmml_data || {}).regions || [];
  }
  getListeTypeUnites() {
    return ((<any>window).pmml_data || {}).typeUnites || [];
  }
  getRegionsContact() {
    return ((<any>window).pmml_data || {}).regions_contact || [];
  }
  getListeTypeUnitesContact() {
    return ((<any>window).pmml_data || {}).typeUnites_contact || [];
  }

  CLEF_COOKIE_INSCRIPTION = "pmml.ignorerInscription";
  CLEF_COOKIE_FERMER_INSCRIPTION = "pmml.fermerInscription";

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie =
      name + "=" + (value || "") + expires + "; path=/; SameSite=strict";
  }
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  eraseCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999;";
  }

  setIgnorerTemporairementInscription() {
    try {
      sessionStorage.setItem(this.CLEF_COOKIE_FERMER_INSCRIPTION, "1");
    } catch (error) {}
  }

  doitIgnorerTemporairementInscription() {
    let ignorerInscription = false;

    if (this.estConnecte()) {
      this.setIgnorerTemporairementInscription();
      return true;
    }

    try {
      if (sessionStorage.getItem(this.CLEF_COOKIE_FERMER_INSCRIPTION))
        ignorerInscription = true;
    } catch (error) {}

    return ignorerInscription;
  }

  setIgnorerInscription() {
    try {
      localStorage.setItem(this.CLEF_COOKIE_INSCRIPTION, "1");
      this.setCookie(this.CLEF_COOKIE_INSCRIPTION, "1", 365);
    } catch (error) {}
  }

  doitIgnorerInscription() {
    let ignorerInscription = false;

    if (this.estConnecte()) {
      this.setIgnorerInscription();
      return true;
    }

    try {
      if (localStorage.getItem(this.CLEF_COOKIE_INSCRIPTION))
        ignorerInscription = true;
    } catch (error) {}

    if (this.getCookie(this.CLEF_COOKIE_INSCRIPTION)) ignorerInscription = true;

    return ignorerInscription;
  }

  confirmer() {}
}
