import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { ContactsService } from '../api/contacts.service';
import { SegmentService } from '../api/segment.service';

// TODO: Mettre dans ENV
const COURTIER_DEV = {
  "sCodeUsager": "nick",
  "permalien": "nick",
  "sInitiaux": "NSL",
  "sCourriel": "nick@pmml.ca",
  "sNomUser": "Nick Slobodinuk",
  "sNomEquipe": "Nick Slobodinuk",
  "sTitre": "Courtier Immobilier",
  "sUrlPhoto": "https://pmml.ca/wp-content/uploads/2022/02/equipe-nick-slobodinuk.png",
  "sUrlPhotoSeul": "https://pmml.ca/wp-content/uploads/2021/05/Nick-Slobodinuk.png",
  "sTelephone": "514-909-7467",
  "sJsonSocial": {
    "nom": "Slobodinuk",
    "prenom": "Nick",
    "facebook": "https://www.facebook.com/nick.slobodinuk",
    "linkedIn": "https://www.linkedin.com/in/nickslobodinuk/",
    "instagram": "https://www.instagram.com/nick_kolai/",
    "titreEquipe": "Courtiers Immobiliers",
    "titreEquipeAn": "Real Estate Brokers",
    "bgCarteAffaire": "https://pmml.ca/wp-content/uploads/2022/05/Background-Image-Commerce.png"
  },
  "sType": "agent",
  "Associes": "kevin.chassepot,david.pehlivanian"
};

const COURTIER_HYP_DEV = {
  "sCodeUsager": "christian",
  "permalien": "christian.pomerleau",
  "sInitiaux": "CPO",
  "sCourriel": "christian.pomerleau@pmml.ca",
  "sNomUser": "Alex & Christian",
  "sNomEquipe": "Alexandre Boisvert et Christian Pomerleau",
  "sTitre": "Courtier Hypothécaire",
  "sUrlPhoto": "https://pmml.ca/wp-content/uploads/2022/01/E%CC%81quipe-Pomerleau-Boisvert.png",
  "sUrlPhotoSeul": "https://pmml.ca/wp-content/uploads/2021/05/Christian-Pomerleau-1.png",
  "sTelephone": "514-826-2052",
  "sJsonSocial": {
    "nom": "Pomerleau",
    "prenom": "Christian",
    "facebook": "https://www.facebook.com/christian.pomerleau.5",
    "linkedIn": "https://www.linkedin.com/in/christian-pomerleau-74963629/",
    "instagram": "https://www.instagram.com/christian.pomerleau/",
    "titreEquipe": "Courtiers Hypothécaires",
    "titreEquipeAn": "Mortgage Brokers"
  },
  "sType": "ch",
  "Associes": "tommy.colizza,rodney.st-louis,jessie-ann.pomerleau,etienne.racicot,alexandre.boisvert,admir.bikic"
};

const AUTRE_USER = {
  "sCodeUsager": "eric.moran",
  "permalien": "eric.moran",
  "sInitiaux": "EMOR",
  "sCourriel": "eric.moran@pmml.ca",
  "sNomUser": "Eric Moran",
  "sNomEquipe": null,
  "sTitre": null,
  "sUrlPhoto": "https://pmml.ca/wp-content/uploads/2020/05/Eric-Moran-IA_.png",
  "sUrlPhotoSeul": null,
  "sTelephone": null,
  "sJsonSocial": null,
  "sType": "Soutien",
  "Associes": null
};

@Component({
  selector: 'app-cartes-details-courtiers',
  templateUrl: './cartes-details-courtiers.component.html',
  styleUrls: ['./cartes-details-courtiers.component.scss'],
})
export class CartesDetailsCourtiersComponent implements OnInit {
  constructor(
    private contacts: ContactsService,
    private segment: SegmentService
  ) { }

  @Input('courtier') courtier: String;

  detailsCourtier: any = {}
  listingsCourtier: any;
  overlayActif = false;
  lienCarteCourtier: any;
  backGroundCourtier = 'https://pmml.ca/wp-content/uploads/2022/06/carte-digitale-multi-comm-industriel.jpg'
  photoCourtier: any;

  ngOnInit(): void {
    this.overlayActif = location.hash.includes('ouvre')
    if (!this.courtier || this.courtier == null || this.courtier == undefined || this.courtier == '') window.location.href = 'https://pmml.ca/';

    // // COURTIER + CONDITIONS DEV //
    // if(!environment.production) {
    //   this.detailsCourtier =  AUTRE_USER;
    //   if(this.detailsCourtier?.sJsonSocial?.bgCarteAffaire) this.backGroundCourtier = this.detailsCourtier?.sJsonSocial?.bgCarteAffaire
    //   if(this.detailsCourtier?.sUrlPhotoSeul) {
    //     this.photoCourtier = `${this.detailsCourtier.sUrlPhotoSeul}`.trim()
    //   } else {
    //     this.photoCourtier = `${this.detailsCourtier.sUrlPhoto}`.trim()
    //   }
    //   this.lienCarteCourtier = `https://pmml.ca/c/${this.detailsCourtier?.permalien}`
    // } 
    // // FIN ENV DEV //
    

    this.loadCourtierDetails();
  }

  async loadCourtierDetails() {
    console.log('loadCourtierDetails', this.courtier)
    this.detailsCourtier = await this.contacts.getDetailsCourtier(this.courtier);
    if(this.detailsCourtier?.sJsonSocial?.bgCarteAffaire) this.backGroundCourtier = this.detailsCourtier?.sJsonSocial?.bgCarteAffaire
    if(this.detailsCourtier?.sUrlPhotoSeul) {
      this.photoCourtier = `${this.detailsCourtier.sUrlPhotoSeul}`.trim()
    } else {
      this.photoCourtier = `${this.detailsCourtier.sUrlPhoto}`.trim()
    }
    
    this.lienCarteCourtier = `https://pmml.ca/c/${this.detailsCourtier?.permalien}`;

    this.segment.consultation('courtierCarteAffaire', { courtier: this.courtier, ...this.detailsCourtier })

    this.afficherFormulaireCh();
  }

  segmentTrack(evenement: string) {
    this.segment.contactAction(evenement, { courtier: this.courtier, ...this.detailsCourtier })
  }

  afficherFormulaireCh(): void {
    if(this.detailsCourtier?.sType != "ch") return;

    document.querySelector<HTMLElement>('.formulaireDemandeInfo').style.display = 'block';
    document.querySelector<HTMLElement>('.formulaireDemandeInfo').style.paddingTop = '0.2em';
    document.querySelector<HTMLElement>('.formulaireDemandeInfo').style.paddingRight = '1.5em';
    document.querySelector<HTMLElement>('.formulaireDemandeInfo').style.paddingBottom = '0.2em';
    document.querySelector<HTMLElement>('.formulaireDemandeInfo').style.paddingLeft = '1.5em';
    let formulaire = document.getElementById("formulaireHypothecaire");
    let wordpressForm = document.getElementById("formulaireDemandeInfo");
    formulaire.replaceWith(wordpressForm);
    // @ts-ignore
    wordpressForm.firstElementChild.classList.remove(...wordpressForm.firstElementChild.classList);
  } 

  getVCardCourtier() {
    let new_window = open(`/api/courtiers/${this.courtier}/vCard`);
    setTimeout(() => { new_window.close(); }, 1000);
    this.segmentTrack('courtierVCard');
  }

  openOverlay() {
    this.overlayActif = true;
    this.segmentTrack('courtierPartagerOuverture');
  }

  closeOverlay() {
    this.overlayActif = false
  }

  scrollToListings() {
    document.getElementById('listingsCourtier').scrollIntoView(true);
  }
}
