<div class="price-section container">
    <div class="row h-100">
        <div class="col h-100">
            <div *ngIf="(data?.sTypeLogement === 'iCommercial' || data?.sTypeLogement === 'iMultiLogement' || data?.sTypeLogement === 'iUnitesGrand' || data?.sTypeLogement === 'iUnitesChambre' || data?.sTypeLogement === '' || data?.sTypeLogement === 'iTerrain')" 
                    class="price d-flex flex-column align-items-center justify-content-center">
                    <h2>{{ '@menu.multilogement.prixdemande' | translate }}</h2>
                    <span>{{ data?.fPrixDemande | currency : 'CAD' : 'symbol-narrow' : '0.0-0' }}</span>
                    <span class="taxes">+ TPS/TVQ</span>
            </div>
            <div *ngIf="data?.sTypeLogement === 'iLocal'" class="price d-flex flex-column align-items-center justify-content-center">
                <h3>{{ '@ilocal.superficie' | translate }}</h3>
                <div>{{ data?.iPiedsCarreTotal | number : '1.0-0' }} {{'pi2' | translate}}</div>
            </div>
        </div>
    </div>
</div>