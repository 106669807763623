<div class="container mb-4 mt-4">
    <div class="row" [ngClass]="{
        'd-flex justify-content-center video-portrait': data?.iVideoPortrait
    }">
        <div [ngClass]="{
            'col-12 col-lg-8': !data?.iVideoPortrait,
            'col-12 col-lg-3': data?.iVideoPortrait
        }">
            <div id="video-container" class="video" *ngIf="data?.sVideoYoutubeFr">
                <div *ngIf="!showVideo" style="position: absolute; width: 100%;" (click)="lireVideo()">
                    <img *ngIf="data?.iVideoPortrait" [src]="data?.sVideoCouverture | resizePhotoPrincipale">
                    <img *ngIf="!data?.iVideoPortrait" [src]="data?.sImagePrincipale | resizePhotoPrincipale">
                    <img src="https://pmml.ca/wp-content/uploads/2020/09/youtube_play_overlay.png" class="play-overlay">
                    <a
                        [href]="('https://www.youtube.com/watch?v=' + (data?.sVideoYoutubeFr | translateValeur : data?.sVideoYoutubeAn))">
                        <span>{{ data | pmmlProprieteTitre : true }}</span>
                    </a>
                </div>
                <div id="video-iframe" frameborder="0" color="white" enablejsapi="1" style="height: 100%; width: 100%;"
                    [style.aspectRatio]="data?.iVideoPortrait ? '9 / 16' : '16 / 9'">
                </div>
            </div>
            <div id="video-container" class="video" *ngIf="!data?.sVideoYoutubeFr && data?.sImagePrincipale">
                <div style="width: 100%;">
                    <img [src]="data?.sImagePrincipale | resizePhotoPrincipale">
                </div>
            </div>
        </div>
        <div  [ngClass]="{
                'col-12 col-lg-4': !data?.iVideoPortrait,
                'col-12 col-lg-7': data?.iVideoPortrait
            }">
            <div class="d-flex flex-column h-100 justify-items-stretch">
                <div class="bloque-titre">
                    <h1>
                        {{data | pmmlProprieteTitre : true}}
                    </h1>
                    <h2 [ngStyle]="{'padding-bottom.px': (data?.iVideoPortrait ? 0 : 24), 'display': 'flex', flexWrap: '1', justifyContent: 'space-between'}">
                        <!-- Tout sauf iTerrain & iCommercial -->
                        <span class="unitesTotaux"
                            *ngIf="data?.sTypeLogement !== 'iTerrain' && data?.sTypeLogement !== 'iCommercial' && data?.sTypeLogement !== 'iSemiCommercial' && data?.sTypeLogement !== 'iFondCommerce'">
                            <ng-container *ngIf="data?.IDPropriete !== 15440 && data?.IDPropriete !== 851616">
                                <span *ngIf="data?.IDPropriete == 813299 && data?.iFlyer">700 + </span>
                                <span *ngIf="data?.IDPropriete == 813299 && !data?.iFlyer">{{ data?.iUnitesTotal }} </span>
                                <span *ngIf="data?.IDPropriete !== 813299">{{ data?.iUnitesTotal }} </span>
                                <span
                                    *ngIf="data?.sTypeLogement !== 'iHotel' && data?.sTypeLogement !== 'iUnitesChambre'">{{
                                    '@menu.multilogement.unites' | translate }}</span>
                                <span *ngIf="data?.sTypeLogement == 'iHotel' || data?.sTypeLogement == 'iUnitesChambre'">{{
                                    'chambres' | translate }}</span>
                            </ng-container>
                            &nbsp;
                        </span>

                        <!-- Semi-Commercial -->
                        <span class="unitesTotaux" *ngIf="data?.sTypeLogement == 'iSemiCommercial'">
                            {{ data?.iUnitesTotal }} {{ '@menu.multilogement.unites' | translate }}, {{
                            '@menu.terrain.superficie' | translate }} : {{ data?.iPiedsCarreTotal | number : '1.0-0' }}
                            {{ '@pi2' | translate }}
                        </span>

                        <!-- Location -->
                        <span class="unitesTotaux" *ngIf="data?.sTypeLogement == 'iLocal' && data?.iPiedsCarreTotal">
                            <!-- {{'@menu.commercial.superficie' | translate}} :  -->
                            {{ data?.iPiedsCarreTotal | number : '1.0-0' }} {{ '@pi2' | translate }}
                        </span>

                        <!-- Terrain & Commercial -->
                        <span class="unitesTotaux"
                            *ngIf="(data?.sTypeLogement == 'iTerrain' || data?.sTypeLogement == 'iCommercial') && data?.iPiedsCarreTotal">
                            <!-- {{ '@menu.terrain.superficie' | translate }} : -->
                            {{ data?.iPiedsCarreTotal | number : '1.0-0' }} {{ '@pi2' | translate }}
                        </span>
                        <!-- Prix demande -->
                        <div class="prixDemande">
                            <app-prix-demande [data]="data" [location]="location"></app-prix-demande>
                        </div>
                    </h2>
                </div>
                <!-- REMARQUES -->
                <div class="bloque-remarques" style="flex-grow: 1;"
                    [innerHTML]="(data?.sRemarques | translateValeur : data?.sRemarquesAn) | nl2br"></div>
                <!-- INFOS COURTIER -->
                <div class="bloque-courtier">
                    <app-infos-courtier [data]="data" [location]="location"></app-infos-courtier>
                </div>
                <!-- LIEN PDF -->
                <div class="text-center bloque-voir-pdf">
                    <a [href]="('/' | translateValeur : '/en/') + 'location/' + data?.sLien  + aParamCode"
                        target="_blank" rel="noopener noreferrer">
                        <i class="fal fa-file-pdf mr-2"></i>
                        {{'@fichePdf.voir-fiche' | translate}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>