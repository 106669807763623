<div class="container -cache-overflow pb-0 pb-md-5">
    <div class="row pl-lg-4 pr-lg-4 align-items-center justify-content-center">
        <!-- REMARQUES -->
        <div class="col-12 pt-5 pt-md-0 description" >
            <h3 *ngIf="(data?.sTypeLogement !== 'iLocal' && data?.sTypeLogement !== 'iTerrain' && data?.sTypeLogement !== 'iPortfolio' && data?.sTypeLogement !=='Goplex')">
                {{ '@description.propriete' | translate }}
            </h3>
            <h3 *ngIf="data?.sTypeLogement === 'iLocal'">
                {{ '@description.local' | translate }}
            </h3>
            <h3 *ngIf="data?.sTypeLogement === 'Goplex'">
                {{ '@loyers.colRemarque' | translate }}
            </h3>
            <h3 *ngIf="data?.sTypeLogement === 'iTerrain'">
                {{ '@description.terrain' | translate }}
            </h3>
            <h3 *ngIf="data?.sTypeLogement === 'iPortfolio'">
                <!-- {{ '@description.portfolio' | translate }} -->
                {{ '@loyers.colRemarque' | translate }}
            </h3>
            <!-- Description texte -->
            <p class="pb-4 pb-md-4 pb-lg-0" [innerHTML]="(data?.sRemarques | translateValeur : data?.Traduction?.sRemarques) | urlifyFromText"></p>
        </div>

        <!-- CARACTÉRISTIQUES -->
        <!-- <div class="col-12 pt-5 pb-5">
            <h3>
                Caractéristiques
            </h3>
        </div> -->
        <div class="col-12 pt-5"></div>
        <div class="col-xs-12 col-md-4 caracteristique">
            <h4>
                <i class="fal fa-map"></i>
                <br>
                {{ '@info.cadastre' | translate }}
            </h4>
            <div class="valeur">{{ (data?.sCadastre | translateValeur : data?.Traduction?.sCadastre) }}</div>
        </div>
        <div class="col-xs-12 col-md-4 caracteristique">
            <h4>
                <i class="fal fa-ruler-combined"></i>
                <br>
                {{ '@superficie.terrain' | translate }}
            </h4>
            <div class="valeur">{{ data?.fSuperficieTerrain | translateValeur : data?.Traduction?.fSuperficieTerrain  }}</div>
        </div>
        <div class="col-xs-12 col-md-4 caracteristique">
            <h4>
                <i class="fal fa-ruler-combined"></i>
                <br>
                {{ '@superficie.batiment' | translate }}
            </h4>
            <div class="valeur">{{ data?.fSuperficieBatiment | translateValeur : data?.Traduction?.fSuperficieBatiment  }}</div>
        </div>
        <div class="col-xs-12 col-md-4 caracteristique">
            <h4>
                <i class="fal fa-calendar-day"></i>
                <br>
                {{ 'iAnneeConstruction' | translate }}
            </h4>
            <div class="valeur">{{ data?.iAnneeConstruction | translateValeur : data?.Traduction?.iAnneeConstruction }}</div>
        </div>
        <div class="col-xs-12 col-md-4 caracteristique">
            <h4>
                <i class="fal fa-hammer"></i>
                <br>
                {{ 'sTypeConstruction' | translate }}
            </h4>
            <div class="valeur">{{ data?.sTypeConstruction | translateValeur : data?.Traduction?.sTypeConstruction }}</div>
        </div>
        <div class="col-xs-12 col-md-4 caracteristique">
            <h4>
                <i class="fad fa-th"></i>
                <br>
                {{'sRepartition' | translate }}
            </h4>
            <div class="valeur">{{ (data?.sRepartition | translateValeur : data?.Traduction?.sRepartition) }}</div>
        </div>
        <div class="col-12"></div>
    </div>

    <!-- CONFIDENTIALITÉ -->
    <div class="text-center pt-3" *ngIf="data?.iFlyer == 1 && data?.iAbbrege != 1">
        <h3>{{ "fiche.confidentiel.text" | translate }}</h3>
    </div>

    
</div>