import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-thumbnails-com',
  templateUrl: './thumbnails-com.component.html',
  styleUrls: ['./thumbnails-com.component.scss']
})
export class ThumbnailsComComponent implements OnInit {
  env = environment;

  constructor() { }

  @Input() data: any;

  ngOnInit(): void {
  }

}
