import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { debounceTime, fromEvent, startWith, Subject } from "rxjs";

import { AuthService } from "src/app/auth.service";
import { environment } from "../../../../environments/environment";
import { ConnexionFavorisDialogComponent } from "../../liste-priorites/connexion-favoris-dialog/connexion-favoris-dialog.component";
import { FavorisInformationsComponent } from "../../liste-priorites/favoris-informations/favoris-informations.component";
import { ProprietesService } from "./../../../api/proprietes.service";
// import { ChatlioComponent } from '../../../chatlio/chatlio.component';
@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  #menu;
  environment = environment;
  sLien: string;
  aParamsPresent: Boolean;
  aParamCode: any;
  shouldForceMobile: boolean;
  dialogRefFavorisTooltip;
  dialogRefConnexionFavoris;
  private dialogAlreadyOpen: boolean = false;
  languePage;

  @Input() nouveauLiens = false;
  @Input() afficherFavoris: boolean = true;

  constructor(
    private proprietes: ProprietesService,
    private auth: AuthService,
    public dialog: MatDialog
  ) {
    this.setScrolled();
    this.sLien = String(window.location.pathname)
      .replace(/\/$/, "")
      .split("/")
      .pop();
  }

  masquerMenu = true;
  @Input() location = false;

  get menu() {
    if (!this.#menu) {
      const avecEnfants = {};
      const menu = (window as any).pmml_menu;
      const sansEnfants = [];
      for (const objet of menu) {
        const entre = { ID: objet.ID, text: objet.title, url: objet.url };

        if (objet.menu_item_parent !== "0") {
          if (!avecEnfants[objet.menu_item_parent])
            avecEnfants[objet.menu_item_parent] = [];

          avecEnfants[+objet.menu_item_parent].push(entre);
        } else {
          sansEnfants.push(entre);
        }
      }

      this.#menu = sansEnfants.map((objet) => {
        if (avecEnfants[objet.ID]) {
          objet.children = avecEnfants[objet.ID];
        }
        return objet;
      });
    }
    return this.#menu;
  }

  @ViewChild("menuWrapper") menuElement!: ElementRef<HTMLDivElement>;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.shouldForceMobile = window.innerWidth < 1220 ? true : false;
  }

  scrolled = false;
  @HostListener("document:scroll", ["$event"]) private scroll(ev: Event) {
    setTimeout(() => {
      this.setScrolled();
    }, 100);
  }

  @Input("data") data: any;
  @Input("ficheLocaux") ficheLocaux: string;

  ngOnInit() {
    this.shouldForceMobile = window.innerWidth < 1220 ? true : false;

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("a")) {
      this.aParamsPresent = true;
      this.aParamCode = `?a=${urlParams.get("a")}`;
    } else this.aParamCode = "";
  }

  ngAfterViewInit() {
    const menuResize = new Subject();
    const observer = new ResizeObserver(() => menuResize.next(null));

    observer.observe(this.menuElement.nativeElement);

    menuResize.subscribe(() => {
      if (this.menuElement) {
        const children = this.menuElement.nativeElement.children;
        let heiught = 0;
        for (let i = 0; i < children.length; i++) {
          heiught += children[i].clientHeight;
        }
        document.documentElement.style.setProperty(
          "--listing-header-height",
          heiught.toString() + "px"
        );
      }
    });

    // fromEvent(window, "resize")
    //   .pipe(startWith(null))
    //   .subscribe((event) => {
    //     if (this.menuElement) {
    //       const children = this.menuElement.nativeElement.children;
    //       let heiught = 0;
    //       for (let i = 0; i < children.length; i++) {
    //         heiught += children[i].clientHeight;
    //       }
    //       document.documentElement.style.setProperty(
    //         "--listing-header-height",
    //         heiught.toString() + "px"
    //       );
    //       console.log(
    //         "here",
    //         this.menuElement.nativeElement.getBoundingClientRect()
    //       );
    //     }
    //   });
  }

  setScrolled() {
    if (
      window.document
        .getElementsByTagName("html")[0]
        .classList.contains("cdk-global-scrollblock")
    )
      return;
    this.scrolled = window.scrollY != 0;
  }

  hideFavorisTooltip() {
    if (this.dialogRefConnexionFavoris && !this.estConnecte())
      this.dialogRefFavorisTooltip.close();
    this.dialogRefFavorisTooltip?.close();
  }

  showFavorisTooltip(event) {
    // N'affiche pas le toggle d'information(MouseOver) en mobile
    if (!this.shouldForceMobile) {
      const x = event.clientX,
        y = event.clientY,
        elementMouseIsHover = document.elementFromPoint(x, y);

      // if (this.estConnecte()) return;
      // if (propriete.fav) return;
      this.dialogRefFavorisTooltip = this.dialog.open(
        FavorisInformationsComponent,
        {
          hasBackdrop: false,
          data: { element: elementMouseIsHover, type: "menu" },
        }
      );
    }
  }

  ajouterFavoris(propriete, event) {
    if (this.estConnecte()) {
      if (!propriete.fav) {
        this.hideFavorisTooltip();
        propriete.fav = true;
        this.proprietes.setFavori(propriete.sLien, true);
      } else {
        this.hideFavorisTooltip();
        propriete.fav = false;
        this.proprietes.setFavori(propriete.sLien, false);
      }
    } else {
      const target = new ElementRef(event.currentTarget);
      const dialogRef = this.dialog.open(ConnexionFavorisDialogComponent, {
        hasBackdrop: false,
        data: { trigger: target, type: "menu" },
      });
    }
  }

  estConnecte() {
    return this.auth.token ? true : false;
  }

  // ouvertDemandeInfo() {
  //   this.dialog.open(ChatlioComponent, {
  //     width: '500px'
  //   })
  // }
}
