<span *ngIf="environment?.modeTeasing">
    <app-inf-sup [data]="data"></app-inf-sup>
    <!-- <app-informations [data]="data"></app-informations> -->
</span>

<span *ngIf="!environment?.modeTeasing">
    <app-inf-sup [data]="data"></app-inf-sup>
    <app-informations [data]="data"></app-informations>
    <app-map [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-map>
    <app-thumbnails [data]="data" *ngIf="data?.sJsonData?.bPortfolioCommeMulti"></app-thumbnails>
    <app-portfolio-descriptif [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-portfolio-descriptif>
    <app-info-fin-multi [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix && !data.bVacant"></app-info-fin-multi>
    <app-somloyers-evmunicipale [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix && !data?.sJsonData?.bPortfolioCommeMulti"></app-somloyers-evmunicipale>
    <!-- Version portfolio comme multi -->
    <app-sommaires [data]="data" *ngIf="data?.sJsonData?.bPortfolioCommeMulti"></app-sommaires>
    <!-- Version portfolio comme multi -->
    <app-photos [data]="data" *ngIf="data?.sJsonData?.bPortfolioCommeMulti"></app-photos>
</span>
