import { HttpClient } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer, Title } from "@angular/platform-browser";
import {
  Subject,
  debounceTime,
  fromEvent,
  map,
  merge,
  startWith,
  tap,
} from "rxjs";
import { DemandeDataroomComponent } from "src/app/demande-dataroom/demande-dataroom.component";
import { environment } from "../../../../environments/environment";
import { ProprietesService } from "../../../api/proprietes.service";
import { AuthService } from "../../../auth.service";
import { PdfComponent } from "../../pdf/pdf.component";
import { SegmentService } from "./../../../api/segment.service";
import { TranslateValeurPipe } from "./../../../translate-valeur.pipe";

@Component({
  selector: "app-propriete-details",
  templateUrl: "./propriete-details.component.html",
  styleUrls: ["./propriete-details.component.scss"],
})
export class PrioriteDetailsComponent implements OnInit, AfterViewInit {
  constructor(
    private proprietes: ProprietesService,
    private auth: AuthService,
    private segmentService: SegmentService,
    private translate: TranslateValeurPipe,
    private title: Title,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private dialog: MatDialog
  ) {}

  environment = environment;

  offsetHaut: number;
  @ViewChild("conteneurDetails") conteneurDetails: ElementRef;

  @Input("nouveauliens") nouveauLiens;
  @Input() lien: string;
  @Input() ficheLocaux: string;

  detail: any;
  bBureauLocation: boolean = false;
  bLand: boolean = false;
  bMultilogement: boolean = false;
  bListingPDF: boolean = false;
  bBureauVente: boolean = false;
  bCommercialVente: boolean = false;
  bCommercialLocation: boolean = false;
  bGroupeLocation: boolean = false;
  bPortfolio: boolean = false;
  bPortfolioComm: boolean = false;
  bGoplex: boolean = false;
  affichageFormulaire: boolean = false;
  hostSite: any;
  aParamCode: any;
  prefixURL: any;

  @ViewChild("modePortraitListing") modePortraitListing: ElementRef;

  forceScroll = new Subject<void>();
  outOfView$ = merge(fromEvent(window, "scroll"), this.forceScroll).pipe(
    map((e: any) => {
      if (window.innerWidth <= 666) return true;
      if (this.detail?.iVideoPortrait != 1) return true;

      const rect = document.getElementById("inf-sup").getBoundingClientRect();

      return rect.top < 0;
    })
  );

  ngOnInit() {
    this.hostSite = document.location.host;
    if (document.documentElement.lang == "en-CA") this.prefixURL = "en/";
    else this.prefixURL = "";

    let lien: string = this.lien;

    if (!this.lien) {
      const path = window.location.pathname.split("/");
      if (path[1] === "en") {
        if (path[2] !== "") {
          lien = path[2];
        }
      } else {
        if (path[1] !== "") {
          lien = path[1];
        }
      }

      this.lien = lien;
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("a")) {
      this.aParamCode = `?a=${urlParams.get("a")}`;
    } else this.aParamCode = "";

    this.proprietes.detail(lien).then(
      async (detail: any) => {
        if (!detail)
          window.location.replace("https://" + document.location.hostname) +
            <string>this.translate.transform("/", "/en/");
        if (detail == "besoinConnecter") return this.seConnecter();
        if (detail == "pasAuthorise") {
          return (window.location.href = <string>(
            this.translate.transform("/demande-dacces/", "/en/access-request/")
          ));
        }
        if (detail == "nonTrouve") return (window.location.href = "/");

        if (typeof (window as any)?.gtag === "function") {
          (window as any)?.gtag("event", detail?.sTypeLogement, {
            event_category: "detailPropriete",
            event_label: `${detail?.sNumeroCivique} ${detail?.sRue}, ${detail?.sNomVille}`,
          });
        }
        this.affichageFormulaire = true;
        this.detail = detail;
        this.bBureauLocation = false;
        this.bLand = false;
        this.bListingPDF = false;
        this.bMultilogement = false;
        this.bBureauVente = false;
        this.bCommercialVente = false;
        this.bCommercialLocation = false;
        this.bGroupeLocation = false;
        this.bPortfolio = false;
        this.bPortfolioComm = false;
        this.bGoplex = false;

        setTimeout(() => this.forceScroll.next(), 100);

        if (this.detail?.IDPropriete == 145647 && this.detail?.iFlyer) {
          this.title.setTitle(
            <string>(
              this.translate.transform(
                "RPA pour autonome et semi autonome, Laurentides",
                "Senior home for autonomous and semi-autonomous, Laurentides"
              )
            )
          );
        }

        if (this.detail?.IDPropriete == 223692 && this.detail?.iFlyer) {
          this.title.setTitle(
            <string>(
              this.translate.transform(
                "Industriel, Mont-Laurier / Rivière-Rouge",
                "Industrial, Mont-Laurier / Rivière-Rouge"
              )
            )
          );
        }

        try {
          if (this.detail?.iAbbrege == 1) {
            document.querySelector<HTMLElement>(
              ".formulaireConfidentialite"
            ).style.display = "none";
          } else {
            document.querySelector<HTMLElement>(
              ".formulaireConfidentialite"
            ).style.display = "block";
          }
        } catch (error) {}

        this.detail.bGroupeLocation = false;
        if (this.ficheLocaux == "1") {
          this.detail.bGroupeLocation = true;
          return (this.bGroupeLocation = true);
        }
        if (this.detail?.bListingPDF || this.detail?.sJsonData?.bListingPDF) {
          this.bListingPDF = true;
          return;
        }

        switch (this.detail.sTypeLogement) {
          case "":
          case "iMultiLogement":
          case "iUniteGrand":
          case "iUnitesChambre":
          case "iRPA":
            // if ((this.detail.iUnitesTotal < 12 && this.detail.sTypeLogement == '') && this.hostSite !== 'goplex.com' && environment.production) window.location.replace('https://goplex.com/' + this.prefixURL + this.detail?.sLien + this.aParamCode);
            this.bMultilogement = true;
            break;

          case "iTerrain":
            // if (this.hostSite !== "landev.ai" && environment.production)
            //   window.location.replace(
            //     "https://landev.ai/" +
            //       this.prefixURL +
            //       this.detail?.sLien +
            //       this.aParamCode
            //   );
            this.bLand = true;
            break;

          case "iCommercial":
          case "iDivertissement":
            // if (this.detail.IDCategorie === 1 || this.detail.IDCategorie == 0) this.bCommercialVente = true;
            // if (this.detail.IDCategorie === 5) this.bCommercialVente = true;
            this.bCommercialVente = true;
            break;
          case "iHotel":
            this.bCommercialVente = true;
            break;
          case "iPortfolio":
            this.bPortfolio = true;
            break;
          case "iPortfolioComm":
            this.bPortfolioComm = true;
            break;
          case "iGoplex":
            // if (this.hostSite !== 'goplex.com' && environment.production) window.location.replace('https://goplex.com/' + this.prefixURL + this.detail?.sLien + this.aParamCode);
            this.bGoplex = true;
          case "iLocal":
            if (
              this.detail.IDCategorie === 1 ||
              this.detail.IDCategorie == 0
            )
              this.bBureauLocation = true;
            else if (this.detail.IDCategorie === 5)
              this.bBureauLocation = true;
            else this.bBureauLocation = true;
            break;

          default:
            this.bMultilogement = true;
            break;
        }
        let typeLogement = detail.sTypeLogement;
        if (detail.sTypeLogement == "") typeLogement = "iMultilogement";

        if (this.nouveauLiens) {
          const url = new URL(window.location as any);

          if (url.pathname.endsWith("/"))
            url.pathname = url.pathname.slice(0, -1);

          if (
            url.pathname !== detail.slugFr &&
            url.pathname !== detail.slugAn
          ) {
            const anglais = url.pathname.includes("en/");
            url.pathname = anglais ? detail.slugAn : detail.slugFr;

            window.history.replaceState({}, "", url.toString());
          }
        }

        await this.segmentService.track("Propriete_Consult", {
          IDPropriete: detail.IDPropriete || null,
          sNumeroCivique: detail.sNumeroCivique || null,
          sRue: detail.sRue || null,
          sNomVille: detail.sNomVille || null,
          sRegion: detail.sNomSecteur || null,
          audianceType: typeLogement || null,
          sCourtierCourriel: detail.sCourtierCourriel || null,
          sCourtierPhoto: detail.sCourtierPhoto || null,
          sCourtierTitre: detail.sCourtierTitre || null,
          sCourtierTel: detail.sCourtierTel || null,
          sCourtierNom: detail.sNomCourtier || null,
        });
        (window as any)?.fbq("track", "AddToCart", {
          url: detail.sLien,
        });
      },
      (error) => {
        if (error == "besoinConnecter") this.seConnecter();
        else window.location.href = "/404";
      }
    );
  }

  ngAfterViewInit() {
    try {
      this.offsetHaut = document
        .querySelector('[id="menu"]')
        .getBoundingClientRect().height;
    } catch {}

    if (!this.offsetHaut) {
      this.offsetHaut = this.conteneurDetails?.nativeElement?.offsetTop;
      setTimeout(() => {
        this.offsetHaut = this.conteneurDetails?.nativeElement?.offsetTop;
      }, 1000);
    }
  }

  seConnecter() {
    return this.auth.login(window.location.pathname);
  }

  afficheDataroom() {
    this.dialog.open(DemandeDataroomComponent, {
      disableClose: true,
      width: `600px`,
      data: { lien: this.lien },
    });
  }
}
