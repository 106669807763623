import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-infos-courtier',
  templateUrl: './infos-courtier.component.html',
  styleUrls: ['./infos-courtier.component.scss']
})
export class InfosCourtierComponent {

  constructor() { }

  @Input('data') data: any;
  @Input() location = false;
  @Input() versionMobile = false;
}
