import {
  AfterViewInit,
  Component,
  ElementRef,
  Host,
  HostListener,
  Input,
  NgZone,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { PhotoDialogComponent } from "../photos/photo-dialog.component";
import { VideoDialogComponent } from "../videos/video-dialog/video-dialog.component";
import { environment } from "src/environments/environment";
import { ResizePhotoPrincipalePipe } from "src/app/pipes/resize-photo-principale.pipe";

const preload = (src, meta = {}) =>
  new Promise((resolve) => {
    const image = new Image();
    image.src = src;
    image.onload = () => resolve({ ...meta, src });
  });

@Component({
  selector: "app-carrousel-complet",
  templateUrl: "./carrousel-complet.component.html",
  styleUrls: ["./carrousel-complet.component.scss"],
})
export class CarrouselCompletComponent implements OnInit, AfterViewInit {
  data: any;
  @Input("data")
  set in(val) {
    this.data = val;
    this.preChargementImages();
  }

  @Input() mode: "mini" | "normal" = "normal";
  @Input() skipPhotoPrincipale = false;

  @ViewChild("slides") slidesEl: ElementRef;
  @ViewChild("carrousel") carrousel: ElementRef<HTMLDivElement>;
  @ViewChildren("images") imageElements: QueryList<
    ElementRef<HTMLImageElement>
  >;

  DEBOUNCE_TIME_SEGMENT = 1000;
  imagesPrecharge = false;
  slidePresente = 0;
  interetSlide: Subject<any> = new Subject<string>();

  currentX = 0;
  offsetX = 0;
  offsets = new Map<string, number>();
  imagesPrecharges = [];
  direction = "f";

  idIdxMap: Record<string, number>;

  // Variables for Swiping on Mobile
  startPosX = 0;
  startOffset = 0;
  swipeStarted = false;

  constructor(
    private dialog: MatDialog,
    private zone: NgZone,
    // private segment: SegmentService,
    private photoUrl: ResizePhotoPrincipalePipe,
  ) {}

  ngAfterViewInit(): void {
    new Observable((subscriber) => {
      // @ts-ignore
      const resizeObserver = new ResizeObserver((...args) => {
        this.zone.run(() => {
          subscriber.next(args);
        });
      });
      resizeObserver.observe(this.slidesEl.nativeElement);

      return () => resizeObserver.disconnect();
    })
      .pipe(debounceTime(100))
      .subscribe(() => {
        if (this.slidesEl.nativeElement.children.length > 0) {
          this.zone.run(() => {
            this.imagesPrecharge = true;
            this.preCalcOffsets();
          });
        }
      });
  }

  ngOnInit(): void {}

  // Initialization
  async preChargementImages() {
    const aVideo =
      this.data.iVideoPortrait != 1 &&
      !!this.data.sVideoYoutubeAn &&
      !!this.data.sVideoYoutubeFr;
    const lienPrincipale = this.photoUrl.transform(this.data.sImagePrincipale, false);

    const loaded = [lienPrincipale];
    const promises = [];

    if (!this.skipPhotoPrincipale) {
      promises.push(preload(lienPrincipale, {
        type: aVideo ? "video" : "photo",
        an: this.data.sVideoYoutubeAn,
        fr: this.data.sVideoYoutubeFr,
        photo: {
          src: this.data.sImagePrincipale,
          srcDirecte: true,
        },
      }))
    }

    const IDPrincipale = +`${lienPrincipale}`.substring(20).split("_").shift();


    let idx = 0;
    for (const photo of this.data.Photos) {
      const src = `${environment.cloudflaresImages.baseUrl}${photo.IDImage}_${photo.sNomFichier}/1400`;
      if ((!aVideo && photo.IDImage === IDPrincipale) || loaded.includes(src))
        continue;

      loaded.push(src);
      promises.push(preload(src, { photo, idx: idx++ }));
    }
    this.imagesPrecharges = await Promise.all(promises);
    const sub = this.imageElements.changes.subscribe(async () => {
      await this.preappend();
      sub.unsubscribe();
      this.idIdxMap = {
        __: this.imagesPrecharges.length - 2,
        pre: this.imagesPrecharges.length - 1,
        post: 0,
        _: 1,
      };
    });
  }

  preappend() {
    return new Promise((resolve) => {
      const slides = this.slidesEl.nativeElement as HTMLDivElement;
      const realPre = Array.from(slides.children).slice(-2);
      const realPost = Array.from(slides.children).slice(0, 2);

      const pre = realPre.map((el) => el.cloneNode(true) as HTMLDivElement);
      const post = realPost.map((el) => el.cloneNode(true) as HTMLDivElement);

      pre[0].firstElementChild.id = "__";
      pre[1].firstElementChild.id = "pre";

      post[0].firstElementChild.id = "post";
      post[1].firstElementChild.id = "_";

      pre[1].onclick = (e) => this.onImageClick(e);
      post[0].onclick = (e) => this.onImageClick(e);

      slides.prepend(...pre);
      slides.append(...post);
      resolve(null);
    });
  }

  preCalcOffsets() {
    this.offsets.clear();
    const cRect = this.carrousel.nativeElement.getBoundingClientRect();
    const cOff = cRect.x;
    const sl = this.slidesEl.nativeElement.children[0];
    const pl = getComputedStyle(sl).getPropertyValue("padding-left");
    let offset = cOff + parseInt(pl);
    for (const child of this.slidesEl.nativeElement.children) {
      this.offsets.set(child.firstElementChild.id, offset);
      offset += child.getBoundingClientRect().width;
    }

    this.currentX = this.calcOffset();
  }

  // Misc
  getPreImage() {
    return document.querySelector(".slides #pre");
  }

  getPostImage() {
    return document.querySelector(".slides #post");
  }

  getImageElement(idx) {
    if (typeof idx === "string")
      return this.slidesEl.nativeElement.querySelector(`[id="${idx}"]`);
    return this.imageElements.toArray()[idx].nativeElement;
  }

  // Events
  @HostListener("window:keyup", ["$event"])
  onKeypress(event: any) {
    if (event?.target?.nodeName === "INPUT") return;

    if (event.key === "ArrowLeft") {
      event.preventDefault();
      this.precedent();
    }

    if (event.key === "ArrowRight") {
      event.preventDefault();
      this.prochaine();
    }
  }

  @HostListener("window:touchstart", ["$event"])
  onTouchStart(event: TouchEvent) {
    let el = event.target as Element;
    if (el.id === "fleche") return;

    while (el.id !== "carrousel") {
      el = el.parentElement;
      if (el === document.body) {
        return;
      }
    }
    this.swipeStarted = true;

    const touch = event.touches[0];
    this.startPosX = touch.pageX;
    this.startOffset = this.currentX;
    this.slidesEl.nativeElement.classList.add("teleport");
  }

  @HostListener("window:touchmove", ["$event"])
  onTouchMove(event: TouchEvent) {
    if (this.swipeStarted) {
      const touch = event.changedTouches[0];
      const delta = -(touch.pageX - this.startPosX) * 1.5;
      let nextOffset = this.startOffset + delta;
      const preOffset = this.offsets.get("pre");
      const postOffset = this.offsets.get("post");

      const preDiff = preOffset - nextOffset;
      const postDiff = nextOffset - postOffset;

      if (preDiff > 0) {
        const preRealOffset = this.offsets.get(
          this.imagesPrecharges.length - 1 + ""
        );
        nextOffset = preRealOffset - preDiff;
      }

      if (postDiff > 0) {
        const postRealOffset = this.offsets.get("0");
        nextOffset = postRealOffset + postDiff;
      }

      this.currentX = nextOffset;
    }
  }

  @HostListener("window:touchend")
  onTouchEnd() {
    if (this.swipeStarted) {
      this.swipeStarted = false;
      const visible = {};
      for (const child of this.slidesEl.nativeElement.children) {
        const childRect = (child as HTMLDivElement).getBoundingClientRect();

        if (childRect.right >= 0 && childRect.left <= window.innerWidth) {
          const vr = childRect.x + childRect.width;
          const vl = window.innerWidth - childRect.x;
          const v = Math.ceil(Math.min(vr, vl));
          visible[v] = child.firstElementChild.id;
        }
      }

      const most = Math.max(...Object.keys(visible).map((k) => +k)).toString();
      const idx = this.idIdxMap[visible[most]] ?? +visible[most];

      this.slidePresente = idx;
      this.offsetX = this.calcOffset(visible[most]);
      const slideAmount = this.offsetX - this.currentX;
      if (slideAmount === 0) return;
      this.direction = slideAmount >= 0 ? "f" : "b";

      if (this.animationId) this.resetAnimation();
      this.animationId = requestAnimationFrame(this.animateTo.bind(this));
    }
  }

  onImageClick(event) {
    const idx = this.idIdxMap[event.target.id] ?? +event.target.id;
    const imagePrecharge = this.imagesPrecharges[idx];

    this.dialog.open(PhotoDialogComponent, {
      width: "95vw",
      maxWidth: "1595px",
      data: {
        photo: imagePrecharge.photo,
        listePhotos: this.imagesPrecharges.map((pre) => pre.photo),
        index: imagePrecharge.idx,
      },
      panelClass: "photos",
    });
  }

  onVideoClick() {
    const portrait = window.innerHeight > window.innerWidth;

    this.dialog.open(VideoDialogComponent, {
      height: portrait ? "" : "90vh",
      width: portrait ? "90vw" : "80vw",
      data: {
        videoAn: this.data.sVideoYoutubeAn,
        videoFr: this.data.sVideoYoutubeFr,
      },
    });
  }

  // Calculations
  calcOffset(idx: number | string = this.slidePresente) {
    const img = this.getImageElement(idx);
    const cRect = this.carrousel.nativeElement.getBoundingClientRect();
    const iRect = img.getBoundingClientRect();
    const realOffset = this.offsets.get(img.id);
    const offset = realOffset - cRect.x;
    const width = iRect.width;
    const max = cRect.width;
    const center = max / 2 - width / 2;
    const final = -(center - offset);

    return final;
  }

  // Navigation
  async precedent() {
    this.slidePresente--;

    if (this.slidePresente <= -1) {
      this.slidePresente = this.imagesPrecharges.length - 1;
    }

    this.direction = "b";
    this.offsetX = this.calcOffset();

    if (this.animationId) this.resetAnimation();
    this.animationId = requestAnimationFrame(this.animateTo.bind(this));

    this.interetSlide.next(this.slidePresente);
  }

  async prochaine() {
    this.slidePresente++;
    if (this.slidePresente >= this.imagesPrecharges.length) {
      this.slidePresente = 0;
    }

    this.direction = "f";
    this.offsetX = this.calcOffset();

    if (this.animationId) this.resetAnimation();
    this.animationId = requestAnimationFrame(this.animateTo.bind(this));

    this.interetSlide.next(this.slidePresente);
  }

  animationId;
  animationDuration = 500;
  animationStart;
  animationDistance;
  animationStartX;
  animationPostX;
  animationZeroX;
  animationPreX;
  animationEndX;
  animationFrames;
  animationPrevTime;

  resetAnimation() {
    cancelAnimationFrame(this.animationId);
    delete this.animationId;
    delete this.animationStart;
    delete this.animationDistance;
    delete this.animationStartX;
  }

  animationTimeFn(x: number): number {
    // https://easings.net/

    // Ease In Out
    return -(Math.cos(Math.PI * x) - 1) / 2;
  }

  onAnimationStart(timestamp) {
    this.animationStartX = this.currentX;
    this.animationStart = timestamp;
    this.animationPostX = this.calcOffset("post");
    this.animationPreX = this.calcOffset("pre");
    this.animationZeroX = this.calcOffset(0);
    this.animationEndX = this.calcOffset(this.imagesPrecharges.length - 1);
    this.animationFrames = [];

    if (this.currentX >= this.offsetX && this.direction === "f") {
      const distanceToPost = this.animationPostX - this.currentX;
      const distanceFromZero = this.offsetX - this.animationZeroX;

      this.animationDistance = distanceToPost + distanceFromZero;
    } else if (this.currentX <= this.offsetX && this.direction === "b") {
      const distanceToPre = this.currentX - this.animationPreX;
      const distanceFromEnd = this.animationEndX - this.offsetX;

      this.animationDistance = -(distanceToPre + distanceFromEnd);
    } else {
      this.animationDistance = this.offsetX - this.currentX;
    }
  }

  animateTo(timestamp) {
    // Understanding this function

    // The images go as follows
    // __, pre, ...array of images , post, _
    if (!this.animationStart) this.onAnimationStart(timestamp);

    const timeElapsed = timestamp - this.animationStart;
    const timeMax = this.animationDuration;
    const progress = Math.min(this.animationTimeFn(timeElapsed / timeMax), 1);

    // We add the distance travalled to the starting position of the animation
    let nextX = this.animationStartX + this.animationDistance * progress;

    // if after post image
    if (nextX >= this.animationPostX) {
      // We need to change the offset to be based off of the 0-index image
      nextX = nextX - this.animationPostX + this.animationZeroX;
    }

    // If before pre image
    if (nextX <= this.animationPreX) {
      // We need to change the offset to be based off of the last-index image
      nextX = this.animationEndX - this.animationPreX + nextX;
    }

    this.currentX = nextX;

    if (timeElapsed >= timeMax) {
      // Snap to the real offset
      this.currentX = this.calcOffset(this.slidePresente);
      return this.resetAnimation();
    }

    this.animationId = requestAnimationFrame(this.animateTo.bind(this));
  }
}
