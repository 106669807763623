import {
  Component,
  ElementRef, forwardRef,
  Input,
  OnInit, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeInputComponent),
      multi: true,
    },
  ],
})
export class CodeInputComponent implements OnInit, ControlValueAccessor {
  COUNT = Array.from({ length: 4 }, (_, i) => i);

  @Input() failed = false;

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {}

  get isFocused() {
    if (!this.input) return false;
    return this.input.nativeElement === document.activeElement;
  }

  get active() {
    return Math.max(this.value?.length - 1, 0);
  }

  _value: any;
  _disabled = false;
  _onChange!: Function;
  _onTouched!: Function;

  ngOnChanges() {}

  get value() {
    return this._value;
  }
  set value(_val) {
    const val = _val.substring(0, this.COUNT.length);
    if (val !== this._value) {
      this._value = val;
      if (this._onChange) this._onChange(this._value);
      if (this._onTouched) this._onTouched();
    }
  }

  writeValue(obj: any): void {
    this._value = obj;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  disablenonNumbers(event: KeyboardEvent) {
    if (
      !Array.from('1234567890').includes(event.key) &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
