import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bloques-stats',
  templateUrl: './bloques-stats.component.html',
  styleUrls: ['./bloques-stats.component.scss']
})
export class BloquesStatsComponent implements OnInit {

  constructor() { }

  @Input() data: any;
  @Input() colLg: number = 3;

  ngOnInit(): void {
  }

}
