import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-slider-pmmltv',
  templateUrl: './slider-pmmltv.component.html',
  styleUrls: ['./slider-pmmltv.component.scss']
})
export class SliderPMMLTVComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
