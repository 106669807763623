export const en = {
    "@general.champ-requis": "Your {{champ}} is required",
    "@general.champ-f-requis": "Your {{champ}} is required",
    "@general.chargement": "Loading...",
    
    "langue": "fr",
    "a": "to",
    "prix": "Price",
    "taille": "Size",
    "chambres": "Rooms",
    "appartement": "Apartment",
    "immediatement": "Now",
    "adeterminer": "To be determined",
    "adresse": "Addresse",
    "revenus_special_date": "Revenue as of July 1, 2021",
    "connection-afficher-fiche": "Log in now to access the full listing!",
    "@popup-inscription.component.titre": "Receive our new listings",
    "@popup-inscription.component.explicatif": "Be among the first to discover PMML’s news, such as new market registrations, informative video capsules via PMML.TV channel, enriching articles on the real estate investment market and much more!",
    "@popup-inscription.component.explicatif.suite": "Log in now to access it.",
    "@popup-inscription.component.masquer": "Don’t show this again",
    "@popup-inscription.component.confirmer": "Subscribe",
    "@popup-inscription.component.fermer": "Close",
    "@pmml-tv-titre": "Watch our episodes on land development",
    "@formulaire-alertes.component.confirmer": "Your preferences have been updated",
    "@formulaire-alertes.component.confirmer.courriel": "Your request has been received and will be processed shortly",
    "@enregistrer": "Save",
    "@courriel.valide": "You must enter a valid email",
    "@telephone.valide": "You must enter a valid phone number",
    "@envoyer": "Send",
    "@required": "Required",
    "@invalid": "Invalid value",
    "@formulaire-alertes.component.fermer": "Close",
    "@formulaire-alertes.component.succes": "Saved successfully!",
    "@formulaire-alertes.component.erreur": "Error while saving, please contact us to complete your profile",
    "@formulaire-alertes.component.desabonnement": "You are unsubscribed",

    "propriete": "PROPERTIES FOR SALE",
    "@chercher.vous": "WHAT ARE YOU LOOKING FOR?",

    "@listeProprietes.rechercheDescriptif": "Search by suburb, city, region or area",
    "@listeProprietes.typeUnite.tout": "All",

    "@message-mapbox": "USE CTRL + WHEEL TO ZOOM IN/OUT OF THE MAP",
    "@message-mapbox-mobile": "USE TWO FINGERS TO INTERACT WITH THE MAP",
    
    "@pmmltv.chaines.choisir": "Choose your asset type",
    "@pmmltv.chaines.tout": "All categories",
    "@pmmltv.voir-plus": "Load more",

    "@pmmltv.chaine.197": "Market Indicators",
    "@pmmltv.chaine.63": "Financing Advices",
    "@pmmltv.chaine.32": "Investment Advices",
    "@pmmltv.chaine.169": "Success Stories ",
    "@pmmltv.chaine.151": "PMML on the road",
    "@pmmltv.chaine.97": "PMML Café",
    "@pmmltv.chaine.1637": "Land Investment",
    "@pmmltv.chaine.1722": "Market Insights",
    "@pmmltv.chaine.1751": "Interview with professionals",
    "@pmmltv.chaine.mtlMultiFamilyRealEstate": "Montreal Multifamily Real Estate",
    "@pmmltv.chaine.1817": "GoPlex",
    "@pmmltv.chaine.29": "Watch our informative episodes to learn more about real estate, multifamily and commercial investment market.",
    "@pmmltv.chaine.1923": "Chartered Appraiser",
    "@pmmltv.chaine.1929": "VLOG",

    "@pmmltv.chaine.2016": "Watch our informative episodes to learn more about real estate, multifamily and commercial investment market.",
    "@pmmltv.chaine.2016.texte": "Watch our informative episodes to learn more about real estate, multifamily and commercial investment market.",
    "@pmmltv.chaine.1996" : "Financing",
    "@pmmltv.chaine.1996.texte" : "Watch our informative episodes to learn more about real estate, multifamily and commercial investment market.",
    "@pmmltv.chaine.1984" : "Multifamily",
    "@pmmltv.chaine.1984.texte" : "Watch our informative episodes to learn more about real estate, multifamily and commercial investment market.",
    "@pmmltv.chaine.1986" : "Industrial",
    "@pmmltv.chaine.1986.texte" : "Watch our informative episodes to learn more about real estate, multifamily and commercial investment market.",
    "@pmmltv.chaine.1992" : "Land",
    "@pmmltv.chaine.1992.texte" : "Watch our informative episodes to learn more about real estate, multifamily and commercial investment market.",
    "@pmmltv.chaine.2138" : "Office space",
    "@pmmltv.chaine.2138.texte" : "Watch our informative episodes to learn more about real estate, multifamily and commercial investment market.",
    "@pmmltv.chaine.1990" : "Retail",
    "@pmmltv.chaine.1990.texte" : "Watch our informative episodes to learn more about real estate, multifamily and commercial investment market.",
    "@pmmltv.chaine.1988" : "Plex",
    "@pmmltv.chaine.1988.texte" : "Watch our informative episodes to learn more about real estate, multifamily and commercial investment market.",
    "@fichePdf.voir-fiche": "View full listing",
    
    "@pmmltv.chaine.29.texte": "Watch our informative episodes to learn more about real estate, multifamily and commercial investment market.",
    "@pmmltv.chaine.1929.texte": " Watch “behind the scenes” episodes on our brokers day to day professional lives.",
    "@pmmltv.chaine.1923.texte": "Discover new tricks from our chartered appraisers on property value or towards real estate.",
    "@pmmltv.chaine.undefined.texte": "Do you have a major project? Watch our informative investing episodes.",
    "@pmmltv.chaine.null.texte": "Do you have a major project? Watch our informative investing episodes.",
    "@pmmltv.chaine.197.texte": "Consult our informative episodes on the main economic indicators.",
    "@pmmltv.chaine.63.texte": "Check out our informative fundraising episodes and optimize your goals.",
    "@pmmltv.chaine.32.texte": "Do you have a major project? Watch our informative investing episodes.",
    "@pmmltv.chaine.169.texte": "Let yourself be inspired by the journey of its successful investors.",
    "@pmmltv.chaine.151.texte": "Meet successful investors and learn more about their projects.",
    "@pmmltv.chaine.97.texte": "Join us around the table to learn about the progress of several investors.",
    "@pmmltv.chaine.1637.texte": "Watch our episodes on land development",
    "@pmmltv.chaine.1722.texte": "Discover our informative episodes about the latest market insights.",
    "@pmmltv.chaine.mtlMultiFamilyRealEstate.texte": "Learn more about the multifamily real estate investment.",
    "@pmmltv.chaine.1751.texte": "Discover the diverse interviews with the professionals that influence our industry!",
    "@pmmltv.chaine.1817.texte": "Discover our informative episodes on buying or selling plexes.",

    "fiche.confidentiel.text": "Complete listing available following the signing of a confidentiality agreement",
    "@propriete.type": "Property type",
    "@propriete.type.tout": "All properties",
    "@propriete.type.plex": "Plex ",
    "@propriete.type.duplex": "Duplex",
    "@propriete.type.triplex": "Triplex",
    "@propriete.type.quadruplex": "Quadruplex",
    "@propriete.type.quintuplex": "5 to 11 units",
    "@propriete.type.multilogement": "Multifamily",
    "@propriete.type.marchesCapitaux": "Capital market",
    "@propriete.type.multilogtypea": "12 to 24 units",
    "@propriete.type.multilogtypeb": "25 to 49 units",
    "@propriete.type.multilogtypec": "50 or more units + portfolios",
    "@propriete.type.commerceDetail": "Retail",
    "@propriete.type.bureaux": "Offices", 
    "@propriete.type.commercial": "Retail",
    "@propriete.type.semiCommercial": "Semi-commercial",
    "@propriete.type.industriel": "Industrial",
    "@propriete.type.terrains": "Land",
    "@propriete.type.rpa": "Private seniors residence",
    "@propriete.type.portfolio": "Portfolio",
    "@propriete.type.hotel": "Hotels and accommodation",
    "@propriete.type.divertissement": "Entertainment",
    "@propriete.type.medical": "Medical",
    "@propriete.type.recent": "Built 2010 and up",
    "@propriete.type.plex5a11": "Plex 5-11 units",
    "@propriete.type.fondCommerce": "Business goodwill",

    "@region.montrealouest": "Montreal, West of St-Laurent",
    "@region.montrealest": "Montreal East of St-Laurent",
    "@region.lavalrivenord": "Laval / North Shore",
    "@region.rivesudmtl": "South Shore of Montreal",
    "@region.lanaudiere": "Lanaudière",
    "@region.estrie": "Estrie",
    "@region.mauricie": "Mauricie",
    "@region.outaouais": "Outaouais",
    "@region.saguenay": "Saguenay",
    "@region.centreduquebec": "Centre-du-Québec",
    "@region.regiondequebec": "Quebec Region",

    "@modifier.recherche.profil": "Vous pouvez vous inscrire pour recevoir nos dernières nouveautés!",
    "@propriete.non.dispo.critere": "No properties are currently for sale according to your search criteria.",
    "@propriete.non.dispo": "No property available at the moment!",
    "@favoris.non.dispo": "You currently have no favorites or the ones you had have been sold. You can add more favorites ",
    "@favoris.non.dispo.ici": "here.",
    "@moncompte": "My account",

    "Courtier immobilier": "Real estate broker",
    "Directeur Marketing constructions récentes": "Marketing Director for recent constructions",
    "Courtier immobilier agréé": "Chartered Real Estate Broker",
    "Courtier Immobilier Commercial": "Commercial Real Estate Broker",
    "Agent de location": "Rental agent",
    "Courtier Hypothécaire Commercial": "Commercial Mortgage Broker",
    "@menu.equipeCourtier": "Team ",
    "@menu.courtierSeul": "PMML Broker",
    
    "@type.venteLocation": "Sale/rent",
    "@propriete.venteEtLocation": "All",
    "@propriete.vente": "For sale",
    "@propriete.aVendre": "For sale",
    "@propriete.location": "For rent",
    "@propriete.aLouer": "For rent",

    "@zonage": "Zoning",
    

    "@menu.commercial.superficie": "Available space",
    "@menu.terrain.superficie": "Area",
    "@menu.multilogement.unites": "Units",
    "@menu.multilogement.prixdemande": "Asking Price",
    "@fiche.prix.plusTaxes": "+ GST/PST",
    "@fiche.prix.plusTaxesSemi": "+ GST/PST on the commercial portion",
    "@ouvrir.dans.maps": "Open in Google Maps",

    "@descriptif.entreprise": "Company Overview",
    "@revenus.net.fd": "Net Revenue",
    "@contact.sPrenom": "First name",
    "@contact.sNom": "Last name",
    "@contact.sTelephoneSMS": "Mobile",
    "@contact.sCourriel": "Email",
    "@contact.sTelephone1": "Phone",
    "@contact.erreur": "We received an error while submitting your request",
    "@contact.compagnie": "Company name", 
    "@contact.poste": "Postion", 
    "@contact.commentaires": "Comments", 

    "@tresorerie" : "Cash Flow",
    "@paiement.hyp.annuel": "Annual Mortgage Cost",
    "@ROI.prix.demande": "Return on investment at asking price",
    "@revenus.net": "Net Revenues",
    "reserve.mobilier": "Furniture Reserve",

    "@categorie.commerce.0": "Commercial",
    "@categorie.commerce.1": "Office building",
    "@categorie.commerce.5": "Retail business",
    "@categorie.commerce.10": "Retail business",
    "@categorie.commerce.12": "Retail business",
    "@categorie.commerce.14": "Restaurant",
    "@categorie.commerce.15": "Industrial",
    "@categorie.commerce.16": "Industrial",
    "@categorie.commerce.17": "Industrial",
    "@categorie.commerce.25": "Health care facility",
    "@categorie.commerce.30": "Entertainment",
    "@categorie.commerce.35": "Universal building",
    "@categorie.commerce.40": "Warehouse",
    "@categorie.commerce.45": "Multi-vocational",
    "@categorie.commerce.80": "Hotels and accommodation",

    "@contact.sVille": "City",
    "@contact.iFlgLangue": "Language",
    "@contact.iFlgLangue.fr": "French",
    "@contact.iFlgLangue.en": "English",
    "@contact.iConsentement": "I agree to receive PMML’s communications",
    "@contact.regions": "Regions",
    "@contact.typeUnites": "Unit types",
    "@entretien.reparation": "Repairs and maintenance",
    "@contact.associationProfil": "Here are the settings for the account associated with the email :",
    "@contact.listes": "My alerts",
    "@contact.listes.descriptif": "To ensure that your search results are as representative as possible, select your preferences from the distribution lists below.",
    "@nouveautes": "New arrivals",
    "@contact.listes.nouveautes": "New listings - weekly",
    "@contact.listes.pmmlTv": "PMML.TV - weekly",
    "@contact.listes.constructionsNeuves": "New constructions - monthly",
    "@contact.listes.centLogements": "100 units and more - monthly",
    "@contact.listes.desabonnement": "I would like to unsubscribe from all",
    "@contact.listes.desabonnement.desc": "I would like to unsubscribe from all PMML email lists",
    "@contact.listes.utiliserCourrielSecondaire": "Use my secondary email",
    "@contact.listes.autreCourriel": "Other email",

    "@contact.pmmlTv": "PmmlTV",
    "@contact.pmmlTv.descriptif": "Watch our information capsules and get the best advice and market indicators to optimize your investments.",
    "@contact.perspectivesMarche": "Market Insights",
    "@contact.perspectivesMarche.descriptif": "Learn more about the multifamily market through reports and indicators from our professionals. ",
    "@contact.typeProprietes": "Property type",

    "@contact.maj.pmml": "PMML Weekly",
    "@contact.maj.descriptif": "The newsletter to stay informed about market news and success stories in multi-residential and commercial real estate investment: PMML Insights, a glimpse into PMML masterminds and masterclasses, as well as updates for new PMML.tv episodes.",

    "@contact.courtageCommercial.pmml": "Commercial Brokerage professional",
    "@contact.courtageCommercial.descriptif": "Get exclusive information designed for commercial real estate and mortgage brokerage’s professionals, discover the tools developed by PMML made available to the team's brokers, all while staying informed about programs designed to propel the careers of brokerage professionals.",

    "@contact.marcheDesCapitaux.pmml": "PMML Capital markets",
    "@contact.marcheDesCapitaux.descriptif": "",

    "@contact.evenements.pmml": "Events",
    "@contact.evenements.descriptif": "Stay connected and informed about the latest events and networking opportunities. Get a punctual rundown of upcoming events, valuable networking events, and insider insights tailored to enhance your professional network.",

    // "@contact.majindice.pmml": "PMML Index",
    // "@contact.majindice.descriptif": "I would like to receive an alert informing me of the update of the PMML index data.",

    "@contact.nouveautes": "New Listings Alert",
    "@contact.nouveautes.descriptif": "I am looking for an income property or rental space and would like to receive new listings according to my criteria selected below.",

    "@contact.ev.special": "SPECIAL EVENTS (occasionally)",
    "@contact.ev.descriptif": "Be among the first to hear about market and company updates, while also being informed of upcoming events to grow your real estate knowledge.",

    "@adresse.local": "Suite",

    "@connectez-vous": "Log in to add favourites, and access them wherever you are!",

    "@visite.virtuelle" : "Virtual visit",
    "@cartographie": "Cartography",

    "@listing.carte": "Map",
    "@listing.tuiles": "Tiles",
    "@listing.tableau": "Listing",

    "@voir.fiche.complete": "See the complete listing",
    "@voir.fiche.complete.desc": "To see the complete file you need to be connected.",
    "@listing.fiche.complete": "Complete listing",
    "@click.ici": "Click here",

    "@loyers.titre": "Rent roll",
    "@loyers.pourVisionner": "To see the detailled rent rolls of this building, you need to be connected.",
    "@loyers.colUnite": "Unit",
    "@loyers.colLogement": "Logement",
    "@loyers.colType": "Type",
    "@loyers.colMontant": "Amount",
    "@loyers.colEcheance": "Renewal",
    "@loyers.colRemarque": "Notes",
    "@loyers.echeance.annuel": "Yearly",
    "@loyers.echeance.mensuel": "Monthly",
    "@loyers.echeance.vancant": "Vacant",
    "@loyers.totalLoyersRes": "Residential monthly total",
    "@loyers.totalLoyersComm": "Commercial monthly total",
    "@loyers.totalLoyersRang": "Lockers monthly total",
    "@loyers.totalSta": "Parkings monthly total",
    "@loyers.totalAutre": "Others monthly total",
    "@loyers.totalMensuel": "Monthly total",
    "@loyers.totalAnnuel": "Yearly total",
    "@loyers.remarqueUnite": "Remarks for unit : ",

    "@descriptif.unite": "Unit description",

    "@favoris.tooltip.contenu": "Select the star to add this property to your favorites. You will then be able to check this property later, directly through your account.",
    "@ne.plus.afficher.message": "Don't show this message again",
    "@mespreferences": "My preferences",
    "@monprofil": "My profile",
    "@modifierprofil": "Edit my profile",
    "@mesfavoris": "My favorites",

    "@location.price.sqft": "Location ($/SQ. Ft.)",
    "@echeance.baux": "Lease expiration",
    "@annuel": "yearly",
    "@annuels": "yearly",
    '@annuelles': 'yearly',
    "@mois": "Month",
    "@deneigement": "Snow Removal",
    "@contrat.service": "Services Contract",
    "@salaires": "Salaries",
    "@electricite": "Electricity",
    "@frais.admin": "Administration fees",
    "@frais.operations": "Operation fees",
    "@revenus.brut.total": "Total Gross Income",
    "@recup": "Recuperation",
    "@recup.taxes": "Taxes recuperation",
    "@recup.assurances": "Insurances recuperation",
    "@recup.maintenance": "maintenance recuperation",
    "@assurances": "Insurances",
    "@gaz": "Gas",
    "@ascenseur": "Elevator",
    "@evaluation.municipale.total": "Total Municipal Assessment", 
    "@evaluation.municipale.batiment": "Building Municipal Assessment",
    "@evaluation.municipale.terrain": "Land Municipal Assessment",
    "@depenses.non.recup": "Non-recoverable expenses",
    "@reserve.structurel": "Structural Reserve",
    "@frais.gestion": "Management Fees",
    "@depenses.total": "Total Expenses",
    "@depenses.total.annuel": "Annual Expenses",
    "@fd.descriptif2-com.revenus": "Revenues",
    "@loyers.net": "Net rent ($/SQ. Ft.)",
    "@en.savoir.plus": "Read more",
    "@mise.de.fond.necessaire": "CASH DOWN NEEDED",
    "@rendement.sur.liquidite": "Cash on cash return",
    "@rendement.sur.liquidite.capitalisation": "Return on liquidity + capitalization",
    "@loyers.additionnels": "Additionnal rent ($/SQ. Ft.)",
    "@description.terrain": "Land Description",
    "description.appartements": "Description of the apartments",
    "@loyer.additionnel.estime": "Estimated additional rent",
    "@nb.etages.permis": "Number of permitted floors",
    "@superficie.disponible.sqft": "Available area",
    "@multilogement": "Multifamily",
    "@commerce.de.details": "Retails",
    "@semi.commercial": "Semi-Commercial",
    "@industriel": "Industrial",
    "@terrains": "Lands",
    "@rpa": "Private Senior Residence",
    "@header.alouer": "For rent",
    "@header.avendre": "For sale",
    "@header.telephone": "Phone",
    "@header.cellphone": "Cellphone",
    "@header.courriel": "Email",
    "@prix.sqft": "Price / SQ. Ft.",
    "@densite": "Allowable density",
    "@services.publics.disponibles": "Available Public Services",
    "@usages.particuliers": "Special uses",
    "@dispositions.particulieres": "Particular dispositions",
    "@description.propriete": "Property Description",
    "@description.portfolio": "Portfolio Description",
    "@espace.locatif.disponible": "Rental space available",
    "@espace.locatif" : "Rental space",
    "@espaces.locatifs": "RENTAL SPACES",    
    "@description.local": "Rental Space Description",
    "@secteur": "Sector",
    "@pi2": "SQ. Ft.",
    "@map.attraits": "Main attractions of the area",
    "@residentiel": "Residential",
    "@commercial": "Commercial",
    "@descriptif.titre": "Building description",
    "@descriptif.nbunites": "Number of units",
    "@rdc" : "GF",
    "@descriptif.etages": "Floors",
    "@descriptif.nbetages": "Number of floors",
    "@descriptif.structure": "Structure Type",
    "@type.logements": "Building Type",

    "@info.commercial.type": "Building type",
    "@info.faitssaillants": "Highlights",
    "@info.categorie.immeuble": "Building Category",
    "@info.cadastre": "Cadastral number",
    "@ilocal.superficie": "Available area in SQ. Ft.",
    "@info.superficie.louable": "Leasable area in SQ. Ft.",
    "@info.price.sqft": "Price per SQ. Ft.",
    "@info.annee.construction": "Year built",
    "@chauffage": "Heating",
    "cloture": "Fenced",
    "topographie": "Topography",
    "@climatisation": "Air conditioning",
    "bonjour": "Hello,",
    "maj.tout.temps": "You can update your information at any time.",
    "table-matieres": "Table of contents",
    "prepare-pour": "Prepared for",
    "emprunteur": "Borrower",
    "emprunteurs": "Borrower(s)",
    "garant": "Guarantor",
    "garants": "Guarantor(s)",
    "prepare-par": "Prepared by",
    "ans": "Years",
    "actuel": "Current",
    "suite": "Continued",
    
    "@ans": "years",

    "@depenses.type.actuel": "Actual",
    "@depenses.type.schl": "CMHC",
    "@depenses.type.estime": "Estimate",
    "@depenses.type.normalise": "Normalised",

    "@etage": "Stage",
    "@conditions.location": "Location conditions",
    "@terme.bail": "TERM OF LEASE",
    "hy-p": "Documents related to the building",
    "hy-p-index": "Index of buildings",
    "hy-p-role": "Property assessment roll",
    "hy-p-taxes-m": "Municipal taxes",
    "hy-p-taxes-s": "School tax",
    "hy-p-hydro": "Hydro-Quebec",
    "hy-p-gaz-met": "Gaz Metropolitain",
    "hy-p-rentroll": "Summary of residential rents",
    "hy-p-certificat-loc": "Certificate of location",
    "hy-a": "Documents related to the borrower",
    "hy-g": "Documents related to the guarantor",
    "hy-a-bilan": "Balance sheet",
    "hy-a-credit": "Credit report",
    "hy-a-etat-avoir": "Statement of personal net worth",
    "hy-a-programme-schl": "CMHC Program",
    "hy-a-avis-cotisation": "Notice of assessment",
    "hy-a-releve-taxes": "Tax statements",
    "hy-a-etat-loyers": "Statements of real estate rentals",
    "hy-pa": "Promise to purchase and listing",
    "titreSecurite": "Security",
    "@bureaux": "Offices", 
    "@toutes.les.proprietes": "All Types Of Properties",
    "@trouver": "find",
    "@se.connecter": "Login / Signup",
    "titreConditions": "Conditions Precedent",
    "@total.mensuel": "Montly Total",
    "@total.annuel": "Yearly Total",
    "@hauteurlibre": "Free Height",
    "@stationnement": "Parking",
    "@bureau.gestionnaire": "Manager's office",
    "@nettoyage.quotidien": "Daily Cleaning",
    "texteSecurite1": "A first mortgage charge over land and improvements located at the subject in accordance with the Quebec Law;",
    "texteSecurite2": "A general immovable hypothec on all Rents and Leases; ",
    "texteSecurite3": "A first ranking hypothec on the moveables;",
    "texteSecurite4": "Such other security as may be required by the Lender and/or its solicitor.",
    "syselectromec": "Electromecanic System",
    "@superficie.batiment": "Building area",
    "@superficie.totale.brut.sqft": "Total gross area in SQ. Ft.",
    "@superficie.louable.sqft": "Area to rent in SQ. Ft.",
    "@superficie.moyenne.unite.sqft": "Average area per unit in SQ. Ft.",
    "eclairage": "Lights",
    "syssecurite": "Security System", 
    "@evaluation.municipale": "Municipal Assessment",
    "consultation.goplex": "For selling, purchassing or financing plex's, discover Goplex.com,",
    "consultation.goplex2": "a new Plex specialized website powered by PMML",
    "consultation.landev": "For selling, purchassing or financing land, discover Landev.ai,",
    "consultation.landev2": "a new specialized website  powered by PMML",
    "acces.landev": "Access to LANDEV.AI",
    "acces.goplex": "Access to GOPLEX.COM",
    "@terrain": "Land",
    "@batiment": "Building",
    "@evaluationTotal": "Total",
    "@nondisponible": "N/A",
    "@surdemande": "On demand",

    "batisse": "Building",
    "@installationsexistantes": "Existing Facilities",
    "superficie": "Area",
    "superficie.min": "Min. SQ. Ft.",
    "superficie.max": "Max. SQ. Ft.",
    "filtrer.region" : "Filter by region",
    "@acces": "Access",
    "@transports": "Public Transports",
    "@superficie.terrain": "Land size",
    "dimension.terrain": "Land dimension",
    "dimension.cuisine": "Kitchen dimension",
    "dimension.chambres": "Room's dimensions",
    "texteConditions1": "Title must to acceptable to the Lender.",
    "texteConditions2": "Satisfactory Phase I Environmental Report from an approved consultant.",
    "titre": "Building description",
    "titreCaracteristiques": "Features",
    "titreConstruction": "Construction",
    "titreType": "Building type",
    "sSysChauffage": "Heating system",
    "sRespChaufeEau": "Hot water system",
    "sPaneauElectrique": "Electrical panels",
    "sPlomberie": "Plumbing",
    "sEtatCuisines": "Condition of the kitchens",
    "sEtatCB": "Condition of the bathrooms",
    "sPlanchers": "Condition of flooring",
    "sEtudeEnviro": "Environmental study",
    "sAnneeToit": "Condition of roof",
    "sRevetementExt": "Siding",
    "sBalcons": "Condition of balconies",
    "@unites": "Units",
    "sTypePortes": "Condition of doors",
    "sFenetres": "Condition of windows",
    "sRevetStationnement": "Parking surface",
    "@voir.fiche.descriptive": "View listing",
    "@voir.immeuble": "View building",
    "@voir.fiche.visite": "Visit in",
    "sSonette": "Intercom system",
    "sSysIncendie": "Fire alarm system",
    "@nombre.unites": "Number of units",

    "sAutre4": "Janitor agreement",
    "iAnneeConstruction": "Year built",
    "sTypeBatiment": "Building type",
    "sTypeConstruction": "Construction type",
    "iEtages": "Number of floors",
    "sAutre2": "Other information",
    "@informations.generales": "General Information",

    "sRepartition": "Number of units",
    "sStationnements": "Number of parkings",
    "sRespElectricite": "Responsibility for heating",
    "sRespEauChaude": "Responsibility for hot water",
    "sRespElectros": "Responsibility for appliances",
    "sLaveuseSecheuse": "Washer and dryer outlet",
    "sBuanderie": "Laundry room",
    "@buanderie": "Laundry room",
    "sAutre3": "Other information",
    "sRenoMajeur10Ans": "Capital spendings in recent years",
    "titreFinance": "Financial information",
    "titreHistorique": "Historical financial information",
    "titreRevenus": "Revenue",
    "@revDep.proprietaireOccupant": "Owner occupant",
    "@revDep.Investisseur": "Investor",
    "@depenses": "Expenses",
    "titreDepenses": "Expenses",
    "titreIndicateurs": "Indicators",
    "titreFinancement": "Financing",
    "titreTresorerie": "Treasury",
    "titreInformationsSup": "Additional information",
    "titreCourtageImmobilier": "Real Estate Brokerage",
    "titreCourtageHypothecaire": "Mortgage Brokerage",

    "@fd.commercial.infosFoncieres": "Municipal Assessment",

    "taille.appartement": "Area of the apartment",

    "@mesFavoris": "My favorites",
    
    "montant": "Amount",
    "ValeurEcon": "Economic value",
    "ValeurMarchande": "Market value",
    "RPL": "RPU",
    "CPL": "CPU",
    "MRB": "GIM",
    "MRN": "NIM",
    "TGA": "CAP",
    "Conventionnel": "Conventionnel",
    "SCHL": "CMHC",
   
    "@opportunite.signalisation": "Signage opportunity",
    "@prix.stationnement.reserve": "Price reserved parking space",
    "@prix.stationnement.nonreserve": "Price non-reserved parking space",

    "@stationnement.interieur": "Interior Parking",
    "@stationnement.exterieur": "Outdoor Parking",
    "fRevenusRes": "Residential revenues",
    "fRevenusComm": "Commercial revenues",
    "fRevenusStationnement": "Parking revenues",
    "fRevenusBuanderie": "Laundry room revenues",
    "fRevenusLockers": "Storage revenues",
    "fRevenusAutres": "Other revenues",
    "fTotalRevenus": "Total revenue",
    "fVacMauvaiseCHarmo": "Vacancy/Bad debt",
    "fAdministrationHarmo": "Administration",
    "fTaxes": "Property taxes",
    "fTaxesMunicipalesHarmo": "Municipal Taxes",
    "fTaxesScolairesHarmo": "School Taxes",
    "fAssurancesHarmo": "Insurance",
    "fElectriciteHarmo": "Electricity",
    "fChauffageHarmo": "Heating", 
    "fEnergie": "Public services", 
    "fDeneigementHarmo": "Snow removal",
    "fAscenceurHarmo": "Elevator",
    "fGazonHarmo": "Equipment rental",
    "fEntretien": "Repairs and maintenance",
    "fEntretienHarmo": "Maintenance reserve",
    "fConciergeHarmo": "Wages / janitor",
    "freservefElectromenagers": "Furniture reserve",
    "fDepensesAutresHarmo": "Other Expenses",
    "fTotalDepenses": "Total expenses",
    "fRevenuNet": "Net income",
    "@vocation.optimale": "Optimal Vocation",
    "@schl.annuel":"Yearly CMHC",
    "Montant_FinPos": "Loan amount",
    "MiseDeFond": "Down payment",
    "fTGA": "Financing cap. rate",
    "fMRB": "Financing GIM",
    "fRatioRCD": "Debt coverage ratio",
    "fRatioRCDexSCHL": "DCR excl. CMHC premium",
    "fRatioPretPrix": "Loan to price ratio",
    "fRatioPretVE": "Loan to economic value ratio",
    "fTaux": "Interest rate",
    "iAmortissement": "Amortization",
    "iTerme": "Term",
    "PaiementAnnuel": "Annual mortgage payment",
    "LiquiditePret": "Net cash after mortgage",
    "fPrimeSCHL": "CMHC Premium",
    "@annee.renovation": "Year of renovation",
    "titreRepartition": "Breakdown of the appartments",
    "@amenagement.actuel": "Current Development",
    "@rangement.velos": "Bike storage",

    "@aire.reception": "Reception Area",
    "@cuisinette": "Kitchenette",
    "@toilettes": "Washrooms",
    "@internet.telecom": "Internet and telecom",
    "@salle.reunion": "Meeting Room",
    "colNombre": "Number",
    "colMoyenne": "Rents average",
    "colTotalMensuel": "Monthly total",
    "colTotalAnnuel": "Annual total",
    "@financement": "Financing",
    "@conventionnel": "Conventional",
    "@schl": "CMHC",
    "@montant.pret.maximum": "Maximum loan amount",
    "@tga.financement": "Financing TGA",
    "@ratio.dette": "Debt Ratio",
    "@ratio.couverture.dette": "Debt coverage ratio",
    "@taux.interets": "Interest rates",
    "@assumation": "Assumption",
    "colLocataire": "Tenant",

    "simulateur.financement": "Financing simulator",
    "prix.propriete": "Property price",
    "mise.de.fonds.necessaire": "Down payment required",
    "montant.financement.hypo": "Mortgage financing amount",
    "versement.mensuel": "Monthly payment",
    "montants": "Amounts",
    "montant.actuel": "Current amount",
    "montant.ajuste": "Potential amount",
    "pourcen.ajustement": "Adjustments %",

    "@disponibilite": "Availability",
    "@superficie.disponible.contigue": "Contiguous available area",
    "@amortissement": "Amortization",
    "@cout.par.logement": "Cost per unit",
    "@liquidite.mensuel": "Monthly liquidity",

    "@terme": "Term",
    "@taux.global.actualisation": "Capitalization rate",
    "@multiplicateur.revenus.net": "Net revenue multiplicator",
    "@multiplicateur.revenus.brut": "Gross revenue multiplicator",
    "colDebut": "Start",
    "colElectros": "Appliances",
    "@rangement": "Storage",
    "totalResidentiels": "Residential rents monthly total",
    "totalCommerciaux": "Commercial rents monthly total",
    "totalRangements": "Storage rents monthly total",
    "totalStationnements": "Parking rents monthly total",
    "totalAutre": "Other",
    "titreSum": "Summary",
    "titreObjectif": "Purpose",
    "titreUtilisation": "Uses",
    "titrePlanFinancier": "Financing program",
    "titreSource": "Sources",
    "titreUtilisationFonds": "Use of Funds",
    "titreRemboursement": "Repayment",
    "texteObjectif-fin": "To provide a new CMHC first mortgage of {montant} to facilitate the purchase of the subject.",
    "texteObjectif-ref": "To provide a new CMHC first mortgage of {montant} to facilitate the refinance of the subject.",
    "premiereHy": "First Mortgage",
    "miseDeFond": "Down Payment",
    "total": "Total",
    "prixAchat": "Purchase Price",
    "montantRefinancement": "Value",
    "nouvellePremiereHy": "New First Mortgage",
    "RPV": "Loan to Value Ratio",
    "@CPL": "Value per Suite",
    "@cout.occupation": "Occupancy cost",
    "CPC": "Value per Room",
    "GIM": "GIM",
    "terme": "Term",
    "amortissement": "Amortization",
    "NOI": "NOI",
    "serviceDette": "Total Debt Service",
    "RCD": "Debt Service Coverage",
    "texteUtilisationFonds-fin": "To provide a new CMHC first mortgage to facilitate the financing of the subject property.",
    "texteUtilisationFonds-ref": "To provide a new CMHC first mortgage to facilitate the refinance of the subject property.",
    "texteRemboursement": "By monthly installments of capital and interest.",
    "texteTauxInteret": "The interest rate for calculation purposes is {tauxCalc}. Today’s rate is {tauxAct} for {ansTerme} years",
    "repartitionPieces": 'Type of units',

    "donneesLocatif": "Rental market statistics",
    "moyenne": "Average",
    "medianne": "Median",
    "loyerMin": "Min. Rent",
    "loyerMax": "Max. Rent",
    "typeDelogement": "Building type",
    "moyenneRegion": "Average by region",
    "distributionLoyers": "Distribution des loyers",
    "loyerMoyenAnnee": "Distribution des loyers",

    "titre_stats_sociodemo" : "Socio-Demographic Data",
    "population_totale": "Total population",
    "age_moyen" : "Average age of the population",
    "age_proportion" : "Breakdown by age group",
    "population_active" : "Active population",
    "indicateurs_economique" : "Economic indicators",
    "taux_emploi" : "Employment rate",
    "chomage" : "Unemployment rate",
    "revenu_disponible": "Available income per capita",
    "croissance_population": "Population growth",
    "habitants": "Residents",
    "province": "Province",
    "dollar_paran": "$ / year",
    "region_admin": "Region",
    "region_de": "Region of",

    "@carte.style.satellite": "Satellite",
    "@carte.style.rue": "Street",


    "@goplex.footer.disclaimer": "The remarks, descriptions, characteristics and financial projections contained in this document are for information only and should not be considered official. The information contained herein comes from sources we believe to be reliable, but for which we cannot guarantee the accuracy. It is the buyer's responsibility to check everything and to declare themselves satisfied or dissatisfied with it during the due diligence process following an accepted promise to purchase.",
    "@goplex.footer.centrevillemtl": "DOWNTOWN MONTREAL",
    "@goplex.footer.montreal": "MONTREAL",
    "@goplex.footer.quebec": "QUEBEC CITY",
    "@goplex.footer.rivenord": "LAVAL / NORTH SHORE",
    "@goplex.footer.rivesud": "LONGUEUIL / SOUTH SHORE",
    "@goplex.footer.rendezvous": "On appointment only",
    "@goplex.footer.pmmldescriptif": "PMML REAL ESTATE AGENCY AND MORTGAGE BROKERAGE FIRM",
    "@contact.info.header": "Request additional information",
    "@chat.dialog.start": "Submit",
    "@revenues.bubble": "Select the appartment you will to occupy",
    "@connexion": "Please login to use this feature.",
    "@connexion.ok": "Login",
    "@connexion.cancel": "Cancel",

    "@ajoutercontact": "Add to my contacts",
    "@partagercontact": "Share contact",
    "@wpformid": "24268",
    "@wpformid.nouveau": "1752",
    "@proprietescourtier": "My listings",
    "@proprietespmml": "PMML listings",
    "@liencopie": "Link copied!",
    "@qr-instructions": "Point your camera at the QR code to open the contact",
    "@sujetemail": "Contact information for",
    "@copierlien": "Copy this link",
    "@partagerpartext": "Share by text message",
    "@partagerparemail": "Share by email",
    "@partagerparfacebook": "Share with Facebook",
    "@partagerparlinkedin": "Share with LinkedIn",

    "@rapportsmarche": "Market Reports",
    "@evaluationfin": "Receive a financing assessment",
    "@evaluationmarchande": "Receive a market appraisal",
    "@evaluationimmobilier": "Receive a chartered appraisal",

    "@verifier.titre": "Confirm your identity",
    "@verifier.phone.soustitre": "Receive the activation code by SMS to access this page.",
    "@verifier.accept.sms": "I agree to receive communications from PMML by SMS.",
    "@verifier.phone.bouton": "Confirm",
    "@verifier.code.soustitre": "Enter the code sent to your mobile device.",
    "@verifier.code.bouton": "Confirm",
    "@verifier.code.invalid": "Invalid code",
    "@verifier.code.back": "Send me a new code",
    "@verifier.verified.soustitre": "Your phone number has been successfully confirmed.",

    "@rapports.hypothecaire": "Mortgage market",
    "@rapports.marche-capiteaux": "Capital market",
    "@rapports.telecharger": "Download",

    "@rapports.ouvrirLien": 'Your report is ready!',
    "@rapports.ouvrirLienMessage": 'See the report',
    "@rapports.provincial": 'Province of Quebec',

    '@demande-dataroom.activator': 'Request access to dataroom',
    '@demande-dataroom.titre': 'Request a data room',
    '@demande-dataroom.prenom-input': 'First name',
    '@demande-dataroom.nom-input': 'Last name',
    '@demande-dataroom.courriel-input': 'Email',
    '@demande-dataroom.adresse-input': 'Address',
    '@demande-dataroom.phone-input': 'Phone',
    '@demande-dataroom.confirme-code': 'Please confirm your code sent to your phone',
    '@demande-dataroom.annuler': 'Cancel',
    '@demande-dataroom.continuer': 'Continue',
    '@demande-dataroom.accepter': 'Confirm',
    '@demande-dataroom.fermer': 'Close',
    '@demande-dataroom.demande-envoyer1': 'Your request has been sent successfully.',
    '@demande-dataroom.demande-envoyer2': 'You will receive an email as soon as the dataroom is ready.',

    '@demande-dataroom.erreur.nom': "Please enter your last name",
    '@demande-dataroom.erreur.prenom': "Please enter your first name",
    '@demande-dataroom.erreur.courriel': "Please enter a valid email address",
    '@demande-dataroom.erreur.phone': "Please enter a valid phone number",
    '@demande-dataroom.erreur.adresse': "Please enter your address",

    '@demande-dataroom.erreur-titre': 'An error has occurred',
    '@demande-dataroom.erreur-message': 'Visit our contact page to send us a message.',
};