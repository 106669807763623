<div id="inf-sup" class="-cache-overflow container p-0 pt-lg-3 pb-3" [class.container]="!noContainer">
    <div class="row">
        <div class="col-12 col-lg-auto" *ngIf="videoModePortrait && (largeur$|async) > 666">
            <div class="mx-auto">
                <ng-container *ngTemplateOutlet="videoPortraitTemplate"></ng-container>
            </div>
        </div>
        <div class="col w-100 d-flex flex-column">
            <div *ngIf="videoModePortrait && (largeur$|async) > 666" class="row"
                style="background: #343a40; color: white">
                <div class="col p-3 px-4 px-lg-3">
                    <h1>
                        {{data | pmmlProprieteTitre}}
                    </h1>
                    <h2>
                        <!-- Tout sauf iTerrain & iCommercial -->
                        <span class="unitesTotaux"
                            *ngIf="data?.sTypeLogement !== 'iTerrain' && data?.sTypeLogement !== 'iCommercial' && data?.sTypeLogement !== 'iSemiCommercial'">
                            <span *ngIf="data?.IDPropriete == 813299 && data?.iFlyer">700 + </span>
                            <span *ngIf="data?.IDPropriete == 813299 && !data?.iFlyer">{{ data?.iUnitesTotal }}
                            </span>
                            <span *ngIf="data?.IDPropriete !== 813299">{{ data?.iUnitesTotal }} </span>
                            <span
                                *ngIf="data?.sTypeLogement !== 'iHotel' && data?.sTypeLogement !== 'iUnitesChambre'">{{
                                '@menu.multilogement.unites' | translate }}</span>
                            <span *ngIf="data?.sTypeLogement == 'iHotel' || data?.sTypeLogement == 'iUnitesChambre'">{{
                                'chambres' | translate }}</span>
                        </span>

                        <!-- Semi-Commercial -->
                        <span class="unitesTotaux" *ngIf="data?.sTypeLogement == 'iSemiCommercial'">
                            {{ data?.iUnitesTotal }} {{ '@menu.multilogement.unites' | translate }}, {{
                            '@menu.terrain.superficie' | translate }} : {{ data?.iPiedsCarreTotal | number :
                            '1.0-0' }}
                            {{ '@pi2' | translate }}
                        </span>

                        <!-- Location -->
                        <span class="unitesTotaux" *ngIf="data?.sTypeLogement == 'iLocal' && data?.iPiedsCarreTotal">
                            <!-- {{'@menu.commercial.superficie' | translate}} :  -->
                            {{ data?.iPiedsCarreTotal | number : '1.0-0' }} {{ '@pi2' | translate }}
                        </span>

                        <!-- Terrain & Commercial -->
                        <span class="unitesTotaux"
                            *ngIf="(data?.sTypeLogement == 'iTerrain' || data?.sTypeLogement == 'iCommercial') && data?.iPiedsCarreTotal">
                            <!-- {{ '@menu.terrain.superficie' | translate }} : -->
                            {{ data?.iPiedsCarreTotal | number : '1.0-0' }} {{ '@pi2' | translate }}
                        </span>
                    </h2>
                </div>
                <div class="col-auto d-flex justify-content-center align-items-center px-3 pr-4">
                    <a [href]="('/' | translateValeur : '/en/') + 'location/' + data?.sLien  + aParamCode">
                        <i class="fal fa-file-pdf icone fa-lg"></i>
                    </a>
                    &nbsp; &nbsp;
                    <i (click)="ajouterFavoris(data, $event)" [ngClass]="{'fal': !data?.fav, 'fas': data?.fav}"
                        class="fa-lg fa-star cursor-pointer"></i>
                </div>
            </div>
            <div class="row px-0" *ngIf="videoModePortrait && (largeur$|async) <= 666">
                <div class="col">
                    <div class="mx-auto">
                        <ng-container *ngTemplateOutlet="videoPortraitTemplate"></ng-container>
                    </div>
                </div>
            </div>
            <div class="row h-100">
                <div class="col d-flex flex-column">
                    <div class="row align-items-center h-100 pt-3">
                        <div class="col-12 col-lg-4 h-lg-100 d-flex flex-column justify-content-center" *ngIf="!location && (data?.sTypeLogement === 'iLocal' || data?.fPrixDemande > 0 || data?.sJsonData?.fr?.PrixTexte || data?.sTypeLogement === 'iTerrain')">
                            <ng-container>
                                <h3 *ngIf="data?.sTypeLogement === 'iLocal'">{{ '@ilocal.superficie' | translate }}</h3>
                                <h4 *ngIf="data?.sTypeLogement === 'iLocal'">{{ data?.iPiedsCarreTotal | number :
                                    '1.0-0' }}
                                    {{'@pi2' | translate}}</h4>
                            </ng-container>

                            <ng-container>
                                <h3
                                    *ngIf="data?.sTypeLogement !=='iLocal' && ((data?.fPrixDemande != 0 && data?.fPrixDemande != null) && !data?.iMasquerPrix)">
                                    {{ '@menu.multilogement.prixdemande' | translate }}</h3>
                                <h4 class="pb-4 pb-md-4 pb-lg-0"
                                    *ngIf=" data?.sTypeLogement !=='iLocal' && ((data?.fPrixDemande != 0 && data?.fPrixDemande != null) && !data?.iMasquerPrix)">
                                    {{ data?.fPrixDemande | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}}
                                    <span style="font-size: 25px;"
                                        *ngIf="!data.iPlusTaxes && data?.sJsonData?.fr?.PrixPost"><br>
                                        {{data?.sJsonData?.fr?.PrixPost | translateValeur :
                                        data?.sJsonData?.an?.PrixPost}}</span>
                                    <small
                                        *ngIf="data?.sTypeLogement === 'iSemiCommercial'  && (!data?.sJsonData?.fr?.PrixPost || !data?.sJsonData?.an?.PrixPost)">{{'@fiche.prix.plusTaxesSemi'
                                        | translate}}</small>
                                    <small
                                        *ngIf="(data?.sTypeLogement === 'iCommercial' || data?.sTypeLogement == 'iPortfolioComm') && (!data?.sJsonData?.fr?.PrixPost || !data?.sJsonData?.an?.PrixPost)"><br />{{'@fiche.prix.plusTaxes'
                                        | translate}}</small>
                                    <small
                                        *ngIf="data?.sTypeLogement !== 'iSemiCommercial' && data?.sTypeLogement !== 'iCommercial' && data.iPlusTaxes && (!data?.sJsonData?.fr?.PrixPost || !data?.sJsonData?.an?.PrixPost)"><br />{{'@fiche.prix.plusTaxes'
                                        | translate}}</small>
                                </h4>
                            </ng-container>

                            <ng-container>
                                <h3 *ngIf="data?.sTypeLogement !=='iLocal' && data?.sJsonData?.fr?.PrixTexte">
                                    {{ '@menu.multilogement.prixdemande' | translate }}
                                    <br>
                                </h3>
                                <h4 class="pb-4 pb-md-4 pb-lg-0"
                                    *ngIf="data?.sTypeLogement !=='iLocal' && data?.sJsonData?.fr?.PrixTexte">
                                    {{data?.sJsonData?.fr?.PrixTexte | translateValeur : data?.sJsonData.an.PrixTexte}}
                                </h4>
                            </ng-container>

                            <ng-container>
                                <h3 *ngIf="data?.sTypeLogement === 'iTerrain'">{{ '@info.cadastre' | translate }}</h3>
                                <h4 *ngIf="data?.sTypeLogement === 'iTerrain'">{{ data?.sCadastre | translateValeur :
                                    data?.Traduction?.sCadastre }}</h4>
                            </ng-container>

                            <ng-container *ngIf="data?.sTypeLogement === 'iTerrain'">
                                <h3>{{ '@menu.terrain.superficie' | translate }}</h3>
                                <h4 class="superficie m-0 p-0 pb-4 pb-md-4 pb-lg-0">
                                    {{ data?.iPiedsCarreTotal | number: '1.0-0' }}
                                    {{ '@pi2' | translate }}
                                </h4>
                            </ng-container>

                            <div *ngIf="data?.iFlyer == 1 && data?.iFlyerStats == 1" class="mt-4"
                                style="margin: 0 auto;">
                                <app-bloques-stats [data]="data"></app-bloques-stats>
                                <div class="d-block d-md-none mb-5"></div>
                            </div>
                        </div>
                        <div class="col-12 col-lg h-lg-100 px-5 text-center d-flex flex-column justify-content-center">
                            <ng-container
                                *ngIf="data?.sTypeLogement !=='iLocal'">
                                <h3
                                    *ngIf="(data?.sTypeLogement !== 'iLocal' && data?.sTypeLogement !== 'iTerrain' && data?.sTypeLogement !== 'iPortfolio')">
                                    {{ '@description.propriete' | translate }}
                                </h3>
                                <h3 *ngIf="data?.sTypeLogement === 'iLocal'">
                                    {{ '@description.local' | translate }}
                                </h3>
                                <h3 *ngIf="data?.sTypeLogement === 'iTerrain'">
                                    {{ '@description.terrain' | translate }}
                                </h3>
                                <h3 *ngIf="data?.sTypeLogement === 'iPortfolio'">
                                    {{ '@description.portfolio' | translate }}
                                </h3>
                                <p class="pb-4 pb-md-4 pb-lg-0"
                                    [innerHTML]="(data?.sRemarques | translateValeur : data?.Traduction?.sRemarques) | urlifyFromText">
                                </p>
                            </ng-container>

                            <!-- here -->

                            <ng-container *ngIf="data?.sTypeLogement === 'iCommercial' && !location">
                                <h3>{{ '@info.commercial.type' | translate }}</h3>
                                <h4 class="pb-4 pb-4 pb-md-4 pb-lg-0">
                                    {{ data?.sTypeBatiment | translateValeur : data?.Traduction?.sTypeBatiment }}
                                </h4>
                            </ng-container>

                            <ng-container *ngIf="data?.sTypeLogement === 'iCommercial' && !location">
                                <h3>{{ '@info.categorie.immeuble' | translate }}</h3>
                                <h4 class="pb-4 pb-4 pb-md-4 pb-lg-0">
                                    {{ '@categorie.commerce.' + data?.IDCategorie | translate }}
                                </h4>
                            </ng-container>

                            <!-- <ng-container *ngIf="data?.sTypeLogement === 'iTerrain'">
                                <h3>{{ '@menu.terrain.superficie' | translate }}</h3>
                                <h4 class="superficie m-0 p-0 pb-4 pb-md-4 pb-lg-0">
                                    {{ data?.iPiedsCarreTotal | number: '1.0-0' }}
                                    {{ '@pi2' | translate }}
                                </h4>
                            </ng-container> -->

                            <ng-container *ngIf="data?.sTypeLogement === 'iTerrain' && data?.iFlyer == 0">
                                <h3>{{ 'titreInformationsSup' | translate }}</h3>
                                <p class="pb-4 pb-md-4 pb-lg-0">
                                    {{ data?.sAutre5 | translateValeur : data?.Traduction?.sAutre5 }}
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="videoModePortrait" class="row d-none d-lg-flex">
                        <div class="col" style="background: #cecece;">
                            <div class="d-flex justify-content-start p-2">
                                <app-infos-courtier [data]="data"></app-infos-courtier>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-12 text-center mt-3 pt-5 pb-5" *ngIf="data?.iFlyer == 1 && data?.iAbbrege != 1">
            <h3>{{ "fiche.confidentiel.text" | translate }}</h3>
        </div>
    </div>
</div>

<ng-template #videoPortraitTemplate>
    <div class="position-relative d-flex justify-content-center">
        <img *ngIf="!showVideo" (click)="lireVideo()" class="cursor-pointer overlay-content"
            [src]="data?.sVideoCouverture | translateValeur: data?.sVideoCouvertureAn" alt="">

        <div *ngIf="!showVideo" (click)="lireVideo()"
            class="cursor-pointer overlay-content d-flex justify-content-center align-items-center">
            <img width="80px" src="https://pmml.ca/images/shorts-logo.svg" alt="">
        </div>

        <iframe style="width: 100%; aspect-ratio: 9/16;" [src]="embed" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
    </div>
</ng-template>