<div class="descriptif-section pb-5">
    <div class="container w-100">
        <h3 *ngIf="(data?.sTypeLogement !=='iPortfolio')">{{ '@descriptif.titre' | translate }}</h3>
        <h3 *ngIf="(data?.sTypeLogement ==='iPortfolio')">{{ '@description.portfolio' | translate }}</h3>
        <div class="row marge justify-content-center">
            <div class="col-11 col-sm-11 col-lg-6">
                <div class="general">
                    <h4 class="titre-tableau"><i class="fas fa-info-circle"></i> {{ '@informations.generales' | translate }}</h4>
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th>{{ '@info.cadastre' | translate }}</th>
                                <td>{{ data?.sCadastre | translateValeur : data?.Traduction?.sCadastre }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@superficie.terrain' | translate }}</th>
                                <td>{{ data?.fSuperficieTerrain | translateValeur : data?.Traduction?.fSuperficieTerrain  }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="d-sm-block d-lg-none">
                        <table class="table table-striped">
                            <tbody>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ '@info.cadastre' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sCadastre | translateValeur : data?.Traduction?.sCadastre}}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ '@superficie.terrain' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.fSuperficieTerrain | translateValeur : data?.Traduction?.fSuperficieTerrain }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
                <div class="construction">
                    <h4 class="titre-tableau"><i class="fas fa-construction"></i> Construction</h4>
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th>{{ 'iAnneeConstruction' | translate }}</th>
                                <td>{{ data?.iAnneeConstruction | translateValeur : data?.Traduction?.iAnneeConstruction }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sTypeBatiment' | translate }}</th>
                                <td>{{ data?.sTypeBatiment | translateValeur : data?.Traduction?.sTypeBatiment }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sTypeConstruction' | translate }}</th>
                                <td>{{ data?.sTypeConstruction | translateValeur : data?.Traduction?.sTypeConstruction }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'iEtages' | translate }}</th>
                                <td>{{ data?.iEtages | translateValeur : data?.Traduction?.iEtages }}</td>
                            </tr>
                            <tr>
                                <th><i class="fas fa-question-circle"></i> {{ 'sAutre2' | translate }}</th>
                                <td>{{ data?.sAutre2 | translateValeur : data?.Traduction?.sAutre2 }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="d-sm-block d-lg-none">
                        <table class="table table-striped">
                            <tbody>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'iAnneeConstruction' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.iAnneeConstruction | translateValeur : data?.Traduction?.iAnneConstruction }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sTypeBatiment' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sTypeBatiment | translateValeur : data?.Traduction?.sTypeBatiment }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sTypeConstruction' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sTypeConstruction | translateValeur : data?.Traduction?.sTypeConstruction }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sAutre2' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sAutre | translateValeur : data?.Traduction?.sAutre }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
                <div class="type-immeuble">
                    <h4 class="titre-tableau"><i class="fas fa-building"></i> {{ '@info.commercial.type' | translate }}</h4>
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th>{{'sRepartition' | translate }}</th>
                                <td>{{ data?.sRepartition | translateValeur : data?.Traduction?.sRepartition }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sStationnements' | translate }}</th>
                                <td>{{ data?.sStationnements | translateValeur : data?.Traduction?.sStationnements }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRespElectricite' | translate }}</th>
                                <td>{{ data?.sRespElectricite | translateValeur : data?.Traduction?.sRespElectricite }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRespEauChaude' | translate }}</th>
                                <td>{{ data?.sRespEauChaude | translateValeur : data?.Traduction?.sRespEauChaude }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRespElectros' | translate }}</th>
                                <td>{{ data?.sRespElectros | translateValeur : data?.Traduction?.sRespElectros }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sLaveuseSecheuse' | translate }}</th>
                                <td>{{ data?.sLaveuseSecheuse | translateValeur : data?.Traduction?.sLaveuseSecheuse }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sBuanderie' | translate }}</th>
                                <td>{{ data?.sBuanderie | translateValeur : data?.Traduction?.sBuanderie }}</td>
                            </tr>
                            <tr>
                                <th><i class="fas fa-question-circle"></i> {{ 'sAutre3' | translate }}</th>
                                <td>{{ data?.sAutre3  | translateValeur : data?.Traduction?.sAutre3 }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="d-sm-block d-lg-none">
                        <table class="table table-striped">
                            <tbody>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sRepartition' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sRepartition | translateValeur : data?.Traduction?.sRepartition }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sStationnements' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sStationnements | translateValeur : data?.Traduction?.sStationnements }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sRespElectricite' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sRespElectricite | translateValeur : data?.Traduction?.sRespElectricite }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sRespEauChaude' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sRespEauChaude | translateValeur : data?.Traduction?.sRespEauChaude }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sRespElectros' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sRespElectros | translateValeur : data?.Traduction?.sRespElectros }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sLaveuseSecheuse' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sLaveuseSecheuse | translateValeur : data?.Traduction?.sLaveuseSecheuse }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sAutre3' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sAutre3 | translateValeur : data?.Traduction?.sAutre3 }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
            </div>

            <div class="col-11 col-sm-11 col-lg-6">
                <div class="evaluation-municipale ">
                    <h4 class="titre-tableau"><i class="fas fa-usd-circle"></i> {{ '@evaluation.municipale' | translate }}</h4>
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th>{{ '@evaluation.municipale.terrain' | translate }}</th>
                                <td>{{ data?.sEvaluationMunicipaleTerrain  }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@evaluation.municipale.batiment' | translate }}</th>
                                <td>{{ data?.sEvaluationMunicipaleBatiment }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@evaluation.municipale.total' | translate }}</th>
                                <td>{{ data?.sEvaluationMunicipale }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="d-sm-block d-lg-none">
                        <table class="table table-striped">
                            <tbody>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ '@evaluation.municipale.terrain' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sEvluationMunicipaleTerrain }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ '@evaluation.municipale.batiment' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sEvaluationMunicipaleBatiment }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ '@evaluation.municipale.total' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sEvaluationMunicipale }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
            <div class="caracteristiques">
                <h4 class="titre-tableau"><i class="fas fa-file-contract"></i> {{ 'titreCaracteristiques' | translate }}</h4>
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th>{{ 'sSysChauffage' | translate }}</th>
                                <td>{{ data?.sSysChauffage | translateValeur : data?.Traduction?.sSysChauffage }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRespChaufeEau' | translate }}</th>
                                <td>{{ data?.sRespChaufeEau | translateValeur : data?.Traduction?.sRespChaufeEau }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sPaneauElectrique' | translate }}</th>
                                <td>{{ data?.sPaneauElectrique | translateValeur : data?.Traduction?.sPaneauElectrique }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sPlomberie' | translate }}</th>
                                <td>{{ data?.sPlomberie | translateValeur : data?.Traduction?.sPlomberie }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sEtatCuisines' | translate }}</th>
                                <td>{{ data?.sEtatCuisines | translateValeur : data?.Traduction?.sEtatCuisines }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sEtatCB' | translate }}</th>
                                <td>{{ data?.sEtatCB | translateValeur : data?.Traduction?.sEtatCB }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sPlanchers' | translate }}</th>
                                <td>{{ data?.sPlanchers | translateValeur : data?.Traduction?.sPlanchers }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sEtudeEnviro' | translate }}</th>
                                <td>{{ data?.sEtudeEnviro | translateValeur : data?.Traduction?.sEtudeEnviro }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sAnneeToit' | translate }}</th>
                                <td>{{ data?.sAnneeToit | translateValeur: data.Traduction.sAnneeToit}}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRevetementExt' | translate }}</th>
                                <td>{{ data?.sRevetementExt | translateValeur : data?.Traduction?.sRevetementExt }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sBalcons' | translate }}</th>
                                <td>{{ data?.sBalcons | translateValeur : data?.Traduction?.sBalcons }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sTypePortes' | translate }}</th>
                                <td>{{ data?.sTypePortes | translateValeur : data?.Traduction?.sTypePortes }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sFenetres' | translate }}</th>
                                <td>{{ data?.sFenetres | translateValeur : data?.Traduction?.sFenetres }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sRevetStationnement' | translate }}</th>
                                <td>{{ data?.sRevetStationnement | translateValeur : data?.Traduction?.sRevetStationnement }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sSonette' | translate }}</th>
                                <td>{{ data?.sSonette | translateValeur : data?.Traduction?.sSonette }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sSysIncendie' | translate }}</th>
                                <td>{{ data?.sSysIncendie | translateValeur : data?.Traduction?.sSysIncendie }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sAutre4' | translate }}</th>
                                <td>{{ data?.sAutre4 | translateValeur : data?.Traduction?.sAutre4 }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="d-sm-block d-lg-none">
                        <table class="table table-striped">
                            <tbody>
                                <tr class="m-tr-titre">
                                <td class="m-td-titre">{{ 'sSysChauffage' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sSysChauffage | translateValeur : data?.Traduction?.sSysChauffage }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                <td class="m-td-titre">{{ 'sRespChaufeEau' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sRespChaufeEau | translateValeur : data?.Traduction?.sRespChaufeEau }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sPaneauElectrique' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sPaneauElectrique | translateValeur : data?.Traduction?.sPaneauElectrique }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sPlomberie' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sPlomberie | translateValeur : data?.Traduction?.sPlomberie }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sEtatCuisines' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sEtatCuisines | translateValeur : data?.Traduction?.sEtatCuisines }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sEtatCB' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sEtatCB | translateValeur : data?.Traduction?.sEtatCB }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sPlanchers' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sPlanchers | translateValeur : data?.Traduction?.sPlanchers }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sEtudeEnviro' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sEtudeEnviro | translateValeur : data?.Traduction?.sEtudeEnviro }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sAnneeToit' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sAnneeToit }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sRevetementExt' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sRevetementExt | translateValeur : data?.Traduction?.sRevetementExt }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sBalcons' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sBalcons | translateValeur : data?.Traduction?.sBalcons }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sTypePortes' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sTypePortes | translateValeur : data?.Traduction?.sTypesPortes }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sFenetres' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sFenetres | translateValeur : data?.Traduction?.sFenetres }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sRevetStationnement' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sRevetStationnement | translateValeur : data?.Traduction?.sRevetStationnement }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sSonette' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sSonette | translateValeur : data?.Traduction?.sSonette }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sSysIncendie' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sSysIncendie | translateValeur : data?.Traduction?.sSysIncendie }}</td>
                                </tr>
                                <tr class="m-tr-titre">
                                    <td class="m-td-titre">{{ 'sAutre4' | translate }}</td>
                                </tr>
                                <tr class="m-tr-valeur">
                                    <td class="m-td-valeur">{{ data?.sAutre4 | translateValeur : data?.Traduction?.sAutre4 }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
