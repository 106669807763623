<div class="container-fluid">
    <!-- <div class="row justify-content-end fermer">
        <div class="col-auto" (click)="fermer()">
            <i class="fas fa-times"></i>
        </div>
    </div> -->
    <img [src]="src" alt="">
    <div class="fermer" (click)="fermer()">
        <i class="fas fa-times"></i>
    </div>
    <div class="precedente">
        <i class="fas fa-chevron-left" (click)="precedente()"></i>
    </div>
    <div class="suivante">
        <i class="fas fa-chevron-right" (click)="suivante()"></i>
    </div>
    <!-- <div class="switch row align-items-center" *ngIf="affichageListe">
        <div class="col-auto">
            <i class="fas fa-chevron-left" (click)="precedente()"></i>
        </div>
        <div class="col"></div>
        <div class="col-auto">
            <i class="fas fa-chevron-right" (click)="suivante()"></i>
        </div>
    </div> -->
</div>