import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-portfolio-descriptif',
  templateUrl: './portfolio-descriptif.component.html',
  styleUrls: ['./portfolio-descriptif.component.scss']
})
export class PortfolioDescriptifComponent implements OnInit {

  constructor() { }

  @Input() data: any;

  ngOnInit(): void {
  }

}
