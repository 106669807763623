<div class="bootstrapImportation">
  <app-menu [nouveauLiens]="!!nouveauLiens" id="menu" [data]="detail" [ficheLocaux]="ficheLocaux"
    [afficherFavoris]="(outOfView$|async)"></app-menu>
  <div #conteneurDetails>
    <!-- <app-menu-deux [data]="detail"></app-menu-deux> -->
    <div class="container pt-5 mt-5" *ngIf="detail?.IDPropriete == null">
      <div class="row justify-content-center">
        <div class="spinner-border" style="width: 3rem;height: 3rem;" role="status">
          <span class="sr-only">{{ '@general.loading' | translate }}</span>
        </div>
      </div>
    </div>

    <div style="display:none; margin-right: 0px !important; margin-left: 0px !important;"
      [ngClass]="{'formulaireConfidentialite' : affichageFormulaire}"></div>

    <app-header
      *ngIf="detail?.IDPropriete && !bGoplex && !bListingPDF && (detail?.iVideoPortrait != '1' || bGroupeLocation)"
      [data]="detail" [bGroupeLocation]="bGroupeLocation"></app-header>

    <!-- <section class="container d-flex justify-content-center" *ngIf="!environment.masqueDataroom">
      <button class="btn btn-black btn-lg" (click)="afficheDataroom()">
        <i class="fas fa-door-open"></i> {{'@demande-dataroom.activator' | translate}}
      </button>
    </section> -->

    <app-listing-pdf *ngIf="bListingPDF" [location]="bGroupeLocation" [data]="detail"></app-listing-pdf>
    <app-multilogement *ngIf="bMultilogement" [data]="detail"></app-multilogement>
    <app-terrain *ngIf="bLand" [data]="detail"></app-terrain>
    <app-bureau-vendre *ngIf="bBureauVente" [data]="detail"></app-bureau-vendre>
    <app-commercial *ngIf="bCommercialVente" [data]="detail"></app-commercial>
    <app-commercial-location *ngIf="bCommercialLocation" [data]="detail"></app-commercial-location>
    <app-groupe-location *ngIf="bGroupeLocation" [data]="detail"></app-groupe-location>
    <app-portfolio *ngIf="bPortfolio" [data]="detail"></app-portfolio>
    <app-portfolio-commercial *ngIf="bPortfolioComm" [data]="detail"></app-portfolio-commercial>
    <app-multi-go-plex *ngIf="bGoplex" [data]="detail" [menuOffsetHaut]="offsetHaut"></app-multi-go-plex>

    <!-- PIED -->
    <app-pied *ngIf="!detail?.iFlyer"></app-pied>
  </div>
</div>