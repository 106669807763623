import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from "../../../auth.service";

@Component({
  selector: 'app-connexion-favoris-dialog',
  templateUrl: './connexion-favoris-dialog.component.html',
  styleUrls: ['./connexion-favoris-dialog.component.scss']
})
export class ConnexionFavorisDialogComponent implements OnInit {

  private readonly matDialogRef: MatDialogRef<ConnexionFavorisDialogComponent>;
  private readonly triggerElementRef: ElementRef;
  constructor(
    private auth: AuthService,
    matDialogRef: MatDialogRef<ConnexionFavorisDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { trigger: ElementRef, type }
  ) {
    this.matDialogRef = matDialogRef;
    this.triggerElementRef = data.trigger;
  }

  dialogWidth: number = 300;
  dialogHeight: number = 180;

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    if (this.triggerElementRef?.nativeElement != "") { 
      const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
      if (this.data.type == "menu") {
        matDialogConfig.position = { left: `${rect.left - this.dialogWidth + 332}px`, top: `${rect.bottom}px` };
      } else {
        matDialogConfig.position = { left: `${rect.left - this.dialogWidth + 40}px`, top: `${rect.bottom}px` };
      }
    }
    matDialogConfig.width = `${this.dialogWidth}px`;
    matDialogConfig.height = `${this.dialogHeight}px`;
    this.matDialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
    this.matDialogRef.updatePosition(matDialogConfig.position);
  }

  cliqueAnnuler(): void {
    this.matDialogRef.close();
  }

  cliqueConnexion(): void {
    this.auth.login(window.location.href);
  }
}
