import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'nl2li'
})
export class Nl2liPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
  ) {}
  
  transform(value: unknown, type?: unknown): unknown {
    if(!value) return null;
    let html: any;
    
    //  Dépenses en capital importantes depuis 10 ans
    if (type == "Reno") {
      html = `
      ${String(value).trim().split(/\n/gim).map((ligne, index) => {
        if (String(ligne || '').trim() == '') return '</br>';
        if (index == 0 || index == 1 || index == 2 || index == 3 || index == 4 || index == 5 || index == 6 ) return `<li>${ligne}</li>`}).join(' ')}`;
    } else {
      html = `
      ${String(value).trim().split(/\n/gim).map((ligne, index) => {
        if (String(ligne || '').trim() == '') return '</br>';
        if (index == 0 || index == 1 || index == 2 || index == 3) return `<li>${ligne}</li>`}).join(' ')}`;
    }
    
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
