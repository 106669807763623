<span *ngIf="environment?.modeTeasing && !connected">
    <app-inf-sup [data]="data"></app-inf-sup>
    <app-informations [data]="data"></app-informations>
    <div class="se-connecter text-center pt-5" *ngIf="!connected">
        <h3>{{ "connection-afficher-fiche" | translate }}</h3>
        <button class="connect" *ngIf="!connected" (click)="seConnecter()">{{'@se.connecter' | translate}}</button>
    </div>
</span>

<span *ngIf="!environment?.modeTeasing || connected">
    <app-inf-sup [data]="data"></app-inf-sup>
    <app-informations [data]="data"></app-informations>
    <div class="container">
        <app-map [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-map> 
    </div>
    <app-thumbnails [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-thumbnails>
    <app-descriptif [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-descriptif> 
    <app-info-fin-multi [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix && !data.bVacant"></app-info-fin-multi>
    <app-stats-regional [data]="data" *ngIf="data?.StatsSocioDemoRegional"></app-stats-regional> <!-- *ngIf="data.IDRegionAdmin" -->
    <div *ngIf="data.iAbbrege == 1 && data?.sJsonData?.fr?.TexteContact" style="justify-content: center; align-items: center; display: flex; width: 100vw; padding: 4% 10%; text-align: center;">
        {{data?.sJsonData?.fr?.TexteContact}}
    </div>
    <app-sommaires [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-sommaires>
    <app-photos [data]="data" *ngIf="!data.iFlyer && !data.iMasquerPrix"></app-photos>
    <!-- <app-pied *ngIf="!data?.iFlyer"></app-pied> -->
</span>
