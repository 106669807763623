import { Component, Input, NgZone, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Map as MapboxMap } from "mapbox-gl";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  from,
  fromEvent,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap
} from "rxjs";
import { GeneralService } from "src/app/api/general.service";
import { ProprietesService } from "src/app/api/proprietes.service";
import { environment } from "src/environments/environment";

const MODES = [
  { icon: "fas fa-th-large", value: "tuiles", label: "@listing.tuiles" },
  { icon: "fal fa-map-marked-alt", value: "carte", label: "@listing.carte" },
  { icon: "fas fa-bars", value: "liste", label: "@listing.tableau" },
];

const TYPES_IMMEUBLES = environment.typeProprietes;
const TYPES_LISTING = [
  { value: "0", label: "@propriete.vente" },
  { value: "1", label: "@propriete.location" },
];

const NOMBRE_UNITES = [
  // { value: "tout", label: "@listeProprietes.typeUnite.tout" },
  { value: "2-2", label: "@propriete.type.duplex" },
  { value: "3-3", label: "@propriete.type.triplex" },
  { value: "4-4", label: "@propriete.type.quadruplex" },
  { value: "5-11", label: "5 - 11", fin: "@unites" },
  { value: "12-24", label: "12 - 24", fin: "@unites" },
  { value: "25-49", label: "25 - 49", fin: "@unites" },
  { value: "50-", label: "50", fin: "@unites" },
];

const afficheNombreUnitesTypes = ["", "iMultilogement", "iGoplex"];

@Component({
  selector: "app-proprietes",
  templateUrl: "./proprietes.component.html",
  styleUrls: ["./proprietes.component.scss"],
})
export class ProprietesComponent implements OnInit {
  @Input() barreRecherche: "1" | "0" = "1";
  @Input() typeAffichage?: string;
  @Input() venteLocation?: string;
  @Input() marchesCapitaux?: string;

  @Input() courtier?: string;
  @Input() tuilesSeulement?: boolean = false;

  queryParams: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: any) => {
      return searchParams.get(prop);
    },
  });

  displayFn = (region) => (typeof region == 'string' ? region : region.sNomSecteur);
  trackByFn = (index, item) => item.IDPropriete;
  constructor(
    private general: GeneralService,
    private proprietes: ProprietesService,
    private zone: NgZone
  ) {}

  MODES = MODES;
  TYPES_IMMEUBLES = TYPES_IMMEUBLES;
  TYPES_LISTING = TYPES_LISTING;
  NOMBRE_UNITES = NOMBRE_UNITES;
  UNITES_TYPES = afficheNombreUnitesTypes;

  displayMarkersAtZoom = 13;
  mobileFiltres = false;
  nonClusteredFeatures = {};

  getTypeSelonURL() {
    // prettier-ignore
    const pathnameToTypeImmeuble = new Map([
      ["/proprietes/multi-logements/",         "iMultilogement"],
      ["/proprietes/plex/",                    "iPlex"],
      ["/proprietes/semi-commercial/",         "iSemiCommercial"],
      ["/proprietes/commerce-details/",        "iCommercial"],
      ["/proprietes/bureaux/",                 "iBureaux"],
      ["/proprietes/construction-recente/",    "iRecent"],
      ["/proprietes/residences-aines/",        "iRPA"],
      ["/proprietes/industriel/",              "iIndustriel"],
      ["/proprietes/divertissement/",          "iDivertissement"],
      ["/proprietes/portfolio/",               "iPortfolio"],
      ["/proprietes/hotel-hebergement/",       "iHotel"],
      ["/proprietes/immeuble-vocation-sante/", "iMedical"],
      ["/terrain/",                            "iTerrain"],
      ["/nouveautes/",                         "nouveaute"],
    ]);

    const typeImmeuble = pathnameToTypeImmeuble.get(window.location.pathname);

    return typeImmeuble || "";
  }

  state = new FormGroup({
    mode: new FormControl(this.queryParams["mode"] || "tuiles"),
    courtierMode: new FormControl("courtier"), // courtier ou pmml

    typeImmeuble: new FormControl(
      this.queryParams["typeImmeuble"] || this.getTypeSelonURL()
    ),
    typeListing: new FormControl(this.queryParams["typeListing"] || ""), // Vente ou location
    region: new FormControl(this.queryParams["region"] || ""),

    // Industriel
    superficieMin: new FormControl(this.queryParams["superficieMin"] || ""),
    superficieMax: new FormControl(this.queryParams["superficieMax"] || ""),

    // Résidentiel
    nombreUnites: new FormControl(this.queryParams["nombreUnites"] || ""),

    favoris: new FormControl(false),
  });

  filtres$ = this.state.valueChanges.pipe(
    startWith(this.state.value),
    map((filtres) => {
      if (this.typeAffichage === "nouveautes") filtres.nouveaute = 1;

      return filtres;
    }),
    debounceTime(500),
    shareReplay(1)
  );

  regionsAutocomplete$ = combineLatest([
    from(this.general.listeRegions()).pipe(shareReplay(1)),
    this.state.get("region").valueChanges,
    from(this.state.get("region").value).pipe(take(1)),
  ]).pipe(
    map(([regions, query, regionInitial]) => {
      console.log(regionInitial);
      
      if (typeof query !== "string") return regions;
      const normalize = (str) => {
        return str.toLowerCase().replace(/é/g, "e").replace(/è/g, "e");
      };

      const regionsFiltre = regions.filter((option) => {
        const normalized = normalize(option.sNomSecteur);
        return normalized.includes(normalize(query));
      });

      return regionsFiltre;
    })
  );

  zoom$ = new BehaviorSubject(7);
  map$ = new BehaviorSubject(null);
  _bounds$ = new BehaviorSubject(<any>{});
  bounds$ = this._bounds$.pipe(
    map((bounds) => {
      if (!bounds._sw || !bounds._ne) return {};
      return {
        _swLng: bounds._sw.lng,
        _neLng: bounds._ne.lng,
        _swLat: bounds._sw.lat,
        _neLat: bounds._ne.lat,
      };
    }),
    debounceTime(500)
  );

  loadingListings = true;
  listings$ = combineLatest([this.filtres$, this.bounds$]).pipe(
    tap(([filtres, _bounds]) => {
      this.loadingListings = true;
      const params = new URLSearchParams();

      for (const clef in filtres) {
        if (filtres[clef]) params.set(clef, filtres[clef]);
      }

      history.replaceState({}, "", "?" + params.toString());
    }),
    map(([filtres, _bounds]) => {
      const [unitesMin, unitesMax] = filtres.nombreUnites
        .split("-")
        .map(Number);

      const envoieUnites = this.UNITES_TYPES.includes(filtres.typeImmeuble);
      const envoieSuperficie = !envoieUnites;

      const estGoplex = unitesMin === unitesMax && unitesMax <= 4;
      let q = filtres?.region?.sNomSecteur || "";
      if(typeof filtres.region == 'string') q = filtres.region;

      const bounds = q ? {} : _bounds;

      return {
        favoris: filtres.favoris ? 1 : undefined,
        nouveaute: filtres.nouveaute ? 1 : undefined,
        sTypeLogement: estGoplex ? "iGoplex" : filtres.typeImmeuble,
        venteLocation: this.venteLocation == "1" ? "1" : filtres.typeListing,
        marchesCapitaux: this.marchesCapitaux == "1" ? "1" : "",

        iUnitesMin: envoieUnites ? unitesMin : "",
        iUnitesMax: envoieUnites ? unitesMax : "",

        q,

        superficieMin: envoieSuperficie ? filtres.superficieMin : "",
        superficieMax: envoieSuperficie ? filtres.superficieMax : "",

        courtier:
          this.courtier && this.state.get("courtierMode").value === "courtier"
            ? this.courtier
            : "",

        // Selon carte
        ...bounds,
      };
    }),
    map((filters) => {
      for (const key in filters) {
        if (filters[key] === "" || filters[key] === undefined)
          delete filters[key];
      }

      return filters;
    }),
    switchMap((filtres) => this.proprietes.liste(filtres, true)),
    tap(() => {
      this.loadingListings = false;
    }),
    shareReplay(1)
  );

  listingGeojson$ = this.listings$.pipe(
    map((listings: any[]) => {
      console.count("listingGeojson$.map");
      const listingToGeopoint = (listing) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [listing.dPosLong, listing.dPosLat],
          },
          properties: {
            ...listing,
          },
        };
      };

      return {
        type: "FeatureCollection",
        features: listings.map(listingToGeopoint),
      };
    }),
    tap((data) => {
      try {
        const map = this.map$.value;
        map.getSource("listings").setData(data);
      } catch (e) {}
    }),
    shareReplay(1)
  );

  getModeAffichage(listing) {
    const besoinUnites = ["iMultilogement", "iRPA", "iPlex", "iGoplex"];
    if (besoinUnites.includes(listing?.sTypeLogement || "iMultilogement"))
      return "unites";

    return "pieds";
    // if (propriete.iPiedsCarreTotal) return "pieds";

    // return "unites";
  }

  ngOnInit(): void {
    if (this.tuilesSeulement) {
      this.state.get("mode").setValue("tuiles");
    }

    if (this.courtier) {
      fromEvent(window, "resize")
        .pipe(
          map(() => window.innerHeight),
          startWith(window.innerHeight),
          distinctUntilChanged(),
          debounceTime(10)
        )
        .subscribe((height) => {
          const tuileWrapper = document.getElementById(
            "tuiles-wrapper"
          ) as HTMLDivElement;

          if (!tuileWrapper) return;

          const rect = tuileWrapper.getBoundingClientRect();

          const maxHeight = height - rect.top;

          // tuileWrapper.style.maxHeight = maxHeight + "px";
          // tuileWrapper.style.overflow = 'auto'
        });
    }
  }

  async mapLoad(map: MapboxMap) {
    map.on("zoom", () => this.zone.run(() => this.zoom$.next(map.getZoom())));
    map.on("moveend", () =>
      this.zone.run(() => this._bounds$.next(map.getBounds() as any))
    );

    const listingGeojson = await new Promise<any>((resolve) => {
      return this.listingGeojson$.pipe(take(1)).subscribe((data) => {
        resolve(data);
      });
    });

    // Data
    map.addSource("listings", {
      type: "geojson",
      data: listingGeojson,
      cluster: true,
      clusterRadius: 50,
      clusterMaxZoom: this.displayMarkersAtZoom,
    });
    // Clusters
    map.addLayer({
      id: "clusters",
      type: "circle",
      source: "listings",
      filter: ["has", "point_count"],
      maxzoom: this.displayMarkersAtZoom,
      paint: {
        "circle-color": environment.couleurPrimaire,
        "circle-radius": 20,
      },
    });
    // Cluster count
    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "listings",
      filter: ["has", "point_count"],
      maxzoom: this.displayMarkersAtZoom,
      layout: {
        "text-field": ["get", "point_count_abbreviated"],
        "text-font": ["Arial Unicode MS Bold"],
        "text-size": 12,
      },
      paint: {
        "text-color": "#fff",
      },
    });

    map.on("render", () => {
      const features = map.querySourceFeatures("listings");
      const nonClusteredFeatures = {};

      for (const feature of features) {
        const listing = feature.properties as any;

        if (listing.cluster) continue;

        nonClusteredFeatures[listing.IDPropriete] = true;
      }

      this.zone.run(() => (this.nonClusteredFeatures = nonClusteredFeatures));
    });

    map.on("click", "clusters", (e) => {
      map.easeTo({
        // @ts-expect-error
        center: e.features[0].geometry.coordinates,
        zoom: map.getZoom() + 2,
      });
    });

    this.map$.next(map);
  }

  ajouterFavoris(propriete, event) {
    this.proprietes.setFavori(propriete.sLien, !propriete.fav);

    propriete.fav = !propriete.fav;
    event.stopPropagation();
    event.preventDefault();
  }

  toggleMobileFiltres() {
    this.mobileFiltres = !this.mobileFiltres;
  }
}
