<div class="container pt-3 pt-sm-2 pt-lg-0 pleine-largeur-video" *ngIf="!data?.iFlyer && !bGroupeLocation">
    <div class="video pt-2" *ngIf="data?.sVideoYoutubeFr">
        <div id="video-container" class="videoInner">
            <div [ngClass]="{'visible-thumbnail': !showVideo, 'cache': showVideo}" (click)="lireVideo()">
                <img [src]="data?.sImagePrincipale | resizePhotoPrincipale" style="background-size: cover;">
                <img src="https://pmml.ca/wp-content/uploads/2020/09/youtube_play_overlay.png" class="play-overlay">
                <a [href]="('https://www.youtube.com/watch?v=' + (data?.sVideoYoutubeFr | translateValeur : data?.sVideoYoutubeAn))">
                    <span>{{ data | pmmlProprieteTitre }}</span>
                </a>
            </div>
            <div class="videoInner" [ngClass]="{'visible-video': showVideo, 'cache': !showVideo}">
                <div id="video-iframe" frameborder="0" color="white" enablejsapi="1" style="width: 100% !important; height: 100% !important; left: 0; top: 0; position: absolute;"></div>
            </div>
        </div>
    </div>
</div>
<div id="containerImage" class="container" *ngIf="!data?.sVideoYoutubeFr || (data.IDPropriete == 813299 && data?.iFlyer) || bGroupeLocation">
    <img class="pt-1 imagePrincipal" [src]="data?.sImagePrincipale | resizePhotoPrincipale" style="width: 100%;">
</div>