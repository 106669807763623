<!-- TODO: LYES - Renommer ce composant -->
<!-- ORDINATEUR -->
<div class="container immeubleCourtierBox blocCourtier afficherOrdinateur">
    <div class="row justify-content-center courtier-designation" [ngClass]="{compacte: compacte}">
        <!-- COURTIER -->
        <div class="col-auto photo">
            <img [src]="data?.sCourtierPhoto" class="img-fluid round" alt="">
        </div>
        <div class="col-12" [hidden]="compacte"></div>
        <div class="col-auto">
            
            <div class="nom">{{ data?.sCourtierNom }}</div>
            <div class="titre">{{ data?.sCourtierTitre | translate }}</div>
            <div class="equipe" *ngIf="true">Équipe {{ data?.sCourtierNom }}</div>
        </div>
        <div class="col-12" [hidden]="compacte"></div>
        <div class="col-auto pt-4" [hidden]="compacte">
            <div class="d-sm-none d-lg-block">
                <a class="coordonneesCourtier" [href]="'tel:' + (data?.sCourtierTel || '514 360-3603')">
                    <i class="fas fa-phone icone"></i>
                    {{ data?.sCourtierTel || '514 360-3603' }} 
                </a>
            </div>
        </div>
        <div class="col-12"></div>
        <div class="col-auto btn-contact pt-4">
            <button mat-flat-button color="primary">
                <i class="fas fa-envelope icone"></i>
                {{ 'Conatcter' | translate }}
            </button>
        </div>
    </div>
</div>

<!-- ##############  MOBILE & TABLETTE ##############-->
<div class="container immeubleCourtierBox afficherMobile">
     <!-- COURTIER -->
    <div class="row col pt-3 ml-2 justify-content-center">
        <div class="row justify-content-center courtier-photo">
            <img [src]="data?.sCourtierPhoto" class="img-fluid round" alt="">
        </div>

        <div class="row text-center justify-content-center">
            <div class="col-auto pt-5 pr-5 pl-5">
                <h4>{{ data?.sCourtierNom }}</h4>
                {{ data?.sCourtierTitre | translate }}
            </div>
        </div>

        <div class="row pt-4">
            <div class="text-center pt-4">
                <!-- COURRIEL -->
                <div class="row text-center  ml-2">
                    <a [href]="'mailto:' + (data?.sCourtierCourriel || 'info@pmml.ca') + '?subject=' + data?.sNumeroCivique + ' ' + data?.sRue + ', ' + data?.sNomVille">
                        <i class="fas fa-envelope icone"></i></a> <strong class="ml-2 text-center">{{ '@header.courriel' | translate }}</strong><br>
                </div>
                
                <div class="col-auto">
                    <a [href]="'mailto:' + (data?.sCourtierCourriel || 'info@pmml.ca') + '?subject=' + data?.sNumeroCivique + ' ' + data?.sRue + ', ' + data?.sNomVille">{{ data?.sCourtierCourriel || 'info@pmml.ca' }} </a>
                </div>
            </div>

            <div class="text-center pt-4">
                <!-- CELLULAIRE -->
                <div class="col-auto">
                    <a [href]="'tel:' + (data?.sCourtierTel || '514 360-3603')">
                        <i class="fas fa-phone icone"></i></a> <strong class="ml-2">{{ '@header.cellphone' | translate }}</strong><br>
                </div>
                
                <div class="col-auto">
                    <a [href]="'tel:' + (data?.sCourtierTel || '514 360-3603')">{{ data?.sCourtierTel || '514 360-3603' }} </a>
                </div>
            </div>
        </div>
    </div>
</div>
