import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from "../../../auth.service";

@Component({
  selector: 'app-sommaires',
  templateUrl: './sommaires.component.html',
  styleUrls: ['./sommaires.component.scss']
})
export class SommairesComponent implements OnInit {

  constructor(
    private auth: AuthService,
  ) { }

  @Input() data: any;


  statsLoyers: any = {};

  connected: boolean = false;

  ngOnInit() {
    this.calculerSommaire();

    this.auth.user$
    .subscribe((user) => {
      this.connected = user ? true : false;

      if(window.location.hash === '#sommaires') {
        this.scrollToSommaire();
      }
    });
  }

  scrollToSommaire() {
    document.querySelector('#sommaires').scrollIntoView({ behavior: 'smooth' });
  }

  calculerSommaire() {
    this.statsLoyers = {
      totalLoyersRes: 0,
      totalLoyersComm: 0,
      totalLoyersCommRecup: 0,
      totalLoyersCommPlusRecup: 0,
      totalLoyersRang: 0,
      totalSta: 0,
      totalAutre: 0,
      totalMensuel: 0,
      totalRecupMensuel: 0,
      totalMensuelAvecRecup: 0,
      totalAnnuel: 0
    }

    for (const ligne of (this.data?.Loyers || [])) {
      let Mensualite = ligne["iMontant"];

      if (!ligne.iMontantRecupDepenses) ligne.iMontantRecupDepenses = 0;

      const pattern = /^(\d+)x$/i;
      let match = [];
      if (match = pattern.exec(ligne['sIdentifiant'])) {
        Mensualite = Mensualite * Number(match[1]);
      }

      if (ligne.sType == 'Stationnement' || ligne.sType == 'Garage') {
        this.statsLoyers.totalSta += Mensualite;
      }
      else if (ligne.sType == 'Commerce' || ligne.sType == 'Commercial' || ligne.sType == 'Pancarte') {
        this.statsLoyers.totalLoyersComm += Mensualite;
        this.statsLoyers.totalLoyersCommRecup += ligne.iMontantRecupDepenses || 0;
        this.statsLoyers.totalLoyersCommPlusRecup += Mensualite + (ligne.iMontantRecupDepenses || 0);
      }
      else if (ligne.sType == 'Rangement' || ligne.sType == 'Lockers' || ligne.sType == 'Entrepot') {
        this.statsLoyers.totalLoyersRang += Mensualite;
      }
      else if (String(ligne.sType).substr(0, 1) == '*') {
        this.statsLoyers.totalAutre += Mensualite;
        ligne.sType = String(ligne.sType)[1];
      }
      else {
        this.statsLoyers.totalLoyersRes += Mensualite;
      }

      this.statsLoyers.totalRecupMensuel += ligne.iMontantRecupDepenses || 0;
      this.statsLoyers.totalMensuelAvecRecup += Mensualite + (ligne.iMontantRecupDepenses || 0);

      this.statsLoyers.totalMensuel += Mensualite;
    }

    this.statsLoyers.totalAnnuel = this.statsLoyers.totalMensuel * 12;
  }

  seConnecter () {
    return this.auth.login(window.location.pathname + '#sommaires');
  }
}
