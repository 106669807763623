import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, HostListener, ElementRef, OnChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, timer } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../../environments/environment';

import { ProprietesService } from "../../../api/proprietes.service";
import { AuthService } from "../../../auth.service";

import { FavorisInformationsComponent } from "../../liste-priorites/favoris-informations/favoris-informations.component"
import { ConnexionFavorisDialogComponent } from "../connexion-favoris-dialog/connexion-favoris-dialog.component"

@Component({
  selector: 'app-liste-proprietes-tableau',
  templateUrl: './liste-proprietes-tableau.component.html',
  styleUrls: ['./liste-proprietes-tableau.component.scss']
})
export class ListeProprietesTableauComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private proprietes: ProprietesService,
    private auth: AuthService,
    public dialog: MatDialog
  ) { }

  environment = environment;
  @Input() filtres: any;
  @Input() typeAffichage: any;
  public showloader: boolean = false;
  public isFirstLoad: boolean = true;
  private subscription: Subscription;
  private timer: Observable<any>;
  dialogRefFavorisTooltip;
  dialogRefConnexionFavoris;
  shouldShowFavorisTooltip: string;
  toggleMobile: any;

  STYPE_PI_CARREE = ["iIndustriel", "iCommercial", "iTerrain", "iBureaux"];

  @HostListener('document:scroll', ['$event']) private scroll(ev: Event) {
    if (!this.showloader && this.listings[1].length > 0 && this.nombreListingRestant !== 0 && (window.innerHeight + window.scrollY + 450) >= document.body.offsetHeight) {
      this.getMoreListings();
    }
  };

  
  @Input() listings: any = [];
  @Input() isLoadingListings: boolean = true;
  @Input() nombreListingRestant: number;
  @Output() plusDeListings: any = new EventEmitter<any>();


  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.toggleMobile = window.innerWidth < 765 ? true : false;
    this.modificationArrayListe();
  }

  displayedColumns: string[] = ['sNumeroCivique', 'sNomVille', "iUnitesTotal", "fPrixDemande", "favoris"];
  dataSource = new MatTableDataSource();
  typeSite: String = "";
  typeMesure = "";

  ngOnInit(): void {
    this.typeSite = window.location.hostname;

    this.setTimer();
    if (this.typeAffichage != 'nouveautes' || this.typeAffichage) this.onResize();

    this.dataSourceSort();
  }

  ngOnDestroy() {
    if (this.subscription && this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes): void {    
    if (window.location.host == 'landev.ai' || (this.typeSite !== 'landev.ai' && this.STYPE_PI_CARREE.includes(this.filtres?.sTypeLogement))) this.typeMesure = 'iPiedsCarreTotal';
    else this.typeMesure = 'iUnitesTotal';

    this.modificationArrayListe();

    if (this.isFirstLoad && changes.listings && changes.listings.currentValue.length !== 0) {
      this.isFirstLoad = false;
    }

    // Priorise les données de la map
    this.dataSource = new MatTableDataSource(this.listings[1]);

    this.dataSourceSort();

  }

  dataSourceSort() {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
    }, 1500);
  }

  modificationArrayListe() {
    if (this.toggleMobile) this.displayedColumns = ['sNumeroCivique', 'sNomVille', (this.typeMesure || "iUnitesTotal"), "favoris"];
    else this.displayedColumns = ['sNumeroCivique', 'sNomVille', (this.typeMesure || "iUnitesTotal"), "fPrixDemande", "favoris"];
  }

  getColor(fav) {
    if (fav) {
      return '#cc0000';
    }
    return 'black';
  }

  hideFavorisTooltip() {
    if (this.dialogRefConnexionFavoris && !this.estConnecte()) this.dialogRefFavorisTooltip.close();
    this.dialogRefFavorisTooltip?.close();
  }

  showFavorisTooltip(event, propriete) {
    // N'affiche pas le toggle d'information(MouseOver) en mobile
    if (!this.toggleMobile) {
      const x = event.clientX, y = event.clientY, elementMouseIsHover = document.elementFromPoint(x, y);

      // if (this.estConnecte()) return;
      if (propriete.fav) return;
      this.dialogRefFavorisTooltip = this.dialog.open(FavorisInformationsComponent, {
        hasBackdrop: false,
        data: { element: elementMouseIsHover }
      });
    }
  }

  ajouterFavoris(propriete, event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.estConnecte()) {
      if (!propriete.fav) {
        this.hideFavorisTooltip();
        propriete.fav = true;
        this.proprietes.setFavori(propriete.sLien, true);
      } else {
        this.hideFavorisTooltip();
        propriete.fav = false;
        this.proprietes.setFavori(propriete.sLien, false);
      }
    } else {
      const target = new ElementRef(event.currentTarget);
      this.dialogRefConnexionFavoris = this.dialog.open(ConnexionFavorisDialogComponent, {
        hasBackdrop: false,
        data: { trigger: target }
      });
    }
  }

  public setTimer() {
    this.isLoadingListings = true;
    this.showloader = true;
    this.timer = timer(400);
    this.subscription = this.timer.subscribe(() => {
      this.showloader = false;
    });
    this.isLoadingListings = false;
  }

  directionFd(propriete, sTypeLogement) {
    if(sTypeLogement == 'iTerrain') {
      if (document.documentElement.lang !== 'fr-CA') window.open(`https://landev.ai/en/${propriete}`, '_blank');
      else window.open(`https://landev.ai/${propriete}`, '_blank');
    }
    if (document.documentElement.lang !== 'fr-CA') window.open(`/en/${propriete}`, '_blank');
    window.open('/' + propriete, '_blank');
  }

  getMoreListings() {
    this.setTimer();
    this.plusDeListings.emit();
  }

  estConnecte() {
    return this.auth.token ? true : false;
  }
}
