import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extrait'
})
export class ExtraitPipe implements PipeTransform {

  transform(value: string, nbMots: number = 10): string {
    return value.split(/\s+/).slice(0,nbMots).join(" ").replace('<p>', '') + " ...";
  }

}
