import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

import Contact from '../api/models/contact';
import { AuthService } from './../auth.service';
import { ContactsService } from './../api/contacts.service';

@Component({
  selector: 'app-popup-inscription',
  templateUrl: './popup-inscription.component.html',
  styleUrls: ['./popup-inscription.component.scss'],
  // TODO: Voir ce que l'on fait avec ceci
  // https://www.freecodecamp.org/news/how-to-create-angular-6-custom-elements-web-components-c88814dc6e0a/
  // encapsulation: ViewEncapsulation.Native
  encapsulation: ViewEncapsulation.None
})
export class PopupInscriptionComponent implements OnInit {

  constructor(
    private contacts: ContactsService,
    private auth: AuthService,
  ) {
    this.contact = <Contact>{};
    // this.contact.listes = {};
  }

  LISTES = this.contacts.getListes();

  chargement = false;
  afficherPopup = false;
  sauvegardeReussie = false;
  sauvegardeErreur = false;
  connexionCourriel = false;

  contact: Contact;

  ngOnInit() {
    if(this.contacts.doitIgnorerInscription()) return;
    if(this.contacts.doitIgnorerTemporairementInscription()) return;

    setTimeout(() => {
      if(!this.estConnecte()) this.afficherPopup = true;
    }, 4000);
  }
  
  fermer(nePlusAfficher = true, e?: Event) {
    // Set Cookie / Localsotrage pour ne plus afficher
    if(e && e.currentTarget != e.target) return true;
    if(nePlusAfficher) this.contacts.setIgnorerInscription();
    else this.contacts.setIgnorerTemporairementInscription();

    this.afficherPopup = false;
  }

  inscriptionSocial(social) {
    this.auth.login(window.location.href , social)
  }

  estConnecte() {
    return this.auth.token ? true : false;
  }
}
