import { Component, OnInit, HostListener, Input } from '@angular/core';
import { WordpressService } from '../api/wordpress.service';
import { PmmltvInteractionService } from '../pmmltv-interaction.service';
import { concat, of } from 'rxjs';

@Component({
  selector: 'app-pmml-tv',
  templateUrl: './pmml-tv.component.html',
  styleUrls: ['./pmml-tv.component.scss']
})
export class PmmlTvComponent implements OnInit {
  constructor(private wp: WordpressService, private pmmlTvInteraction: PmmltvInteractionService) {}

  @Input() typeChaine: any;
  articles: any[];
  categories: any[];
  private chaine: any;
  private noPage = 1;
  private nbPages: number;
  private nbParPages: number;
  scrollChangement = false;

  @Input('idCategorie') idCategorie: number;

  @HostListener('document:scroll', ['$event']) private scroll(ev: Event) {
    if (
      !this.scrollChangement &&
      this.articles.length > 0 &&
      window.innerHeight + window.scrollY + 450 >= document.body.offsetHeight
    ) {
      this.voirPlus();
    }
  }

  ngOnInit(): void {
    if (this.idCategorie) {
      this.chaine = this.idCategorie;
    }
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.nbParPages = 6;
    if (width >= 992) this.nbParPages = 9;
    if (width >= 1200) this.nbParPages = 12;
    this.wp.listeChaines().subscribe((res) => {
      this.categories = res;
      concat(of(null), this.pmmlTvInteraction.chaine).subscribe((chaine) => {
        this.noPage = 1;
        this.articles = [];
        this.chaine = chaine;
        this.recupArticles();
      });
    });
  }

  recupArticles(noPage = 1): void {
    if(this.typeChaine) this.chaine = this.typeChaine; 
    // Pour débug seulement
    // this.wp.listeArticles(this.chaine, noPage, this.nbParPages).then(([pmmltv]) => this.articles.push(...pmmltv));
    if (this.typeChaine) this.chaine = this.typeChaine;
    this.wp.listeArticles((this.chaine || 29), noPage, this.nbParPages).subscribe(([posts, pages]) => {
      this.articles.push(...posts);
      this.nbPages = pages;
      this.scrollChangement = false;
    });
  }

  voirPlus() {
    if (this.noPage > this.nbPages - 1) return;
    this.scrollChangement = true;
    this.recupArticles(++this.noPage);
  }
}
