import { Component, OnInit, Input } from '@angular/core';
import { ProprietesService } from "../../../api/proprietes.service";

@Component({
  selector: 'app-bureau-loc',
  templateUrl: './bureau-loc.component.html',
  styleUrls: ['./bureau-loc.component.scss']
})
export class BureauLocComponent implements OnInit {

  @Input() data: any;

  constructor(
  ) { }

  ngOnInit(): void {
  }



}
