import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-prix-demande',
  templateUrl: './prix-demande.component.html',
  styleUrls: ['./prix-demande.component.scss']
})
export class PrixDemandeComponent {

  constructor() { }

  @Input() data: any;
  @Input() location: any;

}
