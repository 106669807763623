import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'pmmlArgent'
})
export class ArrondiPipe implements PipeTransform {
  constructor(private cp: CurrencyPipe) { }

  transform(value: any, arrondiMilliers = false, masquerZero = true, reduction = false): string {
    if(isNaN(value) || (masquerZero && value == 0)) return value;
    value = +value;

    if(arrondiMilliers) value = Math.round(value / 1000) * 1000;

    if(reduction) {
      const k = 1000, dm = 0;
      let sizes = ['$', 'K$', 'M$', 'G$'];
      let i = Math.floor(Math.log(value) / Math.log(k));
      return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    return this.cp.transform(value, 'CAD', 'symbol-narrow', '1.0-0');
  }

}
