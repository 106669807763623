import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'proprieteTypeDep'
})
export class ProprieteTypeDepPipe implements PipeTransform {

  transform(value: any, type?: any): string {
    if(+value == 0) return '@depenses.type.actuel';
    if(+value == 1) return '@depenses.type.schl';
    
    if(type == 'Commerciale') {
      if (+value == 2) return '@depenses.type.normalise';
      if (+value == 3) return '@depenses.type.normalise';
      if (+value == 4) return '@depenses.type.normalise';
    } else {
      if (+value == 2) return '@depenses.type.estime';
      if (+value == 3) return '@depenses.type.estime';
      if (+value == 4) return '@depenses.type.estime';
    }
    if (value == undefined || type == 'Commercial') return '@depenses.type.actuel';
  }

}
