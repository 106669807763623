<div class="container">
    <!-- LIGNE 1 -->
    <div class="row align-items-center no-gutters bg-gris-1">
        <div class="col-xs-12 odrer-xs-2 col-lg-6 order-lg-1 padding" *ngIf="!data?.iFlyer && (!data?.sUrlFlyerExtFr  && !data?.sUrlFlyerExtAn)">
            <h3 class="pt-3"><i class="fas fa-comment-exclamation"></i> {{ '@info.faitssaillants' | translate }}</h3>
            <div [innerHTML]="data?.sJsonData?.fr?.FaitsSaillants | translateValeur : data?.sJsonData?.an?.FaitsSaillants | nl2li"></div>
        </div>
        <div class="col-xs-12 odrer-xs-2 col-lg-6 order-lg-1 padding" *ngIf="data?.iFlyer || (data?.sUrlFlyerExtFr  || data?.sUrlFlyerExtAn)">
            <h3 class="pt-3"><i class="fas fa-comment-exclamation"></i> {{ '@info.faitssaillants' | translate }}</h3>
            <div [innerHTML]="data?.FaitsSaillantsFr | translateValeur : data?.FaitsSaillantsAn | nl2li"></div>
        </div>

        <div class="col-xs-12 order-xs-1 col-lg-6 order-lg-2 image">
            <div class="media h-100 w-100"
                [style.background-image]="data.Photos[data.iFlyer ? 0 : 1] | pmmlPhotoUrl : 600"
                [style.backgroundSize]="'cover'"
                [style.background-position]="'center center'">
            </div>
        </div>
    </div>
    <!-- LIGNE 2 -->
    <div class="row align-items-center no-gutters bg-gris-2">
        <div class="col-xs-12 order-xs-3 col-lg-6 order-lg-3 image">
            <div class="media h-100 w-100"
                [style.background-image]="data.Photos[data.iFlyer ? 1 : 2] | pmmlPhotoUrl : 600"
                [style.backgroundSize]="'cover'"
                [style.background-position]="'center center'">
            </div>
        </div>
        <div class="col-xs-12 order-xs-4 col-lg-6 order-lg-4 bg-gris padding order-first">
            <h3 class="pt-3">{{ '@info.superficie.louable' | translate }}</h3>
            <p class="h4 pb-3">{{ data?.iPiedsCarreTotal | number : '1.0-0' }} {{ '@pi2' | translate }}</p>
            <h3 *ngIf="data.IDPropriete !== 825814 && data.IDPropriete !== 825812 && data.IDPropriete !== 825810">
                {{ '@info.price.sqft' | translate}}
            </h3>
            <p *ngIf="data.IDPropriete !== 825814 && data.IDPropriete !== 825812 && data.IDPropriete !== 825810" class="h4 pb-3 pt-4" [hidden]="!data?.sJsonData?.fr?.masquerPrixPC">
                <span [hidden]="!data?.sJsonData?.fr?.masquerPrixPC"></span>
            </p>
            <p class="h4 pb-3" [hidden]="data?.sJsonData?.fr?.masquerPrixPC"><span>{{ (data?.fPrixDemande / data?.iPiedsCarreTotal) | pmmlArgent }}</span></p>
            <h3>{{ '@info.annee.construction' | translate }}</h3>
            <p class="h4 pb-3">{{ data?.iAnneeConstruction }}</p>
        </div>
    </div>
</div> 