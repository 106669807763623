import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateValeurPipe } from "src/app/translate-valeur.pipe";

const IFRAME_API_URL = "https://www.youtube.com/iframe_api";
const IFRAME_API_READY_FN = "onYouTubeIframeAPIReady";
const IFRAME_API_PLAYER_READY_FN = "onPlayerReady";

@Component({
  selector: "app-video-dialog",
  templateUrl: "./video-dialog.component.html",
  styleUrls: ["./video-dialog.component.scss"],
})
export class VideoDialogComponent implements OnInit {
  private YT;
  private player;

  ready = false;
  apiReady = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private translatePipe: TranslateValeurPipe
  ) {}

  ngOnInit(): void {
    this.initVideo();
  }

  initVideo() {
    if (!window["YT"]) {
      const tag = document.createElement("script");
      tag.src = IFRAME_API_URL;
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window[IFRAME_API_READY_FN] = () => {
        this.YT = window["YT"];
        this.apiReady = true;
        this.startVideo();
      };
    } else {
      this.YT = window["YT"];
      this.apiReady = true;
      this.startVideo();
    }
  }

  startVideo() {
    const videoId = this.translatePipe.transform(
      this.data.videoFr,
      this.data.videoAn
    );

    this.player = new this.YT.Player("video-dialog-iframe", {
      videoId,
      playerVars: { rel: 0, autoplay: 1 },
    });

    window[IFRAME_API_PLAYER_READY_FN] = () => {
      this.ready = true;
    };
  }
}
