import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../..//environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    private http: HttpClient,
  ) { }


  private _listeRegions: any[];
  async listeRegions() : Promise<any[]> {
    if (!environment.production) {
      return import('./data-props-list')
        .then((module: any) => {
          return module._listeRegions;
        })
    }

    try {


      if(this._listeRegions) return this._listeRegions;

      return this._listeRegions = (await this.http.get('/api/regions/liste').toPromise()) as any[];
    } catch (error) {
      // TODO: Gérer l'erreur
    }
    return [];
  }

  private _listeSecteurs: any[];
  async listeSecteurs() : Promise<any[]> {
    
    // if (!environment.production) {
    //   return import('./data-props-secteurs')
    //     .then((module: any) => {
    //       return module._listeSecteurs;
    //     })
    // }
    
    try {
      if(this._listeSecteurs) return this._listeSecteurs;

      return this._listeSecteurs = (await this.http.get('/api/secteurs/liste').toPromise()) as any[];
    } catch (error) {
      // TODO: Gérer l'erreur
    }
    return [];
  }
  
  private _listeRegionsAdmin: any[];
  async listeRegionsAdmin() : Promise<any[]> {
    
    // if (!environment.production) {
    //   return import('./data-props-secteurs')
    //     .then((module: any) => {
    //       return module._listeRegionsAdmin;
    //     })
    // }
    
    try {
      if(this._listeRegionsAdmin) return this._listeRegionsAdmin;

      return this._listeRegionsAdmin = (await this.http.get('/api/regions-admin').toPromise()) as any[];
    } catch (error) {
      // TODO: Gérer l'erreur
    }
    return [];
  }
}
