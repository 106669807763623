import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "echeanceloyers",
})
export class EcheanceloyersPipe implements PipeTransform {
  transform(value?: any): string {
    const datePipe = new DatePipe(
      (<any>window).pmml_data.lang == "en" ? "en-US" : "fr-CA"
    );

    if (value == undefined) return "Vacant";
    if (value == "1960-01-01T05:00:00.000Z") {
      if ((<any>window).pmml_data.lang == "en") return "Annual";
      else return "Annuel";
    }
    if (value == "0000-00-00") {
      if ((<any>window).pmml_data.lang == "en") {
        return "Monthly";
      } else {
        return "Mensuel";
      }
    }

    const [date] = String(value).split("T");
    console.log(date);

    return datePipe.transform(date, "MMM yyyy");
  }
}
