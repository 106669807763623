import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject, catchError, filter, map, switchMap, take, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth.service";
/** Error when invalid control is dirty, touched, or submitted. */

@Component({
  selector: "app-demande-dataroom",
  templateUrl: "./demande-dataroom.component.html",
  styleUrls: ["./demande-dataroom.component.scss"],
})
export class DemandeDataroomComponent implements OnInit {
  constructor(
    private auth: AuthService,
    public dialogRef: MatDialogRef<DemandeDataroomComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient
  ) {
    if (environment.dev && !data.lien) {
      data.lien = "xu0j32";
    }
  }

  @ViewChild("codeInputRef") codeInputRef;

  form = new FormGroup({
    nom: new FormControl("", [Validators.required]),
    prenom: new FormControl("", [Validators.required]),
    adresse: new FormControl("", [Validators.required]),
    courriel: new FormControl("", [Validators.required, Validators.email]),
    phone: new FormControl("", [
      Validators.required,
      Validators.pattern(/^[0-9]*$/),
    ]),
  });

  errors = {
    nom: "@demande-dataroom.erreur.nom",
    prenom: "@demande-dataroom.erreur.prenom",
    courriel: "@demande-dataroom.erreur.courriel",
    phone: "@demande-dataroom.erreur.phone",
    adresse: "@demande-dataroom.erreur.adresse",
  };

  codeInput = new FormControl("", []);
  codeInputSubmit = new Subject();

  codeInput$ = this.codeInputSubmit.pipe(
    map(() => this.codeInput.value),
    filter((code) => code.length === 4)
  );

  usager$ = this.auth.user$;

  etapes = ["formulaire", "vérification"];

  wizardEtape = "formulaire";

  ngAfterViewInit() {}

  signeForm() {
    this.usager$.pipe(take(1)).subscribe((usager) => {
      const validForm = !usager
        ? this.form.valid
        : this.form.get("phone").valid;

      if (!validForm || !this.data.lien) return;
      const formValue = this.form.value;
      formValue.phone = `+1${formValue.phone}`;
      const form = usager
        ? {
            phone: formValue.phone,
            IDContact: usager.IDContact,
          }
        : formValue;

      // return;
      this.signeData(form.phone, form)
        .pipe(
          catchError((err) => {
            this.wizardEtape = "erreur";
            return [];
          }),
          switchMap((result: any) =>
            this.http.post(`/api/dossiers/${this.data.lien}/dataroom`, {
              dataSigne: result.jwt,
            })
          )
        )
        .subscribe((res) => {
          this.wizardEtape = "complete";
        });
    });
  }

  signeData(phone, data) {
    this.wizardEtape = "loading";
    return this.http
      .post("/api/verif/signe-data/phone", {
        phone,
        data,
      })
      .pipe(
        take(1),
        tap(() => {
          setTimeout(() => {
            console.log(this.codeInputRef.input.nativeElement.focus());
          }, 100);
          this.wizardEtape = "vérification";
        }),
        switchMap(() => this.codeInput$.pipe(take(1))),
        switchMap((code) =>
          this.http.post("/api/verif/signe-data/code", { phone, code })
        ),
        tap(() => (this.wizardEtape = "loading"))
      );
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
