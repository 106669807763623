<div
  #carrousel
  id="carrousel"
  class="carrousel"
  [class]="mode"
  [class.visible]="imagesPrecharge"
>
  <div id="fleche" class="precedent" (click)="precedent()">
    <i id="fleche" class="fas fa-chevron-left fa-2x"></i>
  </div>
  <div class="slide-info">
    <span id="current">{{ slidePresente + 1 }}</span
    >/<span id="total">{{ imagesPrecharges.length }}</span>
  </div>
  <div
    id="slides"
    #slides
    class="slides"
    [style.transform]="'translateX(' + -currentX + 'px)'"
  >
    <!-- Slides -->
    <div *ngFor="let img of imagesPrecharges; let i = index" class="slide">
      <img
        *ngIf="img.type !== 'video'"
        [id]="i"
        #images
        [src]="img.src"
        (click)="onImageClick($event)"
      />
      <div [id]="i" #images *ngIf="img.type === 'video'" class="video">
        <img [src]="img.src" />
        <div class="overlay" (click)="onVideoClick()">
          <img
            src="https://pmml.ca/wp-content/uploads/2020/09/youtube_play_overlay.png"
            class="play"
          />
        </div>
      </div>
    </div>
  </div>
  <div id="fleche" class="prochaine" (click)="prochaine()">
    <i id="fleche" class="fas fa-chevron-right fa-2x"></i>
  </div>
</div>
