<ng-container *ngIf="wizardEtape !== 'erreur'">
    <h1 *ngIf="wizardEtape !== 'complete'" style="margin:0" mat-dialog-title>{{'@demande-dataroom.titre'|translate}}
    </h1>
    <h1 *ngIf="wizardEtape === 'complete'" style="margin:0" mat-dialog-title>
        {{'@demande-dataroom.demande-envoyer1'|translate}}</h1>

    <div mat-dialog-content>
        <ng-container *ngIf="wizardEtape !== 'complete'">
            <div class="bootstrapImportation">
                <div class="d-flex justify-content-around">
                    <button *ngFor="let etape of etapes; let index = index" class="wizard-etape-indicateur"
                        [attr.data-etape]="etape" [class.active]="etape === wizardEtape">
                        {{index + 1}}
                    </button>
                </div>
            </div>

            <div [style.alignItems]="wizardEtape === 'loading' ? 'center' : ''"
                [style.justifyContent]="wizardEtape === 'loading' ? 'center' : ''"
                style="display: flex; flex-direction: column; gap: 0.25rem; min-height: 200px;  margin-bottom: 0.5rem">

                <mat-spinner *ngIf="wizardEtape === 'loading'"></mat-spinner>

                <ng-container *ngIf="wizardEtape === 'formulaire'" [formGroup]="form">
                    <mat-form-field appearance="fill" class="w-full">
                        <mat-label>{{'@demande-dataroom.phone-input'|translate}}</mat-label>
                        <mat-error>{{errors.phone|translate}}</mat-error>
                        <input matInput autofocus formControlName="phone" prefix="+1 " mask="(000) 000-0000">
                    </mat-form-field>
                    <ng-container *ngIf="!(usager$|async)">
                        <div class="split">
                            <mat-form-field appearance="fill" class="w-full">
                                <mat-label>{{'@demande-dataroom.prenom-input'|translate}}</mat-label>
                                <mat-error>{{errors.prenom|translate}}</mat-error>
                                <input autofocus matInput formControlName="prenom">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="w-full">
                                <mat-label>{{'@demande-dataroom.nom-input'|translate}}</mat-label>
                                <mat-error>{{errors.nom|translate}}</mat-error>
                                <input matInput formControlName="nom">
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label>{{'@demande-dataroom.courriel-input'|translate}}</mat-label>
                            <mat-error>{{errors.courriel|translate}}</mat-error>
                            <input matInput formControlName="courriel">
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label>{{'@demande-dataroom.adresse-input'|translate}}</mat-label>
                            <mat-error>{{errors.adresse|translate}}</mat-error>
                            <input matInput formControlName="adresse">
                        </mat-form-field>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="wizardEtape === 'vérification'">
                    <p>{{'@demande-dataroom.confirme-code'|translate}}</p>
                    <app-code-input #codeInputRef class="bootstrapImportation"
                        [formControl]="codeInput"></app-code-input>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="wizardEtape === 'complete'">
            <p>{{'@demande-dataroom.demande-envoyer2'|translate}}</p>
        </ng-container>
    </div>

    <div mat-dialog-actions class="split">
        <button *ngIf="wizardEtape !== 'complete' && wizardEtape !== 'loading'" mat-button
            (click)="close()">{{'@demande-dataroom.annuler'|translate}}</button>
        <button *ngIf="wizardEtape === 'formulaire'"
            [disabled]="(usager$|async) ? !form.get('phone').valid : !form.valid" mat-flat-button color="accent"
            (click)="signeForm()">
            {{'@demande-dataroom.continuer'|translate}}
            <i class="fas fa-arrow-right"></i>
        </button>
        <button *ngIf="wizardEtape === 'vérification'" mat-button color="accent"
            (click)="codeInputSubmit.next()">{{'@demande-dataroom.accepter'|translate}}</button>
        <button style="margin-left: auto;" *ngIf="wizardEtape === 'complete'" mat-button color="accent"
            mat-dialog-close>{{'@demande-dataroom.fermer'|translate}}</button>
    </div>
</ng-container>

<ng-container *ngIf="wizardEtape === 'erreur'">
    <h1 style="margin:0; color:brown" mat-dialog-title>{{'@demande-dataroom.erreur-titre'|translate}} </h1>

    <div mat-dialog-actions style="display: flex; flex-direction: column; justify-content: center;">
        <p>{{'@demande-dataroom.erreur-message'|translate}}</p>

        <button *ngIf="wizardEtape !== 'complete' && wizardEtape !== 'loading'" mat-flat-button color="error"
            (click)="close()">{{'@demande-dataroom.fermer'|translate}}</button>
    </div>
</ng-container>