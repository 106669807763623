import { Component, OnInit, Input } from '@angular/core';
import { ProprietesService } from "../../../api/proprietes.service";

@Component({
  selector: 'app-bureau-vendre',
  templateUrl: './bureau-vendre.component.html',
  styleUrls: ['./bureau-vendre.component.scss']
})
export class BureauVendreComponent implements OnInit {


  @Input() data: any;
  constructor(

  ) { }

  ngOnInit(): void {
  }




}
