import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer,
  ) {}

  transform(value: any, ...args: any[]): any {
    if(!value) return null;

    return this.sanitizer.bypassSecurityTrustHtml(String(value).trim().replace(/\n/gim, '<br>'));
  }

}
