import { Input } from '@angular/core';
import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

const TIMEOUT_GA_EVENT = 250;

@Component({
  selector: 'pmml-bouton-login',
  template: `<ng-content></ng-content>`
})
export class BoutonLoginComponent implements OnInit {

  estConnecte: boolean = null;

  @Input('redirectionPage') redirectionPage: boolean;

  @HostListener('click', ['$event', '$event.target'])
  onClick(event: Event, btn: HTMLElement) {
    return this.handleClick(event, btn);
  }

  gaTrack(event_category: string, event_action: string, event_label: string, event_value?: number) {
    return new Promise((resolve, reject) => {
      if (typeof (window as any)?.gtag !== 'function') return resolve(false);      
      
      (window as any)?.gtag('event', event_action, {
        event_category, event_label, event_value,
      });

      setTimeout(() => resolve(true), TIMEOUT_GA_EVENT);
    });
  }

  async handleClick(event: Event, btn: HTMLElement): Promise<any> {
    let _btn = btn;
    if (!(_btn?.attributes as any)?.href) _btn = btn.parentElement;

    if (!this.estConnecte) {
      event.stopImmediatePropagation();
      event.preventDefault();
      this.auth.login((_btn?.attributes as any)?.href?.value || '/', null, null, this.redirectionPage == true);
      return false;
    }
  }

  constructor(
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.estConnecte = this.auth.token ? true : false;
  }
}
