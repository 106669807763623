import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-ouvre-lien-component",
  templateUrl: "./ouvre-lien-component.component.html",
  styleUrls: ["./ouvre-lien-component.component.scss"],
})
export class OuvreLienComponentComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<OuvreLienComponentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      titre: string;
      bouton: string;
      lien: string;
    }
  ) {}

  ngOnInit(): void {}
}
