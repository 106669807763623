export const fr = {
    "@general.champ-requis": "Votre {{champ}} est requis",
    "@general.champ-f-requis": "Votre {{champ}} est requise",
    "@general.chargement": "Chargement...",

    "langue": "en",
    "a" : "à",
    "prix": "Prix",
    "taille": "Taille",
    "chambres": "Chambres",
    "appartement": "Appartement",
    "immediatement": "Immédiatement",
    "adeterminer": "À déterminer",
    "adresse": "Adresse",
    "revenus_special_date": "Revenus en date du 1 juillet 2021",
    "connection-afficher-fiche": "Connectez-vous maintenant pour accéder à la fiche complète!",
    "@popup-inscription.component.titre": "Recevez nos nouveautés",
    "@popup-inscription.component.explicatif": "Soyez parmi les premiers à découvrir les nouveautés de PMML, telles que les nouvelles inscriptions sur le marché, le contenu vidéo sur PMML.TV, les articles enrichissants sur le marché de l’investissement immobilier et bien plus!",
    "@popup-inscription.component.explicatif.suite": "Connectez-vous dès maintenant pour y avoir accès.",
    "@popup-inscription.component.masquer": "Ne plus afficher",
    "@popup-inscription.component.confirmer": "S'enregistrer",
    "@popup-inscription.component.fermer": "Fermer",
    "@popup-inscription.component.boutonSocialGoogle": "S'inscrire avec Google",
    "@popup-inscription.component.boutonSocialFB": "S'inscrire avec Facebook",
    "@popup-inscription.component.boutonSocialLI": "S'inscrire avec LinkedIn",
    "@chercher.vous": "QUE RECHERCHEZ-VOUS?",
    "@listeProprietes.rechercheDescriptif": "Rechercher par quartier, ville, secteur ou région",
    "@listeProprietes.typeUnite.tout": "Tous",
    "propriete": "PROPRIÉTÉS À VENDRE",
    "@description.terrain": "Description du terrain",
    "description.appartements": "Descriptif des appartements",
    "@ROI.prix.demande": "Rendement sur mise de fonds",
    "@revenus.net.fd": "Revenu net",
    "@revenus.net": "Revenu Net",
    "@se.connecter": "Se connecter / S'inscrire",
    "batisse": "Bâtisse",
    "superficie": "Superficie",
    "superficie.min": "PI² Min.",
    "superficie.max": "PI² Max.",
    "filtrer.region": "Filtrer par region",
    "@formulaire-alertes.component.confirmer": "Vos préférences ont été mises à jour",
    "@formulaire-alertes.component.confirmer.courriel": "Votre demande à été reçu et sera traité sous peu",
    "@enregistrer": "Enregistrer",
    "@courriel.valide": "Vous devez saisir un courriel valide",
    "@telephone.valide": "Vous devez saisir un numero téléphone valide",
    "@contact.erreur": "Nous avons reçu une erreur lors d'envoyer",
    "@envoyer": "Envoyer",
    "@required": "Champ Obligatoire",
    "@invalid": "Valeur invalide",
    "@formulaire-alertes.component.fermer": "Fermer",
    "@formulaire-alertes.component.succes": "Enregistrement réussi!",
    "@formulaire-alertes.component.erreur": "Erreur lors de la sauvegarde, veuillez nous contacter pour compléter votre profil.",
    "@formulaire-alertes.component.desabonnement": "Vous êtes désabonné",
    "@installationsexistantes": "Installations Existantes",
    "@ouvrir.dans.maps" : "Ouvrir dans Google Maps",
    "@pmml-tv-titre": "Découvrez nos épisodes informatifs sur le développement de terrain",
    "@contact.sPrenom": "Prénom",
    "@contact.compagnie": "Compagnie",
    "@contact.poste": "Poste",
    "@contact.commentaires": "Commentaires",
    "@contact.sNom": "Nom",
    "@contact.sTelephoneSMS": "Mobile",
    "@contact.sCourriel": "Courriel",
    "@contact.sTelephone1": "Téléphone",
    "@contact.sVille": "Ville",
    "@contact.iFlgLangue": "Langue",
    "@contact.iFlgLangue.fr": "Français",
    "@contact.iFlgLangue.en": "Anglais",
    "@descriptif.entreprise": "Descriptif de l'entreprise",
    "@contact.iConsentement": "Je consens à recevoir des communications de PMML",
    "@contact.regions": "Régions",
    "@contact.typeUnites": "Type d'unités",
    
    "@contact.pmmlTv": "PmmlTV",
    "@contact.pmmlTv.descriptif": "Visionnez nos capsules informatives et obtenez les meilleurs conseils et indicateurs pour optimisez vos investissements.",
    "@contact.perspectivesMarche": "Perspectives de marché",
    "@contact.perspectivesMarche.descriptif": "Apprenez davantage sur le marché immobilier grâce aux rapports et indicateurs de nos professionnels.",
    "@contact.typeProprietes": "Types de propriétés",
    "fiche.confidentiel.text": "Fiche complète disponible suite à la signature d'une entente de confidentialité",
    "@fichePdf.voir-fiche": "Voir la fiche complète",

    "@contact.associationProfil": "Voici les paramètres du compte associé au courriel : ",
    "@contact.listes": "Mes alertes",
    "@contact.listes.descriptif": "Afin que les résultats de vos recherches soient les plus représentatifs possible, sélectionnez vos préférences parmi les listes de distributions ci-dessous.",
    "@nouveautes": "Nouveautés",
    "@contact.listes.nouveautes": "Nouveautés - hebdomadaire",
    "@contact.listes.pmmlTv": "PMML.TV - hebdomadaire",
    "@contact.listes.constructionsNeuves": "Constructions neuves - mensuel",
    "@contact.listes.centLogements": "100 logements et plus - mensuel",
    "@contact.listes.desabonnement": "Me désabonner complètement",
    "@contact.listes.desabonnement.desc": "Je désire me désabonner de toutes les listes de distribution venant de PMML",
    "@contact.listes.utiliserCourrielSecondaire": "Utiliser mon courriel secondaire",
    "@contact.listes.autreCourriel": "Autre courriel",

    "@contact.maj.pmml": "PMML Hebdo",
    "@contact.maj.descriptif": "L’abonnement pour rester informé sur l’actualité du marché et des histoires à succès en investissement immobilier multilogement et commercial: PMML Insights, perspectives du marché, un aperçu des masterminds et des masterclass PMML ainsi que les nouveaux épisodes disponibles sur PMML.tv",

    "@contact.courtageCommercial.pmml": "Professionnel du courtage commercial",
    "@contact.courtageCommercial.descriptif": "Recevez des informations exclusives et destinés aux  professionnels du courtage immobilier et hypothécaire commercial, découvrez les outils développés par PMML et mis à la disposition des courtiers de l’équipe, tout en restant informé sur les programmes conçus pour propulser les carrières des professionnels du courtage.",

    "@contact.marcheDesCapitaux.pmml": "PMML Marché des capitaux",
    "@contact.marcheDesCapitaux.descriptif": "Restez informés grâce à une revue mensuelle du marché des capitaux: un résumé rapide et informatif des transactions d’envergures les plus récentes. Accédez à des informations exclusives spécialement conçues pour les professionnels institutionnels et les professionnels de l'industrie.",

    "@contact.evenements.pmml": "Événements",
    "@contact.evenements.descriptif": "Restez connecté et informé des derniers événements et opportunités de réseautage. Obtenez un récapitulatif ponctuel des événements à venir, des précieuses occasions de réseautage, ainsi que des informations exclusives conçues pour enrichir votre réseau professionnel.",

    // "@contact.majindice.pmml": "Indice PMML",
    // "@contact.majindice.descriptif": "Je souhaite recevoir une alerte m'informant de la mise à jour des données de l'indice PMML.",

    "@contact.nouveautes": "Alertes Nouveautés",
    "@contact.nouveautes.descriptif": "Je suis à la recherche d'une propriété à revenus ou un espace locatif et désire recevoir les nouveautés selon mes critères sélectionnés ci-dessous.",

    "@contact.es.locatif": "ALERTE ESPACE LOCATIF",
    "@contact.es.descriptif": "Soyez parmi les premiers à connaître les mises à jour sur le marché locatif.",

    "@categorie.commerce.0": "Commercial",
    "@categorie.commerce.1": "Bureau",
    "@categorie.commerce.5": "Commerce de détail",
    "@categorie.commerce.10": "Commerce de détail",
    "@categorie.commerce.12": "Commerce de détail",
    "@categorie.commerce.14": "Restauration rapide",
    "@categorie.commerce.15": "Industriel",
    "@categorie.commerce.16": "Industriel",
    "@categorie.commerce.17": "Industriel",
    "@categorie.commerce.25": "Médical",
    "@categorie.commerce.30": "Divertissement",
    "@categorie.commerce.35": "Immeubles universel",
    "@categorie.commerce.40": "Entrepôt",
    "@categorie.commerce.45": "Multi vocation",
    "@categorie.commerce.80": "Hôtellerie et hébergement",

    "@connectez-vous": "Connectez-vous pour ajouter des favoris, et accédez-y où que vous soyez!",

    "@mesFavoris": "Mes favoris",

    "@adresse.local": "Local",

    "@visite.virtuelle": "Visite virtuelle",
    "@cartographie": "Cartographie",

    "@listing.carte": "Carte",
    "@listing.tuiles": "Tuiles",
    "@listing.tableau": "Liste",

    "@voir.fiche.complete": "Voir la fiche complète",
    "@voir.fiche.complete.desc": "Pour voir la fiche complète, vous devez être connecté.",
    "@listing.fiche.complete": "Fiche complète",
    "@click.ici": "Cliquez Ici",
    
    "@loyers.titre": "Sommaire des loyers",
    "@loyers.pourVisionner": "Pour visionner le sommaires des loyers de cet immeuble, vous devez être connecté.",

    "@loyers.colUnite": "Unité",
    "@loyers.colLogement": "Logement",
    "@loyers.colType": "Type",
    "@loyers.colMontant": "Montant",
    "@loyers.colEcheance": "Échéance",
    "@loyers.colRemarque": "Remarques",
    "@loyers.totalResidentiel": "Mensualité des loyers résidentiels",
    "@loyers.echeance.annuel": "Annuel",
    "@loyers.echeance.mensuel": "Mensuel",
    "@loyers.echeance.vancant": "Vacant",
    "@loyers.totalLoyersRes": "Total Mensuel Résidentiel",
    "@loyers.totalLoyersComm": "Total Mensuel Commercial",
    "@loyers.totalLoyersRang": "Total Mensuel Rangement",
    "@loyers.totalSta": "Total Mensuel Stationnement",
    "@loyers.totalAutre": "Total Mensuel Autre",
    "@loyers.totalMensuel": "Total mensuel",
    "@loyers.totalAnnuel": "Total annuel",
    "@loyers.remarqueUnite": "Remarque pour le logement : ",

    "@descriptif.unite": "Descriptif de l'unité",

    "@superficie.disponible.sqft": "Superficie Disponible",
    "@secteur": "Secteur", 
    "@acces": "Accès",
    "@vocation.optimale": "Vocation Optimale",
    "@fd.descriptif2-com.revenus": "Revenus",
    "@schl.annuel":"SCHL ANNUEL",
    "@loyer.additionnel.estime": "Loyer additionnel estimé",
    "@etage": "Étage",
    "@financement": "Financement",
    "@conventionnel": "Conventionnel",
    "@schl": "SCHL",
    "@tga.financement": "TGA de financement",
    "@ratio.dette": "Ratio de dette",
    "@taux.interets": "Taux d'intérêts",
    "@amortissement": "Amortissement",
    "@ratio.couverture.dette": "Ratio de couverture de dettes",
    "@assumation" : "Assumation",
    "@montant.pret.maximum": "Montant du prêt maximum",

    "simulateur.financement": "Simulateur de financement",
    "prix.propriete": "Prix de la propriété",
    "mise.de.fonds.necessaire": "Mise de fonds nécessaire",
    "montant.financement.hypo": "Montant de financement hypothécaire",
    "versement.mensuel": "Versement mensuel",
    "montants": "Montants",
    "montant.actuel": "Montant actuel",
    "montant.ajuste": "Montant potentiel",
    "pourcen.ajustement": "% ajustements",

    "@terrain": "Terrain",
    "@batiment": "Bâtiment",
    "@evaluationTotal": "Totale",

    "@header.alouer": "À louer",
    "@header.avendre": "À vendre",
    "@header.telephone": "Téléphone",
    "@header.cellphone": "Cellulaire",
    "@header.courriel": "Courriel",

    "@message-mapbox": "UTILISER CTRL + ROULETTE POUR POUVOIR ZOOM DANS LA CARTE",
    "@message-mapbox-mobile": "UTILISER DEUX DOIGTS POUR INTERAGIR AVEC LA CARTE",

    "@pmmltv.chaines.choisir": "Choisir votre type d’actif",
    "@pmmltv.chaines.tout": "Toutes catégories",
    "@pmmltv.voir-plus": "Voir plus",
    "@pmmltv.chaine.1923": "Évaluation Agréée",
    "@pmmltv.chaine.1929": "VLOG",
    "@pmmltv.chaine.197": "Indicateurs de marché",
    "@pmmltv.chaine.63": "Conseils de financement",
    "@pmmltv.chaine.32": "Conseils d’investissement",
    "@pmmltv.chaine.169": "Histoires de succès",
    "@pmmltv.chaine.151": "PMML sur la route",
    "@pmmltv.chaine.97": "Café PMML",
    "@pmmltv.chaine.1637": "Investissement terrain",
    "@pmmltv.chaine.1722": "Perspectives de marché",
    "@pmmltv.chaine.1751": "Entrevue avec professionnels",
    "@pmmltv.chaine.1817": "En route vers mon plex",

    "@pmmltv.chaine.2016": "Visionnez nos épisodes informatifs pour approfondir vos connaissances sur l’investissement immobilier, multilogements et commercial.",
    "@pmmltv.chaine.2016.texte": "Visionnez nos épisodes informatifs pour approfondir vos connaissances sur l’investissement immobilier, multilogements et commercial.",
    "@pmmltv.chaine.1996" : "Financement / Hypothécaire",
    "@pmmltv.chaine.1996.texte" : "Visionnez nos épisodes informatifs pour approfondir vos connaissances sur l’investissement immobilier, multilogements et commercial.",
    "@pmmltv.chaine.1984" : "Multilogements",
    "@pmmltv.chaine.1984.texte" : "Visionnez nos épisodes informatifs pour approfondir vos connaissances sur l’investissement immobilier, multilogements et commercial.",
    "@pmmltv.chaine.1986" : "Industriel",
    "@pmmltv.chaine.1986.texte" : "Visionnez nos épisodes informatifs pour approfondir vos connaissances sur l’investissement immobilier, multilogements et commercial.",
    "@pmmltv.chaine.1992" : "Terrain",
    "@pmmltv.chaine.1992.texte" : "Visionnez nos épisodes informatifs pour approfondir vos connaissances sur l’investissement immobilier, multilogements et commercial.",
    "@pmmltv.chaine.2138" : "Bureau",
    "@pmmltv.chaine.2138.texte" : "Visionnez nos épisodes informatifs pour approfondir vos connaissances sur l’investissement immobilier, multilogements et commercial.",
    "@pmmltv.chaine.1990" : "Commerce de détail",
    "@pmmltv.chaine.1990.texte" : "Visionnez nos épisodes informatifs pour approfondir vos connaissances sur l’investissement immobilier, multilogements et commercial.",
    "@pmmltv.chaine.1988" : "Plex",
    "@pmmltv.chaine.1988.texte" : "Visionnez nos épisodes informatifs pour approfondir vos connaissances sur l’investissement immobilier, multilogements et commercial.",

    "@pmmltv.chaine.1923.texte": "Découvrez les différentes astuces de nos évaluateurs agréés sur la valeur d’un bien ou d’un droit immobilier.",
    "@pmmltv.chaine.1929.texte": "Visionnez les épisodes «behind-the-scene» de la vie professionnelle de nos courtiers.",
    "@pmmltv.chaine.29.texte": "Visionnez nos épisodes informatifs pour approfondir vos connaissances sur l’investissement immobilier, multilogements et commercial.",
    "@pmmltv.chaine.undefined.texte": "Vous avez un projet d’envergure? Visionnez nos épisodes informatifs sur l’investissement.",
    "@pmmltv.chaine.null.texte": "Vous avez un projet d’envergure? Visionnez nos épisodes informatifs sur l’investissement.",
    "@pmmltv.chaine.197.texte": "Consultez nos épisodes informatifs sur les principaux indicateurs économiques.",
    "@pmmltv.chaine.63.texte": "Consultez nos épisodes informatifs sur le financement et optimisez vos objectifs.",
    "@pmmltv.chaine.32.texte": "Vous avez un projet d’envergure? Visionnez nos épisodes informatifs sur l’investissement.",
    "@pmmltv.chaine.169.texte": "Laissez-vous inspirer par le parcours de ses investisseurs à succès.",
    "@pmmltv.chaine.151.texte": "Rencontrez des investisseurs à succès et apprenez davantage sur leurs projets.",
    "@pmmltv.chaine.97.texte": "Soyez des nôtres autour de la table pour connaître le cheminement de plusieurs investisseurs.",
    "@pmmltv.chaine.1637.texte": "Découvrez nos épisodes informatifs sur le développement de terrain",
    "@pmmltv.chaine.1722.texte": "Découvrez nos épisodes informatifs sur les plus récentes perspectives de marché.",
    "@pmmltv.chaine.1751.texte": "Découvrez les diverses entrevues entretenues avec les professionnels qui touchent notre industrie !",
    "@pmmltv.chaine.1817.texte": "Découvrez nos épisodes informatifs sur l’achat ou la vente de plex.",

    "@propriete.type": "Type de propriété",
    "@propriete.type.tout": "Toutes les propriétés",
    "@propriete.type.plex": "Plex ",
    "@propriete.type.duplex": "Duplex",
    "@propriete.type.triplex": "Triplex",
    "@propriete.type.quadruplex": "Quadruplex",
    "@propriete.type.quintuplex": "5 à 11 logs.",
    "@propriete.type.multilogement": "Multilogements",
    "@propriete.type.marchesCapitaux": "Marché des capitaux",
    "@propriete.type.multilogtypea": "12 à 24 logements",
    "@propriete.type.multilogtypeb": "25 à 49 logements",
    "@propriete.type.multilogtypec": "50 logements + et portfolios",
    "@propriete.type.semiCommercial": "Semi-commercial",
    "@propriete.type.commerceDetail": "Commerce de détail",
    "@propriete.type.recent": "Construction 2010 et plus",
    "@propriete.type.divertissement": "Divertissement",
    "@propriete.type.bureaux": "Bureaux", 
    "@propriete.type.commercial": "Commercial",
    "@propriete.type.industriel": "Industriel",
    "@propriete.type.terrains": "Terrain",
    "@propriete.type.rpa": "Résidence privée pour aînés",
    "@propriete.type.portfolio": "Portfolio",
    "@propriete.type.hotel": "Hôtellerie et hébergement",
    "@propriete.type.medical": "Médical",
    "@propriete.type.plex5a11": "Plex 5-11 unités",
    "@propriete.type.fondCommerce": "Fonds de commerce",

    "@region.montrealouest": "Montréal Ouest de St-Laurent",
    "@region.montrealest": "Montréal Est de St-Laurent",
    "@region.lavalrivenord": "Laval / Rive-Nord",
    "@region.rivesudmtl": "Rive-Sud de Montréal",
    "@region.lanaudiere": "Lanaudière",
    "@region.estrie": "Estrie",
    "@region.mauricie": "Mauricie",
    "@region.outaouais": "Outaouais",
    "@region.saguenay": "Saguenay",
    "@region.centreduquebec": "Centre-du-Québec",
    "@region.regiondequebec": "Région de Québec",

    "@modifier.recherche.profil": "Vous pouvez vous inscrire pour recevoir nos dernières nouveautés",
    "@propriete.non.dispo.critere": "Aucune propriété n'est actuellement en vente selon vos critères de recherche.",
    "@propriete.non.dispo": "Aucune propriété disponible en ce moment!",
    "@favoris.non.dispo": "Vous avez actuellement aucun favori ou ceux que vous aviez ont été vendus malheureusement. Vous pouvez ajouter plus de favoris ",
    "@favoris.non.dispo.ici": "ici.",

    "Courtier immobilier": "Courtier immobilier",
    "Directeur Marketing constructions récentes": "Directeur Marketing constructions récentes",
    "Courtier Immobilier agréé": "Courtier Immobilier agréé",
    "Courtier Immobilier Commercial": "Courtier Immobilier Commercial",
    "Agent de location": "Agent de location",
    "Courtier Hypothécaire Commercial": "Courtier Hypothécaire Commercial",
    "@menu.equipeCourtier": "Équipe ",
    "@menu.courtierSeul": "Courtier PMML ",

    "@moncompte": "Mon compte",
    
    "@type.venteLocation": "Vente/location",
    "@propriete.venteEtLocation": "Tout",
    "@propriete.vente": "Vente",
    "@propriete.aVendre": "À vendre",
    "@propriete.location": "Location",
    "@propriete.aLouer": "À louer",

    "@menu.commercial.superficie": "Superficie disponible",
    "@menu.terrain.superficie": "Superficie",
    "@menu.multilogement.unites": "Unités",
    "@menu.multilogement.prixdemande": "Prix demandé",
    "@fiche.prix.plusTaxes": "+ TPS/TVQ",
    "@fiche.prix.plusTaxesSemi": "+ TPS/TVQ sur la portion commerciale",

    "@fd.commercial.infosFoncieres": "Informations foncières",
    
    "@zonage": "Zonage",
    "@frais.operations": "Frais d'opération",
    "@assurances": "Assurances",
    "@electricite": "Électricité",
    "@entretien.reparation": "Entretien et réparations",
    "@deneigement": "Déneigement",
    "@contrat.service": "Contrat de services",
    "@salaires": "Salaires",
    "@trouver": "Trouver",
    "@frais.admin": "Frais administratifs",
    "@gaz": "Gaz",
    "@bureaux": "Bureaux", 
    "@frais.gestion": "Frais Gestion",
    "@depenses.total": "Dépenses totales",
    "@depenses.total.annuel": "Dépenses totales annuel",
    "@reserve.structurel": "Réserve Structurelle",
    "@depenses.non.recup": "Dépenses non récupérables",
    "@description.propriete": "Description de la propriété",
    "@description.portfolio": "Description du Portfolio",
    "@espace.locatif.disponible" : "Espace locatif disponible",
    "@espace.locatif" : "Espace locatif",
    "@espaces.locatifs" : "ESPACES LOCATIFS",
    "@description.local": "Description de l'espace locatif",
    "@commerce.de.details": "Commerce de détails",
    "@residentiel": "Résidentiel",
    "@semi.commercial": "Semi-Commercial",
    "@industriel": "Industriel",
    "@terrains": "Terrains",
    "@rpa": "Résidence pour personnes agés",
    "@commercial": "Commercial",
    "@info.commercial.type": "Type d'immeuble",
    "@info.faitssaillants": "Faits saillants",
    "@info.categorie.immeuble": "Catégorie d'immeuble",
    "@info.cadastre": "Numéro de cadastre",
    "@ilocal.superficie": "Superficie disponible",
    "@info.superficie.louable": "Superficie louable",
    "@superficie.disponible.contigue": "Superficie Disponible Contiguë",
    "@info.price.sqft": "Prix par pi²",
    "@info.annee.construction": "Année de construction",
    "@pi2": "pi²",
    "@voir.fiche.descriptive": "Voir la fiche",
    "@voir.immeuble": "Voir l'immeuble",
    "@voir.fiche.visite": "Visite en",
    "@unites": "Unités",
    "@nombre.unites": "Nombre d'unités",
    "@type.logements": "Type d'immeuble",
    "@amenagement.actuel": "Aménagement actuel",
    "@multilogement": "Multilogement",

    "@favoris.tooltip.contenu": "Sélectionnez l'étoile pour ajouter cette propriété à vos favoris. Vous pourrez ainsi consulter cette propriété ultérieurement, directement via votre compte.",
    "@ne.plus.afficher.message": "Ne plus afficher ce message",
    "@mespreferences": "Mes préférences",
    "@monprofil": "Mon profil",
    "@modifierprofil": "Modifier mon profil",
    "@mesfavoris": "Mes favoris",

    "@annee.renovation": "Année de rénovation",
    "@map.attraits": "Principaux attraits du secteur",
    "@evaluation.municipale.terrain": "Évaluation municipale du terrain",
    "@evaluation.municipale.batiment": "Évaluation municipale du bâtiment",
    "@evaluation.municipale.total": "Évaluation municipale totale", 
    "@descriptif.titre": "Descriptif de l'immeuble",
    "@descriptif.nbunites": "Nombre d'unités",
    "@nb.etages.permis": "Nombre d'étages permis",
    "@rdc": "RDC",
    "@descriptif.etages": "Étages",
    "@descriptif.nbetages": "Nombre d'étages",
    "@descriptif.structure": "Type de structure",
    "@transports": "Transports en commun",
    "@rangement.velos": "Rangement pour vélos",
    "@stationnement.interieur": "Stationnement Intérieur",
    "@stationnement.exterieur": "Stationnement Extérieur",
    "@prix.stationnement.reserve": "Prix stationnement réservé",
    "@prix.stationnement.nonreserve": "Prix stationnement non-réservé",
    "@opportunite.signalisation": "Opportunité de signalisation",
    "@densite": "Densité permise",
    "@prix.sqft": "Prix / pi²",
    "@services.publics.disponibles": "Services Publics Disponibles",
    "@usages.particuliers": "Usages Particuliers",
    "@dispositions.particulieres": "Dispositions Particulières",
    "bonjour": "Bonjour,",
    "maj.tout.temps": "Vous pouvez mettre à jour vos informations en tout temps.",
    "table-matieres": "Table des matières",
    "prepare-pour": "Préparé pour",
    "emprunteur": "Emprunteur",
    "emprunteurs": "Emprunteur(s)",
    "garant": "Garant",
    "garants": "Garant(s)",
    "prepare-par": "Préparé par",
    "ans": "Ans",
    "actuel": "Actuel",
    '@annuelles': 'annuelles',
    "@annuels": "annuels",
    '@annuel': 'annuel',
    "@recup": "Récupération",
    "@recup.taxes": "Récupération taxes",
    "@recup.assurances": "Récupération asurances",
    "@recup.maintenance": "Récupération maintenance",
    "suite": "Suite",
    "@depenses": "Dépenses",
    "@revenus.brut.total": "Revenus Bruts Totaux",
    "@superficie.batiment": "Superficie du batiment",
    "@superficie.louable.sqft": "Superficie louable",
    "@superficie.totale.brut.sqft": "Superficie totale brut",
    "@superficie.terrain": "Superficie du terrain",
    "dimension.terrain": "Dimension du terrain",
    "dimension.cuisine": "Dimension de la cuisine",
    "dimension.chambres": "Dimension des chambres",
    "hy-p": "Documents afférents à l’immeuble",
    "hy-p-index": "Index des immeubles",
    "hy-p-role": "Rôle d’évaluation",
    "hy-p-taxes-m": "Taxes municipales",
    "hy-p-taxes-s": "Taxe scolaire",
    "hy-p-hydro": "Hydro-Québec",
    "hy-p-gaz-met": "Gaz Metropolitain",
    "hy-p-rentroll": "Sommaire des loyers résidentiels",
    "hy-p-certificat-loc": "Certificat de localisation",
    "hy-a": "Documents visant l’emprunteur",
    "hy-g": "Documents visant le garant",
    "hy-a-bilan": "Bilan",
    "hy-a-credit": "Rapport de crédit",
    "hy-a-etat-avoir": "État de l’avoir net personnel",
    "hy-a-programme-schl": "Programme SCHL",
    "hy-a-avis-cotisation": "Avis de cotisation",
    "hy-a-releve-taxes": "Relevé de taxes",
    "hy-a-etat-loyers": "État des loyers de biens immeubles",
    "hy-pa": "Promesse d'achat et fiche descriptive",
    "@superficie.moyenne.unite.sqft": "Superficie moyenne par unité",
    "@evaluation.municipale": "Évaluation Municipale",
    "@cuisinette": "Cuisinette",
    "@toilettes": "Toilettes",
    "@climatisation": "Climatisation",
    "@ascenseur": "Ascenseur",
    "@chauffage": "Chauffage",
    "cloture": "Clôturé",
    "topographie": "Topographie",
    "titreSecurite": "Security",
    "@nettoyage.quotidien": "Daily cleaning",
    "@conditions.location": "Conditions de location",
    "@terme.bail": "TERMES DU BAIL",
    "@ans": "ans",
    "@nondisponible": "N/D",
    "@surdemande": "Sur demande",

    "taille.appartement": "Superficie de l'appartement",
    "nombre.de.pieces": "Nombre de pièces",

    "@depenses.type.actuel": "Actuel",
    "@depenses.type.schl": "SCHL",
    "@depenses.type.estime": "Estimé",
    "@depenses.type.normalise": "Normalisé",


    "consultation.goplex": "Pour la vente, l’achat et le financement de Plex, découvrez Goplex.com,",
    "consultation.goplex2": "le nouveau site spécialisé en plex propulsé par PMML.",
    "consultation.landev": "Pour la vente, l’achat et le financement de terrain, découvrez Landev.ai,",
    "consultation.landev2": "le nouveau site spécialisé en terrain propulsé par PMML.",
    "acces.landev": "Accéder à LANDEV.AI",
    "acces.goplex": "Accéder à GOPLEX.COM",
    "@bureau.gestionnaire": "Bureau du gestionnaire",
    "titreConditions": "Conditions Precedent",
    "@internet.telecom": "internet et télécom",
    "texteSecurite1": "A first mortgage charge over land and improvements located at the subject in accordance with the Quebec Law;",
    "texteSecurite2": "A general immovable hypothec on all Rents and Leases; ",
    "texteSecurite3": "A first ranking hypothec on the moveables;",
    "texteSecurite4": "Such other security as may be required by the Lender and/or its solicitor.",
    "eclairage": "Éclairage",
    "syssecurite": "Système de sécurité", 
    "texteConditions1": "Title must to acceptable to the Lender.",
    "texteConditions2": "Satisfactory Phase I Environmental Report from an approved consultant.",
    "syselectromec": " Système Électromécanique",
    "@hauteurlibre": "Hauteur libre",
    "titre": "Description de l'immeuble",
    "titreCaracteristiques": "Caractéristiques",
    "titreConstruction": "Construction",
    "titreType": "Type d'immeuble",
    "sSysChauffage": "Système pour le chauffage",
    "sRespChaufeEau": "Système pour l'eau chaude",
    "sPaneauElectrique": "Panneaux électriques",
    "sPlomberie": "Plomberie",
    "sEtatCuisines": "Condition des cuisines",
    "sEtatCB": "Condition des salles de bain",
    "sPlanchers": "Recouvrement des planchers",
    "sEtudeEnviro": "Étude environnementale",
    "sAnneeToit": "Condition du toit",
    "sRevetementExt": "Revêtement extérieur",
    "sBalcons": "Condition des balcons",
    "sTypePortes": "Condition des portes ",
    "sFenetres": "Condition des fenêtres",
    "sRevetStationnement": "Type de stationnement",
    "sSonette": "Intercom et sonnette",
    "sSysIncendie": "Système d'alarme Incendie",
    "sAutre4": "Entente concierge",
    "iAnneeConstruction": "Année de construction",
    "sTypeBatiment": "Type de bâtiment",
    "sTypeConstruction": "Type de construction",
    "iEtages": "Nombre d’étages",
    "sAutre2": "Autres informations",
    "sRepartition": "Nombre d'unités",
    "sStationnements": "Nombre de stationnements",
    "sRespElectricite": "Responsabilité du chauffage",
    "sRespEauChaude": "Responsabilité de l’eau chaude",
    "sRespElectros": "Responsabilité des électros",
    "sLaveuseSecheuse": "Entrées laveuse/sécheuse",
    "sBuanderie": "Buanderie",
    "@buanderie": "Buanderie",
    "@tresorerie" : "Trésorerie",
    "@paiement.hyp.annuel": "Paiement Hypothécaire Annuel",
    "@informations.generales": "Informations Générales",
    "@rangement": "Rangement",
    "sAutre3": "Autres informations",
    "sRenoMajeur10Ans": "Dépenses en capital importantes depuis 10 ans",
    "@stationnement": "Stationnement",
    "titre-informations-financieres": "Informations financières",
    "titreHistorique": "Informations financières historique",
    "titreRevenus": "Revenus",
    "@revDep.proprietaireOccupant": "Propriétaire occupant",
    "@revDep.Investisseur": "Investisseur",
    "titreDepenses": "Dépenses",
    "titreIndicateurs": "Indicateurs",
    "titreFinancement": "Financement",
    "titreTresorerie": "Trésorerie",
    "titreInformationsSup": "Informations supplémentaires",
    "titreCourtageImmobilier": "Courtage Immobilier",
    "titreCourtageHypothecaire": "Courtage Hypothécaire",
    "montant": "Montant",
    "ValeurEcon": "Valeur économique",
    "ValeurMarchande": "Valeur marchande",
    "RPL": "RPL",
    "CPL": "CPL",
    "MRB": "MRB",
    "MRN": "MRN",
    "@cout.par.logement": "Coût par logement",
    "@liquidite.mensuel": "Liquidité mensuel",
    "@multiplicateur.revenus.net": "Multiplicateur de revenu net",
    "@multiplicateur.revenus.brut": "Multiplicateur de revenu brut",
    "@taux.global.actualisation": "Taux global d'actualisation",
    "@terme": "Terme",
    "@disponibilite": "Disponibilité",
    "TGA": "TGA",
    "Conventionnel": "Conventionnel",
    "SCHL": "SCHL",
    "fRevenusRes": "Revenus résidentiels",
    "fRevenusComm": "Revenus commerciaux",
    "fRevenusStationnement": "Revenus stationnements",
    "fRevenusBuanderie": "Revenus buanderie",
    "fRevenusLockers": "Revenus rangements",
    "fRevenusAutres": "Revenus autres",
    "fTotalRevenus": "Revenus totaux",
    "fVacMauvaiseCHarmo": "Vacances / Mauv. Créances",
    "fAdministrationHarmo": "Administration",
    "fTaxes": "Impôts fonciers",
    "fTaxesMunicipalesHarmo": "Taxes municipales",
    "fTaxesScolairesHarmo": "Taxes scolaires",
    "fAssurancesHarmo": "Assurances",
    "fElectriciteHarmo": "Électricité",
    "fChauffageHarmo": "Chauffage", 
    "fEnergie": "Services publics", 
    "fDeneigementHarmo": "Déneigement",
    "fAscenceurHarmo": "Ascenseur",
    "fGazonHarmo": "Location d'équipement",
    "fEntretien": "Réparations et entretien",
    "fEntretienHarmo": "Réserve entretien",
    "fConciergeHarmo": "Salaire / concierge",
    "freservefElectromenagers": "Réserve pour mobilier",
    "fDepensesAutresHarmo": "Autres dépenses",
    "fTotalDepenses": "Dépenses total",
    "reserve.mobilier": "Réserve pour mobilier",
    "fRevenuNet": "Revenu net",
    "Montant_FinPos": "Montant du prêt",
    "MiseDeFond": "Mise de fonds",
    "fTGA": "TGA de financement",
    "fMRB": "MRB de financement",
    "fRatioRCD": "Ratio de couverture de dette",
    "fRatioRCDexSCHL": "RCD excl. prime SCHL",
    "fRatioPretPrix": "Ratio prêt / prix",
    "fRatioPretVE": "Ratio prêt / VÉ",
    "fTaux": "Taux d'intérêt",
    "iAmortissement": "Amortissement",
    "iTerme": "Terme",
    "@loyers.additionnels": "Loyers Addtionnels Estimés ($/pi²)",
    "@loyers.net": "Loyer net ($/pi²)",
    "@en.savoir.plus": "En savoir plus",
    "@rendement.sur.liquidite": "Rendement sur liquidité",
    "@rendement.sur.liquidite.capitalisation": "Rendement sur liquidité + capitalisation",
    "@mise.de.fond.necessaire": "Mise de fond nécessaire",
    "@location.price.sqft": "Location ($/pi²)",
    "PaiementAnnuel": "Paiement hypothécaire annuel",
    "LiquiditePret": "Liquidité après hypothèque",
    "fPrimeSCHL": "Prime SCHL",
    "titreRepartition": "Répartition des logements",
    "colNombre": "Nombre",
    "colMoyenne": "Moyenne des loyers",
    "colTotalMensuel": "Total mensuel",
    "colTotalAnnuel": "Total annuel",
    "colLocataire": "Locataire",
    "@aire.reception": "Aire de réception",
    "@salle.reunion": "Salle de réunion",
    "colDebut": "Début",
    "@echeance.baux": "Échéance des baux",
    "@mois": "Mois",
    "colElectros": "Électros",
    "totalResidentiels": "Mensualité des loyers résidentiels",
    "totalCommerciaux": "Mensualité des loyers commerciaux",
    "totalRangements": "Mensualité des rangements",
    "totalStationnements": "Mensualité des stationnements",
    "totalAutre": "Autre",
    "prixVendu": "Prix VENDU",
    "dateVendu": "VENDU depuis",
    "@CPL": "Coût par logement (CPL) ",
    "@cout.occupation": "Coût d'occupation ",
    "@MRB": "Multi. de rev. brut (MRB) ",
    "@MRN": "Multi. de rev. net (MRN)",
    "@TGA": "Taux global d'act. (TGA)",
    "nombreUnites": "Nombre d’unités",
    "typeUnites": "Type d'unité",
    "detailConstruction": "Construction",
    "responsableChauffage": "Resp. du chauffage",
    "responsableEauCheaude": "Resp. de l'eau chaude",
    "distance": "Distance du sujet",
    "titreObjectif": "Objectif",
    "titreUtilisation": "Utilisation",
    "titrePlanFinancier": "Plan financier",
    "titreSource": "Sources",
    "titreUtilisationFonds": "Utilisation des fonds",
    "titreRemboursement": "Remboursement",
    "texteObjectif-fin": "To provide a new CMHC first mortgage of {montant} to facilitate the purchase of the subject.",
    "texteObjectif-ref": "To provide a new CMHC first mortgage of {montant} to facilitate the refinance of the subject.",
    "premiereHy": "Première hypothèque",
    "miseDeFond": "Mise de fond",
    "total": "Total",
    "prixAchat": "Prix d’achat",
    "montantRefinancement": "Valeur",
    "nouvellePremiereHy": "Nouvelle première Hypotrèque",
    "RPV": "Ratio prêt-valeur",
    "@CPLL": "Valeur par unité",
    "CPC": "Valeur par chambre",
    "GIM": "GIM",
    "terme": "Terme",
    "amortissement": "Amortissement",
    "NOI": "NOI",
    "serviceDette": "Service de la dette",
    "RCD": "Couverture de la dette",
    "texteUtilisationFonds-fin": "TODO TODO TODO",
    "texteUtilisationFonds-ref": "TODO TODO TODO",
    "texteRemboursement": "TODO TODO TODO",
    "texteTauxInteret": "The interest rate for calculation purposes is {tauxCalc}%. Today’s rate is {tauxAct}% for {ansTerme} years",
    "repartitionPieces": 'Répartition des pièces',


    "donneesLocatif": "Statistiques du marché locatif",
    "moyenne": "Moyenne",
    "medianne": "Médianne",  
    "loyerMin": "Loyer Min.",  
    "loyerMax": "Loyer Max.",
    "typeDelogement": "Type de logement",
    "moyenneRegion": "Moyenne de la région",
    "distributionLoyers": "Distribution des loyers",
    "loyerMoyenAnnee": "Loyer moyen par année",
    
    "titre_stats_sociodemo" : "Statistiques Socio-Démographiques",
    "population_totale": "Population totale",
    "age_moyen" : "Âge moyen de la population",
    "age_proportion" : "Proportion par tranche d'âge",
    "population_active" : "Population active",
    "indicateurs_economique" : "Indicateurs économique",
    "taux_emploi" : "Taux d'emploi",
    "chomage" : "Taux de chômage",
    "revenu_disponible": "Revenu disponible par habitant",
    "croissance_population": "Croissance de la population",
    "habitants": "Habitants",
    "province": "Province",
    "dollar_paran": "$ / an",
    "region_admin": "Région",
    "region_de": "Région de",

    "@carte.style.satellite": "Satellite",
    "@carte.style.rue": "Rue",


    "@goplex.footer.disclaimer": "Les remarques, descriptions, caractéristiques et projections financières contenues dans le présent document sont à titre de renseignement seulement et ne doivent pas être considérées comme étant officielles. Les renseignements ci-inclus proviennent de sources que nous considérons fiables, mais pour lesquels nous ne pouvons garantir l’exactitude. C’est à l’acheteur de prendre la responsabilité de tout vérifier et de s’en déclarer satisfait ou insatisfait lors du processus de vérification diligente suite à une promesse d’achat acceptée.",
    "@goplex.footer.centrevillemtl": "CENTRE-VILLE MONTRÉAL",
    "@goplex.footer.montreal": "MONTRÉAL",
    "@goplex.footer.quebec": "QUÉBEC",
    "@goplex.footer.rivenord": "LAVAL / RIVE-NORD",
    "@goplex.footer.rivesud": "LONGUEUIL / RIVE-SUD",
    "@goplex.footer.rendezvous": "Sur rendez-vous seulement",
    "@goplex.footer.pmmldescriptif": "PMML AGENCE IMMOBILIÈRE ET CABINET EN COURTAGE HYPOTHÉCAIRE",
    "@contact.info.header": "Demande d'info",
    "@chat.dialog.start": "Soumettre",
    "@revenues.bubble": "Veuillez selectionner le logement occupé",
    "@connexion": "Pour utiliser la calculatrice de financement de cet immeuble, vous devez être connecté.",
    "@connexion.ok": "Ce connecter",
    "@connexion.cancel": "Annuler",

    "@ajoutercontact": "Ajouter à mes contacts",
    "@partagercontact": "Partager le contact",
    "@wpformid": "23975",
    "@wpformid.nouveau": "1756",
    "@proprietescourtier": "Mes propriétés",
    "@proprietespmml": "Propriétés PMML",
    "@liencopie": "Lien copié",
    "@qr-instructions": "Pointez votre appareil photo sur le code QR pour recevoir la carte",
    "@sujetemail": "Coordonnées de",
    "@copierlien": "Copier le lien",
    "@partagerpartext": "Partager par message texte",
    "@partagerparemail": "Partager par courriel",
    "@partagerparfacebook": "Partager par Facebook",
    "@partagerparlinkedin": "Partager par LinkedIn",

    "@rapportsmarche": "Rapports de marché",
    "@evaluationfin": "Recevez une évaluation de financement",
    "@evaluationmarchande": "Recevez une évaluation marchande",
    "@evaluationimmobilier": "Recevez une évaluation agréée",

    "@verifier.titre": "Confirmer votre identité",
    "@verifier.phone.soustitre": "Recevez le code d’activation par SMS pour accéder à cette page.",
    "@verifier.accept.sms": "J'accepte de recevoir des communications de PMML à mon numéro mobile.",
    "@verifier.phone.bouton": "Confirmer",
    "@verifier.code.soustitre": "Saisissez le code envoyé sur votre appareil mobile.",
    "@verifier.code.bouton": "Confirmer",
    "@verifier.code.invalid": "Code invalide",
    "@verifier.code.back": "Envoyez un nouveau code",
    "@verifier.verified.soustitre": "Votre numéro de téléphone a été confirmé avec succès.",

    "@rapports.hypothecaire": "Marché Hypothécaire",
    "@rapports.marche-capiteaux": "Marché des capitaux",
    "@rapports.telecharger": "Télécharger",

    "@rapports.ouvrirLien": 'Votre rapport est prêt !',
    "@rapports.ouvrirLienMessage": 'Voir le rapport',
    "@rapports.provincial": 'Provincial',

    '@demande-dataroom.activator': 'Demande accès dataroom',
    '@demande-dataroom.titre': 'Demande de Dataroom',
    '@demande-dataroom.prenom-input': 'Prénom',
    '@demande-dataroom.nom-input': 'Nom',
    '@demande-dataroom.courriel-input': 'Courriel',
    '@demande-dataroom.adresse-input': 'Adresse',
    '@demande-dataroom.phone-input': 'Numéro de téléphone',
    '@demande-dataroom.confirme-code': 'Saisissez le code de confirmation reçu par SMS',
    '@demande-dataroom.annuler': 'Annuler',
    '@demande-dataroom.continuer': 'Continuer',
    '@demande-dataroom.accepter': 'Accepter',
    '@demande-dataroom.fermer': 'Fermer',
    '@demande-dataroom.demande-envoyer1': 'Votre demande a été envoyée avec succès.',
    '@demande-dataroom.demande-envoyer2': 'Tu recevras un courriel dès que le dataroom sera prêt.',

    '@demande-dataroom.erreur.nom': "Veuillez saisir votre nom",
    '@demande-dataroom.erreur.prenom': "Veuillez saisir votre prénom",
    '@demande-dataroom.erreur.courriel': "Veuillez saisir une adresse email valide",
    '@demande-dataroom.erreur.phone': "Veuillez saisir un numéro de téléphone valide",
    '@demande-dataroom.erreur.adresse': "Veuillez saisir votre adresse",

    '@demande-dataroom.erreur-titre': 'Une erreur est survenue',
    '@demande-dataroom.erreur-message': 'Visitez notre page de contact pour nous envoyer un message.',
};