import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-pied',
  templateUrl: './pied.component.html',
  styleUrls: ['./pied.component.scss']
})
export class PiedComponent implements OnInit {
  environment = environment;

  constructor() { }

  @Input() data: any;

  ngOnInit() {
  }

}
