import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ProprietesService } from "../../api/proprietes.service";
import { WordpressService } from "../../api/wordpress.service";
import { AuthService } from "../../auth.service";

@Component({
  selector: 'app-multilogement',
  templateUrl: './multilogement.component.html',
  styleUrls: ['./multilogement.component.scss']
})
export class MultilogementComponent implements OnInit {

  connected: boolean = false;

  constructor(
    private wp: WordpressService,
    private proprietes: ProprietesService,
    private auth: AuthService,
  ) { }

  environment = environment;
  @Input() data: any;

  ngOnInit() {
    this.auth.user$
    .subscribe((user) => {
      this.connected = user ? true : false;
    });
  }
  
  seConnecter() {
    return this.auth.login(window.location.pathname + '#sommaires');
  }
}
