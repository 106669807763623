import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { datadogRum } from '@datadog/browser-rum';
import * as moment from 'moment';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import { SegmentService } from './../api/segment.service';

const DELAI_OUVERTURE_SECONDES =25_000

@Component({
  selector: 'app-chat-pmml',
  templateUrl: './chat-pmml.component.html',
  styleUrls: ['./chat-pmml.component.scss'],
  animations: [
    trigger(
      'animationChatContaineur', [
      transition(':enter', [
        style({ transform: 'translateZ(100%)', opacity: 0 }),
        animate('100ms', style({ transform: 'translateZ(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateZ(0)', opacity: 1 }),
        animate('100ms', style({ transform: 'translateZ(100%)', opacity: 0 }))
      ])
    ]
    )
  ]
})

export class ChatPmmlComponent implements OnInit, AfterViewInit {

  langue = document.documentElement.lang;
  environment = environment;
  // Début conversation
  tempInput: any;
  popUpOuvert: boolean = false;
  masquerChat: Boolean = false;
  masquerCourriel: Boolean = true;
  debutConversation: any[] = [];
  debutConversationBouton: any[] = [];
  messageChargement = false;
  formulaireClient: FormGroup;

  arrChoixClient = [];

  chatMessages = [];
  longueurChatMessage: Number = null;
  afficherMediaSociaux: boolean = false;
  mediaSociaux = [
    { "text": "Facebook", value: "https://www.facebook.com/pmml.ca"},
    { "text": "Instagram", value: "https://www.instagram.com/pmml.ca/"},
    { "text": "Linkedin", value: "https://www.linkedin.com/company/pmml"},
    { "text": "Spotify", value: "https://open.spotify.com/show/0uHkvJ2ZoBcHdhvOpaNUkK"},
    { "text": "Youtube", value: "https://www.youtube.com/channel/UCJCTqgUX8u5UtnDPdE8OmPA"},
  ]
  informationUtilisateur: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private segment: SegmentService
  ) { }

  private socket: io.Socket;
  donneesConnexion: any;
  ouvertureAutomatique: Boolean = false;
  indexOuverture: number = 0;
  nbReponses: number = 0;

  @ViewChild('sectionChat', {static: false, read: ElementRef}) sectionChat: ElementRef;
  @ViewChildren("chatMessagesDivs", {read: ElementRef}) chatMessagesDivs: QueryList<ElementRef>;
  
  ngOnInit(): void {
    try {
      this.formulaireClient = this.formBuilder.group({
        prenomClient: ['', Validators.required],
        nomClient: ['', Validators.required],
        courrielClient: ['', Validators.email],
        numeroTelClient: ['', [Validators.required, Validators.pattern(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/)]],
        messageClient: ['', Validators.required],
      })

      this.socket = io.io('/', {path: '/api/socket.io', transports:['websocket', 'polling']})
      .on('error', (err) => {
        datadogRum.addError(err);
      })
      .on('disconnect', () => {})
      .on('connect', () => this.socket_onConnect())
      .on('session', (data) => this.socket_onSession(data))
      .on('message', (data) => {
        const delai = 300 + Math.random() * 200; 
        this.messageChargement = true;
        window.setTimeout(() => this.socket_onMessage(data), delai);
      });
      
    } catch (error) {
      console.warn(error);
    }
  }

  ngAfterViewInit(): void {
    this.chatMessagesDivs.changes
    .subscribe(() => {
      for (const item of Array.from(this.chatMessagesDivs).reverse()) {
        if(item?.nativeElement?.classList && !item.nativeElement.classList.contains('doScroll')) continue;
        item.nativeElement.scrollIntoView();
        // this.sectionChat.nativeElement.scrollTop += 20;
        // console.log(item.nativeElement.top);
        // this.chatScrollTop = item.nativeElement.top;
        break;
      }
    });
  }

  socket_onConnect() {
    // Initialisation de la conersation
    if(this.donneesConnexion) return this.socket.emit('donneesConnexion', this.donneesConnexion);
    this.socket.send({ text: 'Bonjour', lang: document.documentElement.lang, });

    // Ouverture automatique une fois par jour
    const estDesktop = window.innerWidth > 768;
    const dernierOuverture = localStorage.getItem("PMML_CHAT_OUVERT") || 0
    const diff = moment().diff(moment(dernierOuverture), 'hours');

    if (diff >= 24 && !this.ouvertureAutomatique && estDesktop) {
      setTimeout(() => {
        this.popUpOuvert = true;
        this.ouvertureAutomatique = true;
        this.indexOuverture++;
        localStorage.setItem("PMML_CHAT_OUVERT", new Date().toISOString());
      }, DELAI_OUVERTURE_SECONDES)
    }
  }
  socket_onSession(data) {
    this.donneesConnexion = data;
    this.socket.emit('lien', window.location.href);
    // this.socket.emit('session', window.location.href);
  }
  socket_onMessage(data) {
    for(let message of data) {
      this.interceptionMessage(message);
    }

    this.chatMessages = this.chatMessages.concat(data);
    if (!this.popUpOuvert) this.longueurChatMessage = this.chatMessages.length;
    this.messageChargement = false;
  }

  async ouvrirFermerChat() {
    this.popUpOuvert = !this.popUpOuvert;
    sessionStorage.setItem("CHAT_OUVERT", "true");
    
    // Reset du compteur de message lorsqu'ouvert
    if (this.popUpOuvert) {
      if (!this.ouvertureAutomatique || this.indexOuverture >= 1) this.eventTracker('chatOuverture', Date.now());
      this.longueurChatMessage = null; 
      this.indexOuverture++;
    } else {
      if (!this.ouvertureAutomatique || this.indexOuverture >= 1) this.eventTracker('chatFermeture', Date.now());
    }
  }

  // Ajouter premi're interaction
  async eventTracker(event, donnees) {
    console.log(event, donnees); 
    // await this.segment.track(event, donnees);

    // Google analytics
    // if (typeof (window as any)?.gtag !== 'function') return;
    (window as any)?.gtag('event', event, {
      event_category: event,
      event_label: donnees
    });
  }

  async envoyerMessage(messageOriginal, texteActuel, index, type = false) {
    if(texteActuel.disabled) return;
    this.messageChargement = true;
    this.nbReponses++;

    if(this.nbReponses == 1) this.eventTracker('firstInteraction', Date.now());
    
    this.socket.send({ messageOriginal, text: texteActuel.value, type});
    this.chatMessages[index].choices = [{ title: texteActuel.title, value: texteActuel.value, disabled: true}]
    this.tempInput = undefined;
  }

  soumettreFormulaire(message, formulaire) {
    if (this.formulaireClient.invalid) return;
    this.socket.send({ formulaire, text: 'envoiCourriel' });
    message.formulaireEnvoye = true;
  }

  // convenience getter for easy access to form fields
  get f() { return this.formulaireClient.controls; }
  
  detectionUrlMessage(message) {
    try {
      const RGX_URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i
      let rgxMatchUrl = message?.text?.match(RGX_URL);
      if (rgxMatchUrl) {
        message.text = message?.text?.replace(rgxMatchUrl[0], "");
        message.url = rgxMatchUrl[0];
        return message;
      };
    } catch (error) {
      console.error(error);
    }
  }

  interceptionMessage(message) {
    if (message.text == "formulaire-pmml") {
      delete message.text;
      message.noScroll = true;
      return message.formulaire = true;
    } 

    if (message.text == "media-sociaux") {
      delete message.text;
      return message.mediaSociaux = true;
    }
  }

  //   let dataCourriel = {
  //   "IDPropriete": 5433,
  //   "AdresseComplete": "1010 De Sallaberry, Ahunstic, Montreal",
  //   "sLien": "pmml.ca/lienla",
  //   "nomClient": "Jean",
  //   "prenomClient": "Richard",
  //   "numeroTelClient": "514-525-8525",
  //   "courrielClient": "test@gmail.com",
  //   "messageClient": "test",
  //   "nomCourtier": "Thierry Samlal",
  //   "courrielCourtier": "thierry.samlal@pmml.ca"
  // }
}
