import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProprietesService {
  constructor(private http: HttpClient) {}

  liste(filtres = {}, observable = false) {
    // if (environment?.dev) {
    //   return import('./data-props-listings').then((module: any) => {
    //     return module.proprieteList;
    //   });
    // }
    // if (!environment.production) {
    //   return import('./data-prop').then((module: any) => {
    //     return module.propriete;
    //   });
    // }

    if (observable) {
      return this.http.get('/api/proprietes/avendre', { params: filtres });
    }

    return this.http
      .get('/api/proprietes/avendre', { params: new HttpParams({ fromObject: filtres }) })
      .toPromise()
      .then((res) => {
        return res;
      });
  }


  getListingsCourtier(sCodeUsager) {
    return this.http.get(`/api/proprietes/avendre?courtier=${sCodeUsager}`)
    .toPromise()
    .then((res) => {
      return res;
    });
  }

  detail(lien) {
    // Mode développement
    if (!environment.production) {
      return import('./data-prop').then((module: any) => {
        return module.propriete;
      });
    }

    let reqURL = `/api/proprietes/avendre/${lien}`;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('a')) {
      reqURL += `?a=${urlParams.get('a')}`;
    }

    return this.http
      .get(reqURL)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((e) => {
        if (e.status === 401) return 'besoinConnecter';
        if (e.status === 403) return 'pasAuthorise';
        if (e.status === 404) return 'nonTrouve';
      });
  }

  recupFavoris() {
    if (!environment.production) {
      return import('./data-props-favoris').then((module: any) => {
        return module.favoris;
      });
    }

    return this.http
      .get(`/api/proprietes/avendre?favoris=1`)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  setFavori(sLien, ajouter = true) {
    return this.http
      .post(`/api/usager/favoris`, { ID: sLien, ajouter: ajouter ? 1 : 0 })
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  // Utilisé pour le hash param
  listeRegionsPreDefini() {
    return [
      { valeur: "Montréal, est de la rue St-Laurent", texte: "montreal-est-de-st-laurent", id: 1},
      { valeur: "Montréal ouest de la rue St-Laurent", texte: "montreal-ouest-de-st-laurent", id: 2},
      { valeur: "Montréal", texte: "montreal", id: [1,2]},
      { valeur: "Laval / Rive-nord ", texte: "laval-rive-nord", id: 3},
      { valeur: "Rive-Sud de Montréal", texte: "rive-sud-de-montreal", id: 5},
      { valeur: "Lanaudière", texte: "lanaudiere", id: 6},
      { valeur: "Estrie", texte: "estrie", id: 12},
      { valeur: "Beauce", texte: "beauce", id: 7},
      { valeur: "Mauricie", texte: "mauricie", id: 11},
      { valeur: "Outaouais", texte: "outaouais", id: 12},
      { valeur: "Saguenay", texte: "saguenay", id: 15},
      { valeur: "Centre-du-Québec", texte: "centre-du-quebec", id: 20},
      { valeur: "Région de Québec", texte: "region-de-quebec", id: 100},
    ]
  }

  listeRegionsRapport() {
    return this.listeRegionsPreDefini()
      .filter((region) => ![1, 2, 7, 15].includes(+region.id))
      .sort((a: any, b: any) =>  (a.valeur > b.valeur ? 1 : -1));
  }

  listeTypes() {
    return [

      { valeur: 'iPlex', texte: '@propriete.type.plex' },
      { valeur: 'iDuplex', texte: '@propriete.type.duplex' },
      { valeur: 'iTriplex', texte: '@propriete.type.triplex' },
      { valeur: 'iQuadruplex', texte: '@propriete.type.quadruplex' },
      { valeur: 'iQuintuplexPlus', texte: '@propriete.type.quintuplex' },
      { valeur: 'iMultilogements', texte: '@propriete.type.multilogement' },
      { valeur: 'iMultilogementA', texte: '@propriete.type.multilogtypea' },
      { valeur: 'iMultilogementB', texte: '@propriete.type.multilogtypeb' },
      { valeur: 'iMultilogementC', texte: '@propriete.type.multilogtypec' },
      { valeur: 'iSemiCommercial', texte: '@propriete.type.semiCommercial' },
      { valeur: 'iCommercial', texte: '@propriete.type.commerceDetail' },
      { valeur: 'iRecent', texte: '@propriete.type.recent' },
      { valeur: 'iIndustriel', texte: '@propriete.type.industriel' },
      { valeur: 'iBureaux', texte: '@propriete.type.bureaux' },
      { valeur: 'iTerrain', texte: '@propriete.type.terrains' },
      { valeur: 'iRPA', texte: '@propriete.type.rpa' },
      { valeur: 'iHotel', texte: '@propriete.type.hotel' },
      { valeur: 'iMedical', texte: '@propriete.type.medical' }
    ];
  }

  redirectionVersFiche(sLien, sTypeLogement, iUnitesTotal) {
    let urlFiche = 'https://pmml.ca/';
    if (sTypeLogement == 'iTerrain') urlFiche = 'https://landev.ai/';
    // else if (sTypeLogement == 'iGoplex' || (sTypeLogement == '' && iUnitesTotal < 11)) urlFiche = 'https://goplex.com/';
    if (document.documentElement.lang !== 'fr-CA') urlFiche += 'en/';

    return urlFiche + sLien;
  }
}