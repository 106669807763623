<section class="descriptif">
    <div class="container">
        <div class="row rangee justify-content-center">
            <div class="col-11 col-sm-11 col-lg-6">
                <div class="revenus">
                    <h4 class="titre-tableau ligne-sous">
                        <i class="fas fa-usd-circle"></i>
                        {{ 'titreRevenus' | translate }}
                    </h4>
                    <table class="table table-striped ordinateur">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="col-rev-1">{{ '@annuels' | translate }}</th>
                                <th class="col-rev-2">%</th>
                                <th class="col-rev-3">RPL(M)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{ '@residentiel' | translate }} <span *ngIf="data?.IDPropriete == 244550">*</span> </th>
                                <td>{{ data?.fRevenusRes | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fRevenusRes">{{ ((data?.fRevenusRes * 100) / data?.fTotalRevenu) | number : '1.0-0' }} % </span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fRevenusResRpl">{{ data?.fRevenusResRpl / 12 | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@commercial' | translate }}</th>
                                <td>{{ data?.fRevenusComm | pmmlArgent }}</td>
                                <td>
                                    <span [hidden]="!data?.fRevenusComm">{{ ((data?.fRevenusComm * 100) / data?.fTotalRevenu) | number : '1.0-0'}} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fRevenusCommRpl">{{ data?.fRevenusCommRpl / 12 | pmmlArgent}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@stationnement' | translate }}</th>
                                <td>{{ data?.fRevenusStationnement | pmmlArgent }}</td>
                                <td>
                                    <span [hidden]="!data?.fRevenusStationnement">{{ ((data?.fRevenusStationnement * 100) / data?.fTotalRevenu) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fRevenusStationnementRpl">{{ data?.fRevenusStationnementRpl / 12 | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@buanderie' | translate }}</th>
                                <td>{{ data?.fRevenusBuanderie | pmmlArgent }}</td>
                                <td>
                                    <span [hidden]="!data?.fRevenusBuanderie">{{ ((data?.fRevenusBuanderie * 100) / data?.fTotalRevenu) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fRevenusBuanderieRpl">{{ data?.fRevenusBuanderieRpl / 12 | pmmlArgent}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@rangement' | translate }}</th>
                                <td>{{ data?.fRevenusLockers | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fRevenusLockers">{{ ((data?.fRevenusLockers * 100) / data?.fTotalRevenu) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fRevenusLockersRpl">{{ data?.fRevenusLockersRpl / 12 | pmmlArgent}}</span>
                                </td>
                            </tr>
                            <tr *ngIf="data?.fRevenusAutres"> 
                                <th>{{ data?.sAutreRevText | translateValeur : data?.sAutreRevTextEn}}</th>
                                <td>{{ data?.fRevenusAutres | pmmlArgent }}</td>
                                <td>{{ (data?.fRevenusAutres / data?.fTotalRevenu * 100) | number : '1.0-0' }} %</td>
                                <td>{{ (data?.fRevenusAutresRpl / 12) | pmmlArgent }}</td>
                            </tr>
                            <tr *ngIf="data?.fRevenusAutres2"> 
                                <th>{{ data?.sAutreRevText2 | translateValeur : data?.sAutreRevText2En}}</th>
                                <td>{{ data?.fRevenusAutres2 | pmmlArgent }}</td>
                                <td>{{ (data?.fRevenusAutres2 / data?.fTotalRevenu * 100) | number : '1.0-0' }} %</td>
                                <td>{{ (data?.fRevenusAutres2Rpl / 12) | pmmlArgent }}</td>
                            </tr>
                            <tr *ngIf="data?.fRevenusAutres3"> 
                                <th>{{ data?.sAutreRevText3 | translateValeur : data?.sAutreRevText3En}}</th>
                                <td>{{ data?.fRevenusAutres3 | pmmlArgent }}</td>
                                <td>{{ (data?.fRevenusAutres3 / data?.fTotalRevenu * 100) | number : '1.0-0' }} %</td>
                                <td>{{ (data?.fRevenusAutres3Rpl / 12) | pmmlArgent }}</td>
                            </tr>
                            <tr *ngIf="data?.fRevenusAutres4"> 
                                <th>{{ data?.sAutreRevText4 | translateValeur : data?.sAutreRevText4En}}</th>
                                <td>{{ data?.fRevenusAutres4 | pmmlArgent }}</td>
                                <td>{{ (data?.fRevenusAutres4 / data?.fTotalRevenu * 100) | number : '1.0-0' }} %</td>
                                <td>{{ (data?.fRevenusAutres4Rpl / 12) | pmmlArgent }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr class="blanc final final-bas">
                                <th>{{ 'fTotalRevenus' | translate }} <span *ngIf="data?.IDPropriete == 244550">*</span> </th>
                                <td>{{ data?.fTotalRevenu | pmmlArgent}}</td>
                                <td></td>
                                <td>{{ (data?.fRevenusLockersRpl + data?.fRevenusBuanderieRpl + data?.fRevenusStationnementRpl + data?.fRevenusCommRpl + data?.fRevenusResRpl) / 12 | pmmlArgent}}</td>
                            </tr>
                        </tfoot>
                    </table>
                    <table class="table table-striped mobile">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>%</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{ '@residentiel' | translate }}</th>
                                <td>{{ data?.fRevenusRes | pmmlArgent }}</td>
                                <td  style="white-space: nowrap;">
                                    <span [hidden]="!data?.fRevenusRes">{{ ((data?.fRevenusRes * 100) / data?.fTotalRevenu) | number : '1.0-0' }} %</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@commercial' | translate }}</th>
                                <td>{{ data?.fRevenusComm | pmmlArgent }}</td>
                                <td  style="white-space: nowrap;">
                                    <span [hidden]="!data?.fRevenusComm">{{ ((data?.fRevenusComm * 100) / data?.fTotalRevenu) | number : '1.0-0' }} %</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@stationnement' | translate }}</th>
                                <td>{{ data?.fRevenusStationnement | pmmlArgent }}</td>
                                <td  style="white-space: nowrap;">
                                    <span [hidden]="!data?.fRevenusStationnement">{{ ((data?.fRevenusStationnement * 100) / data?.fTotalRevenu) | number : '1.0-0' }} %</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@buanderie' | translate }}</th>
                                <td>{{ data?.fRevenusBuanderie | pmmlArgent }}</td>
                                <td  style="white-space: nowrap;">
                                    <span [hidden]="!data?.fRevenusBuanderie">{{ ((data?.fRevenusBuanderie * 100) / data?.fTotalRevenu) | number : '1.0-0' }} %</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@rangement' | translate }}</th>
                                <td>{{ data?.fRevenusLockers | pmmlArgent }}</td>
                                <td  style="white-space: nowrap;">
                                    <span [hidden]="!data?.fRevenusLockers">{{ ((data?.fRevenusLockers * 100) / data?.fTotalRevenu) | number : '1.0-0' }} %</span>
                                </td>
                            </tr>
                            <tr *ngIf="data?.fRevenusAutres"> 
                                <th>{{ data?.sAutreRevText | translateValeur : data?.Traduction?.sAutreRevText}}</th>
                                <td>{{ data?.fRevenusAutres | pmmlArgent }}</td>
                                <td style="white-space: nowrap;">{{ (data?.fRevenusAutres / data?.fTotalRevenu * 100) | number : '1.0-0' }} %</td>
                            </tr>
                            <tr *ngIf="data?.fRevenusAutres2"> 
                                <th>{{ data?.sAutreRevText2 | translateValeur : data?.Traduction?.sAutreRevText2}}</th>
                                <td>{{ data?.fRevenusAutres2 | pmmlArgent }}</td>
                                <td style="white-space: nowrap;">{{ (data?.fRevenusAutres2 / data?.fTotalRevenu * 100) | number : '1.0-0' }} %</td>
                            </tr>
                            <tr *ngIf="data?.fRevenusAutres3"> 
                                <th>{{ data?.sAutreRevText3 | translateValeur : data?.Traduction?.sAutreRevText3}}</th>
                                <td>{{ data?.fRevenusAutres3 | pmmlArgent }}</td>
                                <td style="white-space: nowrap;">{{ (data?.fRevenusAutres3 / data?.fTotalRevenu * 100) | number : '1.0-0' }} %</td>
                            </tr>
                            <tr *ngIf="data?.fRevenusAutres4"> 
                                <th>{{ data?.sAutreRevText4 | translateValeur : data?.Traduction?.sAutreRevText4}}</th>
                                <td>{{ data?.fRevenusAutres4 | pmmlArgent }}</td>
                                <td style="white-space: nowrap;">{{ (data?.fRevenusAutres4 / data?.fTotalRevenu * 100) | number : '1.0-0' }} %</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr class="blanc final final-bas">
                                <th>{{ 'fTotalRevenus' | translate }}</th>
                                <td>{{ data?.fTotalRevenu | pmmlArgent}}</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                    <span class="text-left" *ngIf="data?.IDPropriete == 244550">*{{ 'revenus_special_date' | translate }}</span> 
                </div>
            </div>
            <div class="col-11 col-sm-11 col-lg-6 d-none d-lg-block" *ngIf="(data?.fPrixDemande != 0 && data?.fPrixDemande != null) && (data?.IDPropriete !== 244550)">
                <div class="financement">
                    <h4 class="titre-tableau ligne-sous">
                        <i class="fas fa-university"></i>
                        {{ '@financement' | translate }}
                    </h4>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th><span *ngIf="data?.fin?.infoFinancement?.conv?.bActiver">{{ '@conventionnel' | translate }}</span></th>
                                <th><span *ngIf="data?.fin?.infoFinancement?.schl?.bActiver">{{ '@schl' | translate }}</span></th>
                                <th><span *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver || data?.fin?.infoFinancement?.actuel2?.bActiver">{{ '@assumation' | translate }}</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{ '@montant.pret.maximum' | translate }}</th>
                                <td><span *ngIf="data?.fin?.infoFinancement?.conv?.bActiver">{{ data?.fin?.infoFinancement?.conv?.fMontantFinancement | pmmlArgent}}</span></td>
                                <td><span *ngIf="data?.fin?.infoFinancement?.schl?.bActiver">{{ data?.fin?.infoFinancement?.schl?.fMontantFinancement | pmmlArgent}}</span></td>
                                <td>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1)">{{ calculMontantFin(data?.fin?.infoFinancement?.actuel1, data?.fin?.infoFinancement?.actuel2, data?.fin?.infoFinancement?.actuel3) | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@tga.financement' | translate }}</th>
                                <td><span *ngIf="data?.fin?.infoFinancement?.conv?.bActiver">{{ data?.fin?.infoFinancement?.conv?.fTGA }} %</span></td>
                                <td><span *ngIf="data?.fin?.infoFinancement?.schl?.bActiver">{{ data?.fin?.infoFinancement?.schl?.fTGA }} %</span></td>
                                <td>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@ratio.couverture.dette' | translate }}</th>
                                <td><span *ngIf="data?.fin?.infoFinancement?.conv?.bActiver">{{ data?.fin?.infoFinancement?.conv?.fRCD }}</span></td>
                                <td><span *ngIf="data?.fin?.infoFinancement?.schl?.bActiver">{{ data?.fin?.infoFinancement?.schl?.fRCD }}</span></td>
                                <td>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@taux.interets' | translate }}</th>
                                <td><span *ngIf="data?.fin?.infoFinancement?.conv?.bActiver">{{ data?.fin?.fTauxConv }} %</span></td>
                                <td><span *ngIf="data?.fin?.infoFinancement?.schl?.bActiver">{{ data?.fin?.fTauxSCHL }} %</span></td>
                                <td>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fTaux)">{{data?.fin?.infoFinancement?.actuel1?.fTaux}} %</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fTaux && data?.fin?.infoFinancement?.actuel2?.fTaux)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fTaux)">{{data?.fin?.infoFinancement?.actuel2?.fTaux}} %</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@amortissement' | translate }}</th>
                                <td><span *ngIf="data?.fin?.infoFinancement?.conv?.bActiver">{{ data?.fin?.infoFinancement?.conv?.iAmortissementAns }} {{'ans' | translate}}</span></td>
                                <td><span *ngIf="data?.fin?.infoFinancement?.schl?.bActiver">{{ data?.fin?.infoFinancement?.schl?.iAmortissementAns }} {{'ans' | translate}}</span></td>
                                <td>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iAmortissementAns)">{{data?.fin?.infoFinancement?.actuel1?.iAmortissementAns}} {{'ans' | translate}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iAmortissementAns && data?.fin?.infoFinancement?.actuel2?.iAmortissementAns)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.iAmortissementAns)">{{data?.fin?.infoFinancement?.actuel2?.iAmortissementAns}} {{'ans' | translate}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@terme' | translate }}</th>
                                <td><span *ngIf="data?.fin?.infoFinancement?.conv?.bActiver">{{ data?.fin?.infoFinancement?.conv?.iTerme }} {{'ans' | translate}}</span></td>
                                <td><span *ngIf="data?.fin?.infoFinancement?.schl?.bActiver">{{ data?.fin?.infoFinancement?.schl?.iTerme }} {{'ans' | translate}}</span></td>
                                <td>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iTerme)">{{data?.fin?.infoFinancement?.actuel1?.iTerme}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iTerme && data?.fin?.infoFinancement?.actuel2?.iTerme)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.iTerme)">{{data?.fin?.infoFinancement?.actuel2?.iTerme}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-11 col-sm-11 col-lg-6">
                <div class="depenses">
                    <h4 class="titre-tableau ligne-sous">
                        <i class="fas fa-money-check-edit-alt"></i>
                        {{ '@depenses' | translate }}
                    </h4>
                    <table class="table table-striped ordinateur">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="col-dep-1"></th>
                                <th class="col-dep-2">{{ '@annuelles' | translate }}</th>
                                <th class="col-dep-3">% / RB</th>
                                <th class="col-dep-4">CPL(A)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{ 'fVacMauvaiseCHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fVacMauvaiseC">{{ data?.bVacMauvaiseCHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fVacMauvaiseC | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fVacMauvaiseC">{{ ((data?.fVacMauvaiseC / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fVacMauvaiseCCpl">{{ data?.fVacMauvaiseCCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>administration</th>
                                <td><span *ngIf="data?.fAdministration">{{ data?.bAdministrationHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fAdministration | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fAdministration">{{ ((data?.fAdministration / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fAdministrationCpl">{{ data?.fAdministrationCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ 'fTaxesMunicipalesHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fTaxesMunicipales">{{ data?.bTaxesMunicipalesHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fTaxesMunicipales | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fTaxesMunicipales">{{ ((data?.fTaxesMunicipales / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fTaxesMunicipalesCpl">{{ data?.fTaxesMunicipalesCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ 'fTaxesScolairesHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fTaxesScolaires">{{ data?.bTaxesScolairesHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fTaxesScolaires | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fTaxesScolaires">{{ ((data?.fTaxesScolaires / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fTaxesScolairesCpl">{{ data?.fTaxesScolairesCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@assurances' | translate }}</th>
                                <td><span *ngIf="data?.fAssurances">{{ data?.bAssurancesHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fAssurances | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fAssurances">{{ ((data?.fAssurances / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fAssurancesCpl">{{ data?.fAssurancesCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@electricite' | translate }}</th>
                                <td><span *ngIf="data?.fElectricite">{{ data?.bElectriciteHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fElectricite | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fElectricite">{{ ((data?.fElectricite / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fElectriciteCpl">{{ data?.fElectriciteCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@chauffage' | translate }}</th>
                                <td><span *ngIf="data?.fChauffage">{{ data?.bChauffageHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fChauffage | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fChauffage">{{ ((data?.fChauffage / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fChauffageCpl">{{ data?.fChauffageCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@deneigement' | translate }}</th>
                                <td><span *ngIf="data?.fDeneigement">{{ data?.bDeneigementHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fDeneigement | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fDeneigement">{{ ((data?.fDeneigement / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fDeneigementCpl">{{ data?.fDeneigementCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@ascenseur' | translate }}</th>
                                <td><span *ngIf="data?.fAscenceur">{{ data?.bAscenceurHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fAscenceur | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fAscenceur">{{ ((data?.fAscenceur / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fAscenceurCpl">{{ data?.fAscenceurCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ 'fGazonHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fGazon">{{ data?.bGazonHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fGazon | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fGazon">{{ ((data?.fGazon / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fGazonCpl">{{ data?.fGazonCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ 'fEntretienHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fEntretien">{{ data?.bEntretienHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fEntretien | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fEntretien">{{ ((data?.fEntretien / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fEntretienCpl">{{ data?.fEntretienCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ 'fConciergeHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fConcierge">{{ data?.bConciergeHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fConcierge | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fConcierge">{{ ((data?.fConcierge / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fConciergeCpl">{{ data?.fConciergeCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ 'freservefElectromenagers' | translate }}</th>
                                <td><span *ngIf="data?.fElectromenagers">{{ data?.bElectromenagersHarmo | proprieteTypeDep | translate}}</span></td>
                                <td>{{ data?.fElectromenagers | pmmlArgent}}</td>
                                <td>
                                    <span [hidden]="!data?.fElectromenagers">{{ ((data?.fElectromenagers / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</span>
                                </td>
                                <td>
                                    <span [hidden]="!data?.fElectromenagersCpl">{{ data?.fElectromenagersCpl | pmmlArgent }}</span>
                                </td>
                            </tr>
                            <tr *ngIf="data?.fDepensesAutres"> 
                                <th>{{ data?.sAutreDepText | translateValeur : data?.sAutreDepTextEn}}</th>
                                <td></td>
                                <td>{{ data?.fDepensesAutres | pmmlArgent }}</td>
                                <td>{{ (data?.fDepensesAutres / data.fTotalRevenu * 100) | number : '1.0-0' }} %</td>
                                <td>{{ (data?.fDepensesAutresCpl) | pmmlArgent }}</td>
                            </tr>
                            <tr *ngIf="data?.fDepensesAutres2"> 
                                <th>{{ data?.sAutreDepText2 | translateValeur : data?.sAutreDepText2En}}</th>
                                <td></td>
                                <td>{{ data?.fDepensesAutres2 | pmmlArgent }}</td>
                                <td>{{ (data?.fDepensesAutres2 / data.fTotalRevenu * 100) | number : '1.0-0' }} %</td>
                                <td>{{ (data?.fDepensesAutres2Cpl) | pmmlArgent }}</td>
                            </tr>
                            <tr *ngIf="data?.fDepensesAutres3"> 
                                <th>{{ data?.sAutreDepText3 | translateValeur : data?.sAutreDepText3En}}</th>
                                <td></td>
                                <td>{{ data?.fDepensesAutres3 | pmmlArgent }}</td>
                                <td>{{ (data?.fDepensesAutres3 / data.fTotalRevenu * 100) | number : '1.0-0' }} %</td>
                                <td>{{ (data?.fDepensesAutres3Cpl) | pmmlArgent }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>{{ '@depenses.total' | translate }}</th>
                                <td ></td>
                                <td >{{ data?.fTotalDepense | pmmlArgent }}</td>
                                <td>{{ ((data?.fTotalDepense / data?.fTotalRevenu) * 100) | number : '1.0-0' }} %</td>
                                <td>{{ (data?.fTotalDepense / data?.iUnitesTotal) | pmmlArgent }}</td>
                            </tr>
                            <tr class="blanc final-bas">
                                <th>{{ '@revenus.net' | translate }}</th>
                                <td></td>
                                <td>{{ data?.fRevenuNet | pmmlArgent }}</td>
                                <td></td>
                                <td>{{ (data?.fRevenuNet / data?.iUnitesTotal) | pmmlArgent }}</td>
                            </tr>
                        </tfoot>
                    </table>
                    <table class="table table-striped mobile">
                        <thead>
                            <tr>
                                <th>{{ '@depenses' | translate }}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{ 'fVacMauvaiseCHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fVacMauvaiseC ">{{ data?.bVacMauvaiseCHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fVacMauvaiseC | pmmlArgent }}</td>
                            </tr>
                            <tr>
                                <th>administration</th>
                                <td><span *ngIf="data?.fAdministration">{{ data?.bAdministrationHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fAdministration | pmmlArgent }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'fTaxesMunicipalesHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fTaxesMunicipales">{{ data?.bTaxesMunicipalesHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fTaxesMunicipales | pmmlArgent }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'fTaxesScolairesHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fTaxesScolaires">{{ data?.bTaxesScolairesHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fTaxesScolaires | pmmlArgent }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'fAssurancesHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fAssurances">{{ data?.bAssurancesHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fAssurances | pmmlArgent }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@electricite' | translate }}</th>
                                <td><span *ngIf="data?.fElectricite">{{ data?.bElectriciteHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fElectricite | pmmlArgent }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@chauffage' | translate }}</th>
                                <td><span *ngIf="data?.fChauffage">{{ data?.bChauffageHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fChauffage | pmmlArgent }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@deneigement' | translate }}</th>
                                <td><span *ngIf="data?.fDeneigement">{{ data?.bDeneigementHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fDeneigement | pmmlArgent }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@ascenseur' | translate }}</th>
                                <td><span *ngIf="data?.fAscenceur">{{ data?.bAscenceurHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fAscenceur | pmmlArgent }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'fGazonHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fGazon">{{ data?.bGazonHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fGazon | pmmlArgent }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'fEntretienHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fEntretien">{{ data?.bEntretienHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fEntretien | pmmlArgent }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'fConciergeHarmo' | translate }}</th>
                                <td><span *ngIf="data?.fConcierge">{{ data?.bConciergeHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fConcierge | pmmlArgent}}</td>
                            </tr>
                            <tr>
                                <th>{{ 'reserve.mobilier' | translate }}</th>
                                <td><span *ngIf="data?.fElectromenagers">{{ data?.bElectromenagersHarmo | proprieteTypeDep | translate }}</span></td>
                                <td>{{ data?.fElectromenagers | pmmlArgent}}</td>
                            </tr>
                            <tr *ngIf="data?.fDepensesAutres"> 
                                <th>{{ data?.sAutreDepText | translateValeur : data?.Traduction?.sAutreDepText}}</th>
                                <td></td>
                                <td>{{ data?.fDepensesAutres | pmmlArgent }}</td>
                            </tr>
                            <tr *ngIf="data?.fDepensesAutres2"> 
                                <th>{{ data?.sAutreDepText2 | translateValeur : data?.Traduction?.sAutreDepText2}}</th>
                                <td></td>
                                <td>{{ data?.fDepensesAutres2 | pmmlArgent }}</td>
                            </tr>
                            <tr *ngIf="data?.fDepensesAutres3"> 
                                <th>{{ data?.sAutreDepText3 | translateValeur : data?.Traduction?.sAutreDepText3}}</th>
                                <td></td>
                                <td>{{ data?.fDepensesAutres3 | pmmlArgent }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>{{ '@depenses.total' | translate }}</th>
                                <td></td>
                                <td>{{ data?.fTotalDepense | pmmlArgent }}</td>
                            </tr>
                            <tr class="blanc final-bas">
                                <th>{{ '@revenus.net' | translate }}</th>
                                <td></td>
                                <td>{{ data?.fRevenuNet | pmmlArgent }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="col-11 col-sm-11 col-lg-6 d-lg-none" *ngIf="(data?.fPrixDemande != 0 && data?.fPrixDemande != null) && (data?.IDPropriete !== 244550)">
                <div class="financement">
                    <h4 class="titre-tableau ligne-sous">
                        <i class="fas fa-university"></i>
                        {{ '@financement' | translate }}
                    </h4>
                    <table class="table table-striped d-sm-none">
                        <thead>
                            <tr>
                                <!-- <th></th> -->
                                <th *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ '@conventionnel' | translate }}</span></th>
                                <th *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ '@schl' | translate }}</span></th>
                                <th *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver || data?.fin?.infoFinancement?.actuel2?.bActiver"><span>{{ '@assumation' | translate }}</span></th>
                            </tr>
                        </thead>
                        <tbody class="this-one">
                            <tr>
                                <th colspan="3" style="text-align: center; text-transform: uppercase;">{{ '@montant.pret.maximum' | translate }}</th>
                            </tr>
                            <tr>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fMontantFinancement | pmmlArgent}}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fMontantFinancement | pmmlArgent}}</span></td>
                                <td *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1)"><span>{{ calculMontantFin(data?.fin?.infoFinancement?.actuel1, data?.fin?.infoFinancement?.actuel2, data?.fin?.infoFinancement?.actuel3) | pmmlArgent }}</span></td>
                            </tr>
                            <tr>
                                <th colspan="3" style="text-align: center; text-transform: uppercase;">{{ '@tga.financement' | translate }}</th>
                            </tr>
                            <tr>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fTGA }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fTGA }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1"></td>
                            </tr>
                            <tr>
                                <th colspan="3" style="text-align: center; text-transform: uppercase;">{{ '@ratio.couverture.dette' | translate }}</th>
                            </tr>
                            <tr>
                                <!-- <th>{{ '@ratio.couverture.dette' | translate }}</th> -->
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fRCD }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fRCD }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1"></td>
                            </tr>
                            <tr>
                                <th colspan="3" style="text-align: center; text-transform: uppercase;">{{ '@taux.interets' | translate }}</th>
                            </tr>
                            <tr>
                                <!-- <th>{{ '@taux.interets' | translate }}</th> -->
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.fTauxConv }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.fTauxSCHL }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fTaux)">{{data?.fin?.infoFinancement?.actuel1?.fTaux}} %</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fTaux && data?.fin?.infoFinancement?.actuel2?.fTaux)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fTaux)">{{data?.fin?.infoFinancement?.actuel2?.fTaux}} %</span>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="3" style="text-align: center; text-transform: uppercase;">{{ '@amortissement' | translate }}</th>
                            </tr>
                            <tr>
                                <!-- <th>{{ '@amortissement' | translate }}</th> -->
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.iAmortissementAns }} {{'ans' | translate}}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.iAmortissementAns }} {{'ans' | translate}}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iAmortissementAns)">{{data?.fin?.infoFinancement?.actuel1?.iAmortissementAns}} {{'ans' | translate}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iAmortissementAns && data?.fin?.infoFinancement?.actuel2?.iAmortissementAns)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.iAmortissementAns)">{{data?.fin?.infoFinancement?.actuel2?.iAmortissementAns}} {{'ans' | translate}}</span>
                                </td>
                            </tr>
                            <tr>
                               <th colspan="3" style="text-align: center; text-transform: uppercase;">{{ '@terme' | translate }}</th>
                            </tr>
                            <tr>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.iTerme }} {{'ans' | translate}}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.iTerme }} {{'ans' | translate}}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iTerme)">{{data?.fin?.infoFinancement?.actuel1?.iTerme}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iTerme && data?.fin?.infoFinancement?.actuel2?.iTerme)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.iTerme)">{{data?.fin?.infoFinancement?.actuel2?.iTerme}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-striped d-none d-sm-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ '@conventionnel' | translate }}</span></th>
                                <th *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ '@schl' | translate }}</span></th>
                                <th *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver || data?.fin?.infoFinancement?.actuel2?.bActiver"><span>{{ '@assumation' | translate }}</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{ '@montant.pret.maximum' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fMontantFinancement | pmmlArgent}}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fMontantFinancement | pmmlArgent}}</span></td>
                                <td *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1)"><span>{{ calculMontantFin(data?.fin?.infoFinancement?.actuel1, data?.fin?.infoFinancement?.actuel2, data?.fin?.infoFinancement?.actuel3) | pmmlArgent }}</span></td>
                            </tr>
                            <tr>
                                <th>{{ '@tga.financement' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fTGA }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fTGA }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1"></td>
                            </tr>
                            <tr>
                                <th>{{ '@ratio.couverture.dette' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fRCD }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fRCD }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1"></td>
                            </tr>
                            <tr>
                                <th>{{ '@taux.interets' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.fTauxConv }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.fTauxSCHL }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fTaux)">{{data?.fin?.infoFinancement?.actuel1?.fTaux}} %</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fTaux && data?.fin?.infoFinancement?.actuel2?.fTaux)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fTaux)">{{data?.fin?.infoFinancement?.actuel2?.fTaux}} %</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@amortissement' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.iAmortissementAns }} {{'ans' | translate}}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.iAmortissementAns }} {{'ans' | translate}}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iAmortissementAns)">{{data?.fin?.infoFinancement?.actuel1?.iAmortissementAns}} {{'ans' | translate}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iAmortissementAns && data?.fin?.infoFinancement?.actuel2?.iAmortissementAns)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.iAmortissementAns)">{{data?.fin?.infoFinancement?.actuel2?.iAmortissementAns}} {{'ans' | translate}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@terme' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.iTerme }} {{'ans' | translate}}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.iTerme }} {{'ans' | translate}}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iTerme)">{{data?.fin?.infoFinancement?.actuel1?.iTerme}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.iTerme && data?.fin?.infoFinancement?.actuel2?.iTerme)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.iTerme)">{{data?.fin?.infoFinancement?.actuel2?.iTerme}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-11 col-sm-11 col-lg-6" *ngIf="(data?.fPrixDemande != 0 && data?.fPrixDemande != null) && (data?.IDPropriete !== 244550)">
                <div class="tresorerie d-none d-sm-block">
                    <h4 class="titre-tableau ligne-sous">
                        <i class="fas fa-treasure-chest"></i>
                        {{ '@tresorerie' | translate }}
                    </h4>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ '@conventionnel' | translate }}</span></th>
                                <th *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ '@schl' | translate }}</span></th>
                                <th *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver || data?.fin?.infoFinancement?.actuel2?.bActiver"><span>{{ '@assumation' | translate }}</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{ '@revenus.net.fd' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fRevenuNet | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fRevenuNet | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver || data?.fin?.infoFinancement?.actuel2?.bActiver"><span>{{ data?.fRevenuNet | pmmlArgent }}</span></td>
                            </tr>
                            <tr>
                                <th>{{ '@paiement.hyp.annuel' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fPaiementAnnuel | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fPaiementAnnuel | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fPaiementAnnuel)">{{data?.fin?.infoFinancement?.actuel1?.fPaiementAnnuel | pmmlArgent}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fPaiementAnnuel && data?.fin?.infoFinancement?.actuel2?.fPaiementAnnuel)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fPaiementAnnuel)">{{data?.fin?.infoFinancement?.actuel2?.fPaiementAnnuel | pmmlArgent}}</span>
                                </td>
                            </tr>
                            <tr class="blancGris final-bas">
                                <th>{{ 'LiquiditePret' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fLiquiditeAnnuel | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fLiquiditeAnnuel | pmmlArgent  }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1)">{{data?.fin?.infoFinancement?.actuel1?.fLiquiditeAnnuel | pmmlArgent}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fLiquiditeAnnuel && data?.fin?.infoFinancement?.actuel2?.fLiquiditeAnnuel)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1)">{{data?.fin?.infoFinancement?.actuel2?.fLiquiditeAnnuel | pmmlArgent}}</span>
                                </td>
                            </tr>
                        <tbody>
                            <tr style="text-align: center; font-weight:  700;">
                                <td colspan="4" style="text-align: center; text-transform: uppercase;">
                                    {{ '@ROI.prix.demande' | translate }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th>{{ '@mise.de.fond.necessaire' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fMiseDeFond | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fMiseDeFond | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fMiseDeFond)">{{data?.fin?.infoFinancement?.actuel1?.fMiseDeFond | pmmlArgent}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fMiseDeFond && data?.fin?.infoFinancement?.actuel2?.fMiseDeFond)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fMiseDeFond)">{{data?.fin?.infoFinancement?.actuel2?.fMiseDeFond | pmmlArgent}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@rendement.sur.liquidite' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fRendLiquidite | number : '1.1-1' }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fRendLiquidite | number : '1.1-1' }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fRendLiquidite)">{{data?.fin?.infoFinancement?.actuel1?.fRendLiquidite | number : '1.1-1'}} %</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fRendLiquidite && data?.fin?.infoFinancement?.actuel2?.fRendLiquidite)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fRendLiquidite)">{{data?.fin?.infoFinancement?.actuel2?.fRendLiquidite | number : '1.1-1'}} %</span>
                                </td>
                            </tr>
                            <tr class="blancGris final-bas">
                                <th>{{ '@rendement.sur.liquidite.capitalisation' | translate }}</th>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fRendLiquiditeCapital | number : '1.1-1' }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fRendLiquiditeCapital | number : '1.1-1' }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fRendLiquiditeCapital)">{{data?.fin?.infoFinancement?.actuel1?.fRendLiquiditeCapital | number : '1.1-1'}} %</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fRendLiquiditeCapital && data?.fin?.infoFinancement?.actuel2?.fRendLiquiditeCapital)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fRendLiquiditeCapital)">{{data?.fin?.infoFinancement?.actuel2?.fRendLiquiditeCapital | number : '1.1-1'}} %</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tresorerie d-block d-sm-none" *ngIf="(data?.fPrixDemande != 0 && data?.fPrixDemande != null) && (data?.IDPropriete !== 244550)">
                    <!-- Trésorie - Mobile seulement -->
                    <h4 class="titre-tableau ligne-sous">
                        <i class="fas fa-treasure-chest"></i>
                        {{ '@tresorerie' | translate }}
                    </h4>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ '@conventionnel' | translate }}</span></th>
                                <th *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ '@schl' | translate }}</span></th>
                                <th *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver || data?.fin?.infoFinancement?.actuel2?.bActiver"><span>{{ '@assumation' | translate }}</span></th>
                            </tr>
                        </thead>
                        <tbody class="this-one">
                            <tr style="text-align: center; font-weight:  700; border-top: 2px solid black;">
                                <td colspan="4" style="text-align: center; text-transform: uppercase;">
                                    {{ '@revenus.net.fd' | translate }}
                                </td>
                            </tr>
                            <tr>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fRevenuNet | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fRevenuNet | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver || data?.fin?.infoFinancement?.actuel2?.bActiver"><span>{{ data?.fRevenuNet | pmmlArgent }}</span></td>
                            </tr>
                            <tr style="text-align: center; font-weight:  700; border-top: 2px solid black;">
                                <td colspan="4" style="text-align: center; text-transform: uppercase;">
                                    {{ '@paiement.hyp.annuel' | translate }}
                                </td>
                            </tr>
                            <tr>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fPaiementAnnuel | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fPaiementAnnuel | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fPaiementAnnuel)">{{data?.fin?.infoFinancement?.actuel1?.fPaiementAnnuel | pmmlArgent}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fPaiementAnnuel && data?.fin?.infoFinancement?.actuel2?.fPaiementAnnuel)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fPaiementAnnuel)">{{data?.fin?.infoFinancement?.actuel2?.fPaiementAnnuel | pmmlArgent}}</span>
                                </td>
                            </tr>
                            <tr style="text-align: center; font-weight:  700; border-top: 2px solid black;">
                                <td colspan="4" style="text-align: center; text-transform: uppercase;">
                                    {{ 'LiquiditePret' | translate }}
                                </td>
                            </tr>
                            <tr class="blancGris final-bas">
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fLiquiditeAnnuel | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fLiquiditeAnnuel | pmmlArgent  }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fLiquiditeAnnuel)">{{data?.fin?.infoFinancement?.actuel1?.fLiquiditeAnnuel | pmmlArgent}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fLiquiditeAnnuel && data?.fin?.infoFinancement?.actuel2?.fLiquiditeAnnuel)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fLiquiditeAnnuel)">{{data?.fin?.infoFinancement?.actuel2?.fLiquiditeAnnuel | pmmlArgent}}</span>
                                </td>
                            </tr>
                            <tr style="text-align: center; font-weight:  700; border-top: 2px solid black;">
                                <td colspan="4" style="text-align: center; text-transform: uppercase;">
                                    {{ '@mise.de.fond.necessaire' | translate }}
                                </td>
                            </tr>
                            <tr>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fMiseDeFond | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fMiseDeFond | pmmlArgent }}</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fMiseDeFond)">{{data?.fin?.infoFinancement?.actuel1?.fMiseDeFond | pmmlArgent}}</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fMiseDeFond && data?.fin?.infoFinancement?.actuel2?.fMiseDeFond)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fMiseDeFond)">{{data?.fin?.infoFinancement?.actuel2?.fMiseDeFond | pmmlArgent}}</span>
                                </td>
                            </tr>
                            <tr style="text-align: center; font-weight:  700; border-top: 2px solid black;">
                                <td colspan="4" style="text-align: center; text-transform: uppercase;">
                                    {{ '@rendement.sur.liquidite' | translate }}
                                </td>
                            </tr>
                            <tr>
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fRendLiquidite | number : '1.1-1' }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fRendLiquidite | number : '1.1-1' }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fRendLiquidite)">{{data?.fin?.infoFinancement?.actuel1?.fRendLiquidite | number : '1.1-1'}} %</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fRendLiquidite && data?.fin?.infoFinancement?.actuel2?.fRendLiquidite)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fRendLiquidite)">{{data?.fin?.infoFinancement?.actuel2?.fRendLiquidite | number : '1.1-1'}} %</span>
                                </td>
                            </tr>
                            <tr style="text-align: center; font-weight:  700; border-top: 2px solid black;">
                                <td colspan="4" style="text-align: center; text-transform: uppercase;">
                                    {{ '@rendement.sur.liquidite.capitalisation' | translate }}
                                </td>
                            </tr>
                            <tr class="blancGris final-bas">
                                <td *ngIf="data?.fin?.infoFinancement?.conv?.bActiver"><span>{{ data?.fin?.infoFinancement?.conv?.fRendLiquiditeCapital | number : '1.1-1' }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.schl?.bActiver"><span>{{ data?.fin?.infoFinancement?.schl?.fRendLiquiditeCapital | number : '1.1-1' }} %</span></td>
                                <td *ngIf="data?.fin?.infoFinancement?.actuel1?.bActiver === 1 || data?.fin?.infoFinancement?.actuel2?.bActiver === 1">
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fRendLiquiditeCapital)">{{data?.fin?.infoFinancement?.actuel1?.fRendLiquiditeCapital | number : '1.1-1'}} %</span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel1?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel1?.fRendLiquiditeCapital && data?.fin?.infoFinancement?.actuel2?.fRendLiquiditeCapital)"> | </span>
                                    <span *ngIf="(data?.fin?.infoFinancement?.actuel2?.bActiver === 1 && data?.fin?.infoFinancement?.actuel2?.fRendLiquiditeCapital)">{{data?.fin?.infoFinancement?.actuel2?.fRendLiquiditeCapital | number : '1.1-1'}} %</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="indicateurs" *ngIf="data?.fPrixDemande != 0 && data?.fPrixDemande != null">
                    <app-bloques-stats [data]="data" [colLg]="3"></app-bloques-stats>
                </div>
            </div>
        </div>
    </div>
</section>