<div class="container carrousel pt-0 mt-0" *ngIf="imagesPrecharge">
    <!-- Carrousel -->
    <ng-container *ngFor="let slide of imagesPresente; let i = index">
       <img class="slideImageCentale" [src]="slide.src"
             *ngIf="i === slidePresente"
              (click)="pleinEcranSlider()"
              (swipeleft)="boutonSuivant()" 
              (swiperight)="boutonPrecedent()">
        <i *ngIf="affichageLoupe" class="fal fa-search-plus loupeZoom"></i>
        <!-- <div
          *ngIf="i === slidePresente"
          [style.background]="slide.src | photoPrincipaleUrl"
          [style.backgroundSize]="'cover'"
          [style.backgroundPosition]="'center center'"
          (click)="pleinEcranSlider()" (swipeleft)="boutonSuivant()" (swiperight)="boutonPrecedent()">
        </div> -->
    </ng-container>
    <br>

    <!-- Bouton de contrôle -->
    <button class="boutonControl suivant" (click)="boutonSuivant()">
        <i class="fal fa-chevron-right"></i>
    </button>

    <button class="boutonControl precedent" (click)="boutonPrecedent()">
        <i class="fal fa-chevron-left"></i>
    </button>
</div>

<!-- Thumbnail -->



<!-- POP UP -->

<div id="overlaySlider" class="carrousel" *ngIf="imagesPrecharge && !estMobile" (keydown)="pleinEcranSlider()">
    <!-- Carrousel -->
    <ng-container *ngFor="let slide of imagesPresente; let i = index">

        <img class="slideImagePopUp"
              [src]="slide.src"
             *ngIf="i === slidePresente"
              (keydown)="pleinEcranSlider()"
              (swipeleft)="boutonSuivant()" (swiperight)="boutonPrecedent()"
        >
    </ng-container>
    <br>

    <i class="fas fa-times fermetureBouton text-center" (click)="pleinEcranSlider()"></i>

    <!-- Bouton de contrôle -->
    <button class="boutonControl suivant boutonControlPopUp" (click)="boutonSuivant()">
        <i class="fal fa-chevron-right"></i>
    </button>

    <button class="boutonControl precedent boutonControlPopUp" (click)="boutonPrecedent()">
        <i class="fal fa-chevron-left"></i>
    </button>

    <div class="pt-5 pt-md-3 blocThumbnail" style="background-color: white;">
        <div class="p-0 m-0 conteneurThumbnail">
            <ng-container *ngFor="let photo of imagesPresente; let i=index">
                <div class="slideThumbnail mr-2"
                        [ngClass]="{'slideThumbnailPresente': slidePresente === i}"
                        [style.background]="'url(' + photo.src + ')'"
                        [style.backgroundSize]="'cover'"
                        [style.backgroundPosition]="'center center'"
                        (click)="ajustementThumbnail(i)">
                </div>
            </ng-container>
        </div>
    </div>
</div>