import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";

@Pipe({
  name: "resizePhotoPrincipale",
})
export class ResizePhotoPrincipalePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string, sanitize = true): unknown {
    const taille = "1400"
      , nomFichier = String(url).split("/").pop()
      , urlFinal = `${environment.cloudflaresImages.baseUrl}${nomFichier}/${taille}`;

    if(!sanitize) return urlFinal
    return this.sanitizer.bypassSecurityTrustResourceUrl(urlFinal);
  }
}
