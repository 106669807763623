<section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-3 bg-danger">
                <div class="logo">
                    <img src="http://localhost:8888/wp-content/uploads/2020/05/pmml.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
