import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-descriptif-locaux',
  templateUrl: './descriptif-locaux.component.html',
  styleUrls: ['./descriptif-locaux.component.scss']
})
export class DescriptifLocauxComponent implements OnInit {

  constructor() { }

  @Input() data: any;

  ngOnInit(): void {
  }

}
