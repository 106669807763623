import { Component, OnInit, Input, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-carrousel',
  templateUrl: './carrousel.component.html',
  styleUrls: ['./carrousel.component.scss']
})
export class CarrouselComponent implements OnInit {

  data: any;
  @Input('data')
  set in(val) {
    this.data = val;
    this.preChargementImages();
  }
  
  imagesPrecharge = false;
  slidePresente = 0;
  slideProchain = false;
  slidePrecedent = false;
  estMobile = false;
  affichageLoupe = true;

  constructor(
  ) { }

  ngOnInit(): void {
    this.estMobile = window.innerWidth < 768 ? true : false;

    setTimeout(() => {
      this.affichageLoupe = false;
    }, 5000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.estMobile = window.innerWidth < 768 ? true : false;
    // event.target.classList.remove('slideImageCentale'); // To Remove
  }
  
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.pleinEcrantActif) this.pleinEcranSlider()
  }

  @HostListener("window:keydown", ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key == "ArrowLeft") this.boutonPrecedent();
    if (event.key == "ArrowRight") this.boutonSuivant();

  }

  imagesPresente = new Array();
  imagesPrecharges = new Array();
  preChargementImages() {
    for (var i = 0; i < this.data?.Photos?.length; i++) {
      this.imagesPrecharges[i] = new Image();
      this.imagesPrecharges[i].src = `${environment.cloudflaresImages.baseUrl}${this.data?.Photos[i].IDImage}_${this.data?.Photos[i].sNomFichier}/1400`
    }
    this.imagesPresente = [...this.imagesPrecharges];
    this.imagesPrecharge = true;

  }

  boutonSuivant() {
    let prochain = this.slidePresente + 1;
    this.slidePresente = prochain === this.data.Photos.length ? 0 : prochain;
    
    this.slidePrecedent = true;
    // Gestion de l'animation
    setTimeout(() => {
      this.slideProchain = true;
    }, 10);
    this.slideProchain = false;

    this.ajustementThumbnail(this.slidePresente)
  }

  boutonPrecedent() {
    let precedent = this.slidePresente - 1;
    this.slidePresente = precedent < 0 ? this.data.Photos.length - 1 : precedent;

    this.slideProchain = false;
    
    // Gestion de l'animation
    setTimeout(() => {
      this.slidePrecedent = true;
    }, 10);
    this.slidePrecedent = false;

    this.ajustementThumbnail(this.slidePresente)
  }
  
  indexThumbnail = 0;
  ajustementThumbnail(indexThumbnailPresent) {
    try {
      if (indexThumbnailPresent == this.imagesPresente.length - 1) indexThumbnailPresent = -1;

      this.slidePresente = indexThumbnailPresent;
      this.indexThumbnail = indexThumbnailPresent;
      
      if(this.slidePresente > 4) {
        let premiereImage = this.imagesPresente.shift();
        this.imagesPresente.push(premiereImage);

        this.slidePresente = this.slidePresente -1;
      }
      
      if(this.slidePresente < 1) {
        this.imagesPresente.splice(0, 0, this.imagesPresente[this.imagesPresente.length - 1])
        this.imagesPresente.pop()

        this.slidePresente = this.slidePresente + 1;
      }
      
    } catch (error) {
      console.error(error);
    }

  }

  pleinEcrantActif = false;
  pleinEcranSlider() {
    this.pleinEcrantActif = !this.pleinEcrantActif;

    if (this.pleinEcrantActif) document.getElementById("overlaySlider").style.display = "block";
    else document.getElementById("overlaySlider").style.display = "none";
  }


}

