<section class="d-flex flex-column justify-content-center align-items-center">
    <div class="titre">
        <h2>{{ 'descriptif.entreprise' | translate }}</h2>
    </div>
    <div class="containeur w-100">
        <div class="row">
            <div class="col-xl-6 d-flex flex-column justify-content-center align-items-center">
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <td class="table-titre">
                                {{ '@annee.renovation' | translate }}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-valeur">
                                {{ data?.iAnneeConstruction | translateValeur : data?.Traduction?.iAnneeConstruction }}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-titre">
                                {{'@amenagement.actuel' | translate}}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-valeur">
                                {{ data?.sJsonData?.fr?.AmenagementActuel | translateValeur : data?.sJsonData?.an?.AmenagementActuel }}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-titre">
                                {{'@hauteur.libre' | translate}}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-valeur">
                                {{ data?.sJsonData?.fr?.HauteurLibre | translateValeur : data?.sJsonData?.an?.HauteurLibre }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-striped">
                    <tbody>
                    <tr>
                        <td class="table-titre">
                            {{ 'titreSecurite' | translate }}
                        </td>
                    </tr>
                    <tr>
                        <td class="table-valeur">
                            {{ data?.sJsonData?.fr?.Securite | translateValeur : data?.sJsonData?.an?.Securite }}
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div class="col-xl-6 d0flex flex-column justify-content-center align-items-center">
                <table class="table table-stripedw-100">
                    <tbody>
                        <tr>
                            <td class="table-titre">
                                {{ '@internet.telecoms' | translate }}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-valeur">
                                {{ data?.sJsonData?.fr?.Internet | translateValeur : data?.sJsonData?.an?.Internet }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-stripedw-100">
                    <tbody>
                        <tr>
                            <td class="table-titre">
                                {{'@chauffage' | translate}}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-valeur">
                                {{ data?.sJsonData?.fr?.Chauffage | translateValeur : data?.sJsonData?.an?.Chauffage }}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-titre">
                                {{ '@climatisation' | translate }}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-valeur">
                                {{ data?.sJsonData?.fr?.Climatisation | translateValeur : data?.sJsonData?.an?.Climatisation }}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-titre">
                                {{ 'eclairage' | translate }}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-valeur">
                                {{ data?.sJsonData?.fr?.sEclairage | translateValeur : data?.sJsonData?.an?.sEclairage }}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-titre">
                                {{'@ascenseur' | translate}}
                            </td>
                        </tr>
                        <tr>
                            <td class="table-valeur">
                                {{ data?.sJsonData?.fr?.Ascenceur | translateValeur : data?.sJsonData?.an?.Anscenceur }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>