import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-rr-goplex',
  templateUrl: './rr-goplex.component.html',
  styleUrls: ['./rr-goplex.component.scss']
})
export class RrGoplexComponent implements OnInit {

  constructor() { }
  
  // @Input() data: any;
  private _data: any;
  @Input('data')
  public get data(): any {
    return this._data;
  }
  public set data(data: any) {
    this._data = data;

    this.listeColonnes = [
      'sIdentifiant',
      ...Object.keys(this.data.proprieteConfig.champsAppartements),
    ];
  }

  listeColonnes = [];

  mobileLoyerActif = 0;

  get mobileLoyer() {
    return this.data.Loyers[this.mobileLoyerActif]
  }

  ngOnInit(): void {
  }

  returnZero() { return 0; }

  onTableScroll(event) {
    const container = event.target as HTMLDivElement;
    const shadow = document.querySelector('[id="shadow"]') as HTMLDivElement

    if (container.scrollLeft > 0) {
      shadow.classList.add('scrolled')
    } else {
      shadow.classList.remove('scrolled')
    }
  }

  goto(idx) {
    if (idx < 0) idx = 0
    if (idx >= this.data.Loyers.length) idx = this.data.Loyers.length - 1

    this.mobileLoyerActif = idx
  }
}
