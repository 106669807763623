import { BoutonLoginComponent } from './bouton-login.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import * as AuthV1 from '../auth.service';
import { OuvreLienComponentComponent } from '../general/ouvre-lien-component/ouvre-lien-component.component';
import { CompleteRegistrationComponent } from '../complete-registration/complete-registration.component';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: '.pmml-bouton-download',
  template: `<ng-content></ng-content>`
})
export class BoutonDownloadRapportComponent extends BoutonLoginComponent implements OnInit {

  @Input('nomRapport') nomRapport: string;

  async handleClick(event: Event, btn: HTMLElement): Promise<any> {
    event.stopImmediatePropagation();
    event.preventDefault();

    let nomRapport = this.nomRapport;
    if(btn.getAttribute('href').startsWith('#rap:')) nomRapport = btn.getAttribute('href').replace('#rap:', '');
    
    await this.gaTrack('rapport', this.nomRapport, 'boutonClique');

    this.telechargerRapport(this.nomRapport);
  }

  async afficherDialogeuConnexion(): Promise<any> {
    const dialogRef = this.dialog.open(CompleteRegistrationComponent, {
      maxWidth: '450px',
      width: '100vw',
      data: {},
    });

    const resultat = await dialogRef.afterClosed().toPromise();
    return resultat;
  }
  
  estAuth() {
    return new Promise((resolve, reject) => {
      if(this.auth.token) return resolve(true);
      const subscription = this.authService.isAuthenticated$.subscribe((isAuth) => {
          resolve(isAuth);
      });

      setTimeout(() => {
        subscription.unsubscribe();
        resolve(false);
      }, 300);
    });
  }

  afficheOuvreLienDialog(lien) {
    const dialogRef = this.dialog.open(OuvreLienComponentComponent, {
      maxWidth: '450px',
      width: '100vw',
      data: {
        titre: '@rapports.ouvrirLien',
        bouton: '@rapports.ouvrirLienMessage',
        lien,
      },
    });
  }

  async telechargerRapport(idRapport) {
    try {
      let isAuth = await this.estAuth();
      if(!isAuth) {
        isAuth = await this.afficherDialogeuConnexion();
      }
      if(!isAuth) return;

      const download = await this.auth.getReportDownload(idRapport, true);
      if(!download) return alert('Erreur lors du téléchargement. Veuillez nous contacter pour plus de détails.');

      this.afficheOuvreLienDialog(download);
    } catch (error) {
      console.error(error);
    }
  }

  constructor(
    public auth: AuthV1.AuthService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {
    super(auth);
  }

  ngOnInit(): void {
    super.ngOnInit()
  }
}
