<div class="container-sm pt-3">
    <!-- LANDEV INSCRIPTION -->
    <div class="box-Terrain pb-4 pt-5 bg-black" role="alert" *ngIf="listings[0] == 'iTerrain' && !environment?.afficherTerrain">
        <a class="col text-decoration-none text-reset" [href]="('http://landev.ai/' | translateValeur : 'https://landev.ai/en/home/')" target="_blank">
            <img src="https://pmml.ca/wp-content/uploads/2021/05/LANDEV.AI-LOGO-ang.png" class="landev-logo pb-3">
        </a>
        <p class="mt-2 h5 font-weight-bold">{{ "consultation.landev" | translate }}</p>
        <p class="h5 pb-4 font-weight-bold">{{ "consultation.landev2" | translate }}</p>
        <a [href]="('http://landev.ai/' | translateValeur : 'https://landev.ai/en/home/')" target="_blank" class="connect">{{ 'acces.landev' | translate }}</a>
    </div>
    <!-- GOPLEX INSCRIPTION -->
    <!-- <div class="box-Terrain pb-4 pt-5 mt-5 mt-md-0" role="alert" *ngIf="filtres?.sTypeLogement == 'iGoplex'">
        <a class="col text-decoration-none text-reset" [href]="('https://landev.ai/terrain/' | translateValeur : 'https://landev.ai/en/land-for-sale/')" target="_blank">
            <img src="https://pmml.ca/wp-content/uploads/2021/11/goplex_logo_menu_pmml.png" class="landev-logo pb-3">
        </a>
        <p class="mt-2 h5 font-weight-bold">{{ "consultation.goplex" | translate }}</p>
        <p class="h5 pb-4 font-weight-bold">{{ "consultation.goplex2" | translate }}</p>
        <a [href]="('https://goplex.com/proprietes/' | translateValeur : 'https://goplex.com/en/for-sale/')" target="_blank" class="connect goplex">{{ 'acces.goplex' | translate }}</a>
    </div> -->
    <div class="row">
        <div class="alert alert-secondary mx-auto" role="alert" [hidden]="showloader || isFirstLoad || isLoadingListings" *ngIf="listings[1]?.length == 0 && filtres.sTypeLogement !== 'iTerrain'">
            <strong>{{ "@propriete.non.dispo" | translate }}</strong>
        </div>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 container ml-md-3 ml-0 " matSort *ngIf="(listings[0] !== 'iTerrain' || environment?.afficherTerrain) && listings[1]?.length > 1">
            <!-- Favoris -->
            <ng-container matColumnDef="favoris">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"  class="curseur-point"><span id="favori" class="favoris" (mouseenter)="showFavorisTooltip($event, element)" (mouseleave)="hideFavorisTooltip()" (click)="ajouterFavoris(element, $event)"><i *ngIf="!element.fav" class="far fa-star favoris__icon"></i><i *ngIf="element.fav" class="fas fa-star favoris__icon"></i></span></td>
            </ng-container>

            <!-- Num Civique -->
            <ng-container matColumnDef="sNumeroCivique">
                <th mat-header-cell *matHeaderCellDef> {{'adresse' | translate }} </th>
                <td mat-cell *matCellDef="let element"  class="curseur-point">
                    <a class="text-dark text-decoration-none" *ngIf="element.TitreAltFr" [href]="('/' | translateValeur : '/en/') + element?.sLien" target="_blank">{{ element.TitreAltFr | translateValeur : element.TitreAltAn }}&nbsp;</a>
                    <a class="text-dark text-decoration-none" *ngIf="element.sTypeLogement !== 'iTerrain' && element.sRue" [href]="('/' | translateValeur : '/en/') + element?.sLien" target="_blank">{{ element.sNumeroCivique }} {{ element.sRue }}&nbsp;</a>
                    <a class="text-dark text-decoration-none" *ngIf="element.sTypeLogement == 'iTerrain' && element.sRue" [href]="('/' | translateValeur : '/en/') + element?.sLien" target="_blank">{{ element.sRue }}&nbsp;</a>
                </td>
            </ng-container>

            <!-- Secteur -->
            <ng-container matColumnDef="sNomVille">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ '@secteur' | translate}} </th>
                <td mat-cell *matCellDef="let element"  class="curseur-point"><a class="text-dark text-decoration-none" [href]="('/' | translateValeur : '/en/') + element?.sLien" target="_blank"> {{element.sNomVille}}&nbsp;</a></td>
            </ng-container>

            <!-- Pi2 ou Unités -->
            <ng-container [matColumnDef]="typeMesure">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-uppercase"> 
                    <span>{{ '@menu.multilogement.unites' | translate }} <span *ngIf="typeSite !== 'goplex.com'">/ {{ '@pi2' | translate }}</span></span>
                </th>
                <td mat-cell *matCellDef="let element" class="curseur-point">
                    <a class="text-dark text-decoration-none" 
                        *ngIf="element.sTypeLogement == 'iSemiCommercial' || element.sTypeLogement == ''"
                        [href]="('/' | translateValeur : '/en/') + element?.sLien" target="_blank"> {{ element.iUnitesTotal | number }} {{ '@menu.multilogement.unites' | translate }}&nbsp;
                    </a>
                    
                    <a class="text-dark text-decoration-none" 
                        *ngIf="element.sTypeLogement !== 'iSemiCommercial' && element.sTypeLogement !== ''"
                        [href]="('/' | translateValeur : '/en/') + element?.sLien" target="_blank"> {{ element.iPiedsCarreTotal | number }} {{ '@pi2' | translate }} &nbsp;
                    </a>
                </td>
            </ng-container>

            <!-- Prix demandé -->
            <ng-container matColumnDef="fPrixDemande">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-uppercase"> {{ '@menu.multilogement.prixdemande' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="curseur-point">
                    <a *ngIf="element.fPrixDemande" class="text-dark text-decoration-none" [href]="('/' | translateValeur : '/en/') + element?.sLien" target="_blank">{{element.fPrixDemande | currency : 'CAD' : 'symbol-narrow' : '0.0-0'}}&nbsp;</a>
                    <a *ngIf="!element.fPrixDemande" class="text-dark text-decoration-none" [href]="('/' | translateValeur : '/en/') + element?.sLien" target="_blank">{{ '@surdemande' | translate }}&nbsp;</a>
                
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row (click)="directionFd(element.sLien, element.sTypeLogement)" *matRowDef="let element; columns: displayedColumns;"></tr>

        </table>
    </div>
    <div class="row justify-content-center" *ngIf="(filtres.sTypeLogement !== 'iTerrain' || environment?.afficherTerrain)">
        <div class="col-auto">
            <mat-spinner [hidden]="!showloader && !isLoadingListings" color="wharm"></mat-spinner>
        </div>
    </div>
    <div style="height: 8vh"></div>
</div>