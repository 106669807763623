<section style="background-image: url('http://localhost:8888/wp-content/uploads/2020/05/51_cover-scaled.jpg');">
    <div class="overlay w-100 h-100 d-flex justify-content-center align-items-center">
        <div class="containeur w-100">
            <div class="banniere d-flex justify-content-center align-items-center">
                <div class="titre d-flex align-items-center justify-content-center">
                    <h2>{{ '@acces' | translate }}</h2>
                </div>
            </div>
            <div class="contenu">
                <div class="section">
                    <p>{{ data?.sJsonData?.fr?.Acces | translateValeur : data?.sJsonData?.an?.Acces }}</p>
                </div>
                <div class="section">
                    <h3>{{ '@transports' | translate }}</h3>
                    <p>{{ data?.sJsonData?.fr?.TransportsEnCommun | translateValeur : data?.sJsonData?.an?.TransportsEnCommun }}</p>
                </div>
                <div class="section">
                    <h3>{{ '@stationnement' | translate }}</h3>
                    <p>{{ data?.sRevetStationnement | translateValeur : data?.Traduction.sRevetStationnement }}</p>
                </div>
            </div>
            <div class="banniere d-flex justify-content-center align-items-center">
                <div class="titre d-flex align-items-center justify-content-center">
                    <h2>{{ '@zonage' | translate }}</h2>
                </div>
            </div>
            <div class="contenu">
                <div class="section">
                    <p>{{ data?.sJsonData?.fr?.Zonage | translateValeur : data?.sJsonData?.an?.Zonage }}</p>
                </div>
                <div class="section">
                    <h3>{{ '@opportunite.signalisation' | translate }}</h3>
                    <p>{{ data?.sJsonData?.fr?.PossibiliteSignalisation | translateValeur : data?.sJsonData?.an?.PossibiliteSignalisation }}</p>
                </div>
            </div>
        </div>
    </div>
</section>
