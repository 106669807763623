<div class="containeur-carte mx-auto" [ngClass]="{'container pl-0 pr-0' : !mapListings, 'container-md': mapListings}">
  <div class="address" [hidden]="!affichageDetailPropriete">
    {{ data | pmmlProprieteTitre}}
    <!-- <span *ngIf="(data?.sTypeLogement === 'iCommercial' || data?.sTypeLogement === 'iSemiCommercial')">
      {{ data?.sNumeroCivique }} {{ data?.sRue }}, {{ data?.sNomVille }}</span>
    <span *ngIf="(data?.sTypeLogement === 'iLocal')">
      {{'@adresse.local' | translate}}: {{data?.sNumeroCivique}} 
      | {{data?.ProprieteParent.sNumeroCivique }} {{ data?.ProprieteParent.sRue }}, 
      {{ data?.ProprieteParent.sNomVille }}
    </span>
    <span *ngIf="(data?.sTypeLogement === 'iTerrain')">{{ data?.sRue }}, {{ data?.sNomVille }}</span>
    <span
      *ngIf="(data?.sTypeLogement !== 'iTerrain' && data?.sTypeLogement !== 'iLocal' && data?.sTypeLogement !== 'iCommercial' && data?.sTypeLogement !== 'iSemiCommercial')">
      {{ data?.sNumeroCivique }} {{ data?.sRue }}, {{ data?.sNomVille }}
    </span> -->
  </div>
  <div class="image-carte"
    *ngIf="(data?.sTypeLogement !== 'iLocal' && !ficheLocaux) && !affichageDepuisListings && afficherSatellite && data?.sCarteRoutiere && data?.IDPropriete !== 813299">
    <img style="width: 100%" [src]="data?.sCarteRoutiere | urlCarte"
      alt="{{ data?.sNoCivique }} {{ data?.sRue }}, {{ data?.sNomVille }}">
  </div>


  <!-- <div class="address pt-0 mt-0" *ngIf="data?.IDPropriete == 813299">
    <span>Portfolio Châteauguay</span>
  </div> 
  <div class="image-carte mb-0 pb-0" *ngIf="data?.IDPropriete == 813299">
    <img [src]="data?.sCarteSatellite | urlCarte" alt="{{ data?.sNoCivique }} {{ data?.sRue }}, {{ data?.sNomVille }}">
  </div>
  <div class="address pt-0 mt-0" *ngIf="data?.IDPropriete == 813299">
    <span>Portfolio Fouquet, Candiac</span>
  </div> -->
  <div class="image-carte" *ngIf="data?.IDPropriete == 813299">
    <img [src]="data?.sCarteRoutiere | urlCarte" alt="{{ data?.sNoCivique }} {{ data?.sRue }}, {{ data?.sNomVille }}">
  </div>

  <div class="carte-gmaps">
    <div *ngIf="data?.sTypeLogement == 'iLocal'">
      <h3 class="summary w-100 mb-4 mt-4"><i class="fas fa-map-marker-alt"></i> {{ '@cartographie' | translate }}
      </h3>
    </div>
    <div class="row justify-content-end container-ouvrir-maps" *ngIf="!affichageDepuisListings"
      [ngClass]="{'d-none': data?.sTypeLogement == 'iPortfolio' || data?.sTypeLogement == 'iPortfolioComm'}">
      <a class="btn-ouvrir-maps mt-2 mr-4 mt-md-3 mr-md-4"
        href="https://www.google.com/maps/search/?api=1&query={{arrPositions?.lat}},{{arrPositions?.lng}}"
        target="_blank">
        <span style="font-size: 30px;"><i class="fas fa-map-marked-alt d-md-none"></i></span>
        <label class="d-none d-md-block m-0"> {{ "@ouvrir.dans.maps" | translate }}</label>
      </a>
    </div>

    <mat-button-toggle-group [(ngModel)]="style" class="view-switcher" [hidden]="!affichageDetailPropriete">
      <mat-button-toggle value="mapbox://styles/mapbox/streets-v11">{{ '@carte.style.rue' | translate
        }}</mat-button-toggle>
      <mat-button-toggle value="mapbox://styles/mapbox/satellite-v9">{{ '@carte.style.satellite' | translate
        }}</mat-button-toggle>
    </mat-button-toggle-group>

    <mgl-map [style]="style" [doubleClickZoom]="false" (mapLoad)="map = $event; initCarte()" *ngIf="!mapListings"
      [zoom]="zoom" [minZoom]="minZoom" [maxZoom]="maxZoom" [center]="[-73.71, 45.53]">

      <mgl-control mglScale unit="metric" position="bottom-right"></mgl-control>
      <mgl-control mglNavigation [showZoom]="true" [showCompass]="true" position="top-right"></mgl-control>
      <mgl-control mglGeolocate [trackUserLocation]="true" position="top-left"></mgl-control>


      <mgl-marker #marqueur *ngFor="let marker of marqueurFinal; let i = index" [lngLat]="marker.position">
        <i class="fas fa-map-marker-alt iconeMarqueur" (click)="openPropriFD(marqueur, i)"></i>
      </mgl-marker>


      <!-- Map pour FD -->
      <mgl-popup [marker]="marqueurActif" *ngIf="marqueurActif && estPortfolio" [anchor]="'right'"
        [className]="'popupContainer'">
        <div
          [ngClass]="{'popupContainer': !data?.sJsonData?.bPortfolioCommeMulti, 'popContainerCommeMulti': data?.sJsonData?.bPortfolioCommeMulti }">
          <div class="imageProprieteFrame" *ngIf="!data?.sJsonData?.bPortfolioCommeMulti">
            <img [src]="marqueurFinal[indexCarte]?.sImagePrincipale | resizePhotoPrincipale">
          </div>

          <div class="popupDetails">
            <span class="popupAddresse" *ngIf="marqueurFinal[indexCarte]?.sTypeLogement == 'iTerrain'">{{
              marqueurFinal[indexCarte]?.label }}</span>
            <span class="popupAddresse" *ngIf="marqueurFinal[indexCarte]?.sTypeLogement !== 'iTerrain'">{{
              marqueurFinal[indexCarte]?.label }}</span>
            <!-- <span class="popupAddresse" *ngIf="marqueurFinal[indexCarte]?.sTypeLogement !== 'iTerrain'">{{ marqueurFinal[indexCarte]?.sNumeroCivique }} {{ marqueurFinal[indexCarte]?.label }}</span> -->
            <br />
            <span class="popupUnites"
              *ngIf="!marqueurFinal[indexCarte]?.sTypeLogement || marqueurFinal[indexCarte]?.sTypeLogement == 'iSemiCommercial'">{{
              marqueurFinal[indexCarte]?.iUnitesTotal | number }} {{ "@menu.multilogement.unites" | translate }}</span>
            <span class="popupUnites"
              *ngIf="marqueurFinal[indexCarte]?.sTypeLogement !== undefined && marqueurFinal[indexCarte]?.sTypeLogement !== 'iSemiCommercial'">{{
              marqueurFinal[indexCarte]?.iPiedsCarreTotal | number}} {{ "@pi2" | translate }}</span>
          </div>

          <div class="popupToolbar pt-3">
            <!-- <a class="hrefImmeuble" [href]="('/' | translateValeur : '/en/') + marqueurFinal[indexCarte]?.idPropriete"
              target="_blank">
              <i class="far fa-share"></i>
            </a>
            <a>
              <i class="far fa-heart"></i>
            </a> -->
          </div>

          <div class="popupVoirPlus" [class.goplex]="environment.cartePopupTranstion"
            *ngIf="!data?.sJsonData?.bPortfolioCommeMulti">
            <a class="hrefImmeuble" [href]="marqueurFinal[indexCarte]?.urlFiche" target="_blank">
              <span class="popupVoirPlus">{{'Voir la fiche descriptive' | translateValeur : 'View listing'}}</span>
            </a>
          </div>
        </div>
      </mgl-popup>
    </mgl-map>
  </div>

  <!-- LANDEV INSCRIPTION -->
  <div class="box-Terrain pb-4 pt-5 mt-5 mt-md-0" role="alert"
    *ngIf="filtres?.sTypeLogement == 'iTerrain' && !environment?.afficherTerrain">
    <a class="col text-decoration-none text-reset"
      [href]="('https://landev.ai/terrain/' | translateValeur : 'https://landev.ai/en/land-for-sale/')" target="_blank">
      <img src="https://pmml.ca/wp-content/uploads/2021/05/LANDEV.AI-LOGO-ang.png" class="landev-logo pb-3">
    </a>
    <p class="mt-2 h5 font-weight-bold">{{ "consultation.landev" | translate }}</p>
    <p class="h5 pb-4 font-weight-bold">{{ "consultation.landev2" | translate }}</p>
    <a [href]="('https://landev.ai/terrain/' | translateValeur : 'https://landev.ai/en/land-for-sale/')" target="_blank"
      class="connect">{{ 'acces.landev' | translate }}</a>
  </div>
  <!-- GOPLEX INSCRIPTION -->
  <!-- <div class="box-Terrain pb-4 pt-5 mt-5 mt-md-0" role="alert" *ngIf="filtresRecherche?.sTypeLogement == 'iGoplex' && location !== 'goplex.com'">
      <a class="col text-decoration-none text-reset" [href]="('https://landev.ai/terrain/' | translateValeur : 'https://landev.ai/en/land-for-sale/')" target="_blank">
          <img src="https://pmml.ca/wp-content/uploads/2021/11/goplex_logo_menu_pmml.png" class="landev-logo pb-3">
      </a>
      <p class="mt-2 h5 font-weight-bold">{{ "consultation.goplex" | translate }}</p>
      <p class="h5 pb-4 font-weight-bold">{{ "consultation.goplex2" | translate }}</p>
      <a [href]="('https://goplex.com/proprietes/' | translateValeur : 'https://goplex.com/en/for-sale/')" target="_blank" class="connect">{{ 'acces.goplex' | translate }}</a>
  </div> -->

  <!-- Map pour Listings -->
  <div class="row" *ngIf="!data">
    <!-- Propriété non disponible -->
    <mat-card class="proprieteNonDispo" (click)="directionMonProfil()"
      *ngIf="!donneesDisponible && filtresRecherche.sTypeLogement !== 'iGoplex'">
      <div class="col-9">
        {{'@propriete.non.dispo.critere' | translate}} <br> {{ '@modifier.recherche.profil' | translate }}
      </div>
      <div class="col-auto d-flex justify-content-center align-items-center">
        <button mat-raised-button class="p-2" (click)="directionMonProfil()"><span *ngIf="!toggleMobile">{{
            '@modifierprofil' | translate }}</span><span *ngIf="toggleMobile">Mon compte</span></button>
      </div>
    </mat-card>

    <div class="pr-0 carte-box">
      <div [ngClass]="{'carte-gmaps' : !toggleMobile}"
        *ngIf="marqueurMapBox && (filtres?.sTypeLogement !== 'iTerrain' || environment?.afficherTerrain )">

        <mgl-map [style]="style" (mapLoad)="map = $event; initCarteListings()" *ngIf="mapListings" [zoom]="zoom"
          [center]="[-73.97, 46.90]" [minZoom]="minZoom" [maxZoom]="maxZoom" [attributionControl]="true">

          <mgl-marker #marqueur *ngFor="let marker of dansChampsVision; let i = index" [lngLat]="marker.position">
            <i class="fas fa-map-marker-alt iconeMarqueur"
              [ngClass]="{'iconeMarqueurHover': marker?.marker, 'iconeMarqueur': !marker?.marker}"
              (click)="openPropri(marqueur,i, marker.position)" (mouseenter)="survolMarqueur(marker)"
              (mouseleave)="neSurvolMarqueur()"></i>
          </mgl-marker>


          <mgl-popup [marker]="marqueurActif" *ngIf="marqueurActif && estPortfolio" [anchor]="'right'" bounds
            [className]="'popupContainer'">
            <div class="popupContainer">
              <div class="imageProprieteFrame">
                <a class="photo" [href]="dansChampsVision[indexCarte]?.urlFiche"
                  [style.background]="dansChampsVision[indexCarte]?.sImagePrincipale | photoPrincipaleUrl">
                </a>
              </div>

              <div class="popupDetails">
                <span class="popupAddresse" *ngIf="dansChampsVision[indexCarte]?.sTypeLogement == 'iTerrain'">{{
                  dansChampsVision[indexCarte]?.label }}</span>
                <span class="popupAddresse" *ngIf="dansChampsVision[indexCarte]?.sTypeLogement !== 'iTerrain'">{{
                  dansChampsVision[indexCarte]?.label }}</span>
                <!-- <span class="popupAddresse" *ngIf="dansChampsVision[indexCarte]?.sTypeLogement !== 'iTerrain'">{{ dansChampsVision[indexCarte]?.sNumeroCivique }} {{ dansChampsVision[indexCarte]?.label }}</span> -->
                <br />
                <span class="popupUnites"
                  *ngIf="dansChampsVision[indexCarte]?.sTypeLogement == 'iMultiLogements' || dansChampsVision[indexCarte]?.sTypeLogement == 'iSemiCommercial'">{{
                  dansChampsVision[indexCarte]?.iUnitesTotal | number }} {{ "@menu.multilogement.unites" | translate
                  }}</span>
                <span class="popupUnites"
                  *ngIf="dansChampsVision[indexCarte]?.sTypeLogement !== 'iMultiLogements' && dansChampsVision[indexCarte]?.sTypeLogement !== 'iSemiCommercial'">{{
                  dansChampsVision[indexCarte]?.iPiedsCarreTotal | number}} {{ "@pi2" | translate }}</span>
              </div>

              <div class="popupToolbar pt-3">
                <!-- <a class="hrefImmeuble" [href]="('/' | translateValeur : '/en/') + dansChampsVision[indexCarte]?.idPropriete"
                    target="_blank">
                    <i class="far fa-share"></i>
                  </a> -->
                <!-- <span *ngIf="estConnecte()">
                    <i class="far fa-heart iconeMarqueur pr-2" *ngIf="!this.favoris" (click)="this.favoris = !this.favoris" (change)="ajoutSupressionFavoris(dansChampsVision[indexCarte]?.idPropriete)"></i>
                    <i class="fas fa-heart iconeMarqueur pr-2" *ngIf="this.favoris || dansChampsVision[indexCarte]?.favoris" (click)="this.favoris = !this.favoris" (change)="ajoutSupressionFavoris(dansChampsVision[indexCarte]?.idPropriete)"></i>
                    <input *ngIf="!estConnecte()" id="filtre-favoris-desktop" name="filtre-favoris" type="checkbox" (click)="reactionDeconnecte()">
                  </span>
                
                  <span *ngIf="!estConnecte()">
                    <i class="far fa-heart iconeMarqueur pr-2" (change)="reactionDeconnecte()"></i>
                  </span> -->
              </div>

              <div class="popupVoirPlus" [class.goplex]="environment.cartePopupTranstion">
                <a class="hrefImmeuble" [href]="dansChampsVision[indexCarte]?.urlFiche" target="_blank">
                  <span class="popupVoirPlus">{{'Voir la fiche descriptive' | translateValeur : 'View listing'}}</span>
                </a>
              </div>
            </div>
          </mgl-popup>
        </mgl-map>
      </div>
    </div>
    <div class="boxListings pl-1 d-none d-md-block" style="width: 21.5%;">
      <div class="p-1" *ngFor="let listing of choixTuiles(listingTuile, dansChampsVision)">
        <div class="listing">
          <a class="photo" [alt]="titreFormat(listing)" [href]="listing.urlFiche" target="_blank"
            (mouseenter)="survolMarqueur(listing)" (mouseleave)="neSurvolMarqueur()">
            <span id="favori" class="favoris" (mouseenter)="showFavorisTooltip($event, listing)"
              (mouseleave)="hideFavorisTooltip()" (click)="ajouterFavoris(listing, $event)"><i *ngIf="!listing.fav"
                class="far fa-star favoris__icon"></i><i *ngIf="listing.fav"
                class="fas fa-star favoris__icon"></i></span>

            <div class="informations" [ngClass]="{'onHoverMarker': listing?.marker}">
              <h4>{{ listing.label }}</h4>
              <span
                *ngIf="listing?.iUnitesTotal && listing?.sTypeLogement != 'iTerrain' && listing?.sTypeLogement != 'iCommercial'  && listing?.sTypeLogement != 'iPortfolioComm' && listing?.sTypeLogement != 'iLocal' && !listing?.iLocation">
                {{listing?.iUnitesTotal}} {{ '@unites' | translate }}
              </span>
              <span *ngIf="listing?.iPiedsCarreTotal && listing?.sTypeLogement == 'iCommercial' && !listing?.iLocation">
                {{listing?.iPiedsCarreTotal | number}} {{ '@pi2' | translate }}
              </span>
              <span
                *ngIf="listing?.iPiedsCarreTotal && listing?.sTypeLogement == 'iPortfolioComm' && !listing?.iLocation">
                {{listing?.iPiedsCarreTotal | number}} {{ '@pi2' | translate }}
              </span>
              <span *ngIf="listing?.iLocation && listing?.SuperficieMin && listing?.SuperficieMax">
                <span *ngIf="+listing?.SuperficieMax > +listing?.SuperficieMin">
                  {{listing?.SuperficieMin | number}} {{ '@pi2' | translate }} à {{listing?.SuperficieMax | number}} {{ '@pi2' | translate }}
                </span>
                <span *ngIf="+listing?.SuperficieMax <= +listing?.SuperficieMin">
                  {{listing?.SuperficieMax | number}} {{ '@pi2' | translate }} à {{listing?.SuperficieMin | number}} {{ '@pi2' | translate }}
                </span>
              </span>
            </div>
            <img [alt]="titreFormat(listing)" [src]="listing?.sImagePrincipale | photoPrincipaleUrl: 600:true"
              class="bgImagePrincipal">
          </a>
        </div>
      </div>
    </div>
  </div>