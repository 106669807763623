<section class="d-flex justify-content-center align-items-center">
    <div class="containeur w-100">
        <div class="row rangee">
            <div class="col-lg-6 col-xs-12" style="margin-bottom: 20px;">
                <div class="descriptif-propriete">
                    <h2 class="titre-section"><i class="fas fa-building "></i> {{ '@descriptif.titre' | translate }}</h2>
                    <table class="table table-striped">
                        <thead>
                            <tr class="top">
                                <th class="top-titre"></th>
                                <th class="top-titre"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr>
                                <th>{{ '@menu.multilogement.unites' | translate }}</th>
                                <td>{{ data?.iUnitesTotal }}</td>
                            </tr> -->
                            <tr>
                                <th>{{ '@descriptif.etages' | translate }}</th>
                                <td>{{ data?.iEtages }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@stationnement' | translate }}</th>
                                <td>
                                    {{ data?.sStationnements | translateValeur : data?.Traduction?.sStationnements }}
                                </td>
                            </tr>
                            <tr *ngIf="data?.sRevetStationnement || data?.Traduction?.sRevetStationnement">
                                <th>{{ 'sRevetStationnement' | translate }}</th>
                                <td>
                                    {{ data?.sRevetStationnement | translateValeur : data?.Traduction?.sRevetStationnement }}
                                </td>
                            </tr>
                            <tr>
                                <td class="titre-groupement" colspan="2">
                                    Construction
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@descriptif.structure' | translate }}</th>
                                <td>{{ data?.sTypeBatiment | translateValeur : data?.Traduction?.sTypeBatiment }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sFenetres' | translate }}</th>
                                <td>{{ data?.sFenetres | translateValeur : data?.Traduction?.sFenetres }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sAnneeToit' | translate }}</th>
                                <td>{{ data?.sAnneeToit | translateValeur: data.Traduction.sAnneeToit }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@hauteurlibre' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.iHauteurLibre | translateValeur : data?.sJsonData?.an?.iHauteurLibre }}</td>
                            </tr>
                            <tr>
                                <td class="titre-groupement" colspan="2">
                                    {{ 'syselectromec' | translate }}
                                </td>
                            </tr>
                            <tr>
                                <th>{{ 'eclairage' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.sEclairage | translateValeur : data?.sJsonData?.an?.sEclairage }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'syssecurite' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.sSystemeSecurite | translateValeur : data?.sJsonData?.an?.sSystemeSecurite }}</td>
                            </tr>
                            <tr>
                                <th>{{ 'sSysIncendie' | translate }}</th>
                                <td>{{ data?.sSysIncendie | translateValeur : data?.Traduction?.sSysIncendie }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@installationsexistantes' | translate }}</th>
                                <td [innerHTML]="(data?.sJsonData?.fr?.sInstallationExistantes | translateValeur : data?.sJsonData?.an?.sInstallationExistantes) | nl2br"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12">
                <div class="descriptif-propriete">
                    <h2 class="titre-section"><i class="fas fa-usd-circle"></i> {{ '@fd.commercial.infosFoncieres' | translate }}</h2>
                    <table class="table table-striped">
                        <thead>
                            <tr class="top">
                                <th class="top-titre"></th>
                                <th class="top-titre"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{ '@info.cadastre' | translate }}</th>
                                <td>{{ data?.sCadastre | translateValeur : data?.Traduction?.sCadastre }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@superficie.terrain' | translate }}</th>
                                <td>{{ data?.fSuperficieTerrain | translateValeur: data.Traduction.fSuperficieTerrain }}</td>
                            </tr>
                            <tr>
                                <td class="titre-groupement" colspan="2">
                                    {{ '@evaluation.municipale' | translate }}
                                </td>
                            </tr>
                            <tr>
                                <th>{{ '@terrain' | translate }}</th>
                                <td>{{ data?.sEvaluationMunicipaleTerrain }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@batiment' | translate }}</th>
                                <td>{{ data?.sEvaluationMunicipaleBatiment }}</td>
                            </tr>
                            <tr>
                                <th>{{'@evaluationTotal' | translate}}</th>
                                <td>{{ data?.sEvaluationMunicipale }}</td>
                            </tr>
                            <tr *ngIf="!data?.sJsonData?.fr?.Zonage && !data?.sJsonData?.fr?.VocationOptimale">
                                <th>{{ '@zonage' | translate }}</th>
                                <td [innerHTML]="data?.sJsonData?.fr?.sZonage | translateValeur : data?.sJsonData?.an?.sZonage | nl2br"></td>
                            </tr>
                            <tr *ngIf="!data?.sJsonData?.fr?.Zonage && !data?.sJsonData?.fr?.VocationOptimale">
                                <th>{{ '@vocation.optimale' | translate }}</th>
                                <td [innerHTML]="data?.sJsonData?.fr?.VocationOptimale | translateValeur : data?.sJsonData?.an?.VocationOptimale | nl2li"></td>
                            </tr>
                            <tr>
                                <th>{{ '@acces' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.Acces | translateValeur : data?.sJsonData?.an?.Acces }}</td>
                            </tr>
                            <tr>
                                <th>{{ '@transports' | translate }}</th>
                                <td>{{ data?.sJsonData?.fr?.sTransportsEnCommun | translateValeur : data?.sJsonData?.an?.sTransportsEnCommun }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
