import { Component, OnInit, Input } from '@angular/core';
import { WordpressService } from "../../api/wordpress.service";
import { ProprietesService } from "../../api/proprietes.service";
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  constructor(
    private wp: WordpressService,
    private proprietes: ProprietesService,
  ) { }
  
  environment = environment;
  @Input() data: any;

  ngOnInit(): void {    
  }

}
