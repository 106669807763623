<div id="favoris" *ngIf="estConnecte()">
    <h2><i style="font-size: 24px; margin-right: 4px;" class="fas fa-star"></i>{{'@mesFavoris' | translate}} ({{nbrFavoris}})</h2>
    <div class="container">
        <div class="row">
            <div class="alert alert-secondary mx-auto" role="alert" [hidden]="showLoader" *ngIf="favoris?.length == 0">
                <strong>{{ "@favoris.non.dispo" | translate }}<a [href]="routeProprietes">{{ "@favoris.non.dispo.ici" | translate }}</a></strong>
            </div>
            <div *ngFor="let fav of favoris" class="col-xl-4 col-lg-6 col-md-6 p-3">
                <div class="listing">
                    <a class="photo" 
                        [href]="('/' | translateValeur : '/en/') + fav.sLien" target="_blank"
                        [style.background]="fav.sImagePrincipale | photoPrincipaleUrl"
                        [style.backgroundSize]="'cover'">
                        <!-- [ngStyle]="{'color': getColor(fav.fav)}" -->
                        <span id="favori" class="favoris" (mouseenter)="showFavorisTooltip($event)" (click)="ajouterFavoris(fav, $event)"><i *ngIf="!fav.fav" class="far fa-star favoris__icon"></i><i *ngIf="fav.fav" class="fas fa-star favoris__icon"></i></span>
                        <div class="informations">
                            <h5>
                                <span [hidden]="fav.iLocation">{{ '@propriete.aVendre' | translate }}</span>
                                <span [hidden]="!fav.iLocation">{{ '@propriete.aLouer' | translate }}</span>
                            </h5>
                            <h4>{{ fav | pmmlProprieteTitre }}</h4>
                            <span [hidden]="fav.iUnitesTotal == 0  || fav.sTypeLogement == 'iTerrain' || fav.sTypeLogement == 'iCommercial' || fav.sTypeLogement == 'iLocal' || fav.iLocation">
                                {{fav.iUnitesTotal}} {{ '@unites' | translate }}
                            </span>
                            <span [hidden]="!fav.iPiedsCarreTotal  || fav.sTypeLogement != 'iCommercial' || fav.iLocation">
                                {{fav.iPiedsCarreTotal | number}} {{ '@pi2' | translate }}
                            </span>
                            <button type="button">{{ '@voir.fiche.descriptive' | translate }}</button>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
