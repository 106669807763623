<div class="row justify-content-center" *ngIf="typeRapportSel == null">
    <div class="col-lg-4 col-12 mb-4 mt-4" *ngFor="let typeRapport of TYPES_RAPPORTS">
        <mat-menu #menuRegions="matMenu" class="menuRegions">
            <button mat-menu-item style="font-weight: 600;"
                (click)="telechargerRapport(typeRapport.id, 'province')">
                {{'@rapports.provincial' | translate}}
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item *ngFor="let region of REGIONS" [value]="region.id"
                (click)="telechargerRapport(typeRapport.id, region.texte)">
                {{region.valeur}}
            </button>
        </mat-menu>
        <div class="text-center">
            <a [matMenuTriggerFor]="menuRegions">
                <img [src]="(typeRapport.imageFr | translateValeur : typeRapport.imageAn)" style="width: 375px;" alt="">
            </a>
        </div>
        <!-- <div class="text-center mt-3">
            <button mat-button (click)="choixTypeRapport(typeRapport.id)"
                class="btnIndustrie"
                [class.actif]="typeRapport.id == typeRapportSel"
                style="font-size: 1.3rem; text-transform: uppercase;">
                {{typeRapport.titre | translate}}
            </button>
        </div> -->
        <div class="mt-3 text-center">
            
            <button mat-button [matMenuTriggerFor]="menuRegions"
                class="btnIndustrie">
                <i class="fal fa-download"></i>
                {{'@rapports.telecharger' | translate}}
            </button>
            <!-- <mat-form-field appearance="fill">
                <mat-label>Choisir une région ...</mat-label>
                <mat-select (selectionChange)="telechargerRapport(typeRapport.id, $event)">
                  <mat-option *ngFor="let region of REGIONS" [value]="region.id">
                    {{region.valeur}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
            
        </div>
    </div>
</div>
<div class="row justify-content-center align-content-center mt-4" *ngIf="typeRapportSel">
    <!-- <mat-menu #menuAnnees="matMenu">
        <button mat-menu-item>Septembre 2022</button>
        <button mat-menu-item>Aout 2022</button>
        <button mat-menu-item>Juillet 2022</button>
        <button mat-menu-item>Juin 2022</button>
    </mat-menu> -->
    <div class="col-auto">
        <button mat-button (click)="choixTypeRapport(null)"
            class="btnIndustrie"
            style="font-size: 1rem; text-transform: uppercase;">
            <i class="fal fa-chevron-left"></i>
            Retour
        </button>
    </div>
    <div class="col">
        <h3>Rapports de marché {{typeRapportSel}}</h3>
    </div>
    <div class="col-12 mb-5"></div>
    <div class="col-lg-3 col-xs-12 mb-5" *ngFor="let region of REGIONS">
        <!-- <div>
            <img src="https://pmml.ca/wp-content/uploads/2022/05/Rapport-Mai2022-MockupFR-2048x1933.png" alt="">
        </div> -->
        <div class="text-center mb-3">
            <strong style="font-size: 1.2rem;">{{region.valeur}}</strong>
        </div>
        <div class="text-center">
            <button mat-button (click)="telechargerRapport(typeRapportSel, region.id)"
                class="btnRapport" style="font-size: 1.1rem;">
                {{'Télécharger' | translate}}
            </button>
            <!-- <button mat-raised-button (click)="telechargerRapport(typeRapportSel, region.id)"
                color="secondary" style="font-size: 1.1rem; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                {{'Télécharger' | translate}}
            </button>
            <button mat-raised-button [matMenuTriggerFor]="menuAnnees"
                color="secondary" style="font-size: 1.1rem; border-top-left-radius: 0; border-bottom-left-radius: 0; min-width: 25px;">
                <i class="fal fa-chevron-down"></i>
            </button> -->
        </div>
    </div>
</div>