import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-menu-deux',
  templateUrl: './menu-deux.component.html',
  styleUrls: ['./menu-deux.component.scss']
})
export class MenuDeuxComponent implements OnInit {

  @Input() data;

  constructor() { }

  ngOnInit(): void {
  }

}
