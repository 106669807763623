import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-groupe-location',
  templateUrl: './groupe-location.component.html',
  styleUrls: ['./groupe-location.component.scss']
})
export class GroupeLocationComponent implements OnInit {

  constructor() { }
  @Input() data: any;

  ngOnInit(): void {
  }

}
