<section>

    <div *ngIf="(data?.iTypeLogement === 'iLocal' && data?.IDCategorie ===  5 || data?.iTypeLogement === 'iLocal' || data?.IDCategorie === 10)" class="faits-saillants d-flex flex-column justify-content-center align-items-center">
        <div class="titre">
            <h2><i class="fas fa-comment-exclamation"></i> {{ '@info.faitssaillants' | translate }}</h2>
        </div>
        <div class="contenu">
            <span [innerHTML]="data?.sJsonData?.fr?.sFaitsSaillants | translateValeur : data?.sJsonData?.an?.sFaitsSaillants"></span>
        </div>
    </div>
    <div class="features d-flex justify-content-center align-items-center">
        <div class="containeur w-100">
            <div class="row">
                <div class="col-xl-4 col-sm-6">
                    <div class="texte d-flex flex-column justify-content-center align-items-center">
                        <span class="titre">Transit score</span>
                        <span class="valeur">{{ data?.sJsonData?.fr?.TransitScore | translateValeur : data?.sJsonData?.an?.TransitScore }}</span>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6">
                    <div class="image"     
                        [style.background]="'url(' + data?.sImagePrincipale + ')'"
                        [style.backgroundSize]="'cover'"
                        [style.backgroundPosition]="'center center'">
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6">
                    <div class="texte d-flex flex-column justify-content-center align-items-center">
                        <span class="titre">Walk score</span>
                        <span class="valeur">{{ data?.sJsonData?.fr?.WalkScore | translateValeur : data?.sJsonData?.an?.WalkScore }}</span>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6">
                    <div class="image"     
                        [style.background]="'url(' + data?.sImagePrincipale + ')'"
                        [style.backgroundSize]="'cover'"
                        [style.backgroundPosition]="'center center'">
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6">
                    <div class="texte d-flex flex-column justify-content-center align-items-center">
                        <span class="titre">{{ '@disponibilite' | translate }}</span>
                        <span class="valeur">{{ data?.sJsonData?.Bail1?.fr?.Disponibilite | translateValeur : data?.sJsonData?.Bail1?.an?.Disponibilite }}</span>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6">
                    <div class="image"     
                        [style.background]="'url(' + data?.sImagePrincipale + ')'"
                        [style.backgroundSize]="'cover'"
                        [style.backgroundPosition]="'center center'">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
