<section class="descriptif">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-11 col-sm-11 col-lg">
                <h4 class="titre-tableau ligne-sous">
                    <i class="fas fa-building"></i>
                    {{ '@espace.locatif.disponible' | translate }}
                </h4>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th class="col-rev-1">{{ '@espace.locatif' | translate }}</th>
                            <th class="col-rev-1 text-center">{{ '@etage' | translate }}</th>
                            <th class="col-rev-2 text-center">{{ 'superficie' | translate }}</th>
                            <th class="col-rev-3 text-center d-none d-md-table-cell ">{{ '@disponibilite' | translate }}</th>
                            <th class="col-rev-2 text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let proprieteEnfant of data?.ProprietesEnfants">
                            <td class="text-left ">
                                Suite {{proprieteEnfant?.sNumeroCivique}}
                            </td>
                            <td class="text-center">
                                <span *ngIf="!proprieteEnfant?.sJsonData?.Bail1?.Etage">
                                    {{ "@rdc" | translate}}
                                </span>
                                <span *ngIf="proprieteEnfant?.sJsonData?.Bail1?.Etage">
                                    {{proprieteEnfant?.sJsonData?.Bail1?.Etage}}
                                </span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="(!proprieteEnfant?.sJsonData?.Bail1?.fr?.Superficie || !proprieteEnfant?.sJsonData?.Bail1?.an?.Superficie)">
                                    {{proprieteEnfant?.sJsonData?.Bail1?.Superficie | number : '1.0-0' }} {{ "@pi2" | translate}}
                                </span>
                                <span *ngIf="(proprieteEnfant?.sJsonData?.Bail1?.fr?.Superficie && proprieteEnfant?.sJsonData?.Bail1?.an?.Superficie)">
                                    {{ proprieteEnfant?.sJsonData?.Bail1?.fr?.Superficie | translateValeur : proprieteEnfant?.sJsonData?.Bail1?.an?.Superficie }}
                                </span>
                            </td>
                            <td class="text-center d-none d-md-table-cell">
                                <span *ngIf="(proprieteEnfant?.sJsonData?.Bail1?.Disponibilite | date: 'yyyy-MM') > (dateAujourdhui | date: 'yyyy-MM')">{{proprieteEnfant?.sJsonData?.Bail1?.Disponibilite | date: 'yyyy-MM'}}</span>
                                <span *ngIf="(proprieteEnfant?.sJsonData?.Bail1?.Disponibilite | date: 'yyyy-MM') <= (dateAujourdhui | date: 'yyyy-MM')">{{ "immediatement" | translate }}</span>
                                <span *ngIf="!proprieteEnfant?.sJsonData?.Bail1?.Disponibilite">{{ "adeterminer" | translate }}</span>
                                
                            </td>
                            <td class="text-left align-middle rm-border-collapse pl-3">
                                <a [href]="('/' | translateValeur : '/en/') + 'location/' + proprieteEnfant?.sLien + jwt" target="_blank">
                                    <span class="d-none d-lg-table-cell">{{ '@listing.fiche.complete' | translate }}</span>
                                    <i class="far fa-file-pdf fd-icone" style="font-size: 25px;"></i>
                                    <span class="pr-2 pr-sm-4 d-none d-lg-inline"></span>
                                </a>
                                <a class="faux-lien" *ngIf="proprieteEnfant?.sJsonData?.UrlMatterport" (click)="clickVisite3D(proprieteEnfant?.sJsonData?.UrlMatterport)">
                                    <span class="d-none d-lg-table-cell">{{ '@voir.fiche.visite' | translate }}</span>
                                    <span class="trois-d fd-icone">3D</span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>