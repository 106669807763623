<div class="container mb-5 pl-2 pr-2 position-relative" (scroll)="onTableScroll($event)" *ngIf="data?.Loyers.length >= 1">
    <h3 class="mb-3">
        {{'titreRepartition' | translate}}
    </h3>
    <div id="shadow" class="table-shadow"></div>
    <div class="table-loyers">
        <table class="table table-striped table-hover w-100">
            <thead>
                <tr>
                    <th style="width: 125px;" class="text-center sticky-col">Logement</th>
                    <th class="text-center" *ngFor="let champRR of data?.proprieteConfig?.champsAppartements | keyvalue: returnZero">{{champRR.value.FR | translateValeur: champRR.value.AN}}</th>
                </tr>
            </thead>
            <tbody *ngFor="let loyer of (data?.Loyers || [])">
                <tr>
                    <th class="text-center sticky-col">{{loyer.sIdentifiant}}</th>
                    <td *ngFor="let champRR of data?.proprieteConfig?.champsAppartements | keyvalue: returnZero" [class]="champRR.value?.class">
                        <span> {{loyer[champRR.key] | rrFormatCol : champRR?.value?.type}} </span>
                        <span *ngIf="loyer?.sJsonData && loyer?.sJsonData[champRR.key]"> {{loyer?.sJsonData[champRR.key] | rrFormatCol : champRR?.value?.type}} </span>
                    </td>
                </tr>
                <tr>
                    <td [attr.colspan]="listeColonnes.length + 1">
                        <strong>{{'@loyers.colRemarque' | translate}} : </strong>
                        {{loyer?.sRemarques}}
                    </td>
                </tr>
            </tbody>
        </table>

                        
        <!-- <table mat-table [dataSource]="data?.Loyers" class="w100 table-striped table-hover">
            
            <ng-container matColumnDef="sIdentifiant" sticky>
                <th mat-header-cell *matHeaderCellDef class="text-center"> {{'@loyers.colLogement' | translate}} </th>
                <th mat-header-cell *matCellDef="let loyer" class="text-center"> {{loyer.sIdentifiant}} </th>
            </ng-container>

            <ng-container *ngFor="let champRR of dataRRProp?.proprieteConfig?.champsAppartements | keyvalue: returnZero()"
                [matColumnDef]="champRR.key">
                <td mat-cell *matHeaderCellDef> {{champRR.value.FR | translateValeur: champRR.value.AN}} </td>
                <td mat-cell *matCellDef="let loyer">
                    <span *ngIf="loyer[champRR.key]"> {{loyer[champRR.key]}} </span>
                    <span *ngIf="loyer?.sJsonData && loyer?.sJsonData[champRR.key]"> {{loyer?.sJsonData[champRR.key]}} </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="listeColonnes"></tr>
            <tbody mat-row>
                <tr mat-row *matRowDef="let loyer; columns: listeColonnes;"></tr>
                <tr>
                    <td [colspan]="listeColonnes.length + 1">ZZZ</td>
                </tr>
            </tbody>
        </table> -->
       
    </div>
    <div class="table-mobile col mb-4">
        <div class="row align-items-end mb-2">
            <span class="col-auto mb-1">
                <button [disabled]="mobileLoyerActif - 1 < 0" (click)="goto(mobileLoyerActif - 1)" class="loyer-fleche">
                    <i class="fas fa-chevron-left fa-lg"></i>
                </button>
            </span>
            <div class="col">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <mat-form-field id="logement">
                            <mat-select [(ngModel)]="mobileLoyerActif">
                                <mat-option *ngFor="let loyer of (data?.Loyers || []); let idx = index" [value]="idx">
                                    Logement {{ loyer.sIdentifiant }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- <h5 class="titre-tableau text-center">Logement {{ mobileLoyer.sIdentifiant }}</h5> -->
            </div>
            <span class="col-auto mb-1">
                <button [disabled]="mobileLoyerActif + 1 >= data?.Loyers.length" (click)="goto(mobileLoyerActif + 1)" class="loyer-fleche">
                    <i class="fas fa-chevron-right fa-lg"></i>
                </button>
            </span>
        </div>
        <table class="table table-striped">
            <tbody>
                <!-- <tr>
                    <th style="width: 60%;">Logement</th>
                    <td style="width: 40%;">{{ mobileLoyer.sIdentifiant }}</td>
                </tr> -->
                <tr *ngFor="let champRR of data?.proprieteConfig?.champsAppartements | keyvalue: returnZero">
                    <th style="width: 60%;">
                        {{ champRR.value.FR | translateValeur: champRR.value.AN}}
                    </th>
                    <td>
                        <span *ngIf="mobileLoyer[champRR.key]">
                            {{ mobileLoyer[champRR.key] | rrFormatCol : champRR?.value?.type }}
                        </span>
                        <span *ngIf="mobileLoyer?.sJsonData && mobileLoyer?.sJsonData[champRR.key]"> 
                            {{ mobileLoyer?.sJsonData[champRR.key] | rrFormatCol : champRR?.value?.type }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th colspan="2">
                        Remarques
                        <br>
                        <span style="font-weight: normal;">{{ mobileLoyer?.sRemarques }}</span>
                    </th>
                </tr> 
            </tbody>
        </table>
    </div>
</div>

<div class="container">
    <!-- <h3 class="mb-3">
        Remarques sur les unités
    </h3><br> -->
    <!-- <div *ngFor="let loyer of data?.Loyers" class="bloqueRelarque"> 
        <h5>{{'@loyers.remarqueUnite' | translate}} {{loyer.sIdentifiant}}</h5>
        <p>{{loyer.sRemarques || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum id orci nisi. Cras eu tellus.'}}</p>
    </div> -->
</div>