<div>
    <div mat-dialog-content>
        <p class="titreConnexion">
            {{ '@connectez-vous' | translate }}
        </p>
    </div>
    <div mat-dialog-actions style="justify-content: center;">
        <button mat-button class="boutonConnexion" (click)="cliqueConnexion()">Connexion</button>
        <button mat-button class="boutonAnnuler" (click)="cliqueAnnuler()">Annuler</button>
    </div>
</div>
